import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const BackFormBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="123"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="0.5"
          y="9.16699"
          width="123"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="0.5"
          y="17.833"
          width="123"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <path
          d="M144 0.992188L134.992 6.24805C134.379 6.60565 134 7.26468 134 7.97461V18C134 19.0931 134.907 20 136 20H152C153.093 20 154 19.0931 154 18V7.97461C154 7.26468 153.621 6.60565 153.008 6.24805L144 0.992188ZM144 3.30859L151.893 7.91211L144 12.8223L136.107 7.91211L144 3.30859ZM144 5L141 8H143V10.3789L144 11L145 10.3789V8H147L144 5ZM136 10.2012L144 15.1777L152 10.2012V18H136V10.2012Z"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
