import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  card: {
    maxWidth: 456,
    width: '100%',
    position: 'relative',
  },
  cardContent: {
    padding: '48px 48px 24px',
  },
  signUpWrapper: {
    background: "no-repeat center url('/background.png')",
    display: 'grid',
    alignItems: 'center',
    // marginTop: 32,
    justifyContent: 'center',
    gridGap: 20,
    height: '100%',
  },
  // signUpWrapper: {
  //   background: "no-repeat center url('/background.png')",
  //   height: '100vh',

  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  backWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'fixed',
    top: 25,
    left: 40,
    columnGap: 5,
    cursor: 'pointer',
    textDecoration: 'none',
    '& h2': {
      margin: 0,
      padding: 0,
      color: '#264A67',
      fontSize: 20,
    },
  },
  btnWrapper: {
    display: 'grid',
    justifyContent: 'center',
    gridGap: 10,
    '& a': {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  more: {
    margin: '10px 0px',
    height: 30,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '5px',
    alignItems: 'center',
    '& .line': {
      width: 110,
      height: 2,
      background: '#C7D6E6',
      display: 'block',
      position: 'relative',
    },
  },
  googleBtn: {
    '& .googlesvgtitle': {
      fill: '#353E47',
      transition: 'all 0.3s linear',
    },
    '& .googlesvgbg': {
      fill: '#FFFFFF',
      transition: 'all 0.3s linear',
    },
    '& .googlesvglogo': {
      'mask-type': 'alpha',
    },
    '&:hover': {
      cursor: 'pointer',
      '& .googlesvgbg': {
        fill: '#3F92D1',
      },
      '& .googlesvgtitle': {
        fill: '#FFFFFF',
      },
    },
  },
  yandexBtn: {
    '& .yandex__bg': {
      fill: 'white',
      transition: 'all 0.3s linear',
    },
    '& .yandex__title': {
      fill: '#353E47',
      transition: 'all 0.3s linear',
    },
    '&:hover': {
      cursor: 'pointer',
      '& .yandex__bg': {
        fill: '#3F92D1',
      },
      '& .yandex__title': {
        fill: '#FFFFFF',
      },
    },
  },
  title: {
    fontWeight: 900,
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  form: {
    textAlign: 'center',
  },
  passwordWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  forgotPassword: {
    alignSelf: 'flex-end',
  },
  button: {
    marginTop: 38,
  },
  singUpLink: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'center',
    color: '#3F92D1',
    textDecoration: 'none',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #3F92D1',
      borderRadius: 5,
    },
  },
  signUpButton: {
    position: 'relative',
    padding: '8px 12px',
    zIndex: 21,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 9,
      right: -10,
      height: 24,
      width: 24,
      backgroundColor: 'inherit',
      zIndex: 10,
      transform: 'rotateZ(45deg)',
      borderRadius: 4,
      '-webkit-transform': 'rotate(45deg) skew(16deg, 16deg)',
    },
  },
  signUpButtonLabel: {
    zIndex: 20,
  },
}));
