import { v4 as uuid } from 'uuid';
import { IBlocks, IComment } from '../editors';

export enum EditableBlockSettingsActionEnum {
  setBlocksList = 'SET_BLOCKS_LIST',
  setSelectedPageId = 'SET_SELECTED_PAGE_ID',
  setEditorShow = 'SET_EDITOR_SHOW',
  changeTitle = 'CHANGE_TITLE',
  setBlockIdx = 'SET_BLOCK_IDX',
  setRemoveShow = 'SET_REMOVE_SHOW',
  setEditBlock = 'SET_EDIT_BLOCK',
  showCreateProjectModal = 'SHOW_CREATE_PROJECT_MODAL',
  hideCreateProjectModal = 'HIDE_CREATE_PROJECT_MODAL',
  setSidebarRollUp = 'SET_SITEBAR_ROLLUP',
  setExportReport = 'SET_EXPORT_REPORT',
}

interface IEditableBlockSettings {
  blocksList: IBlocks[];
  selectedPageId: string | null;
  editorShow: boolean;
  title: string;
  blockIdx: number | null;
  showRemove: boolean;
  editBlock: IBlocks | null;
  comments: IComment[];
  createProjectModal: boolean;
  sidebarRollup: boolean;
  exportReport: boolean;
}

export const blocksList: IBlocks[] = [
  {
    id: uuid(),
    type: 'HEADER',
    title: 'Шапка',
    index: 1,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'FOOTER',
    title: 'Футер',
    index: 1,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'PICTURE_AND_TEXT',
    title: 'Картинка + текст блок',
    index: 2,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TEXT_AND_PICTURE',
    title: 'Текст блок + Картинка',
    index: 9,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TEXT',
    title: 'Текстовый блок',
    index: 10,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '2_COLUMNS_TEXT',
    title: 'Текстовый блок 2 колонки',
    index: 6,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '3_COLUMNS_TEXT',
    title: 'Текстовый блок 3 колонки',
    index: 5,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '5_COLUMNS_TEXT',
    title: 'Текстовый блок 5 колонк',
    index: 5,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'PICTURE',
    title: 'Блок с картинкой',
    index: 3,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'POPUP_WIDGETS',
    title: 'Попап, виджет',
    index: 30,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'CONTACTS',
    title: 'Контакты',
    index: 29,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TIMELINE',
    title: 'Таймлайн',
    index: 27,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TABLES',
    title: 'Таблица',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'MAP',
    title: 'Карта',
    index: 24,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '2_COLUMNS_PICTURE',
    title: 'Блок с картинкой 2 колонки',
    index: 8,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '3_COLUMNS_PICTURE',
    title: 'Блок с картинкой 3 колонки',
    index: 7,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'PICTURE_AND_ACTION',
    title: 'Картинка + Действие',
    index: 23,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TEXT_AND_ACTION',
    title: 'Текст + Действие',
    index: 23,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'CARD_ITEM',
    title: 'Карточка товара',
    index: 23,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '3_COLUMNS_CARDS',
    title: 'Товары',
    index: 23,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'PARTNERS',
    title: 'Партнеры',
    index: 25,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'MIXED',
    title: 'Смешанный контент',
    index: 11,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'SEARCH',
    title: 'Поиск',
    index: 12,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'BACK_FORM',
    title: 'Форма обратной связи',
    index: 22,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'SEARCH_RESULT',
    title: 'Результаты поиска',
    index: 13,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'CARUSEL',
    title: 'Карусель, слайдшоу',
    index: 14,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'CARUSEL_TEXT',
    title: 'Карусель, слайдшоу с текстом',
    index: 14,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: '3_TO_2_ELEMENTS',
    title: 'Элементы 3х2',
    index: 14,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TEAMS',
    title: 'Команда, клиенты',
    index: 16,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'DOWNLOAD_FILE',
    title: 'Скачать файл',
    index: 17,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'VIDEO',
    title: 'Видео',
    index: 18,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'AUTH',
    title: 'Авторизация',
    index: 19,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'REGISTRATION',
    title: 'Регистрация',
    index: 20,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'SOCIAL',
    title: 'Мы в соц. сетях',
    index: 21,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'FORM_WITH_VIDEO',
    title: 'Форма с Видео',
    index: 21,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'FORM_WITH_PICTURE',
    title: 'Форма с Картинкой',
    index: 21,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'FORM_WITH_TEXT',
    title: 'Форма с Текстом',
    index: 21,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'FAQ',
    title: 'FAQ',
    index: 21,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'CALL_ACTION',
    title: 'Призыв к действию',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TEXT_WITH_VIDEO',
    title: 'Текст с Видео',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'VIDEO_WITH_TEXT',
    title: 'Видео с Текст',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'BLOGS',
    title: 'Статьи',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'GOODS_AND_SERVICE',
    title: 'Товары и услуги',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'LIST_BLOCK',
    title: 'Список',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'QUOTE_AND_REVIEW',
    title: 'Цитата и отзыв',
    index: 26,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'TABLES_2',
    title: 'Календарь',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'FILTERS',
    title: 'Фильтр',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'QUIZ_OR_SERVEY',
    title: 'Квиз или опрос',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'COMMENTS',
    title: 'Комментарий',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'EMPTY',
    title: 'Пустой блок',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'UNKNOWN',
    title: 'Нераспознанный блок',
    index: 28,
    comments: [],
    active: false,
  },
  {
    id: uuid(),
    type: 'CUSTOM',
    title: 'Кастомный блок',
    index: 28,
    comments: [],
    active: false,
  },
];

const IS: IEditableBlockSettings = {
  blocksList,
  selectedPageId: null,
  editorShow: false,
  title: 'Добавить блок',
  blockIdx: null,
  showRemove: false,
  editBlock: null,
  comments: [],
  createProjectModal: false,
  sidebarRollup: true,
  exportReport: false,
};
interface EditableBlockReducerActionSetBlocksList {
  type: EditableBlockSettingsActionEnum.setBlocksList;
  payload: IBlocks[];
}

interface EditableBlockReducerActionSetEditBlock {
  type: EditableBlockSettingsActionEnum.setEditBlock;
  payload: IBlocks;
}

interface EditableBlockReducerActionSetSelectedPageId {
  type: EditableBlockSettingsActionEnum.setSelectedPageId;
  payload: string;
}
interface EditableBlockReducerActionSetEditorShow {
  type: EditableBlockSettingsActionEnum.setEditorShow;
  payload: boolean;
}
interface EditableBlockReducerActionSetRemoveShow {
  type: EditableBlockSettingsActionEnum.setRemoveShow;
  payload: boolean;
}
interface EditableBlockReducerActionChangeTitle {
  type: EditableBlockSettingsActionEnum.changeTitle;
  payload: string;
}
interface EditableBlockReducerActionSetBlockIdx {
  type: EditableBlockSettingsActionEnum.setBlockIdx;
  payload: number;
}

interface EditableBlockReducerActionShowCreateProjectModal {
  type: EditableBlockSettingsActionEnum.showCreateProjectModal;
}
interface EditableBlockReducerActionHideCreateProjectModal {
  type: EditableBlockSettingsActionEnum.hideCreateProjectModal;
}
interface EditableBlockReducerActionSidebar {
  type: EditableBlockSettingsActionEnum.setSidebarRollUp;
  payload: boolean;
}
interface EditableBlockReducerActionExportReport {
  type: EditableBlockSettingsActionEnum.setExportReport;
  payload: boolean;
}

export type EditableBlockReducerActionTypes =
  | EditableBlockReducerActionSetBlocksList
  | EditableBlockReducerActionSetSelectedPageId
  | EditableBlockReducerActionSetEditorShow
  | EditableBlockReducerActionChangeTitle
  | EditableBlockReducerActionSetBlockIdx
  | EditableBlockReducerActionSetRemoveShow
  | EditableBlockReducerActionSetEditBlock
  | EditableBlockReducerActionShowCreateProjectModal
  | EditableBlockReducerActionHideCreateProjectModal
  | EditableBlockReducerActionSidebar
  | EditableBlockReducerActionExportReport;

export const EditableBlockReducer = (
  state = IS,
  action: EditableBlockReducerActionTypes,
): IEditableBlockSettings => {
  switch (action.type) {
    case EditableBlockSettingsActionEnum.setBlocksList:
      return { ...state, blocksList: action.payload };
    case EditableBlockSettingsActionEnum.setSelectedPageId:
      return { ...state, selectedPageId: action.payload };
    case EditableBlockSettingsActionEnum.setEditorShow:
      return { ...state, editorShow: action.payload };
    case EditableBlockSettingsActionEnum.changeTitle:
      return { ...state, title: action.payload };
    case EditableBlockSettingsActionEnum.setBlockIdx:
      return { ...state, blockIdx: action.payload };
    case EditableBlockSettingsActionEnum.setRemoveShow:
      return { ...state, showRemove: action.payload };
    case EditableBlockSettingsActionEnum.setEditBlock:
      return { ...state, editBlock: action.payload };
    case EditableBlockSettingsActionEnum.showCreateProjectModal:
      return { ...state, createProjectModal: true };
    case EditableBlockSettingsActionEnum.hideCreateProjectModal:
      return { ...state, createProjectModal: false };
    case EditableBlockSettingsActionEnum.setSidebarRollUp:
      return { ...state, sidebarRollup: action.payload };
    case EditableBlockSettingsActionEnum.setExportReport:
      return { ...state, exportReport: action.payload };
    default:
      return state;
  }
};
