import React from 'react';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import useStyles from './styles/registrationStep';
import cx from 'clsx';

export const Step = () => {
  const classes = useStyles();
  const step = useTypeSelector((s) => s.registration.step);
  return (
    <div className={classes.wrapper}>
      <div className={classes.steps}>
        <div
          className={cx(
            classes.step,
            step === 1 && classes.activeStep,
            step > 1 && classes.successStep,
          )}
        >
          <span>1</span>
        </div>
        <div
          className={cx(
            classes.step,
            step === 2 && classes.activeStep,
            step > 2 && classes.successStep,
          )}
        >
          <span>2</span>
        </div>
        <div
          className={cx(
            classes.step,
            step === 3 && classes.activeStep,
            step > 3 && classes.successStep,
          )}
        >
          <span>3</span>
        </div>
        <div
          className={cx(
            classes.step,
            step === 4 && classes.activeStep,
            step > 4 && classes.successStep,
          )}
        >
          <span>4</span>
        </div>
      </div>
    </div>
  );
};
