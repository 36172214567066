import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const MapBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4971_6774)">
          <path
            d="M78.0001 3.66699C74.7784 3.66699 72.1667 6.27866 72.1667 9.50033C72.1667 12.8053 75.8367 17.6837 77.3551 19.5595C77.6884 19.9712 78.3117 19.9712 78.6451 19.5595C80.1634 17.6837 83.8334 12.8053 83.8334 9.50033C83.8334 6.27866 81.2217 3.66699 78.0001 3.66699ZM78.0001 11.5837C76.8492 11.5837 75.9167 10.6512 75.9167 9.50033C75.9167 8.34949 76.8492 7.41699 78.0001 7.41699C79.1509 7.41699 80.0834 8.34949 80.0834 9.50033C80.0834 10.6512 79.1509 11.5837 78.0001 11.5837Z"
            fill={blockDefaultColor}
          />
          <path
            d="M2 15.5L82 23L91.25 12.25M100.5 1.5L91.25 12.25M91.25 12.25L133.5 20.1834M148.5 23L133.5 20.1834M133.5 20.1834L135 1.5"
            stroke={blockDefaultColor}
            strokeWidth="2"
          />
        </g>
        <rect
          x="1"
          y="1"
          width="154"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <defs>
          <clipPath id="clip0_4971_6774">
            <rect width="156" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
