import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3F92D1',
      light: '#D1DCE7',
    },
    text: {
      primary: '#353E47',
      secondary: '#6F8194',
    },
    background: {
      default: '#f3f3f3',
    },
  },
  typography: {
    h1: {
      fontSize: 36,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        padding: '0 32px 24px',
        '@media (max-width: 498px)': {
          padding: '0 16px 24px',
        },
      },
    },
  },
});

export default theme;
