import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const Tables2Block = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M153 15H53V17H153V15ZM54 16V3H52V16H54ZM55 2H103V0H55V2ZM103 2H153V0H103V2ZM102 1V21H104V1H102ZM154 3V9H156V3H154ZM154 9V14H156V9H154ZM3 10H155V8H3V10ZM101 22H53V24H101V22ZM53 22H3V24H53V22ZM52 16V23H54V16H52ZM2 21V16H0V21H2ZM2 16V11H0V16H2ZM53 15H1V17H53V15ZM3 8C1.34315 8 0 9.34315 0 11H2C2 10.4477 2.44772 10 3 10V8ZM153 17C154.657 17 156 15.6569 156 14H154C154 14.5523 153.552 15 153 15V17ZM3 22C2.44772 22 2 21.5523 2 21H0C0 22.6569 1.34315 24 3 24V22ZM153 2C153.552 2 154 2.44772 154 3H156C156 1.34314 154.657 0 153 0V2ZM102 21C102 21.5523 101.552 22 101 22V24C102.657 24 104 22.6569 104 21H102ZM54 3C54 2.44772 54.4477 2 55 2V0C53.3431 0 52 1.34315 52 3H54Z"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
