import { makeStyles } from '@material-ui/core';
export const ExampleLinkStyles = makeStyles(() => ({
  example_link: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    columnGap: 5,
    '& span': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
    },
  },
}));
