import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const RegistrationBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 3.75C8.70435 3.75 7.94129 4.06607 7.37868 4.62868C6.81607 5.19129 6.5 5.95435 6.5 6.75C6.5 7.54565 6.81607 8.30871 7.37868 8.87132C7.94129 9.43393 8.70435 9.75 9.5 9.75C10.2956 9.75 11.0587 9.43393 11.6213 8.87132C12.1839 8.30871 12.5 7.54565 12.5 6.75C12.5 5.95435 12.1839 5.19129 11.6213 4.62868C11.0587 4.06607 10.2956 3.75 9.5 3.75ZM14 10.5C11.525 10.5 9.5 12.525 9.5 15C9.5 17.475 11.525 19.5 14 19.5C16.475 19.5 18.5 17.475 18.5 15C18.5 12.525 16.475 10.5 14 10.5ZM8.78809 12.0352C6.41809 12.2317 2.75 13.347 2.75 15.375V17.25H8.44092C8.15892 16.5548 8 15.7965 8 15C8 13.92 8.28859 12.9104 8.78809 12.0352ZM13.25 12.75H14.75V14.25H16.25V15.75H14.75V17.25H13.25V15.75H11.75V14.25H13.25V12.75Z"
          fill={blockDefaultColor}
        />
        <rect
          x="27"
          y="1"
          width="59"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="96"
          y="1"
          width="59"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
