import { makeStyles } from '@material-ui/core';

export const TemplateCardStyle = makeStyles(() => ({
  project_card_wrapper: {
    border: '1px solid #C7D6E5',
    borderRadius: 10,
    width: 282,
    height: 196,
    display: 'grid',
    transition: 'all 0.5s linear',
    '& #sitemap-menu': {
      position: 'relative',
      top: 20,
      right: 130,
    },
    '@media (max-width: 667px)': {
      width: '100% !important',
    },
  },
  error: {
    border: '1px solid #EA4848',
  },
  in_process: {
    border: '1px solid #F2994A',
  },
  btn_group: {
    display: 'flex',
    justifyContent: 'space-evenly',
    opacity: 0,
    transition: 'all 0.3s linear',
    width: '100%',
  },
  show_btn_group: {
    opacity: 1,
  },
  project_card_image: {
    height: 140,
    borderBottom: '1px solid #C7D6E5',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 12,
    boxSizing: 'border-box',
  },
  project_card_captions: {
    height: 'auto',
    padding: '8px 12px 10px',
    '& h4': {
      margin: 0,
      fontWeight: 400,
      fontSize: 16,
      color: '#353E47',
    },
  },
  project_card__desc: {
    display: 'flex',
    margin: 0,
    fontWeight: 400,
    fontSize: 14,
    color: '#6F8194',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    display: 'block',
    width: 282,
    height: 88,
    cursor: 'pointer',
    alignSelf: 'flex-start',
  },
  title: {
    position: 'relative',
    color: '#EA4848',
    '&:hover': {
      '&::after': {
        minWidth: 290,
        content: 'attr(data-title)',
        position: 'absolute',
        bottom: 20,
        right: 10,
        background: '#FCFEFF',
        boxShadow:
          '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        padding: 18,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        color: '#353E47',
        zIndex: 999999,
      },
    },
  },
  title_bottom: {
    position: 'relative',
    '&:hover': {
      '&::after': {
        minWidth: 290,
        content: 'attr(data-title)',
        position: 'absolute',
        top: 20,
        left: 10,
        background: '#FCFEFF',
        boxShadow:
          '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        padding: 18,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        color: '#353E47',
        zIndex: 999999,
      },
    },
  },
  name: {
    width: 250,
    textOverflow: 'ellipsis',
    height: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
