
import { makeStyles } from '@material-ui/core';

export const  NewButtonStyles = makeStyles(() => ({
  new_button: {
    display: 'inline-flex',
    width: 166,
    height: 38,
    backgroundColor: '#FFFFFF',
    border: '1px solid #3090E8',
    borderRadius: '0px 5px 5px 0px',
    outline: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3090E8',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    columnGap: 10,
    overflow: 'hidden',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    '&:focus': {
      outline: '2px solid #3090E8',
    },
  },
  disabled: {
    border: '1px solid #A1AEBB',
    color: '#A1AEBB',
    backgroundColor: '#F2F2F2',
    // filter: 'grayscale(.4)'
    "& svg": {
      fill: '#A1AEBB',
    }
  }
}));
