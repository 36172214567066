import React, { memo, useCallback, useEffect } from 'react';
import useStyles from './styles';
import cx from 'clsx';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface IClosePopupProps {
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
}

export const ClosePopup = memo((props: IClosePopupProps) => {
  const cc = useStyles();

  const KeybordDown = useCallback(
    (e: KeyboardEvent) => {
      e.code === 'Escape' && props.onClick();
    },
    [props],
  );

  useEffect(() => {
    document.addEventListener('keydown', KeybordDown, true);
    return () => {
      document.removeEventListener('keydown', KeybordDown);
    };
  }, [KeybordDown]);

  return (
    <div
      title="Закрыть (Esc)"
      className={cx(props.className)}
      style={props.style}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx(props.className, cc.close)}
        onClick={props.onClick}
      >
        <path
          d="M4.99007 3.99067C4.79114 3.99072 4.59674 4.0501 4.43173 4.16121C4.26672 4.27233 4.1386 4.43013 4.06374 4.61444C3.98889 4.79875 3.9707 5.0012 4.0115 5.1959C4.05231 5.39061 4.15025 5.56872 4.29281 5.70747L10.5858 12.0004L4.29281 18.2934C4.19684 18.3855 4.12021 18.4959 4.06743 18.618C4.01464 18.7402 3.98676 18.8716 3.9854 19.0046C3.98405 19.1377 4.00926 19.2697 4.05954 19.3928C4.10983 19.516 4.1842 19.6279 4.27827 19.722C4.37235 19.8161 4.48426 19.8904 4.60743 19.9407C4.73061 19.991 4.86259 20.0162 4.99563 20.0149C5.12867 20.0135 5.2601 19.9856 5.38223 19.9328C5.50436 19.8801 5.61473 19.8034 5.70687 19.7075L11.9998 13.4145L18.2928 19.7075C18.385 19.8034 18.4953 19.8801 18.6175 19.9328C18.7396 19.9856 18.871 20.0135 19.0041 20.0149C19.1371 20.0162 19.2691 19.991 19.3922 19.9407C19.5154 19.8904 19.6273 19.8161 19.7214 19.722C19.8155 19.6279 19.8899 19.516 19.9401 19.3928C19.9904 19.2697 20.0156 19.1377 20.0143 19.0047C20.0129 18.8716 19.985 18.7402 19.9323 18.618C19.8795 18.4959 19.8028 18.3855 19.7069 18.2934L13.4139 12.0004L19.7069 5.70747C19.8514 5.567 19.9501 5.38616 19.99 5.18864C20.03 4.99112 20.0093 4.78614 19.9308 4.60055C19.8523 4.41497 19.7195 4.25744 19.5499 4.14861C19.3803 4.03978 19.1817 3.98473 18.9803 3.99067C18.7205 3.99841 18.4739 4.10699 18.2928 4.2934L11.9998 10.5864L5.70687 4.2934C5.61368 4.19761 5.50224 4.12146 5.37912 4.06947C5.25601 4.01747 5.12372 3.99068 4.99007 3.99067Z"
          fill="#EB5757"
        />
      </svg>
    </div>
  );
});

export default ClosePopup;
