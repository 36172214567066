import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const SearchBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.75 4.5C7.85939 4.5 5.5 6.85939 5.5 9.75C5.5 12.6406 7.85939 15 10.75 15C12.061 15 13.2588 14.511 14.1807 13.7109L14.5 14.0303V15L18.6895 19.1895C19.1035 19.6035 19.7755 19.6035 20.1895 19.1895C20.6035 18.7755 20.6035 18.1035 20.1895 17.6895L16 13.5H15.0303L14.7109 13.1807C15.511 12.2588 16 11.061 16 9.75C16 6.85939 13.6406 4.5 10.75 4.5ZM10.75 6C12.83 6 14.5 7.67005 14.5 9.75C14.5 11.83 12.83 13.5 10.75 13.5C8.67005 13.5 7 11.83 7 9.75C7 7.67005 8.67005 6 10.75 6Z"
          fill={blockDefaultColor}
        />
        <rect
          x="0.5"
          y="0.5"
          width="155"
          height="23"
          rx="3.5"
          stroke={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
