import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const CommentsBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.07 18.28C7.5 17.38 10.12 16.5 12 16.5C13.88 16.5 16.51 17.38 16.93 18.28C15.57 19.36 13.86 20 12 20C10.14 20 8.43 19.36 7.07 18.28ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83V16.83ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z"
          fill={blockDefaultColor}
        />
        <mask id="path-2-inside-1_6886_22337" fill="white">
          <path d="M34.3692 8C34.3692 4.68629 37.0555 2 40.3692 2H150C153.314 2 156 4.68629 156 8V16C156 19.3137 153.314 22 150 22H22H24.3692C29.8921 22 34.3692 17.5228 34.3692 12V8Z" />
        </mask>
        <path
          d="M40.3692 4H150V0H40.3692V4ZM154 8V16H158V8H154ZM150 20H22V24H150V20ZM22 24H24.3692V20H22V24ZM36.3692 12V8H32.3692V12H36.3692ZM24.3692 24C30.9966 24 36.3692 18.6274 36.3692 12H32.3692C32.3692 16.4183 28.7875 20 24.3692 20V24ZM154 16C154 18.2091 152.209 20 150 20V24C154.418 24 158 20.4183 158 16H154ZM150 4C152.209 4 154 5.79086 154 8H158C158 3.58172 154.418 0 150 0V4ZM40.3692 0C35.951 0 32.3692 3.58172 32.3692 8H36.3692C36.3692 5.79086 38.1601 4 40.3692 4V0Z"
          fill={blockDefaultColor}
          mask="url(#path-2-inside-1_6886_22337)"
        />
        <rect
          x="40"
          y="7"
          width="110"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="40"
          y="14"
          width="51"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
