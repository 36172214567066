import React, { memo, useState } from 'react';

import { TemplateCardStyle } from './TemplateCardStyle';
import { IDraftTempCategoryItem } from '../../../api/draftTempCategory';
import { NewButton } from '../../Button/NewButton';

export interface ITemplateCardProps extends IDraftTempCategoryItem {
  className?: string;
  useNow: (val: IDraftTempCategoryItem) => void;
  goToTemp: (val: IDraftTempCategoryItem) => void;
}

export const TemplateCard = memo((props: ITemplateCardProps) => {
  const cc = TemplateCardStyle();
  const [show,setShow]=useState(false)
  return (
    <div className={`${cc.project_card_wrapper} `} onMouseOver={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
      <div
        className={cc.project_card_image}
        style={{
          backgroundImage: `url(data:image/png;base64,${props.preview})`,
        }}
      >
        <div
          className={cc.link}
          onClick={()=>props.goToTemp(props)}
          onDoubleClick={(e) => {
            e.preventDefault();
          }}
        ></div>
        <div className={`${cc.btn_group} ${show ? cc.show_btn_group : ''}`}>
          <NewButton caption="Использовать" onClick={() => props.useNow(props)} style={{padding: '0 10px', width: '132px'}}/>
          <NewButton caption="Посмотреть" onClick={() => props.goToTemp(props)} style={{padding: '0 10px', width: '116px'}}/>
        </div>
      </div>
      {props && (
        <div
          onClick={()=>props.goToTemp(props)}
          className={cc.project_card_captions}
          style={{ cursor: 'pointer' }}
        >
          <h4 className={cc.name}>{props.name}</h4>
          <div className={cc.project_card__desc}>
            <span>{props.category_name}</span>
            <span>{props.pages_count} Страниц</span>
          </div>
        </div>
      )}
    </div>
  );
});
