import React, { FC, useCallback, useEffect, useState } from 'react';
import { TemplateButtonStyle } from './TemplateButtonStyle';
import { ReactComponent as LongArrowLeft } from '../../assets/icons/long_arrow_left.svg';
import { ReactComponent as LongArrowRight } from '../../assets/icons/long_arrow_right.svg';
import { Devider } from '../../Layout/bar/NewEditorBar/devider';
import { getDraftTemp } from '../../api/draftTemp';
import { useLocation, useRoute } from 'wouter';
import { IDraftTempCategoryItem } from '../../api/draftTempCategory';

export const TemplateButton: FC = () => {
  const cc = TemplateButtonStyle();
  const [, params] = useRoute('/template/:id');
  const [, setLocation] = useLocation();
  const [tempList, setTempList] = useState<IDraftTempCategoryItem[]>();
  const color = 'black';

  const getNext = useCallback(() => {
    if (tempList && params) {
      const idx = tempList.findIndex((item) => item.id === params.id);
      const find = tempList.find((_, index) => index === idx + 1);
      if (find) {
        setLocation(`/template/${find.id}`);
        return;
      }

      const first = tempList.find((_, index) => index === 0);
      first && setLocation(`/template/${first.id}`);
    }
  }, [tempList, params, setLocation]);

  const getBack = useCallback(() => {
    if (tempList && params) {
      const idx = tempList.findIndex((item) => item.id === params.id);
      const find = tempList.find((_, index) => index === idx - 1);
      if (find) {
        setLocation(`/template/${find.id}`);
        return;
      }

      const last = tempList.find((_, index) => index === tempList.length - 1);
      last && setLocation(`/template/${last.id}`);
    }
  }, [tempList, params, setLocation]);

  const init = useCallback(async () => {
    const { data } = await getDraftTemp();
    data && setTempList(data);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className={cc.tempBtnWrapper}>
      <div className={cc.tempBtn} onClick={getBack}>
        <LongArrowLeft fill={color} style={{ position: 'relative' }} />
        <span>Предыдущий</span>
      </div>
      <Devider color="gray" />
      <div className={cc.tempBtn} onClick={getNext}>
        <span>Следующий</span>
        <LongArrowRight fill={color} style={{ position: 'relative' }} />
      </div>
    </div>
  );
};
