import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const FormWithPictureBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0735 6.43934C19.3441 6.15804 19.7112 6 20.094 6C20.4767 6 20.8438 6.15804 21.1144 6.43934C21.3851 6.72064 21.5371 7.10218 21.5371 7.5C21.5371 7.89782 21.3851 8.27936 21.1144 8.56066C20.8438 8.84196 20.4767 9 20.094 9C19.7112 9 19.3441 8.84196 19.0735 8.56066C18.8028 8.27936 18.6508 7.89782 18.6508 7.5C18.6508 7.10218 18.8028 6.72064 19.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M21.5371 16.5L26.5882 10.5L32 18H14L17.9292 12.75L21.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="44.6667"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="55.1667"
          y="0.5"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="9.16699"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="17.833"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <g clipPath="url(#clip0_6812_22973)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.333 0H109.333V24H156.333V0ZM125.493 7.20671L133 2.82682L140.506 7.20671C141.018 7.50471 141.333 8.0539 141.333 8.64551V17C141.333 17.9109 140.577 18.6667 139.667 18.6667H126.333C125.422 18.6667 124.667 17.9109 124.667 17V8.64551C124.667 8.0539 124.982 7.50471 125.493 7.20671ZM139.577 8.59342L133 4.75716L126.423 8.59342L133 12.6852L139.577 8.59342ZM130.5 8.66667L133 6.16667L135.5 8.66667H133.833V10.6491L133 11.1667L132.167 10.6491V8.66667H130.5ZM133 14.6481L126.333 10.501V17H139.667V10.501L133 14.6481Z"
            fill={blockDefaultColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6812_22973">
            <rect x="109.333" width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
