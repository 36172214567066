import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { requestPassportReset } from '../../../api/users.api';
import { Link } from 'wouter';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 900,
    textAlign: 'center',
  },
  form: {
    textAlign: 'center',
  },
  button: {
    maxWidth: 240,
    width: '100%',
    marginTop: 38,
    cursor: 'pointer',
  },
  notificationMessage: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'normal',
    textAlign: 'center',
  },
  forgotSubtitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backButton: {
    position: 'absolute',
    top: 18,
    right: 8,
    color: '#BDBDBD',
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export function RequestRegistrationForm() {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
      .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Некорректный email адрес'
        )
        .required('Email обязателен'),
    }),
    async onSubmit(values) {
      const { ok } = await requestPassportReset(values.email);
      setSuccess(ok);
    },
  });

  if (success) {
    return (
      <Typography variant="body1" className={classes.notificationMessage}>
        {`Сообщение с восстановлением пароля\nотправлено на ваш адрес`}
      </Typography>
    );
  }

  return (
    <>
      <Link href="/login">
        <Button className={classes.backButton} variant="text" color="primary">
          Назад
        </Button>
      </Link>
      <Typography variant="h1" className={classes.title}>
        Забыли пароль?
      </Typography>
      <Typography className={classes.forgotSubtitle}>
        введите e-mail адрес,
        <br />
        указанный при регистрации
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="email"
          type="email"
          fullWidth
          margin="dense"
          label="Email"
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
        >
          Сбросить
        </Button>
      </form>
    </>
  );
}
