import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const TimelineBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 4.5C12.8667 4.5 9.5 7.86675 9.5 12C9.5 16.1333 12.8667 19.5 17 19.5C21.1333 19.5 24.5 16.1333 24.5 12C24.5 7.86675 21.1333 4.5 17 4.5ZM17 6C20.3226 6 23 8.67741 23 12C23 15.3226 20.3226 18 17 18C13.6774 18 11 15.3226 11 12C11 8.67741 13.6774 6 17 6ZM16.25 7.5V12.3105L19.4697 15.5303L20.5303 14.4697L17.75 11.6895V7.5H16.25Z"
          fill={blockDefaultColor}
        />
        <rect
          x="30"
          y="11"
          width="117"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="30"
          y="9"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="49"
          y="9"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="68"
          y="9"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="87"
          y="9"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="106"
          y="9"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="125"
          y="9"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <path
          d="M146.586 12L145 13.5858V10.4142L146.586 12Z"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="1"
          y="1"
          width="154"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
