import html2canvas from 'html2canvas';
import moment from 'moment';
import wm from '../assets/watermark.png';

export interface toPNGResponse {
  base: string;
  width: number;
  height: number;
  canvas: HTMLCanvasElement;
  maxW?: number;
  maxH?: number;
}

const generateData = (canvas: any, filename: string, onlyGenerate: boolean) => {
  const tmpLink = document.createElement('a');
  tmpLink.target = '_blank';
  tmpLink.download = `${filename}_${moment().format('DD.MM.YYYY_HH:mm')}.png`;
  const base = canvas.toDataURL('image/png;base64');
  if (!onlyGenerate) {
    tmpLink.href = base;
    tmpLink.click();
  }
  return { base, width: canvas.width, height: canvas.height, canvas };
};

export const toPNG = async (
  ref: any,
  filename: string,
  onlyGenerate = false,
  scale: number = 1,
  watermark: boolean = true,
): Promise<toPNGResponse> =>
  new Promise((resolve, reject) => {
    if (!watermark) {
      html2canvas(ref, {
        width: ref.scrollWidth,
        height: ref.scrollHeight,
        windowWidth: ref.innerWidth,
        windowHeight: ref.innerHeight,
        // removeContainer: true,
        useCORS: true,
        logging: true,
        allowTaint: true,
        onclone: async (cloneDoc) => {
          const elem = cloneDoc.getElementById('editorContent');
          elem?.setAttribute(
            'style',
            `transform: scale(${scale + (scale - 1) * -1})`,
          );
          await new Promise((resolve, ) => setTimeout(resolve, 2000))
        },
        // scrollX: ref.scrollWidth,
        // scrollY: ref.scrollHeight,
        // foreignObjectRendering: true,
      }).then((canvas: any): void => {
        resolve(generateData(canvas, filename, onlyGenerate));
      });
      return;
    }

    const top = 20;
    const left = 20;
    const img = new Image();
    img.src = wm;
    img.style.position = 'absolute';
    img.style.top = `${top}px`;
    img.style.left = `${left}px`;
    img.onload = () => {
      const rigthImg = new Image();
      rigthImg.src = wm;
      rigthImg.style.position = 'absolute';
      rigthImg.style.top = `${top}px`;
      rigthImg.style.right = `${left}px`;
      rigthImg.onload = () => {
        // ref?.appendChild(img); //WaterMark added
        // ref?.appendChild(rigthImg); //WaterMark added
        html2canvas(ref, {
          width: ref.scrollWidth,
          height: ref.scrollHeight,
          windowWidth: ref.innerWidth,
          windowHeight: ref.innerHeight,
          // removeContainer: true,
          useCORS: true,
          logging: true,
          allowTaint: true,
          onclone: (cloneDoc) => {
            const elem = cloneDoc.getElementById('editorContent');
            elem?.setAttribute(
              'style',
              `transform: scale(${scale + (scale - 1) * -1})`,
            );
          },
          // scrollX: ref.scrollWidth,
          // scrollY: ref.scrollHeight,
          // foreignObjectRendering: true,
        }).then((canvas: any): void => {
          resolve(generateData(canvas, filename, onlyGenerate));
        });
        // img && ref?.removeChild(img);
        // rigthImg && ref?.removeChild(rigthImg);
      };
    };
  });
