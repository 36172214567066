import React, { memo, useCallback, useEffect, useState } from 'react';
import { NewButtonStyle } from './styles/newbutton';
// import { Player } from '@lottiefiles/react-lottie-player';
// import animation from '../../assets/animation/sand_clock.json';
import cx from 'clsx';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  IconsOutline,
  IIconsOutlineTypes,
  ISDefColor,
  ISHoverColor,
} from '../IconOutliner';

export interface INewButtonProps {
  caption?: string;
  beforeIcon?: IIconsOutlineTypes;
  onClick?: (e:any) => void;
  afterIcon?: IIconsOutlineTypes;
  primary?: boolean;
  noBorder?: boolean;
  height?: number;
  loading?: boolean;
  style?: CSSProperties
  className?: string
}

export const NewButton = memo((props: INewButtonProps) => {
  const cc = NewButtonStyle();
  const [color, setColor] = useState('');

  const setHover = () => {
    if (!props.primary) {
      setColor(ISDefColor);
      return;
    }
    setColor(ISHoverColor);
  };

  const setLeave = useCallback(() => {
    if (!props.primary) {
      setColor(ISHoverColor);
      return;
    }
    setColor(ISDefColor);
  },[setColor,props.primary]);

  const click = useCallback(() => {
    if (!props.loading) {
      props.onClick && props.onClick({});
    }
  }, [props]);

  useEffect(() => {
    setLeave();
  }, [setLeave]);

  return (
    <button
      className={cx(
        cc.btn_wrapper,
        props.primary ? cc.btn_wrapper_primary : '',
        props.noBorder ? cc.noBorder : '',
        props.loading ? cc.loading : '',
        props.className ? props.className : ''
      )}
      onMouseOver={setHover}
      onMouseLeave={setLeave}
      onClick={click}
      style={{ height: props.height ? props.height : 40,...props.style, }}
    >
      {props.beforeIcon && (
        <>
          {!props.loading ? (
            <IconsOutline
              types={props.beforeIcon}
              width={24}
              height={24}
              className={cc.btn_icon}
              noHover
              defaultColor={color}
            />
          ) : (
            <IconsOutline
              types="loading"
              width={24}
              height={24}
              className={cc.btn_icon}
              noHover
              defaultColor={color}
            />
          )}
        </>
      )}
      <span className='newbtn_caption' style={{color}}>{props.caption}</span>
      {props.afterIcon && (
        <>
          {!props.loading ? (
            <IconsOutline
              types={props.afterIcon}
              width={24}
              height={24}
              className={cc.btn_icon}
              noHover
              defaultColor={color}
            />
          ) : (
            <IconsOutline
              types="loading"
              width={24}
              height={24}
              className={cc.btn_icon}
              noHover
              defaultColor={color}
            />
          )}
        </>
      )}
    </button>
  );
});
