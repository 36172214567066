import { makeStyles } from '@material-ui/core';
const color = '#3090E8';

export default makeStyles(() => ({
  copy_btn: {
    width: '100%',
    height: 40,
    border: `1px solid ${color}`,
    boxSizing: 'border-box',
    borderRadius: 5,
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    '& span': {
      fontSize: 20,
      textAlign: 'center',
      color,
    },
    '& svg': {
      '& path': {
        fill: color,
      },
    },
    '&:hover': {
      backgroundColor: color,
      '& span': {
        color: 'white',
      },
      '& svg': {
        '& path': {
          fill: 'white',
        },
      },
    },
  },
}));
