import { ISiteDraft } from './editors';

export enum FeedbackTypeEnum {
  BUG = 'BUG',
  QUESTION = 'QUESTION',
  OTHER = 'OTHER',
}
interface IFeebbackErrors {
  description: null | string;
}
export interface IFeedback {
  type: FeedbackTypeEnum;
  description: string;
  file: File[] | null;
  loading: boolean;
  errors: IFeebbackErrors;
  draftList: ISiteDraft[];
  project: string | null;
}

export enum FeedbackActionEnum {
  setType = 'SET_FEEDBACK_TYPE',
  setDescription = 'SET_FEEDBACK_DESCRIPTION',
  setFile = 'SET_FEEDBACK_FILE',
  addFile = 'ADD_FEEDBACK_FILE',
  deleteFile = 'DELETE_FEEDBACK_FILE',
  draftList = 'SET_FEEDBACK_DRAFTLIST',
  setLoading = 'SET_FEEDBACK_LOADING',
  setErrors = 'SET_FEEDBACK_ERRORS',
  setProject = 'SET_FEEDBACK_PROJECT',

  clear = 'CLEAR_FEEDBACK',
}

export interface IFeedbackAction {
  type: FeedbackActionEnum;
  payload: File[] | null | string | FeedbackTypeEnum | number;
}

const InitialState: IFeedback = {
  type: FeedbackTypeEnum.BUG,
  description: '',
  file: null,
  loading: false,
  draftList: [],
  project: null,
  errors: {
    description: null,
  },
};

export const FeedbackReducer = (
  state = InitialState,
  action: IFeedbackAction,
) => {
  switch (action.type) {
    case FeedbackActionEnum.setType:
      return { ...state, type: action.payload };
    case FeedbackActionEnum.setDescription:
      return { ...state, description: action.payload };
    case FeedbackActionEnum.setFile:
      return { ...state, file: action.payload };
    case FeedbackActionEnum.deleteFile:
      return {
        ...state,
        file: [
          //@ts-ignore
          ...Array.from(state.file).filter((i, idx) => idx !== action.payload),
        ],
      };
    case FeedbackActionEnum.addFile:
      //@ts-ignore
      return { ...state, file: [...state.file, ...action.payload] };
    case FeedbackActionEnum.setLoading:
      return { ...state, loading: action.payload };
    case FeedbackActionEnum.setErrors:
      return { ...state, errors: action.payload };
    case FeedbackActionEnum.clear:
      return InitialState;
    case FeedbackActionEnum.draftList:
      return { ...state, draftList: action.payload };
    case FeedbackActionEnum.setProject:
      return { ...state, project: action.payload };
    default:
      return state;
  }
};
