import React, { useEffect, useState } from 'react';
import useStyles from './styles/index';
import cx from 'clsx';

interface FeedbackErrorMessageProps {
  close: () => void;
  repeat: () => Promise<void>;
  errorMessage: string;
}

export const ErrorMessage = (props: FeedbackErrorMessageProps) => {
  const classes = useStyles();
  const { close, repeat, errorMessage } = props;
  const [message, setMessage] = useState('');

  useEffect(() => {
    const reg = /postIssue\.arg1/g;
    reg.test(errorMessage)
      ? setMessage('Поле комментария обязательно!')
      : setMessage(errorMessage);
  }, [errorMessage, message]);

  return (
    <div className={classes.messageBlock}>
      <h2>Упс :-\</h2>
      <p>
        Что-то пошло не так.
        <br />
        Попробуйте снова или
        <br />
        отправьте нам письмо на адрес
        <br />
        <a href="mailto:support@structura.app">support@structura.app</a>
      </p>
      <p className={classes.err}>{message}</p>
      <div className={classes.btnGroup}>
        <div className={cx(classes.btn, classes.blueBorderBtn)} onClick={close}>
          Закрыть
        </div>
        <div className={cx(classes.btn, classes.blueBtn)} onClick={repeat}>
          Отправить снова
        </div>
      </div>
    </div>
  );
};
