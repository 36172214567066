import { request } from './request';

interface IOAuthToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

interface IPasswordAuth {
  grant_type: 'password';
  username: string;
  password: string;
}

interface IRefreshTokenAuth {
  grant_type: 'refresh_token';
  refresh_token: string;
}

type GetOAuthToken = IPasswordAuth | IRefreshTokenAuth;

function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

export async function authorize(data: GetOAuthToken) {
  const f = new FormData();
  f.append('grant_type', data.grant_type);

  if (data.grant_type === 'password') {
    const { username, password } = data;
    f.append('username', username);
    f.append('password', password);
  }

  if (data.grant_type === 'refresh_token') {
    f.append('refresh_token', data.refresh_token);
  }
  try {

    const response = await request<IOAuthToken>('post', 'oauth/token', f);
    if (response.data) {
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
    }
    return response;
  } catch (e) {
    throw e
  }
}

export async function reAuthorize() {
  localStorage.removeItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  if (refreshToken) {
    const { error, ok } = await authorize({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });

    if (error) {
      if (error.response?.data?.error === 'unauthorized') {
        logout();
      }
    }

    return ok;
  }
}

export async function revokeToken() {
  const token = localStorage.getItem('access_token');
  const f = new FormData();
  if (token) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    f.append('token', token);
  }
  return request('post', 'oauth/revoke', f);
}
