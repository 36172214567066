import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const DownloadFileBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9998 2C10.4478 2 9.99984 2.448 9.99984 3V11H6.49984C6.36723 11 6.24005 11.0527 6.14629 11.1464C6.05252 11.2402 5.99984 11.3674 5.99984 11.5C5.99986 11.6326 6.05255 11.7598 6.14632 11.8535C6.15632 11.8637 6.16674 11.8735 6.17757 11.8828L11.283 16.6973L11.3162 16.7285C11.5013 16.9026 11.7458 16.9996 11.9998 17C12.2539 16.9996 12.4983 16.9026 12.6834 16.7285L12.6971 16.7168C12.7004 16.7129 12.7036 16.709 12.7069 16.7051L17.8104 11.8926C17.8205 11.8839 17.8303 11.8747 17.8397 11.8652L17.8475 11.8594C17.8495 11.8574 17.8514 11.8555 17.8534 11.8535C17.9471 11.7598 17.9998 11.6326 17.9998 11.5C17.9998 11.3674 17.9472 11.2402 17.8534 11.1464C17.7596 11.0527 17.6324 11 17.4998 11H13.9998V3C13.9998 2.448 13.5518 2 12.9998 2H11.9998H10.9998ZM2.99984 20C2.86733 19.9981 2.73576 20.0226 2.6128 20.072C2.48983 20.1214 2.37791 20.1948 2.28354 20.2878C2.18917 20.3809 2.11423 20.4918 2.06308 20.614C2.01193 20.7363 1.9856 20.8675 1.9856 21C1.9856 21.1325 2.01193 21.2637 2.06308 21.386C2.11423 21.5082 2.18917 21.6191 2.28354 21.7122C2.37791 21.8052 2.48983 21.8786 2.6128 21.928C2.73576 21.9774 2.86733 22.0019 2.99984 22H20.9998C21.1323 22.0019 21.2639 21.9774 21.3869 21.928C21.5098 21.8786 21.6218 21.8052 21.7161 21.7122C21.8105 21.6191 21.8854 21.5082 21.9366 21.386C21.9877 21.2637 22.0141 21.1325 22.0141 21C22.0141 20.8675 21.9877 20.7363 21.9366 20.614C21.8854 20.4918 21.8105 20.3809 21.7161 20.2878C21.6218 20.1948 21.5098 20.1214 21.3869 20.072C21.2639 20.0226 21.1323 19.9981 20.9998 20H2.99984Z"
          fill={blockDefaultColor}
        />
        <rect x="32" width="32" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="32"
          y="7"
          width="117"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="32"
          y="14"
          width="96"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="32"
          y="21"
          width="37"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
