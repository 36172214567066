import { useDispatch } from 'react-redux';
import * as SocialLogin from '../api/socialLogin.api';
import { SocialGoogleAuthEnum } from '../store/reducers/socialAuth/SocialGoogleAuth';
import { IUTMItems } from '../utils/UTMArrayToApiString';
import { SnackbarType, useSnackbar } from './useSnackbar';

export const useSocialAuth = () => {
  const dispatch = useDispatch();
  const {setSnackbarValue} = useSnackbar()
  return {
    getGoogleLink: async (utm: IUTMItems, promo: string) => {
      try{
        const { data } = await SocialLogin.GetSocialAuthGoogleLink(utm, promo);
        dispatch({
          type: SocialGoogleAuthEnum.setLink,
          payload: data?.link,
        });
        return data?.link;
      }catch(e:any){
        setSnackbarValue(e.error.error_description, SnackbarType.Error)
      }
    },
    getYandexLink: async (utm: IUTMItems, promo: string) => {
      try{
        const { data } = await SocialLogin.GetSocialAuthYandexLink(utm, promo);
        dispatch({
          type: SocialGoogleAuthEnum.setLink,
          payload: data?.link,
        });
        return data?.link;
      }catch(e:any){
        setSnackbarValue(e.error.error_description, SnackbarType.Error)
      }
    },
  };
};
