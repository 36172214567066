
export enum DraftReducerActionEnum {
  setDraftSaved = 'SET_SAVED',
  setDraftMessage = 'SET_DRAFT_MESSAGE',
  setLoading = 'SET_DRAFT_LOADING',
  setDraftAccess = 'SET_DRAFT_ACCESS',
}

interface IDraftReducerActionSetSaved {
  type: DraftReducerActionEnum.setDraftSaved;
  payload: boolean;
}
interface IDraftReducerActionSetLoading {
  type: DraftReducerActionEnum.setLoading;
  payload: boolean;
}
interface IDraftReducerActionSetMessage {
  type: DraftReducerActionEnum.setDraftMessage;
  payload: string;
}
interface IDraftReducerActionSetDraftAccess {
  type: DraftReducerActionEnum.setDraftAccess;
  payload: IDraftAccessState;
}

export interface IDraftAccessState {
  read:boolean,
  edit:boolean
}

interface IDraftReducerState {
  saved: boolean;
  message: string;
  loading: boolean;
  access: IDraftAccessState
}
const IS: IDraftReducerState = {
  saved: false,
  message: '',
  loading: false,
  access: {
    read: false,
    edit: false
  }
};

type IDraftReducerActionTypes =
  | IDraftReducerActionSetSaved
  | IDraftReducerActionSetMessage
  | IDraftReducerActionSetLoading
  | IDraftReducerActionSetDraftAccess

export const DraftReducer = (
  state = IS,
  action: IDraftReducerActionTypes,
): IDraftReducerState => {
  switch (action.type) {
    case DraftReducerActionEnum.setDraftSaved:
      return { ...state, saved: action.payload };
    case DraftReducerActionEnum.setDraftMessage:
      return { ...state, message: action.payload };
    case DraftReducerActionEnum.setLoading:
      return { ...state, loading: action.payload };
    case DraftReducerActionEnum.setDraftAccess:
      return { ...state, access : action.payload };
    default:
      return state;
  }
};
