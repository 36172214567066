import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const GoodsAndServiceBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="7" r="4" fill={blockDefaultColor} />
        <rect
          x="10.5002"
          y="13"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="10.5002"
          y="18"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="0.5"
          y="0.5"
          width="31.67"
          height="23"
          rx="1.5"
          stroke={blockDefaultColor}
        />
        <circle cx="57" cy="7" r="4" fill={blockDefaultColor} />
        <rect
          x="51.5002"
          y="13"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="51.5002"
          y="18"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="41.5"
          y="0.5"
          width="31.67"
          height="23"
          rx="1.5"
          stroke={blockDefaultColor}
        />
        <circle cx="98" cy="7" r="4" fill={blockDefaultColor} />
        <rect
          x="92.5002"
          y="13"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="92.5002"
          y="18"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="82.5"
          y="0.5"
          width="31.67"
          height="23"
          rx="1.5"
          stroke={blockDefaultColor}
        />
        <circle cx="139" cy="7" r="4" fill={blockDefaultColor} />
        <rect
          x="133.5"
          y="13"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="133.5"
          y="18"
          width="11.2003"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="123.5"
          y="0.5"
          width="31.67"
          height="23"
          rx="1.5"
          stroke={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
