import React, { useCallback, useEffect, useState } from 'react';
import { NewInputStyles } from './styles/NewInputStyles';

export interface INewInputProps {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  type?: string;
  error?: boolean;
  onEnter?: () => void;
  tabIndex?: number;
  autoFocus?: boolean;
}

export const NewInput = (props: INewInputProps) => {
  const cc = NewInputStyles();
  const [type, setType] = useState('text');

  const keyDown = useCallback((e: any) => {
    props.onChange(e.target.value);

    if (e.code === 'Enter') {
      props.onEnter && props.onEnter();
    }
  }, [props]);

  useEffect(() => {
    props.type && setType(props.type);
  }, [props.type, setType]);

  return (
    <input
      value={props.value}
      placeholder={props.placeholder}
      type={type}
      className={`${cc.new_input} ${props.error ? cc.error :''}`}
      onChange={(evt) => props.onChange(evt.target.value)}
      onKeyDown={keyDown}
      tabIndex={props.tabIndex}
      autoFocus={props.autoFocus}
    />
  );
};
