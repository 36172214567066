import React, { FC, useEffect, useRef } from 'react';
import { NewModalStyles } from './styles/NewModalStyles';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useModal } from '../../hooks/useModal';

export const NewModal: FC = () => {
  const cc = NewModalStyles();
  const body = useTypeSelector((s) => s.modal.body);
  const { hideModal } = useModal();
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        hideModal();
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          hideModal();
        }
      }

    document.addEventListener('keydown', handleKeyDown);

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [wrapperRef, hideModal]);

  return (
    <div className={cc.new_modal_wrapper} id="new_modal_wraper">
      <div className={cc.new_modal} ref={wrapperRef}>
        {body}
        <div className={cc.close} onClick={hideModal}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.9902 0.990669C1.79126 0.990721 1.59687 1.0501 1.43186 1.16121C1.26685 1.27233 1.13872 1.43013 1.06387 1.61444C0.989008 1.79875 0.970819 2.0012 1.01162 2.1959C1.05243 2.39061 1.15037 2.56872 1.29293 2.70747L7.5859 9.00043L1.29293 15.2934C1.19696 15.3855 1.12033 15.4959 1.06755 15.618C1.01476 15.7402 0.986879 15.8716 0.985525 16.0046C0.984172 16.1377 1.00938 16.2697 1.05967 16.3928C1.10996 16.516 1.18432 16.6279 1.2784 16.722C1.37248 16.8161 1.48438 16.8904 1.60756 16.9407C1.73073 16.991 1.86271 17.0162 1.99575 17.0149C2.12879 17.0135 2.26022 16.9856 2.38235 16.9328C2.50448 16.8801 2.61485 16.8034 2.70699 16.7075L8.99996 10.4145L15.2929 16.7075C15.3851 16.8034 15.4954 16.8801 15.6176 16.9328C15.7397 16.9856 15.8711 17.0135 16.0042 17.0149C16.1372 17.0162 16.2692 16.991 16.3924 16.9407C16.5155 16.8904 16.6275 16.8161 16.7215 16.722C16.8156 16.6279 16.89 16.516 16.9403 16.3928C16.9906 16.2697 17.0158 16.1377 17.0144 16.0047C17.0131 15.8716 16.9852 15.7402 16.9324 15.618C16.8796 15.4959 16.803 15.3855 16.707 15.2934L10.414 9.00043L16.707 2.70747C16.8515 2.567 16.9502 2.38616 16.9902 2.18864C17.0301 1.99112 17.0095 1.78614 16.9309 1.60055C16.8524 1.41497 16.7196 1.25744 16.55 1.14861C16.3804 1.03978 16.1819 0.984729 15.9804 0.990669C15.7206 0.99841 15.4741 1.10699 15.2929 1.2934L8.99996 7.58637L2.70699 1.2934C2.6138 1.19761 2.50236 1.12146 2.37924 1.06947C2.25613 1.01747 2.12384 0.990677 1.9902 0.990669Z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
