import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const CardItem = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0735 6.43934C19.3441 6.15804 19.7112 6 20.094 6C20.4767 6 20.8438 6.15804 21.1144 6.43934C21.3851 6.72064 21.5371 7.10218 21.5371 7.5C21.5371 7.89782 21.3851 8.27936 21.1144 8.56066C20.8438 8.84196 20.4767 9 20.094 9C19.7112 9 19.3441 8.84196 19.0735 8.56066C18.8028 8.27936 18.6508 7.89782 18.6508 7.5C18.6508 7.10218 18.8028 6.72064 19.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M21.5371 16.5L26.5882 10.5L32 18H14L17.9292 12.75L21.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="44.6667"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="54.6667"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="7"
          width="45.2525"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="14"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="21"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <g clipPath="url(#clip0_5024_5717)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.333 0H109.333V24H156.333V0ZM132.671 4.02637C132.827 3.80137 133.083 3.66667 133.356 3.66667H133.359C133.633 3.6675 133.89 3.80296 134.045 4.02962L137.783 9.5H141.665C141.925 9.5 142.171 9.62128 142.329 9.82878C142.486 10.0354 142.538 10.3055 142.469 10.5563L140.335 18.2777C140.137 18.9968 139.477 19.5 138.73 19.5H127.936C127.189 19.5 126.529 18.996 126.33 18.276L124.196 10.5547C124.128 10.3039 124.181 10.0354 124.337 9.82878C124.496 9.62128 124.741 9.5 125.002 9.5H128.89L132.671 4.02637ZM135.763 9.5L133.353 5.97135L130.915 9.5H135.763ZM137.344 11.1667H126.095L127.936 17.8333H138.729L140.571 11.1667H137.344Z"
            fill={blockDefaultColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_5024_5717">
            <rect x="109.333" width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
