import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'wouter';

const useStyle = makeStyles(() => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  page: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  h2: {
    fontSize: 40,
    padding: '0px !important',
    fontWeight: 'bold',
    alignItems: 'center',
    textAlign: 'center',
    color: '#264A67',
  },
  h3: {
    fontSize: 200,
    padding: '0px !important',
    fontWeight: 'bold',
    lineHeight: '200px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#264A67',
  },
  btn: {
    width: 227,
    height: 33,
    background: '#3F92D1',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    textDecoration: 'none',
    justifySelf: 'center',
  },
}));

export const Error404 = () => {
  const classes = useStyle();
  return (
    <div className={classes.wrapper}>
      <div className={classes.page}>
        <span className={classes.h2}>Ошибка</span>
        <span className={classes.h3}>404</span>
        <Link to="/" className={classes.btn}>Вернуться на главную</Link>
      </div>
    </div>
  );
};
