import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const PopupWidgetsBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="154"
          height="14"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path d="M70 14H86V15.8L78 21.5L70 15.8V14Z" fill="white" />
        <path
          d="M85.5858 15L78 22.5858L70.4142 15H70.5858L76.9393 21.3536L77.6464 20.6464L76.9393 21.3536C77.5251 21.9393 78.4749 21.9393 79.0607 21.3536L85.4142 15H85.5858Z"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
