import { ReactNode } from 'react';

export interface INewModalIS {
  show: boolean;
  body?: ReactNode;
  onClose?: () => void;
}

const IS: INewModalIS = {
  show: false,
  body: null,
};

export enum NewModalReducerActionEnum {
  setModal = 'SET_MODAL',
  setBody = 'SET_BODY',
}

interface SetShowModal {
  type: NewModalReducerActionEnum.setModal;
  payload: boolean;
}

interface SetBodyModal {
  type: NewModalReducerActionEnum.setBody;
  payload: ReactNode;
}

export type NewModelReducerActionTypes = SetBodyModal | SetShowModal;

export const NewModelReducer = (
  state = IS,
  action: NewModelReducerActionTypes,
): INewModalIS => {
  switch (action.type) {
    case NewModalReducerActionEnum.setModal:
      return { ...state, show: action.payload };
    case NewModalReducerActionEnum.setBody:
      return { ...state, body: action.payload };
    default:
      return state;
  }
};
