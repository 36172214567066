import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const Elements3To2Block = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="44.6666"
          height="8"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="55.6667"
          y="1"
          width="44.6666"
          height="8"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="110.333"
          y="1"
          width="44.6666"
          height="8"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="1"
          y="15"
          width="44.6666"
          height="8"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="55.6667"
          y="15"
          width="44.6666"
          height="8"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="110.333"
          y="15"
          width="44.6666"
          height="8"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
