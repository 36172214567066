import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Toolbar,
  Snackbar,
  SnackbarContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'clsx';
import { SnackbarType, useSnackbar } from '../hooks/useSnackbar';
import useStyles from './styles/index';
import { useTypeSelector } from '../hooks/useTypesSelector';
import { useLocation } from 'wouter';
import { NewModal } from '../components/NewModal/NewModal';
import { useProfile } from '../hooks/useProfile';
import { useRegistration } from '../hooks/useRegistration';
import { useLastLocation } from '../hooks/useLastLocation';
import { Bar } from './bar/Bar';
// import { TechWork } from '../components/TechWork/TechWork'; //Банер переезда

export const Layout = memo(({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [location] = useLocation();
  const showModal = useTypeSelector((s) => s.modal.show);
  const profile = useTypeSelector((s) => s.profile.profile);
  const { checkProfile } = useProfile();
  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();
  const { setUTM } = useRegistration();
  const lastLocation = useLastLocation();
  // const [techWork, setTechWork] = useState(true);

  const onSnackbarClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  const query = useMemo(() => {
    const q = window.location.search.replace(/\?/, '').split('&');
    const arr = [];
    for (const item of q) {
      const d: string[] = item.split('=');
      arr.push({ key: d[0], value: d[1] });
    }
    return arr;
  }, []);

  useEffect(() => {
    // Добавление UTM меток в store ниже
    setUTM(query);
    // Добавление UTM меток в store выше
    //eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    !/\/login|\/registration|\/registration\//g.test(location) &&
      lastLocation.set(location);

    !/\/login|\/login\/google|\/login\/yandex|\/registration|\/registration\/.+|\/password-reset|\/password-reset\//g.test(
      location,
    ) && setShow(false);

    setShow(true);
  }, [location, lastLocation]);

  useEffect(() => {
    profile === null && checkProfile();
  }, [profile, checkProfile]);

  return (
    <>
      {show ? (
        <div
          className={cx(classes.root, showModal ? classes.blur : '')}
          id="underRoot"
          data-class={classes.blur}
        >
          <main
            className={classes.contentWrapper}
            id="contentWrapper"
            data-class={classes.contentWrapper}
          >
            <Toolbar className={classes.toolbar} id="toolbar">
              <Bar />
            </Toolbar>
            <div className={classes.content} id="content">
              {children}
            </div>
          </main>
        </div>
      ) : (
        <>{children}</>
      )}
      <Snackbar
        open={Boolean(snackbarValue)}
        onClose={onSnackbarClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          className={cx({
            [classes.snackbarError]: snackbarType === SnackbarType.Error,
            [classes.snackbarSuccess]: snackbarType === SnackbarType.Success,
          })}
          message={snackbarValue}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      {/* {techWork ? <TechWork hideModal={() => setTechWork(false)} /> : <></>} */}
      {showModal ? <NewModal /> : <></>}
    </>
  );
});
