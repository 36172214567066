import React, { FC } from 'react';
import { TogglerStyle } from './TogglerStyle';

export interface ITogglerProps {
  caption: string
  value: boolean;
  onChange: (val: boolean) => void;
}

export const Toggler: FC<ITogglerProps> = (props) => {
  const cc = TogglerStyle();
  return (
    <div className={cc.togglerWrapper} onClick={()=>props.onChange(!props.value)}>
      <div className={cc.toggler}>
        <span className={props.value ? 'active' : ''} />
      </div>
      <span>{props.caption}</span>
    </div>
  );
};
