import { request } from './request';

export interface IPayment {
  id: string;
  confirm_ts: number;
  description: string;
  total: number;
}

export interface IPaymentSet {
  payment_link: string;
}

export interface IPaymentSetPayload {
  purchase_id: string;
}

export function getPaymentByIdAPI(id: string) {
  return request<IPayment>('get', `payments/${id}`);
}

export function getPaymentsAPI() {
  return request<IPayment[]>('get', 'payments');
}

export function setPaymentsAPI(payload: IPaymentSetPayload) {
  return request<IPaymentSet>('post', 'payments', payload);
}
