export interface IUTMItme {
  key: string;
  value: string;
}
export type IUTMItems = IUTMItme[];

export const UTMArrayToApiString = (utm: IUTMItems): string => {
  if(!utm || (utm.length === 1 && utm[0].key === '' && utm[0].value === undefined)){
    return ``
  }
  return `?${utm.map((item) => item.key && item.value ? `${item.key}=${item.value}` : '').join('&')}`;
};
