import { makeStyles } from '@material-ui/core';

const successColor = '#0BA19F';
const warningColor = '#F2994A';

export default makeStyles(() => ({
  emw: {
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    borderRadius: 5,
    display: 'grid',
    gridTemplateColumns: '30px 1fr',
    alignItems: 'center',
    alignSelf: 'space-around',
    margin: '10px 0',
    padding: '5px 0',
    '& svg': {
      justifySelf: 'center',
    },
  },
  success: {
    color: successColor,
    border: `1px solid ${successColor}`,
    '& svg': {
      '& path': {
        fill: successColor,
      },
    },
  },
  warning: {
    color: warningColor,
    border: `1px solid ${warningColor}`,
    '& svg': {
      '& path': {
        fill: warningColor,
      },
    },
  },
}));
