import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  form: {
    textAlign: 'center',
  },
  more: {
    margin: '10px 0px',
    height: 30,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '5px',
    alignItems: 'center',
    '& .line': {
      width: 110,
      height: 2,
      background: '#C7D6E6',
      display: 'block',
      position: 'relative',
    },
  },
  h5: {
    'font-family': 'Roboto',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '14px',
    'line-height': '20px',
    'text-align': 'center',
    'letter-spacing': '0.75px',
    'text-transform': 'uppercase',
    color: '#353E47',
  },
  button: {
    width: '100%',
    marginTop: 38,
  },
  signUpWrapper: {
    display: 'grid',
    alignItems: 'center',
    marginTop: 12,
    justifyContent: 'center',
    gridGap: 10,
  },
  signInLink: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'center',
    color: '#3F92D1',
    textDecoration: 'none',
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #3F92D1',
      borderRadius: 5,
    },
  },
  steper: {
    gridGap: 40,
  },
}));
