import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { completePassportReset } from '../../../api/users.api';
import { useLocation } from 'wouter';
import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';
import { useRegistration } from '../../../hooks/useRegistration';
import { RegistrationNotice } from '../RegistrationNotice';
import { RegistrationTypeMessageEnum } from '../../../store/reducers/registration';
import { VisibilityIcon } from '../../../components/VisibilityIcon';
import { VisibilityOffIcon } from '../../../components/VisibilityOffIcon';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { lastLocation } from '../../../hooks/useLastLocation';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 900,
    textAlign: 'center',
  },
  form: {
    textAlign: 'center',
  },
  button: {
    maxWidth: 240,
    width: '100%',
    marginTop: 38,
  },
  subtitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export function SetPassword({ token }: { token: string }) {
  const classes = useStyles();
  const email = useTypeSelector((s) => s.profile.profile?.email);
  const [, setLocation] = useLocation();
  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);
  const { getPasswordRegPolice } = useRegistration();
  const [regPolice, setRegPolice] = useState(new RegExp(''));
  const [pass, setPass] = useState('');
  const [repPass, setRepPass] = useState('');
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState<string | undefined>('');
  const [showPassword, setShowPassword] = useState(false);

  const getPolice = useCallback(async () => {
    const data = await getPasswordRegPolice();
    setRegPolice(new RegExp(data.regexp));
  }, [getPasswordRegPolice]);

  const onSubmit = useCallback(async () => {
    if (pass && repPass && pass === repPass) {
      const { error } = await completePassportReset({
        token,
        password: pass,
      });

      if (error) {
        setSnackbarValue(error.message, SnackbarType.Error);
      } else {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem(`${lastLocation}_${email}`);
        setSnackbarValue('Пароль успешно изменён', SnackbarType.Success);
        setLocation('/login');
      }
    } else {
      setSnackbarValue('Пароли не совпадают', SnackbarType.Error);
    }
  }, [setSnackbarValue, setLocation, pass, repPass, token, email]);

  useEffect(() => {
    if (!regPolice.test(pass)) {
      setMessage('Пароль должен содержать \n не менее 8 символов на латинице');
      setValid(false);
    } else if (pass !== repPass) {
      setMessage('Пароли не совпадают');
      setValid(false);
    } else {
      setMessage(undefined);
      setValid(true);
    }
  }, [pass, repPass, setMessage, regPolice]);

  useEffect(() => {
    (async () => {
      await getPolice();
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        Задайте пароль
      </Typography>
      <Typography className={classes.subtitle}>
        введите пароль дважды
      </Typography>
      {message ? (
        <RegistrationNotice
          type={RegistrationTypeMessageEnum.warning}
          text={message ? message : ''}
        />
      ) : (
        <></>
      )}

      <div className={classes.form}>
        <TextField
          // onChange={formik.handleChange}
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          name="password"
          fullWidth
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((s) => !s)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Пароль"
          type={showPassword ? 'text' : 'password'}
        />
        <TextField
          // onChange={formik.handleChange}
          value={repPass}
          onChange={(e) => setRepPass(e.target.value)}
          name="password_confirm"
          fullWidth
          margin="dense"
          label="Повторите пароль"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((s) => !s)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={showPassword ? 'text' : 'password'}
        />
        <Button
          onClick={onSubmit}
          className={classes.button}
          disabled={!valid}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
        >
          СОХРАНИТЬ
        </Button>
      </div>
    </>
  );
}
