import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const TablesBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="11" width="152" height="2" fill={blockDefaultColor} />
        <rect y="2" width="2" height="20" fill={blockDefaultColor} />
        <rect
          x="25.6667"
          y="2"
          width="2"
          height="20"
          fill={blockDefaultColor}
        />
        <rect
          x="51.3333"
          y="2"
          width="2"
          height="20"
          fill={blockDefaultColor}
        />
        <rect x="77" y="2" width="2" height="20" fill={blockDefaultColor} />
        <rect
          x="102.667"
          y="2"
          width="2"
          height="20"
          fill={blockDefaultColor}
        />
        <rect
          x="128.333"
          y="2"
          width="2"
          height="20"
          fill={blockDefaultColor}
        />
        <rect x="154" y="2" width="2" height="20" fill={blockDefaultColor} />
        <rect
          x="1"
          y="1"
          width="154"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
