import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const TextBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="156" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect y="7" width="151" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect y="14" width="156" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect y="21" width="66" height="3" rx="1.5" fill={blockDefaultColor} />
      </svg>
    </Block>
  );
});
