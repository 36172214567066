import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const BlogsBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="157"
        height="24"
        viewBox="0 0 157 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="16"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="21"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <path
          d="M21.0112 3.75628C21.1714 3.59219 21.3886 3.5 21.6151 3.5C21.8417 3.5 22.0589 3.59219 22.2191 3.75628C22.3792 3.92038 22.4692 4.14294 22.4692 4.375C22.4692 4.60706 22.3792 4.82962 22.2191 4.99372C22.0589 5.15781 21.8417 5.25 21.6151 5.25C21.3886 5.25 21.1714 5.15781 21.0112 4.99372C20.851 4.82962 20.761 4.60706 20.761 4.375C20.761 4.14294 20.851 3.92038 21.0112 3.75628Z"
          fill={blockDefaultColor}
        />
        <path
          d="M22.4692 9.625L25.4586 6.125L28.6615 10.5H18.0085L20.334 7.4375L22.4692 9.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="44.67"
          height="12"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="54.6667"
          y="16"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="21"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <path
          d="M75.6779 3.75628C75.8381 3.59219 76.0554 3.5 76.2819 3.5C76.5084 3.5 76.7256 3.59219 76.8858 3.75628C77.046 3.92038 77.136 4.14294 77.136 4.375C77.136 4.60706 77.046 4.82962 76.8858 4.99372C76.7256 5.15781 76.5084 5.25 76.2819 5.25C76.0554 5.25 75.8381 5.15781 75.6779 4.99372C75.5178 4.82962 75.4278 4.60706 75.4278 4.375C75.4278 4.14294 75.5178 3.92038 75.6779 3.75628Z"
          fill={blockDefaultColor}
        />
        <path
          d="M77.136 9.625L80.1253 6.125L83.3282 10.5H72.6753L75.0007 7.4375L77.136 9.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="55.6667"
          y="1"
          width="44.67"
          height="12"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="109.333"
          y="16"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          y="21"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <path
          d="M130.344 3.75628C130.505 3.59219 130.722 3.5 130.948 3.5C131.175 3.5 131.392 3.59219 131.552 3.75628C131.712 3.92038 131.802 4.14294 131.802 4.375C131.802 4.60706 131.712 4.82962 131.552 4.99372C131.392 5.15781 131.175 5.25 130.948 5.25C130.722 5.25 130.505 5.15781 130.344 4.99372C130.184 4.82962 130.094 4.60706 130.094 4.375C130.094 4.14294 130.184 3.92038 130.344 3.75628Z"
          fill={blockDefaultColor}
        />
        <path
          d="M131.802 9.625L134.792 6.125L137.995 10.5H127.342L129.667 7.4375L131.802 9.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="110.333"
          y="1"
          width="44.67"
          height="12"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
