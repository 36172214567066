import React, { useCallback } from 'react';
import { useLocation } from 'wouter';
import useStyles from '../../styles/index';
import { EditableInput } from '../../../components/Editor/item/Blocks/EditebleInput';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { Devider } from '../NewEditorBar/devider';
import { NewButton } from '../../../components/Button/NewButton';
import { Menu } from '../../../components/Menu/Menu';

export const PublicBar = () => {
  const classes = useStyles();
  const editorName = useTypeSelector((s) => s.editor.name);
  const [, setLocation] = useLocation();
  const t = localStorage.getItem('access_token');

  const gotoLogin = useCallback(async () => {
    setLocation('/login');
  }, [setLocation]);

  return (
    <div className={classes.btnGroup}>
      <div className="item">
        <NewButton
          onClick={gotoLogin}
          caption={t ? 'Все проекты' : 'Войти'}
          beforeIcon="arrow_left"
          height={60}
          noBorder
          className={classes.goToAll}
        />
        <Devider color="gray" />
        {/* <NewUndo /> */}
      </div>

      <div className="item">
        <EditableInput value={editorName} />
        {t ? (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 0.166656C13.0833 0.166656 12.9167 0.24999 12.75 0.416656L11.0833 2.08332L9.91667 3.24999L0.5 12.6667V16H3.83333L16.0833 3.74999C16.4167 3.41666 16.4167 2.91666 16.0833 2.58332L13.9167 0.416656C13.75 0.24999 13.5833 0.166656 13.3333 0.166656ZM13.3333 2.16666L14.3333 3.16666L13.25 4.24999L12.25 3.24999L13.3333 2.16666ZM11.0833 4.41666L12.0833 5.41666L3.16667 14.3333H2.16667V13.3333L11.0833 4.41666Z"
              fill="#A1AEBB"
            />
          </svg>
        ) : (
          <></>
        )}
      </div>
      <div className="item" style={{ justifySelf: 'end' }}>
        <Menu />
      </div>
    </div>
  );
};
