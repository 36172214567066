import React from 'react';
import useStyles from './styles/index';
import { SuccessMessage } from './successMessage';
import { ErrorMessage } from './errorMessage';

export type FeedbackMessageType = 'SUCCESS' | 'ERROR' | null;

interface FeedbackMessageWrapperProps {
  type: FeedbackMessageType;
  close: () => void;
  repeat: () => Promise<void>;
  errorMessage: string;
}

export const MessageWrapper = (props: FeedbackMessageWrapperProps) => {
  const classes = useStyles();
  const { type, close, repeat, errorMessage } = props;
  return (
    <div className={classes.wrapper}>
      {type === 'SUCCESS' ? (
        <SuccessMessage close={close} />
      ) : (
        <ErrorMessage
          close={close}
          repeat={repeat}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default MessageWrapper;
