import React from 'react';
import { RegistrationTypeMessageEnum } from '../../store/reducers/registration';
import useStyles from './styles/messages';
import cx from 'clsx';
import { useTypeSelector } from '../../hooks/useTypesSelector';

export const RegistrationMessage = () => {
  const classes = useStyles();

  const { text, type } = useTypeSelector((s) => s.registration.message);
  return (
    <>
    { text !== null && (
      <div
        className={cx(
          classes.emw,
          type === RegistrationTypeMessageEnum.success && classes.success,
          type === RegistrationTypeMessageEnum.warning && classes.warning,
        )}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 0.846344C9.67244 0.846344 9.34482 0.965667 9.10649 1.20442L1.20447 9.10643C0.711139 9.59977 0.711139 10.401 1.20447 10.8935L9.10649 18.7972C9.34482 19.0355 9.66255 19.1667 10 19.1667C10.3375 19.1667 10.6544 19.0355 10.8936 18.7972L18.7972 10.8935C19.2906 10.4002 19.2906 9.59894 18.7972 9.10643L10.8936 1.20442C10.6549 0.965667 10.3277 0.846344 10 0.846344ZM10 2.66601L17.334 9.99999L10 17.334L2.66606 9.99999L10 2.66601ZM9.16671 5.83332V10.8333H10.8334V5.83332H9.16671ZM9.16671 12.5V14.1667H10.8334V12.5H9.16671Z" />
        </svg>
        <span>{text}</span>
      </div>
      )
    }
    </>
  );
};
