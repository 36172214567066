import React, { FC, memo, useEffect, useState } from 'react';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as More } from '../../assets/icons/more.svg';
import { ReactComponent as Enter } from '../../assets/icons/enter.svg';
import { ReactComponent as NewWindow } from '../../assets/icons/new_window.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
// import { ReactComponent as Loading } from '../../assets/icons/loading.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as LongArrowLeft } from '../../assets/icons/long_arrow_left.svg';
import { ReactComponent as LongArrowRight } from '../../assets/icons/long_arrow_right.svg';
import { ReactComponent as Crown } from '../../assets/icons/crown.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { Player } from '@lottiefiles/react-lottie-player';

export type IIconsOutlineTypes =
  | 'plus'
  | 'profile'
  | 'arrow_left'
  | 'more'
  | 'enter'
  | 'new_window'
  | 'warning'
  | 'loading'
  | 'minus'
  | 'long_arrow_left'
  | 'long_arrow_right'
  | 'crown'
  | 'download'
  | 'trash'
  | 'export';

export interface IIconsOutline {
  types: IIconsOutlineTypes;
  noHover?: boolean;
  invert?: boolean;
  width?: number;
  height?: number;
  className?: string;
  onClick?: (ent: any) => void;
  defaultColor?: string;
  hoverColor?: string;
}

export const ISDefColor = '#FFFFFF';
export const ISHoverColor = '#3090E8';

interface IGetIconProps {
  types: IIconsOutlineTypes;
  color: string;
  width: number;
  height: number;
}

const GetIcon: FC<IGetIconProps> = ({ types, color, width, height }) => {
  switch (types) {
    case 'plus':
      return <Plus fill={color} width={width} height={height} />;
    case 'profile':
      return <Profile fill={color} width={width} height={height} />;
    case 'arrow_left':
      return <ArrowLeft fill={color} width={width} height={height} />;
    case 'more':
      return <More fill={color} width={width} height={height} />;
    case 'enter':
      return <Enter fill={color} width={width} height={height} />;
    case 'new_window':
      return <NewWindow fill={color} width={width} height={height} />;
    case 'warning':
      return <Warning fill={color} width={width} height={height} />;
    case 'loading':
      return (
        <Player
          autoplay
          loop
          // src="/animation/loader.json"
          src={{
            v: '5.9.4',
            fr: 60,
            ip: 0,
            op: 79,
            w: 600,
            h: 600,
            nm: 'lottie Up-Down fade',
            ddd: 1,
            assets: [
              {
                id: 'comp_0',
                nm: 'Предварительная композиция 1',
                fr: 60,
                layers: [
                  {
                    ddd: 1,
                    ind: 1,
                    ty: 4,
                    nm: 'Shape Layer 2',
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 14,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 17,
                            s: [100],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 56.941,
                            s: [100],
                          },
                          { t: 61, s: [0] },
                        ],
                        ix: 11,
                      },
                      rx: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 7.008,
                            s: [-180],
                          },
                          {
                            i: { x: [0.667], y: [1] },
                            o: { x: [1], y: [0] },
                            t: 37.008,
                            s: [0],
                          },
                          { t: 67.0078125, s: [180] },
                        ],
                        ix: 8,
                      },
                      ry: { a: 0, k: 0, ix: 9 },
                      rz: { a: 0, k: 0, ix: 10 },
                      or: { a: 0, k: [0, 0, 0], ix: 7 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 37,
                            s: [405, 116, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 38, s: [405, 189, 0] },
                        ],
                        ix: 2,
                      },
                      a: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 37,
                            s: [-44, -73, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 38, s: [-44, 0, 0] },
                        ],
                        ix: 1,
                      },
                      s: { a: 0, k: [85.001, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [120, 70], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 10, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 8, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-44, -36.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Rectangle 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                  {
                    ddd: 1,
                    ind: 2,
                    ty: 4,
                    nm: 'Shape Layer 17',
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 6.996,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 9.996,
                            s: [100],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 49.938,
                            s: [100],
                          },
                          { t: 53.99609375, s: [0] },
                        ],
                        ix: 11,
                      },
                      rx: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 0,
                            s: [-180],
                          },
                          {
                            i: { x: [0.667], y: [1] },
                            o: { x: [1], y: [0] },
                            t: 30,
                            s: [0],
                          },
                          { t: 60, s: [180] },
                        ],
                        ix: 8,
                      },
                      ry: { a: 0, k: 0, ix: 9 },
                      rz: { a: 0, k: 0, ix: 10 },
                      or: { a: 0, k: [0, 0, 0], ix: 7 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 17.996,
                            s: [265, 0, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                          },
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 29.996,
                            s: [265, 0, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 31, s: [265, 73, 0] },
                        ],
                        ix: 2,
                      },
                      a: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 29.996,
                            s: [-44, -73, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 31, s: [-44, 0, 0] },
                        ],
                        ix: 1,
                      },
                      s: { a: 0, k: [85.001, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [120, 70], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 10, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 8, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-44, -36.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Rectangle 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                  {
                    ddd: 1,
                    ind: 3,
                    ty: 4,
                    nm: 'Shape Layer 1',
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 11,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 14,
                            s: [100],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 53.941,
                            s: [100],
                          },
                          { t: 58, s: [0] },
                        ],
                        ix: 11,
                      },
                      rx: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 4.004,
                            s: [-180],
                          },
                          {
                            i: { x: [0.667], y: [1] },
                            o: { x: [1], y: [0] },
                            t: 34.004,
                            s: [0],
                          },
                          { t: 64.00390625, s: [180] },
                        ],
                        ix: 8,
                      },
                      ry: { a: 0, k: 0, ix: 9 },
                      rz: { a: 0, k: 0, ix: 10 },
                      or: { a: 0, k: [0, 0, 0], ix: 7 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 34,
                            s: [265, 116, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 35, s: [265, 189, 0] },
                        ],
                        ix: 2,
                      },
                      a: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 34,
                            s: [-44, -73, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 35, s: [-44, 0, 0] },
                        ],
                        ix: 1,
                      },
                      s: { a: 0, k: [85.001, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [120, 70], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 10, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 8, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-44, -36.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Rectangle 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                  {
                    ddd: 1,
                    ind: 5,
                    ty: 4,
                    nm: 'Shape Layer 3',
                    sr: 1,
                    ks: {
                      o: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 8,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 11,
                            s: [100],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 50.941,
                            s: [100],
                          },
                          { t: 55, s: [0] },
                        ],
                        ix: 11,
                      },
                      rx: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 1,
                            s: [-180],
                          },
                          {
                            i: { x: [0.667], y: [1] },
                            o: { x: [1], y: [0] },
                            t: 31,
                            s: [0],
                          },
                          { t: 61, s: [180] },
                        ],
                        ix: 8,
                      },
                      ry: { a: 0, k: 0, ix: 9 },
                      rz: { a: 0, k: 0, ix: 10 },
                      or: { a: 0, k: [0, 0, 0], ix: 7 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 31,
                            s: [125, 116, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 32, s: [125, 189, 0] },
                        ],
                        ix: 2,
                      },
                      a: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 31,
                            s: [-44, -73, 0],
                            to: [0, 12.167, 0],
                            ti: [0, -12.167, 0],
                          },
                          { t: 32, s: [-44, 0, 0] },
                        ],
                        ix: 1,
                      },
                      s: { a: 0, k: [85.001, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ty: 'rc',
                            d: 1,
                            s: { a: 0, k: [120, 70], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 10, ix: 4 },
                            nm: 'Rectangle Path 1',
                            mn: 'ADBE Vector Shape - Rect',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 8, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-44, -36.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Rectangle 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Shape Layer 16',
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [300, 178, 0], ix: 2, l: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                      s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    hasMask: true,
                    masksProperties: [
                      {
                        inv: false,
                        mode: 'a',
                        pt: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 1,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -124.75],
                                    [-61, -124.75],
                                    [-61, -115.75],
                                    [-10.75, -115.75],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 11,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -124.75],
                                    [-61, -124.75],
                                    [-61, -78],
                                    [-10.75, -78],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 28,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -113.25],
                                    [-61, -113.25],
                                    [-61, -78],
                                    [-10.75, -78],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              t: 38,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -78.625],
                                    [-61, -78.625],
                                    [-61, -78],
                                    [-10.75, -78],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                          ],
                          ix: 1,
                        },
                        o: { a: 0, k: 100, ix: 3 },
                        x: { a: 0, k: 0, ix: 4 },
                        nm: 'Mask 1',
                      },
                    ],
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36, -80],
                                  [-36, -109.25],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-1, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Shape 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Shape Layer 10',
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [300, 200, 0], ix: 2, l: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                      s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    hasMask: true,
                    masksProperties: [
                      {
                        inv: false,
                        mode: 'a',
                        pt: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 11,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -124.75],
                                    [-61, -124.75],
                                    [-61, -115.75],
                                    [-10.75, -115.75],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 21,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -124.75],
                                    [-61, -124.75],
                                    [-61, -78],
                                    [-10.75, -78],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 38,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -113.25],
                                    [-61, -113.25],
                                    [-61, -78],
                                    [-10.75, -78],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              t: 48,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-10.75, -78.625],
                                    [-61, -78.625],
                                    [-61, -78],
                                    [-10.75, -78],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                          ],
                          ix: 1,
                        },
                        o: { a: 0, k: 100, ix: 3 },
                        x: { a: 0, k: 0, ix: 4 },
                        nm: 'Mask 1',
                      },
                    ],
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36, -80],
                                  [-36, -109.25],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-1, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Shape 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Shape Layer 9',
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [300, 200, 0], ix: 2, l: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                      s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    hasMask: true,
                    masksProperties: [
                      {
                        inv: false,
                        mode: 'a',
                        pt: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 1,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-35.5, -119.5],
                                    [-36, -119.5],
                                    [-36, -102.75],
                                    [-35.5, -102.75],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 11,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [112.75, -119.5],
                                    [-179.5, -119.5],
                                    [-179.5, -102.75],
                                    [112.75, -102.75],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              t: 21,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [112.75, -119.5],
                                    [-179.5, -119.5],
                                    [-179.5, -77],
                                    [112.75, -77],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                          ],
                          ix: 1,
                        },
                        o: { a: 0, k: 100, ix: 3 },
                        x: {
                          a: 1,
                          k: [
                            {
                              i: { x: [0.833], y: [0.833] },
                              o: { x: [0.167], y: [0.167] },
                              t: 0,
                              s: [-26],
                            },
                            { t: 1, s: [0] },
                          ],
                          ix: 4,
                        },
                        nm: 'Mask 1',
                      },
                      {
                        inv: false,
                        mode: 's',
                        pt: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 27,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-36.625, -117.5],
                                    [-36.625, -117.5],
                                    [-36.625, -104],
                                    [-36.625, -104],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              i: { x: 0.833, y: 0.833 },
                              o: { x: 0.167, y: 0.167 },
                              t: 37,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [112.125, -117.5],
                                    [-179.125, -117.5],
                                    [-179.125, -104],
                                    [112.125, -104],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            {
                              t: 47,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  o: [
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                  ],
                                  v: [
                                    [112.125, -117.5],
                                    [-179.125, -117.5],
                                    [-179.25, -75.25],
                                    [112, -75.25],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                          ],
                          ix: 1,
                        },
                        o: { a: 0, k: 100, ix: 3 },
                        x: {
                          a: 1,
                          k: [
                            {
                              i: { x: [0.833], y: [0.833] },
                              o: { x: [0.167], y: [0.167] },
                              t: 26,
                              s: [-4],
                            },
                            { t: 27, s: [0] },
                          ],
                          ix: 4,
                        },
                        nm: 'Mask 2',
                      },
                    ],
                    shapes: [
                      {
                        ty: 'gr',
                        it: [
                          {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [-2.575, 2.261],
                                  [0, 0],
                                  [0, 0],
                                  [-3.671, -3.014],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [2.928, -2.57],
                                  [0, 0],
                                  [0, 0],
                                  [2.995, 2.459],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.5, -80.188],
                                  [-175.459, -74.5],
                                  [-173.062, -108.531],
                                  [-163.75, -110.53],
                                  [92.5, -110.502],
                                  [104.25, -108.25],
                                  [106.66, -73.125],
                                  [106.5, -78.188],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                          },
                          {
                            ty: 'st',
                            c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                          },
                          {
                            ty: 'tr',
                            p: { a: 0, k: [-1, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Преобразовать',
                          },
                        ],
                        nm: 'Shape 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 79,
                    st: -25,
                    ct: 1,
                    bm: 0,
                  },
                ],
              },
            ],
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 0,
                nm: 'Предварительная композиция 1',
                refId: 'comp_0',
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [348, 422, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [300, 200, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [134, 131.608, 100], ix: 6, l: 2 },
                },
                ao: 0,
                w: 600,
                h: 400,
                ip: 0,
                op: 79,
                st: 0,
                bm: 0,
              },
            ],
            markers: [],
          }}
          style={{ width, height }}
        />
      );
    case 'minus':
      return <Minus fill={color} width={width} height={height} />;
    case 'long_arrow_left':
      return <LongArrowLeft fill={color} width={width} height={height} />;
    case 'long_arrow_right':
      return <LongArrowRight fill={color} width={width} height={height} />;
    case 'crown':
      return <Crown fill={color} width={width} height={height} />;
    case 'download':
      return <Download fill={color} width={width} height={height} />;
    case 'export':
      return <Export fill={color} width={width} height={height} />;
    case 'trash':
      return <Trash fill={color} width={width} height={height} />;
    default:
      return <></>;
  }
};

export const IconsOutline = memo((props: IIconsOutline) => {
  const [defColor, setDefColor] = useState(ISDefColor);
  const [hoverColor, setHoverColor] = useState(ISHoverColor);
  const [color, setColor] = useState(props.invert ? hoverColor : defColor);
  const [width, setWidth] = useState(24);
  const [height, setHeight] = useState(24);

  useEffect(() => {
    props.width && setWidth(props.width);
    props.height && setHeight(props.height);
  }, [props.invert, props.noHover, props.width, props.height]);

  const setHover = () => {
    if (props.noHover) {
      return;
    }
    if (props.invert) {
      setColor(hoverColor);
      return;
    }
    setColor(hoverColor);
  };

  const setLeave = () => {
    if (props.noHover) {
      return;
    }
    if (props.invert) {
      setColor(hoverColor);
      return;
    }
    setColor(defColor);
  };

  useEffect(() => {
    props.invert ? setColor(hoverColor) : setColor(defColor);
  }, [props.invert, hoverColor, defColor]);

  useEffect(() => {
    props.defaultColor && setDefColor(props.defaultColor);
    props.hoverColor && setHoverColor(props.hoverColor);
  }, [props.defaultColor, props.hoverColor]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={`icons ${props.noHover ? 'noHover' : ''}
      ${props.className ? props.className : ''}
      `}
      onMouseOver={setHover}
      onMouseLeave={setLeave}
      onClick={props.onClick}
    >
      {
        <GetIcon
          types={props.types}
          color={color}
          width={width}
          height={height}
        />
      }
    </div>
  );
});
