import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const FooterBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="2"
          width="24"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="34"
          y="2"
          width="24"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="34"
          y="7"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="34"
          y="11"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="34"
          y="15"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="66"
          y="2"
          width="24"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="66"
          y="7"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="66"
          y="11"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="66"
          y="15"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="98"
          y="2"
          width="24"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="98"
          y="7"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="98"
          y="11"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="98"
          y="15"
          width="24"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="130"
          y="2"
          width="24"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="130"
          y="10"
          width="24"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect y="22" width="156" height="2" rx="1" fill={blockDefaultColor} />
        <rect
          x="154"
          y="18"
          width="2"
          height="6"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect y="18" width="2" height="6" rx="1" fill={blockDefaultColor} />
      </svg>
    </Block>
  );
});
