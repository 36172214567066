import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useLocation } from 'wouter';
import { authorize } from '../../api/oauth.api';
import { useFormik } from 'formik';
import { useProfile } from '../../hooks/useProfile';
import useStyles from './styles';
import GoogleBtnLogin from './components/GoogleBtnLogin';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { UTMArrayToApiString } from '../../utils/UTMArrayToApiString';
import { useRequest } from '../../hooks/useRequest';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import { useLastLocation } from '../../hooks/useLastLocation';
import { YandexBtnLogin } from './components/YandexBtnLogin';
import { VisibilityIcon } from '../../components/VisibilityIcon';
import { VisibilityOffIcon } from '../../components/VisibilityOffIcon';

export function LoginForm() {
  const cc = useStyles();
  const [, setLocation] = useLocation();
  const utm = useTypeSelector((s) => s.registration.utm);
  const [regLink, setRegLink] = useState('/registration');
  const [showPassword, setShowPassword] = useState(false);
  const { setSnackbarValue } = useSnackbar();
  const { request } = useRequest();
  const lastLocation = useLastLocation();

  const params = useMemo(() => {
    const q = window.location.search.replace(/\?/, '').split('&');
    const arr = {} as { email: string; pass: string };
    for (const item of q) {
      const d: string[] = item.split('=');
      //@ts-ignore
      arr[d[0]] = d[1];
    }
    return arr;
  }, []);

  const { fetchProfile } = useProfile();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    async onSubmit(values) {
      request(async () => {
        try {
          await authorize({
            grant_type: 'password',
            username: values.email,
            password: values.password,
          });
          await fetchProfile();
          // analitics('login', 'Успешная отправка формы "Вход"');
          const ll = lastLocation.get();
          ll === undefined ? setLocation('/editor') : setLocation(ll);
        } catch (e: any) {
          setSnackbarValue(e.error, SnackbarType.Error);
        }
      });
    },
  });

  useEffect(() => {
    setRegLink(`/registration${UTMArrayToApiString(utm)}`);
  }, [utm, regLink, setRegLink]);

  useEffect(() => {
    if (params.email && params.pass) {
      request(async () => {
        try {
          await authorize({
            grant_type: 'password',
            username: params.email,
            password: params.pass,
          });
          await fetchProfile();
          setLocation('/editor');
        } catch (e: any) {
          setSnackbarValue(e.error, SnackbarType.Error);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.email, params?.pass]);

  return (
    <>
      <Typography variant="h1" gutterBottom className={cc.title}>
        Вход
      </Typography>
      <Typography variant="body1" gutterBottom className={cc.subtitle}>
        Введите данные авторизации
      </Typography>
      <form className={cc.form} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          required
          margin="dense"
          label="Email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <div className={cc.passwordWrapper}>
          <TextField
            fullWidth
            required
            margin="dense"
            onChange={formik.handleChange}
            value={formik.values.password}
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((s) => !s)}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Пароль"
            type={showPassword ? 'text' : 'password'}
          />
          <Link href="/password-reset">
            <Button
              className={cc.forgotPassword}
              size="small"
              variant="text"
              color="primary"
            >
              Забыли пароль?
            </Button>
          </Link>
        </div>
        <div className={cc.btnWrapper}>
          <Button
            className={cc.button}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            Войти
          </Button>
          <div className={cc.more}>
            <span className="line"></span>
            <span>ИЛИ</span>
            <span className="line"></span>
          </div>
          <GoogleBtnLogin />
          <YandexBtnLogin />

          <Link href={regLink} className={cc.singUpLink}>
            {/* <Button
              size="large"
              variant="contained"
              color="primary"
              className={cc.signUpButton}
              cc={{
                label: cc.signUpButtonLabel,
              }}
            > */}
            Регистрироваться
            {/* </Button> */}
          </Link>
        </div>
      </form>
    </>
  );
}
