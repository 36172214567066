import {
  scrapeStatusTypes,
  scrapeErrorTypes,
  sourceTypes,
  scrapeMode,
} from '../../api/siteDraft';

export enum EditorListEnum {
  setList = 'SET_EDITOR_LIST',
  setEditorPage = 'SET_EDITOR_PAGE',
  setEditorLimit = 'SET_EDITOR_LIMIT',
  setEditorOffset = 'SET_EDITOR_OFFSET',
  setInProcess = 'SET_IN_PROCESS',
}

export interface IEditorListItem {
  create_ts: Date;
  id: string;
  name: string;
  preview: string;
  pages_count: number;
  update_ts: Date;
  source?: sourceTypes;
  scrape_status: scrapeStatusTypes;
  scrape_error?: scrapeErrorTypes;
  scrape_mode: scrapeMode;
}

export interface IEditorList {
  in_process: boolean;
  list: IEditorListItem[];
  page: number;
  limit: number;
  offset: number;
}

const IS: IEditorList = {
  in_process: false,
  list: [],
  page: 1,
  limit: 100,
  offset: 0,
};

interface IEditorListActionSetList {
  type: EditorListEnum.setList;
  payload: IEditorListItem[];
}
interface IEditorListActionSetPage {
  type: EditorListEnum.setEditorPage;
  payload: number;
}
interface IEditorListActionSetLimit {
  type: EditorListEnum.setEditorLimit;
  payload: number;
}

interface IEditorListActionSetOffset {
  type: EditorListEnum.setEditorOffset;
  payload: number;
}

interface IEditorListActionSetInProcess {
  type: EditorListEnum.setInProcess;
  payload: boolean;
}

type EditorListActionTypes =
  | IEditorListActionSetList
  | IEditorListActionSetPage
  | IEditorListActionSetLimit
  | IEditorListActionSetOffset
  | IEditorListActionSetInProcess;

export const EditorListReducer = (
  state = IS,
  action: EditorListActionTypes,
): IEditorList => {
  switch (action.type) {
    case EditorListEnum.setList:
      return { ...state, list: action.payload };
    case EditorListEnum.setEditorPage:
      return { ...state, page: action.payload };
    case EditorListEnum.setEditorLimit:
      return { ...state, limit: action.payload };
    case EditorListEnum.setEditorOffset:
      return { ...state, offset: action.payload };
    case EditorListEnum.setInProcess:
      return { ...state, in_process: action.payload };
    default:
      return state;
  }
};
