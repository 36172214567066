import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const Columns2PictureBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.0735 6.43934C33.3441 6.15804 33.7112 6 34.094 6C34.4767 6 34.8438 6.15804 35.1144 6.43934C35.3851 6.72064 35.5371 7.10218 35.5371 7.5C35.5371 7.89782 35.3851 8.27936 35.1144 8.56066C34.8438 8.84196 34.4767 9 34.094 9C33.7112 9 33.3441 8.84196 33.0735 8.56066C32.8028 8.27936 32.6508 7.89782 32.6508 7.5C32.6508 7.10218 32.8028 6.72064 33.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M35.5371 16.5L40.5882 10.5L46 18H28L31.9292 12.75L35.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="72"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path
          d="M115.073 6.43934C115.344 6.15804 115.711 6 116.094 6C116.477 6 116.844 6.15804 117.114 6.43934C117.385 6.72064 117.537 7.10218 117.537 7.5C117.537 7.89782 117.385 8.27936 117.114 8.56066C116.844 8.84196 116.477 9 116.094 9C115.711 9 115.344 8.84196 115.073 8.56066C114.803 8.27936 114.651 7.89782 114.651 7.5C114.651 7.10218 114.803 6.72064 115.073 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M117.537 16.5L122.588 10.5L128 18H110L113.929 12.75L117.537 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="83"
          y="1"
          width="72"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
