import React from 'react';
import { IBlocks } from '../../../store/reducers/editors';
import { TextBlock } from './Blocks/TextBlock';
import { PictureTextBlock } from './Blocks/PictureTextBlock';
import { PictureBlock } from './Blocks/PictureBlock';
import { Columns3TextBlock } from './Blocks/Columns3TextBlock';
import { Columns2TextBlock } from './Blocks/Columns2TextBlock';
import { Columns3PictureBlock } from './Blocks/Columns3PictureBlock';
import { Columns2PictureBlock } from './Blocks/Columns2PictureBlock';
import { AddBlockBtn } from './Blocks/AddBlockBtn';
import { TextPictureBlock } from './Blocks/TextPictureBlock';
import { HeaderBlock } from './Blocks/HeaderBlock';
import { MixedBlock } from './Blocks/MixedBlock';
import { SearchBlock } from './Blocks/SearchBlock';
import { SearchResultBlock } from './Blocks/SearchResultBlock';
import { CaruselBlock } from './Blocks/CaruselBlock';
import { TeamsBlock } from './Blocks/TeamsBlock';
import { DownloadFileBlock } from './Blocks/DownloadFileBlock';
import { VideoBlock } from './Blocks/VideoBlock';
import { AuthBlock } from './Blocks/AuthBlock';
import { RegistrationBlock } from './Blocks/RegistrationBlock';
import { SocialBlock } from './Blocks/SocialBlock';
import { BackFormBlock } from './Blocks/BackFormBlock';
import { PictureActionBlock } from './Blocks/PictureActionBlock';
import { MapBlock } from './Blocks/MapBlock';
import { PartnersBlock } from './Blocks/PartnersBlock';
import { CallActionBlock } from './Blocks/CallActionBlock';
import { TimelineBlock } from './Blocks/TimelineBlock';
import { TablesBlock } from './Blocks/TablesBlock';
import { ContactsBlock } from './Blocks/ContactsBlock';
import { PopupWidgetsBlock } from './Blocks/PopupWidgetsBlock';
import { FooterBlock } from './Blocks/FooterBlock';
import { Columns5TextBlock } from './Blocks/Columns5TextBlock';
import { TextActionBlock } from './Blocks/TextActionBlock';
import { CardItem } from './Blocks/CardItem';
import { Columns3CardBlock } from './Blocks/Columns3CardBlock';
import { CaruselAndTextBlock } from './Blocks/CaruselAndTextBlock';
import { Elements3To2Block } from './Blocks/Elements3To2Block';
import { FormWithVideoBlock } from './Blocks/FormWithVideoBlock';
import { FormWithPictureBlock } from './Blocks/FormWithPictureBlock';
import { FormWithTextBlock } from './Blocks/FormWithTextBlock';
import { FAQBlock } from './Blocks/FAQBlock';
import { TextWithVideoBlock } from './Blocks/TextWithVideoBlock';
import { VideoWithTextBlock } from './Blocks/VideoWithTextBlock';
import { BlogsBlock } from './Blocks/BlogsBlock';
import { GoodsAndServiceBlock } from './Blocks/GoodsAndServiceBlock';
import { ListBlock } from './Blocks/ListBlock';
import { QuoteAndReviewBlock } from './Blocks/QuoteAndReviewBlock';
import { Tables2Block } from './Blocks/Tables2Block';
import { FiltersBlock } from './Blocks/FiltersBlock';
import { QuizOrSurveyBlock } from './Blocks/QuizOrSurveyBlock';
import { CommentsBlock } from './Blocks/CommentsBlock';
import { EmptyBlock } from './Blocks/EmptyBlock';
import { UnknowBlock } from './Blocks/UnknowBlock';
import { CustomBlock } from './Blocks/CustomBlock';

export interface IGenerateBlocks {
  blocks: IBlocks[];
  clickOnBlock: (id: string, block: IBlocks) => void;
  pageId: string;
  showAddBtn: boolean;
  showEditableBlock: boolean;
  setShowEditableBlock: (val: boolean) => void;
  editBlockId: string;
  setShowEditPageId: (id: string) => void;
  titleEditAllowed: boolean;
  pub?: boolean;
}

export const GenerateBlocks = ({
  blocks,
  clickOnBlock,
  pageId,
  showAddBtn = true,
  showEditableBlock = false,
  setShowEditableBlock,
  editBlockId,
  setShowEditPageId,
  titleEditAllowed,
  pub,
}: IGenerateBlocks) => {
  const adBlock: IBlocks = {
    id: '',
    index: 0,
    comments: [],
    title: '',
    type: 'HEADER',
    active: true,
  };

  const addBtnParams = {
    ...adBlock,
    onTitleChange: () => {},
    clickOnBlock,
    showEditableBlock,
    setShowEditableBlock,
    showAddBtn,
    pageId,
    editBlockId,
    setShowEditPageId,
    titleEditAllowed,
    pub,
  };

  return (
    <>
      {blocks?.length ? (
        blocks.map((block, index) => {
          const params = {
            ...block,
            index,
            clickOnBlock,
            showEditableBlock,
            setShowEditableBlock,
            pageId,
            editBlockId,
            setShowEditPageId,
            titleEditAllowed,
            pub,
          };

          switch (block.type) {
            case 'TEXT':
              return <TextBlock {...params} key={block.id} />;
            case 'PICTURE_AND_TEXT':
              return <PictureTextBlock {...params} key={block.id} />;
            case 'TEXT_AND_PICTURE':
              return <TextPictureBlock {...params} key={block.id} />;
            case 'PICTURE':
              return <PictureBlock {...params} key={block.id} />;
            case 'HEADER':
              return <HeaderBlock {...params} key={block.id} />;
            case 'MIXED':
              return <MixedBlock {...params} key={block.id} />;
            case 'SEARCH':
              return <SearchBlock {...params} key={block.id} />;
            case 'SEARCH_RESULT':
              return <SearchResultBlock {...params} key={block.id} />;
            case 'CARUSEL':
              return <CaruselBlock {...params} key={block.id} />;
            case 'CARUSEL_TEXT':
              return <CaruselAndTextBlock {...params} key={block.id} />;
            case 'TEAMS':
              return <TeamsBlock {...params} key={block.id} />;
            case 'DOWNLOAD_FILE':
              return <DownloadFileBlock {...params} key={block.id} />;
            case 'VIDEO':
              return <VideoBlock {...params} key={block.id} />;
            case 'AUTH':
              return <AuthBlock {...params} key={block.id} />;
            case 'REGISTRATION':
              return <RegistrationBlock {...params} key={block.id} />;
            case 'SOCIAL':
              return <SocialBlock {...params} key={block.id} />;
            case 'BACK_FORM':
              return <BackFormBlock {...params} key={block.id} />;
            case 'PICTURE_AND_ACTION':
              return <PictureActionBlock {...params} key={block.id} />;
            case 'TEXT_AND_ACTION':
              return <TextActionBlock {...params} key={block.id} />;
            case 'CARD_ITEM':
              return <CardItem {...params} key={block.id} />;
            case 'MAP':
              return <MapBlock {...params} key={block.id} />;
            case 'PARTNERS':
              return <PartnersBlock {...params} key={block.id} />;
            case 'CALL_ACTION':
              return <CallActionBlock {...params} key={block.id} />;
            case 'TIMELINE':
              return <TimelineBlock {...params} key={block.id} />;
            case 'TABLES':
              return <TablesBlock {...params} key={block.id} />;
            case 'TABLES_2':
              return <Tables2Block {...params} key={block.id} />;
            case 'CONTACTS':
              return <ContactsBlock {...params} key={block.id} />;
            case 'POPUP_WIDGETS':
              return <PopupWidgetsBlock {...params} key={block.id} />;
            case '3_COLUMNS_TEXT':
              return <Columns3TextBlock {...params} key={block.id} />;
            case '5_COLUMNS_TEXT':
              return <Columns5TextBlock {...params} key={block.id} />;
            case '2_COLUMNS_TEXT':
              return <Columns2TextBlock {...params} key={block.id} />;
            case '3_COLUMNS_PICTURE':
              return <Columns3PictureBlock {...params} key={block.id} />;
            case '3_COLUMNS_CARDS':
              return <Columns3CardBlock {...params} key={block.id} />;
            case '2_COLUMNS_PICTURE':
              return <Columns2PictureBlock {...params} key={block.id} />;
            case 'FOOTER':
              return <FooterBlock {...params} key={block.id} />;
            case '3_TO_2_ELEMENTS':
              return <Elements3To2Block {...params} key={block.id} />;
            case 'FORM_WITH_VIDEO':
              return <FormWithVideoBlock {...params} key={block.id} />;
            case 'FORM_WITH_PICTURE':
              return <FormWithPictureBlock {...params} key={block.id} />;
            case 'FORM_WITH_TEXT':
              return <FormWithTextBlock {...params} key={block.id} />;
            case 'FAQ':
              return <FAQBlock {...params} key={block.id} />;
            case 'TEXT_WITH_VIDEO':
              return <TextWithVideoBlock {...params} key={block.id} />;
            case 'VIDEO_WITH_TEXT':
              return <VideoWithTextBlock {...params} key={block.id} />;
            case 'BLOGS':
              return <BlogsBlock {...params} key={block.id} />;
            case 'GOODS_AND_SERVICE':
              return <GoodsAndServiceBlock {...params} key={block.id} />;
            case 'LIST_BLOCK':
              return <ListBlock {...params} key={block.id} />;
            case 'QUOTE_AND_REVIEW':
              return <QuoteAndReviewBlock {...params} key={block.id} />;
            case 'FILTERS':
              return <FiltersBlock {...params} key={block.id} />;
            case 'QUIZ_OR_SERVEY':
              return <QuizOrSurveyBlock {...params} key={block.id} />;
            case 'COMMENTS':
              return <CommentsBlock {...params} key={block.id} />;
            case 'EMPTY':
              return <EmptyBlock {...params} key={block.id} />;
            case 'UNKNOWN':
              return <UnknowBlock {...params} key={block.id} />;
            case 'CUSTOM':
              return <CustomBlock {...params} key={block.id} />;
            default:
              return <></>;
          }
        })
      ) : (
        <AddBlockBtn {...addBtnParams} />
      )}
    </>
  );
};
