import { makeStyles } from '@material-ui/core';
const inputWidth = 233;
export const NewSelectStyles = makeStyles(() => ({
  new_select: {
    // width: inputWidth,
    minWidth: 150,
    height: 40,
    background: '#FFFFFF',
    border: '1px solid #6F8194',
    boxSizing: 'border-box',
    // borderRadius: '5px 0px 0px 5px',
    outline: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '17px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& label': {
      position: 'relative',
      top: 5,
      left: 20,
    },
    '& svg': {
      transition: 'All .3s ease-in-out',
    },

    // "& > select":{
    //   position: 'relative',
    //   "& > div": {
    //     position: 'absolute',
    //     top: 10,
    //     left: 30
    //   }
    // }
  },
  box: {
    position: 'absolute',
    display: 'grid',
    width: inputWidth - 16,
    background: '#FFFFFF',
    border: '1px solid #A1AEBB',
    borderRadius: 8,
    padding: '0px 8px',
    transition: 'All .3s linear',
    '& div': {
      height: 40,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0px 8px',
      '&:hover': {
        backgroundColor: 'rgba(48,144,232, 1)',
        color: '#FFFFFF',
      },
    },
    '& div:not(:last-child)': { borderBottom: '1px solid #6F8194' },
  },
  box_show: {
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  select_wrapper: {
    position: 'relative',
  },
  palceholder: {
    position: 'absolute',
    zIndex: 999,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '13px',
    color: '#6F8194',
    top: 2,
    left: 12,
  },
}));
