import {
  getDraftByTempId,
  getDraftTempCategorys,
  getDraftTempCategorysById,
  IDraftTempCategory,
  IDraftTempCategoryItem,
} from '../api/draftTempCategory';
import { useDispatch } from 'react-redux';
import { IDraftTempCategoryActionEnum } from '../store/reducers/draftTemp';
import { createSiteDraftByTemp, putSiteDraftAccess } from '../api/siteDraft';
import { IEditorListItem } from '../store/reducers/editorList';
import { getDraftTemp, getDraftTempByTempId } from '../api/draftTemp';
import {
  DraftReducerActionEnum,
  IDraftAccessState,
} from '../store/reducers/draft';
import { useCallback } from 'react';
// import { SnackbarType, useSnackbar } from './useSnackbar';
import useLocation from 'wouter/use-location';
import { useRequest } from './useRequest';
import { useDND } from './useDND';

const getCatArr = async () => {
  const { data } = await getDraftTempCategorys();
  const arr: IDraftTempCategory[] = [
    { id: 'all', name: 'Все шаблоны', templates_count: 1 },
    { id: 'hot', name: 'Популярные 🔥', templates_count: 2 },
  ];
  return data ? [...arr, ...data] : arr;
};

export const useDraftTemp = () => {
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const { request } = useRequest();
  const { clearDND } = useDND();

  const getDraftTempList = async () => {
    const data = await getCatArr();
    dispatch({
      type: IDraftTempCategoryActionEnum.setDraftTempList,
      payload: data,
    });
  };

  const getDraftTempById = async (id: string) => {
    if (id === 'hot' || id === 'all') {
      const { data } = await getDraftTemp();
      dispatch({
        type: IDraftTempCategoryActionEnum.setDraftTempCatagoryList,
        payload:
          data && id === 'hot'
            ? Array.isArray(data)
              ? data?.length && data.slice(0, 9)
              : []
            : data,
      });
      return;
    }

    const { data } = await getDraftTempCategorysById(id);
    dispatch({
      type: IDraftTempCategoryActionEnum.setDraftTempCatagoryList,
      payload: data,
    });
  };

  const clearDraftTemp = useCallback(() => {
    dispatch({
      type: IDraftTempCategoryActionEnum.clear,
    });
  }, [dispatch]);

  const copyDraftByTempId = useCallback(
    async (
      catagory_id: string,
      temp_id: string,
    ): Promise<IEditorListItem | undefined> => {
      if (catagory_id === 'hot' || catagory_id === 'all') {
        const { data } = await request(
          async () => await getDraftTempByTempId(temp_id),
        );
        return data && (await request(async () => createSiteDraftByTemp(data)));
      }

      const TempDraft = (await getDraftByTempId(catagory_id, temp_id)).data;
      return (
        TempDraft &&
        (await request(async () => createSiteDraftByTemp(TempDraft)))
      );
    },
    [request],
  );

  const setDraftAccess = useCallback(
    async (EditorId: string, access: IDraftAccessState) => {
      dispatch({
        type: DraftReducerActionEnum.setDraftAccess,
        payload: access,
      });
      await request(async () => await putSiteDraftAccess(EditorId, access));
    },
    [dispatch, request],
  );

  const TempUseById = useCallback(
    async (id: string) => {
      try {
        const data = await request(async () => await getDraftTempByTempId(id));
        const some =
          data &&
          (await request(async () => await createSiteDraftByTemp(data)));
        some && setLocation(`/editor/${some.id}`);
        return some;
      } finally {
        clearDraftTemp();
      }
    },
    [clearDraftTemp, setLocation, request],
  );

  const TempUse = useCallback(
    async (item: IDraftTempCategoryItem) => {
      clearDND();
      dispatch({
        type: IDraftTempCategoryActionEnum.setTempItem,
        payload: null,
      });
      const data = await request(
        async () => await getDraftTempByTempId(item.id),
      );

      try {
        const some =
          data &&
          (await request(async () => await createSiteDraftByTemp(data)));
        some && setLocation(`/editor/${some.id}`);
        return some;
      } finally {
        clearDraftTemp();
      }
    },
    [clearDraftTemp, setLocation, request, clearDND, dispatch],
  );

  const goToTemp = useCallback(
    async (item: IDraftTempCategoryItem) => {
      clearDND();
      dispatch({
        type: IDraftTempCategoryActionEnum.setTempItem,
        payload: null,
      });
      try {
        const data = await request(
          async () => await getDraftTempByTempId(item.id),
        );
        // const some = data && await createSiteDraftByTemp(data);
        data && setLocation(`/template/${data.id}`);
        return data;
      } finally {
        clearDraftTemp();
      }
    },
    [clearDraftTemp, setLocation, request, clearDND, dispatch],
  );

  const getTempDraft = useCallback(
    async (id: string) => {
      const data = await request(async () => await getDraftTempByTempId(id));
      dispatch({
        type: IDraftTempCategoryActionEnum.setTempItem,
        payload: data,
      });
    },
    [dispatch, request],
  );

  return {
    getDraftTempList,
    getDraftTempById,
    copyDraftByTempId,
    clearDraftTemp,
    setDraftAccess,
    TempUse,
    goToTemp,
    getTempDraft,
    TempUseById,
  };
};
