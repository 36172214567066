import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const PictureBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.0735 6.43934C74.3441 6.15804 74.7112 6 75.094 6C75.4767 6 75.8438 6.15804 76.1144 6.43934C76.3851 6.72064 76.5371 7.10218 76.5371 7.5C76.5371 7.89782 76.3851 8.27936 76.1144 8.56066C75.8438 8.84196 75.4767 9 75.094 9C74.7112 9 74.3441 8.84196 74.0735 8.56066C73.8028 8.27936 73.6508 7.89782 73.6508 7.5C73.6508 7.10218 73.8028 6.72064 74.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M76.5371 16.5L81.5882 10.5L87 18H69L72.9292 12.75L76.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="154"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
