import { Card, CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import { RequestRegistrationForm } from './RequestResetPasswordForm';
import { SetPassword } from './SetPassword';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: "no-repeat center url('/background.png')",
    height: '100vh',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 456,
    width: '100%',
    position: 'relative',
  },
}));

interface IProps {
  params: {
    token?: string;
  };
}

export function ResetPassword({ params }: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <CardContent>
          {params.token ? (
            <SetPassword token={params.token} />
          ) : (
            <RequestRegistrationForm />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
