import { makeStyles } from '@material-ui/core';

export const MenuStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#3090E8',
      '& svg': {
          fill: '#FFFFFF',
      },
    },
  },
  box: {
    position: 'absolute',
    minWidth: 140,
    width: 'fit-content',
    minHeight: 40,
    background: '#FFFFFF',
    boxShadow: '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 10,
    height: 'auto',
    display: 'grid !important',
    gridTemplateColumns: '1fr',
    right: 0,
    top: 60,
    zIndex: 9999999,
    boxSizing: 'border-box',
    '& h3': {
      fontSize: 16,
      color: '#6F8194',
    },
    padding: 10,
  },
}));
