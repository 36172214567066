import React from 'react';
import useStyles from './styles';

interface IUserRemoveAvatarProps {
  onClick: () => void;
}

export const UserRemoveAvatar = (props: IUserRemoveAvatarProps) => {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <div title="Удалить Аватар">
      <svg
        width="20"
        height="20"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        className={classes.removeAva}
      >
        <circle cx="8" cy="8" r="8" fill="#FD1F6D" />
        <path
          d="M5.52509 4.81832C5.32983 4.62306 5.01325 4.62306 4.81799 4.81832C4.62272 5.01358 4.62272 5.33017 4.81799 5.52543L7.29286 8.0003L4.81799 10.4752C4.62272 10.6704 4.62272 10.987 4.81799 11.1823C5.01325 11.3775 5.32983 11.3775 5.52509 11.1823L7.99997 8.70741L10.4748 11.1823C10.6701 11.3775 10.9867 11.3775 11.1819 11.1823C11.3772 10.987 11.3772 10.6704 11.1819 10.4752L8.70707 8.0003L11.1819 5.52543C11.3772 5.33017 11.3772 5.01358 11.1819 4.81832C10.9867 4.62306 10.6701 4.62306 10.4748 4.81832L7.99997 7.2932L5.52509 4.81832Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
