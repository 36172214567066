import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const FormWithTextBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="46.6667" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          y="7"
          width="45.2525"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="14"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="21"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="0.5"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="9.16699"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="17.833"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <g clipPath="url(#clip0_6812_23000)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.333 0H109.333V24H156.333V0ZM125.493 7.20671L133 2.82682L140.506 7.20671C141.018 7.50471 141.333 8.0539 141.333 8.64551V17C141.333 17.9109 140.577 18.6667 139.667 18.6667H126.333C125.422 18.6667 124.667 17.9109 124.667 17V8.64551C124.667 8.0539 124.982 7.50471 125.493 7.20671ZM139.577 8.59342L133 4.75716L126.423 8.59342L133 12.6852L139.577 8.59342ZM130.5 8.66667L133 6.16667L135.5 8.66667H133.833V10.6491L133 11.1667L132.167 10.6491V8.66667H130.5ZM133 14.6481L126.333 10.501V17H139.667V10.501L133 14.6481Z"
            fill={blockDefaultColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6812_23000">
            <rect x="109.333" width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
