import React, { FC, useState, useEffect, useCallback } from 'react';
import TextArea from 'react-textarea-autosize';
import { useEditorSitemap } from '../../../hooks/useEditorSitemap';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  area: {
    width: '100%',
    height: 40,
    border: 'none',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    outline: 'none',
    resize: 'none',
    // '&:focus': {
    //   borderBottom: '1px solid #3090E8'
    // }
  },
}));

export const EditorTitle: FC = () => {
  const cc = useStyle();
  const [title, setTitle] = useState('');
  const editorName = useTypeSelector((s) => s.editor.name);
  const { setDraftName } = useEditorSitemap();

  const setStateTitle = useCallback(() => {
    setDraftName(title);
  }, [title, setDraftName]);

  useEffect(() => {
    setTitle(editorName);
  }, [editorName]);

  return (
    <TextArea
      className={cc.area}
      value={title}
      onChange={(e: any) => setTitle(e.target.value)}
      onBlur={setStateTitle}
    />
  );
};
