import React, { FC } from 'react';
import { NewEditorBar } from './NewEditorBar/NewEditorBar';
import { NewProfileBar } from './NewEditorBar/NewProfileBar';

export const Bar: FC = () => {

  return (
    <>
      <NewEditorBar />
      <NewProfileBar />
    </>
  );
};
