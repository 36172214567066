import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { NewModalReducerActionEnum } from '../store/reducers/newmodel';

export const useModal = () => {
  const dispatch = useDispatch();

  return {
    showModal: () => {
      dispatch({ type: NewModalReducerActionEnum.setModal, payload: true });
    },
    hideModal: () => {
      dispatch({ type: NewModalReducerActionEnum.setModal, payload: false });
    },
    setModalBody: (payload: ReactNode) => {
      dispatch({ type: NewModalReducerActionEnum.setBody, payload });
    },
  };
};
