import { useTypeSelector } from './useTypesSelector';
import { useCallback } from 'react';

export const lastLocation = '__lu';

export const useLastLocation = () => {
  const email = useTypeSelector((s) => s.profile.profile?.email);

  const set = useCallback(
    (val: string) => {
      email !== undefined &&
        localStorage.setItem(`${lastLocation}_${email}`, val);
    },
    [email],
  );

  const get = useCallback(() => {
    const l = localStorage.getItem(`${lastLocation}_${email}`);
    if (l) {
      return l;
    }
    return undefined;
  }, [email]);

  return {
    get,
    set,
  };
};
