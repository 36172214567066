import React, { ReactNode, useEffect, useRef } from 'react';
import { IconsOutline, IIconsOutlineTypes } from '../IconOutliner';
import { MenuStyle } from './MenuStyle';

export interface IDropDownMenuBtnProps {
  children: ReactNode;
  icon: IIconsOutlineTypes;
  show: boolean;
  setShow: (val: boolean) => void;
  clickOutside?: (val: boolean) => void;
}

export const DropDownMenuBtn = (props: IDropDownMenuBtnProps) => {
  const cc = MenuStyle();
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.clickOutside ? props.clickOutside(false) : props.setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props, wrapperRef]);

  return (
    <div className={cc.wrapper} ref={wrapperRef}>
      <div
        style={{
          width: 68,
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => props.setShow(!props.show)}
      >
        <IconsOutline
          types={props.icon}
          width={24}
          height={24}
          noHover
          defaultColor="#3090E8"
          hoverColor="#FFFFFF"
        />
      </div>
      {props.show && <div className={cc.box}>{props.children}</div>}
    </div>
  );
};
