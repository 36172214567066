import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const ListBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="6" height="6" rx="3" fill={blockDefaultColor} />
        <rect
          x="10"
          y="1.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect y="9" width="6" height="6" rx="3" fill={blockDefaultColor} />
        <rect
          x="10"
          y="10.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect y="18" width="6" height="6" rx="3" fill={blockDefaultColor} />
        <rect
          x="10"
          y="19.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="64.6667"
          y="1.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="9"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="64.6667"
          y="10.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="18"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="64.6667"
          y="19.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="119.333"
          y="1.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          y="9"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="119.333"
          y="10.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          y="18"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="119.333"
          y="19.5"
          width="36.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
