import { makeStyles } from '@material-ui/core';
import checkIco from '../../../assets/check.svg';
const color = '#6F8194';

export default makeStyles(() => ({
  wrapper: {
    width: 300,
    display: 'flex',
    justifyContent: 'center',
  },
  steps: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    left: 48,
    marginBottom: 20,
  },
  step: {
    width: 24,
    height: 24,
    border: '1px solid #6F8194',
    color,
    borderRadius: 5,
    position: 'relative',
    marginRight: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:first-child)::after':{
      content: '""',
      width: 48,
      position: 'absolute',
      top: 12,
      left: -48,
      display: 'block',
      height: 1,
      backgroundColor: color,
    },
  },
  activeStep: {
    background: color,
    color: 'white',
  },
  successStep: {
    background: '#EAFFFF',
    color: '#EAFFFF',
    '&::before':{
      content: `url("${checkIco}")`,
      position: 'absolute',
      top: 4,
      left: 4,
      display: 'block',
    },
  },
}));
