import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const HeaderBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="15"
          width="24"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="34"
          y="16"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="54"
          y="16"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="74"
          y="16"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="95"
          y="15"
          width="27"
          height="6"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="127"
          y="14"
          width="29"
          height="8"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          width="156"
          height="2"
          rx="1"
          transform="matrix(1 0 0 -1 0 2)"
          fill={blockDefaultColor}
        />
        <rect
          width="2"
          height="6"
          rx="1"
          transform="matrix(1 0 0 -1 154 6)"
          fill={blockDefaultColor}
        />
        <rect
          width="2"
          height="6"
          rx="1"
          transform="matrix(1 0 0 -1 0 6)"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
