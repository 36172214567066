import React, { memo } from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  btn: {
    width: 25,
    height: 25,
    border: '1px solid #3090E8',
    background: '#3090E8',
    borderRadius: ' 0 0 15px 15px',
    transition: 'all 0.3s ease-in-out',
    transform: 'translateY(-1px)',
    '& svg': {
      transform: 'translateX(0.5px)',
    },
    '& .plus': {
      fill: '#FCFEFF',
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover': {
      background: '#FCFEFF',
      '& .plus': {
        fill: '#3090E8',
        stroke: '#3090E8',
      },
    },
  },
}));
interface IAddBtnProps {
  onClick: () => void;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const AddBtn = memo((props: IAddBtnProps) => {
  const cc = useStyles();
  return (
    <div
      title={props.title}
      className={cx(cc.btn, props.className ? props.className : '')}
      onClick={props.onClick}
      style={props.style}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.2859 11.2143H12.7859V5.71426C12.7859 5.28055 12.4339 4.92855 12.0001 4.92855C11.5664 4.92855 11.2144 5.28055 11.2144 5.71426V11.2143H5.71443C5.28071 11.2143 4.92871 11.5663 4.92871 12C4.92871 12.4337 5.28071 12.7857 5.71443 12.7857H11.2144V18.2857C11.2144 18.7194 11.5664 19.0714 12.0001 19.0714C12.4339 19.0714 12.7859 18.7194 12.7859 18.2857V12.7857H18.2859C18.7196 12.7857 19.0716 12.4337 19.0716 12C19.0716 11.5663 18.7196 11.2143 18.2859 11.2143Z"
          className="plus"
        />
      </svg>
    </div>
  );
});
