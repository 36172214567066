import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
// import ReactGA from 'react-ga';
// import { YMInitializer } from 'react-yandex-metrika';

// ReactGA.initialize('UA-207904291-1', {
//   debug: false,
//   titleCase: false,
// });

// ReactGA.pageview(window.location.pathname + window.location.search);

const container = document.getElementById('root');
const root = container && createRoot(container);

root?.render(
  <Provider store={store}>
    {/* <YMInitializer
      accounts={[73562104]}
      options={{
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        defer: true,
      }}
      version="2"
    /> */}
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
