import { makeStyles } from '@material-ui/core';

export const NewEditorListStyles = makeStyles(() => ({
  new_editor_wrapper: {
    display: 'grid',
    padding: 24,
    rowGap: 24,
    backgroundColor: '#FFFFFF',
    '& h2': {
      'font-family': 'Roboto',
      'font-style': 'normal',
      'font-weight': 500,
      'font-size': 18,
      margin: 0,
      display: 'flex',
      'align-items': 'center',
      color: '#353E47',
    },
  },
  new_editor_wrapper_header: {
    display: 'flex',
    columnGap: 24,
    flexWrap: 'wrap',
    rowGap: 15,
    '@media (max-width: 500px)': {
      justifyContent: 'center',
      '& .newbtn_caption': {
        display: 'none',
      },
    },
  },
  new_editor_wrapper_header_btn: {
    display: 'flex',
    columnGap: 24,
    flexWrap: 'wrap',
    rowGap: 15,
    '@media (max-width: 660px)': {
      justifyContent: 'center',
      '& button': {
        width: '100%',
      },
    },
  },
  new_editor_wrapper_body_template: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    columnGap: 30,
    rowGap: 30,
    maxWidth: 'calc(100vw - 50px)',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0 0 20px 0',
    '&::-webkit-scrollbar': {
      scrollbarWidth: 2,
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(gray, 0.7)',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#353E47',
      borderRadius: 10,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#3090E8',
      },
    },
  },
  new_editor_wrapper_body: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 30,
    rowGap: 30,
    padding: '0 0 20px 0',
  },
  empty_editor: {
    width: '100%',
    border: '2px dashed #3090E8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: '20px 30px',
  },
  link: {
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'right',
    textDecoration: 'underline',
    color: '#3090E8',
    cursor: 'pointer',
    padding: '0 5px',
  },
}));
