import React, { memo } from 'react';
import useStyles from './styles';
import cx from 'clsx';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface ICommnetsProps {
  onClick: () => void;
  setted?: boolean
  className?: string;
  style?: CSSProperties;
}

export const Comments = memo((props: ICommnetsProps) => {
  const cc = useStyles();

  return (
    <div
      className={cx(cc.commentsWrapper,  props.setted && props.setted ? cc.setCommentsWrapper : '')}
      style={props.style}
      onClick={props.onClick}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 1.66699C5.4572 1.66699 1.66669 4.97869 1.66669 9.16699C1.66669 13.3553 5.4572 16.667 10 16.667C10.2885 16.667 10.5543 16.6091 10.8334 16.5824V19.2451L12.1175 18.4215C13.9194 17.2651 17.8209 14.2632 18.2862 9.95475C18.3157 9.69845 18.3334 9.43595 18.3334 9.16699C18.3334 4.97869 14.5428 1.66699 10 1.66699ZM10 3.33366C13.7422 3.33366 16.6667 5.99196 16.6667 9.16699C16.6667 9.36749 16.6543 9.56751 16.6309 9.76921V9.77246L16.6293 9.77572C16.3413 12.4429 14.2063 14.5377 12.5 15.9036V14.6439L11.5023 14.8457C11.0143 14.9442 10.5128 15.0003 10 15.0003C6.25784 15.0003 3.33335 12.342 3.33335 9.16699C3.33335 5.99196 6.25784 3.33366 10 3.33366ZM5.83335 8.33366V10.0003H7.50002V8.33366H5.83335ZM9.16669 8.33366V10.0003H10.8334V8.33366H9.16669ZM12.5 8.33366V10.0003H14.1667V8.33366H12.5Z"
        />
      </svg>
    </div>
  );
});
