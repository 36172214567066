import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const ContactsBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.0173 14.616C22.5448 14.6047 21.95 14.577 21.5743 14.511C21.1678 14.439 20.6787 14.2995 20.3345 14.1923C20.0645 14.1083 19.7705 14.1825 19.5702 14.382L17.9075 16.035C16.7585 15.4297 15.8382 14.7248 15.059 13.941C14.2752 13.1618 13.5702 12.2415 12.965 11.0925L14.618 9.429C14.8175 9.22875 14.8917 8.93475 14.8077 8.66475C14.7012 8.32125 14.561 7.83225 14.4897 7.42575C14.423 7.05 14.396 6.45525 14.384 5.98275C14.3742 5.574 14.0413 5.25 13.6325 5.25H11C10.6715 5.25 10.25 5.496 10.25 6C10.25 9.40425 11.63 12.6555 13.9745 15.0255C16.3445 17.37 19.5957 18.75 23 18.75C23.504 18.75 23.75 18.3285 23.75 18V15.3675C23.75 14.9587 23.426 14.6257 23.0173 14.616Z"
          fill={blockDefaultColor}
        />
        <path
          d="M57.6667 4.5C53.5332 4.5 50.1667 7.8665 50.1667 12C50.1667 16.1335 53.5332 19.5 57.6667 19.5H61.4167V18H57.6667C54.3437 18 51.6667 15.323 51.6667 12C51.6667 8.677 54.3437 6 57.6667 6C60.9897 6 63.6667 8.677 63.6667 12V13.125C63.6667 13.7556 63.1724 14.25 62.5417 14.25C61.9111 14.25 61.4167 13.7556 61.4167 13.125V12C61.4167 9.93782 59.7289 8.25 57.6667 8.25C55.6046 8.25 53.9167 9.93782 53.9167 12C53.9167 14.0622 55.6046 15.75 57.6667 15.75C58.7269 15.75 59.6826 15.2992 60.3665 14.5854C60.8398 15.2856 61.64 15.75 62.5417 15.75C63.9826 15.75 65.1667 14.5659 65.1667 13.125V12C65.1667 7.8665 61.8002 4.5 57.6667 4.5ZM57.6667 9.75C58.9183 9.75 59.9167 10.7485 59.9167 12C59.9167 13.2515 58.9183 14.25 57.6667 14.25C56.4152 14.25 55.4167 13.2515 55.4167 12C55.4167 10.7485 56.4152 9.75 57.6667 9.75Z"
          fill={blockDefaultColor}
        />
        <path
          d="M98.3333 4.5C94.2083 4.5 90.8333 7.875 90.8333 12C90.8333 13.35 91.2088 14.625 91.8088 15.75L90.8333 19.5L94.7327 18.6006C95.8577 19.2006 97.0583 19.5 98.3333 19.5C102.458 19.5 105.833 16.125 105.833 12C105.833 9.975 105.083 8.10029 103.658 6.67529C102.233 5.25029 100.358 4.5 98.3333 4.5ZM98.3333 6C99.9083 6 101.408 6.60059 102.608 7.72559C103.733 8.92559 104.333 10.425 104.333 12C104.333 15.3 101.633 18 98.3333 18C97.3583 18 96.3077 17.775 95.4827 17.25L94.9583 16.9497L94.4338 17.1006L92.9338 17.4756L93.3088 16.125L93.4583 15.5244L93.158 15C92.633 14.1 92.3333 13.05 92.3333 12C92.3333 8.7 95.0333 6 98.3333 6ZM95.7083 8.55029C95.5583 8.55029 95.3338 8.54941 95.1838 8.77441C94.9588 8.99941 94.5085 9.45059 94.5085 10.3506C94.5085 11.2506 95.1835 12.1503 95.2585 12.3003C95.4085 12.4503 96.6077 14.3994 98.4827 15.1494C100.058 15.7494 100.358 15.6006 100.733 15.6006C101.108 15.5256 101.859 15.1497 102.009 14.6997C102.159 14.2497 102.158 13.8744 102.233 13.6494C102.158 13.5744 102.083 13.5003 101.858 13.4253C101.708 13.3503 100.808 12.8997 100.583 12.8247C100.358 12.7497 100.284 12.6744 100.134 12.8994C99.9835 13.1244 99.608 13.4994 99.533 13.6494C99.458 13.7994 99.383 13.8006 99.158 13.7256C99.008 13.6506 98.408 13.4997 97.658 12.8247C97.058 12.3747 96.6827 11.7753 96.6077 11.5503C96.4577 11.4003 96.6088 11.2503 96.6838 11.1753L96.9827 10.875C97.0577 10.8 97.0585 10.6497 97.1335 10.5747C97.2085 10.4997 97.2085 10.4244 97.1335 10.2744C97.0585 10.1244 96.683 9.22559 96.533 8.85059C96.383 8.55059 96.2333 8.55029 96.0833 8.55029H95.7083Z"
          fill={blockDefaultColor}
        />
        <path
          d="M139 4.50781C136.108 4.50781 133.756 6.86142 133.756 9.75342C133.756 13.1157 138.231 18.5774 138.421 18.8076L139 19.5093L139.579 18.8076C139.769 18.5766 144.244 13.1157 144.244 9.75342C144.244 6.86067 141.892 4.50781 139 4.50781ZM139 6.00781C141.065 6.00781 142.744 7.68867 142.744 9.75342C142.744 11.7822 140.409 15.2714 139 17.1201C137.592 15.2729 135.256 11.7852 135.256 9.75342C135.256 7.68867 136.935 6.00781 139 6.00781ZM139 7.87549C137.964 7.87549 137.125 8.71474 137.125 9.75049C137.125 10.7862 137.964 11.6255 139 11.6255C140.036 11.6255 140.875 10.7862 140.875 9.75049C140.875 8.71474 140.036 7.87549 139 7.87549Z"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
