import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'wouter';

import { Login } from './views/Login';
import { Registration, RegistrationSteps } from './views/Registration';
import { Settings } from './views/Settings';
import { Layout } from './Layout';
import { Feedback } from './views/Feedback';
import { Error404 } from './components/Error404';
import theme from './utils/theme';
import { DefaultParams } from 'wouter/matcher';
import { GoogleRegister } from './views/Registration/GoogleRegister';

import { EditorList } from './views/EditorList/index';
import { EditorItem } from './views/EditorItem/index';
import { ResetPassword } from './views/Registration/PasswordReset/ResetPassword';
import { NewEditorList } from './views/EditorList/newEditorList';
import { NewEditorItem } from './views/EditorItem/newEditorItem';
import { Template } from './views/Template/Template';
import { TemplateItem } from './views/Template/TemplateItem';

import { YandexRegister } from './views/Registration/YandexRegister';

const excludeRoutes = ['/login', '/registration', '/password-reset'];

function isInExcludeRoutes(loc: string, filtred: boolean = false) {
  if (!filtred) {
    return excludeRoutes.some((r) => loc.includes(r));
  }
  return excludeRoutes
    .filter((item) => item !== '/password-reset')
    .some((r) => loc.includes(r));
}

function PrivateRoute<T extends DefaultParams = DefaultParams>(
  routeProps: RouteProps<T>,
) {
  const [location] = useLocation();

  if (localStorage.getItem('access_token')) {
    if (isInExcludeRoutes(location, true)) {
      return <Redirect to="/editor" />;
      // return <Route {...routeProps} />;
    }

    if (location === '/') {
      return <Redirect to="/editor" />;
    }

    return <Route {...routeProps} />;
  }

  if (isInExcludeRoutes(location)) {
    return <Route {...routeProps} />;
  }

  return <Redirect to="/login" />;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Switch>
          <PrivateRoute path="/" />
          <PrivateRoute path="/login" component={Login} />
          <PrivateRoute
            path="/registration/:token"
            component={RegistrationSteps}
          />
          <PrivateRoute path="/registration" component={Registration} />
          <PrivateRoute path="/login/google" component={GoogleRegister} />
          <PrivateRoute path="/login/yandex" component={YandexRegister} />
          <PrivateRoute
            path="/settings"
            component={() => <Redirect to="/profile" />}
          />
          <PrivateRoute path="/profile" component={Settings} />
          {/*<PrivateRoute path="/archive" component={Archive} />*/}
          {/* <PrivateRoute path="/sitemap" component={SitemapList} /> */}
          {/* <PrivateRoute path="/sitemap/:id" component={Sitemap} /> */}
          <PrivateRoute path="/password-reset" component={ResetPassword} />

          <PrivateRoute
            path="/password-reset/:token"
            component={ResetPassword}
          />
          <PrivateRoute path="/feedback" component={Feedback} />
          <PrivateRoute path="/editor" component={EditorList} />
          {/* <PrivateRoute path="/editor/:id" component={EditorItem} /> */}
          <PrivateRoute path="/neweditor" component={NewEditorList} />
          <PrivateRoute path="/template" component={Template} />
          <PrivateRoute path="/template/:id" component={TemplateItem} />
          <Route path="/neweditor/:id" component={NewEditorItem} />
          <Route path="/editor/:id" component={EditorItem} />
          <Route component={Error404} />
        </Switch>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
