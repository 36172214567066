import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const PictureActionBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0735 6.43934C20.3441 6.15804 20.7112 6 21.094 6C21.4767 6 21.8438 6.15804 22.1144 6.43934C22.3851 6.72064 22.5371 7.10218 22.5371 7.5C22.5371 7.89782 22.3851 8.27936 22.1144 8.56066C21.8438 8.84196 21.4767 9 21.094 9C20.7112 9 20.3441 8.84196 20.0735 8.56066C19.8028 8.27936 19.6508 7.89782 19.6508 7.5C19.6508 7.10218 19.8028 6.72064 20.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M22.5371 16.5L27.5882 10.5L33 18H15L18.9292 12.75L22.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="46"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect x="56" width="100" height="24" rx="4" fill={blockDefaultColor} />
        <path
          d="M106.369 4C104.094 4 102.244 5.85025 102.244 8.125C102.244 9.67506 103.131 11.0793 104.494 11.7783V14.2202L103.253 13.6416C102.543 13.3101 101.706 13.4687 101.166 14.0342L100 15.2559L104.585 19.8408C105.01 20.2661 105.575 20.5 106.176 20.5H110.494C111.735 20.5 112.744 19.4905 112.744 18.25V14.5015C112.744 13.4222 111.975 12.4928 110.915 12.291L108.244 11.7842V11.7783C109.607 11.0793 110.494 9.67506 110.494 8.125C110.494 5.85025 108.644 4 106.369 4ZM106.369 5.5C107.817 5.5 108.994 6.6775 108.994 8.125C108.994 8.81873 108.713 9.46723 108.244 9.9458V8.875C108.244 7.8415 107.403 7 106.369 7C105.336 7 104.494 7.8415 104.494 8.875V9.9458C104.025 9.46723 103.744 8.81873 103.744 8.125C103.744 6.6775 104.922 5.5 106.369 5.5ZM106.369 8.5C106.576 8.5 106.744 8.668 106.744 8.875V13.0249L110.635 13.7646C110.988 13.8314 111.244 14.1422 111.244 14.5015V18.25C111.244 18.6632 110.907 19 110.494 19H106.176C105.976 19 105.787 18.922 105.646 18.7803L102.096 15.231L102.251 15.0698C102.347 14.9701 102.495 14.9432 102.619 15.001L105.994 16.5757V8.875C105.994 8.668 106.162 8.5 106.369 8.5Z"
          fill="white"
        />
      </svg>
    </Block>
  );
});
