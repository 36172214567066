import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { NewShareBtnStyle } from './NewShareBtnStyle';
import { CopyBtn } from '../CopyBtn/CopyBtn';
import cx from 'clsx';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useDraftTemp } from '../../hooks/useDraftTemp';
import { DeepCopy } from '../../utils/DeepCopy';
import { Toggler } from '../Toggler/Toggler';

export const NewShareBtn: FC = () => {
  const cc = NewShareBtnStyle();
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  const access = useTypeSelector(s=>s.draft.access)
  const [share, setShare] = useState(false);
  const EditorId = useTypeSelector((s) => s.editor.id);
  const {setDraftAccess} = useDraftTemp()

  const toggle = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  const changeShare = useCallback(
    (e: boolean) => {
      const acc = DeepCopy(access)
      if (e === true) {
        acc.read = true
      }
      if (e === false) {
        acc.read = false
      }

      setDraftAccess(EditorId,acc)
    },
    [EditorId,access,setDraftAccess],
  );

  const genLink = () => {
    return `${window.location.href}?utm_source=copy_link`;
  };

  useEffect(() => {
    if (access.read) {
      setShare(true);
      return;
    }
    setShare(false);
  }, [access.read, setShare]);

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className={cc.share_wrapper} ref={wrapperRef}>
      <div className={cc.share_btn} onClick={toggle}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15.0006 5.18815 15.0189 5.37583 15.0547 5.56055L7.93945 9.71094C7.39751 9.25184 6.71026 8.99992 6 9C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12C3 12.7956 3.31607 13.5587 3.87868 14.1213C4.44129 14.6839 5.20435 15 6 15C6.70916 14.9986 7.3949 14.746 7.93555 14.2871L15.0547 18.4395C15.0189 18.6242 15.0006 18.8118 15 19C15 19.7956 15.3161 20.5587 15.8787 21.1213C16.4413 21.6839 17.2044 22 18 22C18.7956 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7956 21 19C21 18.2044 20.6839 17.4413 20.1213 16.8787C19.5587 16.3161 18.7956 16 18 16C17.2902 16.0009 16.6037 16.2535 16.0625 16.7129L8.94531 12.5605C8.98108 12.3758 8.99939 12.1882 9 12C8.99939 11.8118 8.98108 11.6242 8.94531 11.4395L16.0605 7.28906C16.6025 7.74816 17.2897 8.00008 18 8C18.7956 8 19.5587 7.68393 20.1213 7.12132C20.6839 6.55871 21 5.79565 21 5C21 4.20435 20.6839 3.44129 20.1213 2.87868C19.5587 2.31607 18.7956 2 18 2Z"
            fill="white"
          />
        </svg>
      </div>
      {show && (
        <div className={cx(cc.share_box, share === true ? 'open' : '')}>
          <div className={cc.share_header}>
            <h2>Поделиться</h2>
            {/* <ClosePopup onClick={() => setShow(false)} /> */}
          </div>
          <div className={cc.share_body}>
            <Toggler value={share} onChange={changeShare} caption='Доступ по ссылке' />
            <br />
            <div className="share_copy">
              <CopyBtn value={genLink()} onAfterClick={() => setShow(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
