import { Button, Link, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { requestRegistration } from '../../api/users.api';
import {
  IRegistrationMessage,
  RegistrationTypeMessageEnum,
} from '../../store/reducers/registration';
import useStyles from './styles/registrationForm';
import { RegistrationMessage } from './RegistrationMessage';
import { RegistrationTimer } from './RegistrationTimer';
import { Step } from './Step';
import cx from 'clsx';
import { RegistrationDescription } from './RegistrationDescription';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { GoogleBtnLogin } from '../Login/components/GoogleBtnLogin';
import { RegistrationNotice } from './RegistrationNotice';
import { useRegistration } from '../../hooks/useRegistration';
// import { useAnalitics } from '../../hooks/useAnalitics';
import { UTMArrayToApiString } from '../../utils/UTMArrayToApiString';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import { YandexBtnLogin } from '../Login/components/YandexBtnLogin';

export function RequestRegistrationForm() {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const utm = useTypeSelector((e) => e.registration.utm);
  const promo = useTypeSelector((e) => e.registration.promo);
  const site_draft_query = useTypeSelector(
    (s) => s.registration.siteDraftQuery,
  );
  const [loginLink, setLoginLink] = useState('/login');
  const { setRegistrationMessage, setUserEmail, setTitle, addStep, setPromo } =
    useRegistration();
  const { setSnackbarValue } = useSnackbar();

  // const analitics = useAnalitics();

  // const getUTM = (key: string) => {
  //   const arr = utm.filter((item) => item.key === key);
  //   return arr[0] && arr[0].value ? arr[0].value : '';
  // };

  const formik = useFormik({
    initialValues: {
      email: '',
      promo_code: '',
    },
    async onSubmit(values) {
      localStorage.setItem('reg_type', 'normal');
      setBtnDisabled(true);
      setUserEmail(values.email);

      const payload: any = {
        email: values.email,
        promo_code: promo,
        site_draft_query,
        referrer_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
      };
      // for (const [key] of Object.entries(payload)) {
      //   if (key !== 'email' && key !== 'promo_code') {
      //     // @ts-ignore
      //     payload[key] = getUTM(key);
      //   }
      // }
      for (const item of utm) {
        payload[item.key] = item.value;
      }

      try {
        const { ok } = await requestRegistration(payload);
        if (ok) {
          // analitics('start', 'Успешная отправка формы "Регистрация"');
          setSuccess(true);
          setTitle('Подтверждение');
          addStep();
          const payload: IRegistrationMessage = {
            text: 'На указанный адрес было отправлено\nписьмо с продолжением регистрации',
            type: RegistrationTypeMessageEnum.success,
          };
          setRegistrationMessage(payload);
        }
      } catch (e: any) {
        setSnackbarValue(e.error.error_description, SnackbarType.Error);
      } finally {
        setBtnDisabled(false);
      }
    },
  });

  useEffect(() => {
    setLoginLink(`/login${UTMArrayToApiString(utm)}`);
  }, [utm, loginLink, setLoginLink]);

  if (success) {
    // Redirect({ path: '/registration' });
    return (
      <>
        <Step />
        <RegistrationDescription />
        <RegistrationMessage />
        <RegistrationNotice
          text="Наши письма доходят практически моментально, но иногда попадают в спам. Пожалуйста, если вы столкнулись с этим, нажмите кнопку “Не спам”"
          type={RegistrationTypeMessageEnum.warning}
        />
        <RegistrationTimer />
        <div className={cx(classes.signUpWrapper, classes.steper)}>
          <Link href="/registration" className={classes.signInLink}>
            Другой Email
          </Link>
          <Link href="/login" className={classes.signInLink}>
            Войти
          </Link>
        </div>
      </>
    );
  }

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <TextField
        required
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        name="email"
        fullWidth
        margin="dense"
        label="Email"
      />
      <TextField
        type="promo_code"
        value={promo}
        onChange={(e) => setPromo(e.target.value)}
        name="promo_code"
        fullWidth
        margin="dense"
        label="Промокод, если есть"
      />
      <Button
        className={classes.button}
        size="large"
        variant="contained"
        color="primary"
        type="submit"
        disabled={btnDisabled}
      >
        Отправить
      </Button>
      <div className={classes.signUpWrapper}>
        <div className={classes.more}>
          <span className="line"></span>
          <span>ИЛИ</span>
          <span className="line"></span>
        </div>
        <GoogleBtnLogin />
        <YandexBtnLogin />
        <Link href={loginLink} className={classes.signInLink}>
          Войти
        </Link>
      </div>
    </form>
  );
}
