import { Avatar } from '@material-ui/core';
import React, { FormEvent, useCallback, useEffect, useRef } from 'react';
import useStyles from './styles';
import cx from 'clsx';
import { UserRemoveAvatar } from './UserRemoveAvatar';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { useDispatch } from 'react-redux';
import { ImageProfileActionTypeEnum } from '../../../store/reducers/settings/profile';
import {
  deleteProfileImage,
  getProfileImage,
  setProfileImage,
} from '../../../api/profile.api';
import { FileToBase } from '../../../utils/FileToBase';
import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';
import { BlobToBase } from '../../../utils/BlobToBase';

export const UserAvatar = () => {
  const classes = useStyles();
  const profile = useTypeSelector((s) => s.profile.profile);
  const image = useTypeSelector((s) => s.settings.profile.image);
  const file = useRef(null);
  const dispatch = useDispatch();
  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);

  const setImage = useCallback(
    (imgString: string | null) => {
      dispatch({
        type: ImageProfileActionTypeEnum.setImageToProfile,
        payload: imgString,
      });
    },
    [dispatch],
  );

  const getImageFromApi = useCallback(async () => {
    try {
      const { data, ok } = await getProfileImage();
      ok && data && setImage(await BlobToBase(data));
    } catch (e) {
      console.warn('Don`t have avatar');
    }
  }, [setImage]);

  const onRemoveAva = useCallback(async () => {
    const { ok } = await deleteProfileImage();
    if (!ok) {
      setSnackbarValue('Не удалось удалить аватар', SnackbarType.Error);
    }
    if (ok) {
      setImage(null);
    }
  }, [setImage, setSnackbarValue]);

  const onChangeImg = async (event: FormEvent<HTMLInputElement>) => {
    // @ts-ignore
    const f: File = event.target.files[0];
    const { ok } = await setProfileImage(f);
    if (!ok) {
      setSnackbarValue('Не удалось загрузить файл', SnackbarType.Error);
    }
    if (ok) {
      setImage(await FileToBase(f));
    }
  };

  const onEditAva = useCallback(() => {
    // @ts-ignore
    file.current && file?.current?.click();
  }, []);

  useEffect(() => {
    getImageFromApi();
  }, [getImageFromApi]);

  return (
    <div className={classes.avaBlock}>
      {profile && (
        <div
          className={cx(classes.avaSize, classes.avaWrapper)}
          title="Изменить Аватар"
          onClick={onEditAva}
        >
          <input
            type="file"
            ref={file}
            className={classes.avaInput}
            onChange={onChangeImg}
            name="avatar"
          />
          {image ? (
            <Avatar
              alt={profile.name}
              src={image}
              className={cx(classes.avaSize)}
            />
          ) : (
            <Avatar alt={profile.name} className={classes.avaSize} />
          )}
        </div>
      )}
      {image && <UserRemoveAvatar onClick={onRemoveAva} />}
    </div>
  );
};
