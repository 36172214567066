import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const VideoBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M78 4.5C73.8578 4.5 70.5 7.85775 70.5 12C70.5 16.1423 73.8578 19.5 78 19.5C82.1423 19.5 85.5 16.1423 85.5 12C85.5 7.85775 82.1423 4.5 78 4.5ZM76.5 14.625V9.375C76.5 9.066 76.8525 8.88975 77.1 9.075L80.6003 11.7C80.8005 11.85 80.8005 12.15 80.6003 12.3L77.1 14.925C76.8525 15.1102 76.5 14.934 76.5 14.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="154"
          height="22"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
