import React, { memo, useCallback } from 'react';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import useStyles from './styles';

export interface ICopyBtnProps {
  value: string;
  onAfterClick?: () => void;
}

export const CopyBtn = memo((props: ICopyBtnProps) => {
  const cc = useStyles();
  const { setSnackbarValue } = useSnackbar();
  const copy = useCallback(() => {
    window.navigator.clipboard.writeText(props.value);
    setSnackbarValue('Скопировано', SnackbarType.Success);
    props.onAfterClick && props.onAfterClick();
  }, [props, setSnackbarValue]);
  return (
    <div className={cc.copy_btn} onClick={copy}>
      <span>Скопировать ссылку</span>
    </div>
  );
});
