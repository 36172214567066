import { makeStyles } from '@material-ui/core';

export const BySiteStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 1220,
    height: 'fit-content',
    // display: 'grid',
    // gridTemplateColumns: '284px 1fr',
    width: '100%',
    '@media (max-width: 1300px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    color: '#353E47',
    margin: '0 0 5px 0',
  },
  link: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    textDecoration: 'underline',
    color: '#3090E8',
    margin: '0px 0px 16px 0px',
    display: 'inline-block',
  },
  demo: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    color: '#A1AEBB',
    margin: '16px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  examples: { display: 'flex', alignItems: 'center', columnGap: 10 },
  bySiteInputs: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #6F8194',
    borderRadius: 5,
    // width: 920,
    '@media (max-width: 1300px)': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1.5fr 167px',
      '& .MuiInputBase-root': {
        border: 'none !important',
      },
      '& input': {
        border: 'none !important',
        borderLeft: '1px solid #6F8194 !important',
        width: '100%',
      },
    },
    '@media (max-width: 780px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      '& .MuiInputBase-root': {
        border: 'none !important',
      },
      '& input': {
        border: 'none !important',
        borderTop: '1px solid #6F8194 !important',
        borderBottom: '1px solid #6F8194 !important',
      },
    },
  },
  btn: {
    border: 'none !important',
    borderLeft: '1px solid #6F8194 !important',
    '@media (max-width: 780px)': {
      width: '100%',
      boxSizing: 'border-box',
      border: 'none !important',
      borderLeft: 'none !important',
      borderRadius: '0 0 0 0',
    },
  },
}));
