import { toPNGResponse } from './toPNG';

export const toPNGinPreview = async ({
  canvas,
  base,
  maxW = 280,
  maxH = 200
}: toPNGResponse): Promise<string> =>
  new Promise((resolve) => {
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.min(maxW / iw, maxH / ih);
      const iwScaled = iw * scale;
      const ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx && ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      const result = canvas.toDataURL('image/png', 0.5);
      resolve(result);
    };
    img.src = base;
  });
