import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const PictureTextBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0735 6.43934C16.3441 6.15804 16.7112 6 17.094 6C17.4767 6 17.8438 6.15804 18.1144 6.43934C18.3851 6.72064 18.5371 7.10218 18.5371 7.5C18.5371 7.89782 18.3851 8.27936 18.1144 8.56066C17.8438 8.84196 17.4767 9 17.094 9C16.7112 9 16.3441 8.84196 16.0735 8.56066C15.8028 8.27936 15.6508 7.89782 15.6508 7.5C15.6508 7.10218 15.8028 6.72064 16.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M18.5371 16.5L23.5882 10.5L29 18H11L14.9292 12.75L18.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="38"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect x="46" width="110" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="46"
          y="7"
          width="107"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="46"
          y="14"
          width="110"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="46"
          y="21"
          width="47"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
