export const FileToBase = async (file: File): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // @ts-ignore
      reader.result && resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
