import React, { useCallback, useEffect } from 'react';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { Button } from '@material-ui/core';
import {
  IRegistrationMessage,
  RegistrationTypeMessageEnum,
} from '../../store/reducers/registration';
import { requestRegistration } from '../../api/users.api';
import useStyles from './styles/registrationTimer';
import { useRegistration } from '../../hooks/useRegistration';

export const RegistrationTimer = () => {
  const classes = useStyles();
  const timer = useTypeSelector((s) => s.registration.timer);
  const email = useTypeSelector((s) => s.registration.user.email);
  const utm = useTypeSelector((e) => e.registration.utm);
  const promo_code = useTypeSelector((s) => s.registration.promo);
  const { setRegistrationMessage, startTimer } = useRegistration();

  const getUTM = useCallback(
    (key: string) => {
      const arr = utm.filter((item) => item.key === key);
      return arr[0] && arr[0].value ? arr[0].value : '';
    },
    [utm],
  );

  let intervalTimer: any;
  const setTimer = useCallback(() => {
    startTimer();
  }, [startTimer]);

  const repeat = useCallback(async () => {
    const payload = {
      email,
      promo_code,
      referrer_id: '',
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: '',
    };
    for (const [key] of Object.entries(payload)) {
      if (key !== 'email') {
        // @ts-ignore
        payload[key] = getUTM(key);
      }
    }
    const { ok } = await requestRegistration(payload);
    if (ok) {
      const payload: IRegistrationMessage = {
        text: 'На указанный адрес было отправлено\nписьмо с продолжением регистрации',
        type: RegistrationTypeMessageEnum.success,
      };
      setRegistrationMessage(payload);
    }
  }, [getUTM, setRegistrationMessage, email, promo_code]);

  useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
    intervalTimer = setInterval(setTimer, 1000);
    return () => {
      clearInterval(intervalTimer);
    };
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalTimer);
    }
  }, [timer, intervalTimer]);

  return (
    <div className={classes.tw}>
      <Button
        size="large"
        variant="contained"
        color="primary"
        type="submit"
        disabled={timer >= 0 ? true : false}
        onClick={repeat}
      >
        ОТПРАВИТЬ ПОВТОРНО
      </Button>
      <br />
      <span>{timer >= 0 && `Через ${timer} секунд`}</span>
    </div>
  );
};
