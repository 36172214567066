import { makeStyles } from '@material-ui/core';

export const TemplateButtonStyle = makeStyles(() => ({
  tempBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 75,
    left: 'calc(100wv - 140px)',
    width: 340,
    height: 40,
    border: '1px solid #A1AEBB',
    borderRadius: 100,
    zIndex: 99999,
    overflow: 'hidden',
    background: 'white',
    '@media (max-width: 700px)': {
      width: 140,
    },
  },
  tempBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10,
    width: '100%',
    height: '100%',
    transition: 'all 0.3s linear',
    '@media (max-width: 700px)': {
      '& span': {
        display: 'none',
      },
    },
    '& svg': {
      transition: 'all 0.3s linear',
      '& path': {
        transition: 'all 0.3s linear',
      },
    },
    '&:hover': {
      background: '#3090E8',
      color: 'white',
      cursor: 'pointer',
      '& svg': {
        '& path': {
          fill: 'white',
        },
      },
    },
  },
}));
