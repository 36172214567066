import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useStyles from './styles';
import cx from 'clsx';
import ClosePopup from '../ClosePopup';
import { CommentInput } from '../CommentInput/CommentInput';
import { IComment, IPages } from '../../store/reducers/editors';
import { AddComment } from './AddComment';

export interface ISetComment {
  title?: string;
  value: IComment[];
  page: any; // IPages | IBlocks
  onChange: (
    page: any, // IPages | IBlocks
    val: string,
    idx: number,
  ) => void;
  onAdded: (
    page: IPages, // IPages | IBlocks
    val: string,
  ) => void;
  onRemove: (
    page: IPages, // IPages | IBlocks
    idx: number,
  ) => void;
  onClose: () => void;
  className?: string;
  style?: CSSProperties;
}

export const SetComment = memo(
  ({
    title = 'Описание',
    value,
    onClose,
    onAdded,
    onChange,
    className,
    style,
    onRemove,
    page,
  }: ISetComment) => {
    const cc = useStyles();
    const wrapperRef = useRef(null);
    const [comment, setComment] = useState('');

    const addComment = useCallback(() => {
      if (comment) {
        onAdded(page, comment);
        setComment('');
      }
    }, [comment, onAdded, page]);

    const onClosePopup = useCallback(() => {
      addComment();
      onClose();
    }, [addComment, onClose]);

    useEffect(() => {
      /**
       * if clicked on outside of element
       */
      function handleClickOutside(event: Event) {
        // @ts-ignore
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          onClosePopup();
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrapperRef, onClosePopup]);

    useEffect(() => {
      //@ts-ignore
      const clientRect = wrapperRef?.current?.getBoundingClientRect();
      const xyCorrection = 30;
      const { innerHeight, innerWidth } = window;
      const x = clientRect.x + clientRect.width - innerWidth + xyCorrection;
      const y = clientRect.y + clientRect.height - innerHeight + xyCorrection;
      //@ts-ignore
      wrapperRef?.current.setAttribute(
        'style',
        `transform: translateX(-${x && x > 0 ? x : 0}px) translateY(-${
          y && y > 0 ? y : 0
        }px)`,
      );
    }, [wrapperRef]);

    return (
      <div
        className={cx(cc.commentsBlock, className ? className : '')}
        style={style}
        ref={wrapperRef}
      >
        <div className={cc.commentHeader}>
          <h2>{title}</h2>
          <ClosePopup onClick={onClosePopup} className={cc.commentBlockClose} />
        </div>
        <div className={cc.commentsList}>
          {value.map((item, idx) => (
            <div key={idx} className={cc.commentsListItem}>
              <CommentInput
                className={cc.noComment}
                value={item.comment}
                onChange={(val: string) => onChange(page, val, idx)}
              />
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => onRemove(page, idx)}
              >
                <g clipPath="url(#clip0_7162_24221)">
                  <path
                    d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                    fill="#EB5757"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7162_24221">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', columnGap: 10 }}>
          <CommentInput
            className={cc.textArea}
            value={comment}
            onChange={setComment}
            // onBlur={onClose}
            placeholder="Добавить описание"
          />
          <AddComment
            onClick={addComment}
            style={{ pointerEvents: comment.length > 0 ? 'all' : 'none' }}
            color={comment.length > 0 ? '#3090E8' : '#67747d'}
          />
        </div>
      </div>
    );
  },
);
