import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const TextPictureBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="108" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect y="7" width="105" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect y="14" width="108" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect y="21" width="46" height="3" rx="1.5" fill={blockDefaultColor} />
        <path
          d="M132.073 6.43934C132.344 6.15804 132.711 6 133.094 6C133.477 6 133.844 6.15804 134.114 6.43934C134.385 6.72064 134.537 7.10218 134.537 7.5C134.537 7.89782 134.385 8.27936 134.114 8.56066C133.844 8.84196 133.477 9 133.094 9C132.711 9 132.344 8.84196 132.073 8.56066C131.803 8.27936 131.651 7.89782 131.651 7.5C131.651 7.10218 131.803 6.72064 132.073 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M134.537 16.5L139.588 10.5L145 18H127L130.929 12.75L134.537 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="117"
          y="1"
          width="38"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
