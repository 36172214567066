import { makeStyles } from '@material-ui/core';
import { ISHoverColor } from '../IconOutliner';
export const ProjectCardMoreBtnStyle = makeStyles(() => ({
  btn: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 10,
    right: 5,
    border: '1px solid transparent',
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${ISHoverColor}`,
    },
  },
  deleteMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#EB5757',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e33434',
    },
  },
}));
