import { Button, makeStyles, TextField, Checkbox } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import {
  IRegistrationMessage,
  IRegistrationUser,
  RegistrationTypeMessageEnum,
} from '../../../store/reducers/registration';
import { completeRegistration } from '../../../api/users.api';
import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';
import { useRegistration } from '../../../hooks/useRegistration';

const useStyles = makeStyles(() => ({
  form: {
    textAlign: 'center',
  },
  button: {
    maxWidth: 240,
    width: '100%',
    marginTop: 38,
  },
  politic: {
    display: 'flex',
    alignItems: 'center',
  },
  politic_text: {
    marginTop: 20,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    display: 'grid',
    alignItems: 'center',
    textAlign: 'left',
  },
}));

export function PersonForm({ token }: { token: string }) {
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const RState = useTypeSelector((s) => s.registration.user);
  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);
  const {
    addStep,
    setRegistrationMessage,
    setTitle,
    setDescription,
    setAgreement,
    setAllUser,
  } = useRegistration();

  const inputHadleChange = (evt: any) => {
    const user: IRegistrationUser = { ...RState };
    // @ts-ignore
    user[evt.target.name] = evt.target.value;
    setAllUser(user);
  };

  const setMessage = useCallback(
    (msg = false) => {
      msg ? setValid(true) : setValid(false);
      const messagePayload: IRegistrationMessage = {
        text: !msg ? 'Принять соглашение обязательно' : null,
        type: RegistrationTypeMessageEnum.warning,
      };
      setRegistrationMessage(messagePayload);
    },
    [setRegistrationMessage, setValid],
  );
  const setAgree = (): void => {
    setAgreement(!RState.agreement);
  };

  const onSubmit = async () => {
    const data = await completeRegistration({
      token,
      first_name: RState.first_name,
      last_name: RState.last_name,
      middle_name: RState.middle_name,
      password: RState.password,
      account_type: 'PERSON',
    });

    if (!data?.ok) {
      const { error } = data;
      if (error) {
        setSnackbarValue(error.response.error_description, SnackbarType.Error);
      }
    }

    data?.data?.access_token &&
      localStorage.setItem('access_token', data.data.access_token);
    data?.data?.refresh_token &&
      localStorage.setItem('refresh_token', data.data.refresh_token);

    setSnackbarValue('Регистрация прошла успешно', SnackbarType.Success);
    addStep();
    setTitle('Ура!');
    setDescription('данные отправлены и регистрация завершена!');
    try {
      ym('73562104', 'reachGoal', 'finish');
      ReactGA.event({
        category: 'click',
        action: 'finish',
        label: 'Успешная отправка формы "Расскажите о себе"',
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // RState.first_name !== '' &&
    RState.agreement ? setMessage(true) : setMessage();
  }, [RState.agreement, setMessage]);
  // }, [RState.first_name, RState.last_name, RState.agreement, setMessage]);

  return (
    <div className={classes.form}>
      <TextField
        value={RState.first_name}
        onChange={inputHadleChange}
        name="first_name"
        fullWidth
        margin="dense"
        label="Имя"
        autoComplete="false"
      />
      <TextField
        value={RState.last_name}
        onChange={inputHadleChange}
        name="last_name"
        fullWidth
        margin="dense"
        label="Фамилия"
        autoComplete="false"
      />
      <TextField
        value={RState.middle_name}
        onChange={inputHadleChange}
        name="middle_name"
        fullWidth
        margin="dense"
        label="Отчество"
        autoComplete="false"
      />

      <div className={classes.politic}>
        <Checkbox
          color="primary"
          name="allow_system_notifications"
          checked={RState.agreement}
          onChange={setAgree}
        />
        <div className={classes.politic_text}>
          <span>Продолжая регистрацию, вы соглашаетесь </span>
          <a
            href="https://structura.app/api/uploads/ISA_OOO_Liczenzionnoe_solashenie_oferta_27_05_2021_g_34423e7d01.pdf"
            target="_blank"
            rel="noreferrer"
          >
            с условиями пользовательского соглашения
          </a>
          <a
            href="https://structura.app/api/uploads/ISA_OOO_Politika_konfidenczialnosti_dlya_servisa_27_05_2021_g_1eb642fc13.pdf"
            target="_blank"
            rel="noreferrer"
          >
            и обработкой персональных данных
          </a>
        </div>
      </div>

      <Button
        className={classes.button}
        size="large"
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={!valid}
      >
        Дальше
      </Button>
    </div>
  );
}
