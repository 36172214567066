import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const SearchResultBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.50008 3.66699C6.28829 3.66699 3.66675 6.28854 3.66675 9.50033C3.66675 12.7121 6.28829 15.3337 9.50008 15.3337C10.9567 15.3337 12.2876 14.7903 13.3119 13.9014L13.6667 14.2562V15.3337L18.3217 19.9886C18.7817 20.4486 19.5284 20.4486 19.9884 19.9886C20.4484 19.5286 20.4484 18.7819 19.9884 18.3219L15.3334 13.667H14.2559L13.9011 13.3122C14.7901 12.2878 15.3334 10.957 15.3334 9.50033C15.3334 6.28854 12.7119 3.66699 9.50008 3.66699ZM9.50008 5.33366C11.8111 5.33366 13.6667 7.18927 13.6667 9.50033C13.6667 11.8114 11.8111 13.667 9.50008 13.667C7.18902 13.667 5.33341 11.8114 5.33341 9.50033C5.33341 7.18927 7.18902 5.33366 9.50008 5.33366Z"
          fill={blockDefaultColor}
        />
        <rect x="39" width="32" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="39"
          y="7"
          width="117"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="39"
          y="14"
          width="96"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="39"
          y="21"
          width="37"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="24"
          y="7"
          width="3"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="24"
          y="14"
          width="3"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
