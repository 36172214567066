import React, { useCallback, useEffect } from 'react';
import { useLocation, useRoute } from 'wouter';
import { useSettingsEditor } from '../../../hooks/useSettingEditor';
import useStyles from '../../styles/index';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { useEditorSitemap } from '../../../hooks/useEditorSitemap';
import { NewFeedback } from '../../NewFeedback';
import { Devider } from './devider';
import { NewButton } from '../../../components/Button/NewButton';
import { NewShareBtn } from '../../../components/ShareBtn/NewShareBtn';
import { EditorTitle } from './EditorTitle';
import { Menu } from '../../../components/Menu/Menu';
import { PublicBar } from '../PublicBar/PublicBar';

export const NewEditorBar = () => {
  const [location, setLocation] = useLocation();
  const classes = useStyles();
  const regEditor = /\/editor$/g;
  const regEditorId = /\/editor\//g;
  const [, params] = useRoute('/editor/:id');
  const profile = useTypeSelector((s) => s.profile.profile);
  const own_draft = useTypeSelector((s) => s.editor.own_draft);
  const { showCreateProjectModal } = useSettingsEditor();
  const { putDraftServer, putPreview, clearDraft, setEvents } =
    useEditorSitemap();

  const goBack = useCallback(() => {
    if (own_draft) {
      putDraftServer();
    }
    clearDraft();
    params?.id && setEvents(params?.id, 'CLOSE');
    setLocation('/editor');
  }, [setLocation, clearDraft, putDraftServer, setEvents, params, own_draft]);

  const savePreview = useCallback(async () => {
    if (own_draft) {
      await putPreview(); // wating for save
    }
  }, [own_draft, putPreview]);

  const createNewKeybordDown = useCallback(
    (e: KeyboardEvent) => {
      e.code === 'KeyN' && showCreateProjectModal();
    },
    [showCreateProjectModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', createNewKeybordDown, true);
    return () => {
      document.removeEventListener('keydown', createNewKeybordDown);
    };
  }, [createNewKeybordDown]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (/\/editor\//.test(location)) {
        savePreview();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [savePreview, location]);

  return (
    <>
      {profile ? (
        <>
          {regEditor.test(location) && (
            <div className={classes.new_editor_list_menu}>
              <div>
                <NewFeedback />
                <Devider color="red" />
              </div>
              <h2>Конструктор</h2>
              <div style={{ justifySelf: 'end' }}>
                <Devider color="primary" />
                <Menu />
              </div>
            </div>
          )}
          {regEditorId.test(location) && (
            <div className={classes.btnGroup}>
              <div className="item">
                <NewButton
                  onClick={goBack}
                  caption="Все проекты"
                  beforeIcon="arrow_left"
                  height={60}
                  noBorder
                  className={classes.goToAll}
                />
                <Devider color="gray" />
              </div>
              <div
                className="item"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <EditorTitle />
              </div>
              <div className="item" style={{ justifySelf: 'end' }}>
                {profile && own_draft && <NewShareBtn />}
                <Menu />
              </div>
            </div>
          )}
        </>
      ) : (
        <>{regEditorId.test(location) ? <PublicBar /> : <></>}</>
      )}
    </>
  );
};
