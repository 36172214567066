import React, { CSSProperties, memo } from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'clsx';

interface IRemoveBlockProps {
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
}
const useStyles = makeStyles(() => ({
  removeWrapper: {
    position: 'absolute',
    width: 25,
    height: 25,
    left: -28,
    border: '1px solid #EB5757',
    background: '#EB5757',
    borderRadius: '15px 0 0 15px',
    transition: 'All 0.3s ease-in-out',
    zIndex: 9999,
    cursor: 'pointer',
    '& svg': {
      transform: 'translateX(2px)',
      transition: 'All 0.3s ease-in-out',
      '& .btn': {
        fill: '#FCFEFF',
        transition: 'All 0.3s ease-in-out',
      },
    },
    '&:hover': {
      background: '#FCFEFF',
      '& svg': {
        '& .btn': {
          fill: '#EB5757',
        },
      },
    },
  },
}));

export const RemoveBlock = memo((props: IRemoveBlockProps) => {
  const cc = useStyles();
  return (
    <div
      className={cx(cc.removeWrapper, props.className ? props.className : '')}
      onClick={props.onClick}
      style={props.style}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 4.5L9.75 5.25H6V6.75H6.75V18C6.75 18.3917 6.8935 18.791 7.17627 19.0737C7.45904 19.3565 7.85833 19.5 8.25 19.5H15.75C16.1417 19.5 16.541 19.3565 16.8237 19.0737C17.1065 18.791 17.25 18.3917 17.25 18V6.75H18V5.25H14.25L13.5 4.5H10.5ZM8.25 6.75H15.75V18H8.25V6.75ZM9.75 8.25V16.5H11.25V8.25H9.75ZM12.75 8.25V16.5H14.25V8.25H12.75Z"
          className="btn"
        />
      </svg>
    </div>
  );
});
