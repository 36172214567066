import { InputLabel } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NewSelectStyles } from './styles/NewSelectStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export interface INewSelectListItem {
  value: any;
  caption: string;
}

export interface INewSelectProps {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  list: INewSelectListItem[];
  error?: boolean;
  style?: CSSProperties;
}

export const NewSelect = (props: INewSelectProps) => {
  const cc = NewSelectStyles();
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);

  const rollStyle = useMemo(
    () => ({ transform: `rotateX(${show ? 180 : 0}deg)` } as CSSProperties),
    [show],
  );
  const toggleRoll = useCallback(() => {
    setShow(!show);
  }, [show]);

  const onSelect = useCallback(
    (value: string) => {
      props.onChange(value);
      toggleRoll();
    },
    [props, toggleRoll],
  );

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        toggleRoll();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, toggleRoll]);

  return (
    <div className={cc.select_wrapper}>
      <InputLabel id={props.placeholder} className={cc.palceholder}>
        {props.placeholder}
      </InputLabel>
      <div className={cc.new_select} onClick={toggleRoll} style={props.style}>
        <label>
          {props.list.find((item) => item.value === props.value)?.caption}
        </label>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={rollStyle}
        >
          <g clipPath="url(#clip0_7506_23923)">
            <path d="M7 10L12 15L17 10H7Z" fill="#6F8194" />
          </g>
          <defs>
            <clipPath id="clip0_7506_23923">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      {show ? (
        <div className={cc.box} ref={wrapperRef}>
          {props.list.map((item, index) => (
            <div key={index} onClick={() => onSelect(item.value)}>
              {item.caption}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}

      {/* <NativeSelect
        value={props.value}
        inputProps={{ id: props.placeholder, name: 'caption' }}
        className={cc.new_select}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        {props.list.map((item, index) => (
          <option
            value={item.value}
            key={index}
            onClick={() => props.onChange(item.value)}
          >
            {item.caption}
          </option>
        ))}
      </NativeSelect> */}
    </div>
  );
};
