import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.7)',
    zIndex: 10000,
  },
  messageBlock: {
    minWidth: 350,
    width: 'auto',
    minHeight: 220,
    height: 'auto',
    background: '#FCFEFF',
    boxShadow:
      '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 10,
    padding: 20,
    '& h2': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#353E47',
      textAlign: 'center',
      margin: 0,
    },
    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '20px',
      alignItems: 'center',
      color: '#353E47',
      '& a': {
        color: '#FD1F6D',
        textDecoration: 'none',
      },
    },
  },
  err: {
    color: '#FD1F6D !important',
    fontWeight: 700,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    columnGap: 20,
  },
  btn: {
    minWidth: 150,
    width: 'auto',
    height: 39,
    borderRadius: 5,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '5px 10px',
  },
  blueBtn: {
    background: '#3F92D1',
    color: '#FCFEFF',
  },
  blueBorderBtn: {
    border: '1px solid #3F92D1',
    color: '#3F92D1',
  },
}));
