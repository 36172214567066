import { IUTMItems, UTMArrayToApiString } from '../utils/UTMArrayToApiString';
import { request, IResponse } from './request';

enum SocialApiEnum {
  google = 'google',
  yandex = 'yandex',
}

function GetSocialAuth<R>(
  provider: SocialApiEnum,
  utmString: string,
  promo: string,
): Promise<IResponse<R>> {
  const arr = [];
  const utm = utmString && utmString.replace(/\?/g, '');
  promo && arr.push(`promo_code=${promo}`);
  utm && arr.push(utm);

  return request<R>(
    'get',
    `users/social-login/${provider}/link${
      arr.length ? `?${arr.join('&')}` : ''
    }`,
  );
}

function GetTokenAuth<R>(
  provider: SocialApiEnum,
  data: IGetSocialAuthTokenData,
  controller: AbortController,
): Promise<IResponse<R>> {
  return request<R>('post', `users/social-login/${provider}/code`, data, {
    signal: controller.signal,
  });
}

interface IGetSocialAuthGoogleLinkResponse {
  link: string;
}

export interface IGetSocialAuthTokenData {
  code: string;
  state: string;
}
interface IGetSocialAuthGoogleTokenResponse {
  access_token: string;
  additionalInformation: any;
  expiration: string | Date;
  expired: boolean;
  expiresIn: number;
  expires_in: number;
  refreshToken: any;
  refresh_token: string;
  scope: string;
  tokenType: string;
  token_type: string;
  value: string;
}

interface IGetSocialAuthYandexTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  state: any;
  scope: any;
}

export function GetSocialAuthGoogleLink(utm: IUTMItems, promo: string) {
  return GetSocialAuth<IGetSocialAuthGoogleLinkResponse>(
    SocialApiEnum.google,
    UTMArrayToApiString(utm),
    promo,
  );
}

export function GetSocialAuthYandexLink(utm: IUTMItems, promo: string) {
  return GetSocialAuth<IGetSocialAuthGoogleLinkResponse>(
    SocialApiEnum.yandex,
    UTMArrayToApiString(utm),
    promo,
  );
}

export async function GetSocialAuthGoogleToken(
  data: IGetSocialAuthTokenData,
  controller: AbortController,
) {
  try {
    return await GetTokenAuth<IGetSocialAuthGoogleTokenResponse>(
      SocialApiEnum.google,
      data,
      controller,
    );
  } catch (e) {
    throw new Error('No get data');
  }
}

export async function GetSocialAuthYandexToken(
  data: IGetSocialAuthTokenData,
  controller: AbortController,
) {
  try {
    return await GetTokenAuth<IGetSocialAuthYandexTokenResponse>(
      SocialApiEnum.yandex,
      data,
      controller,
    );
  } catch (e) {
    throw new Error('No get data');
  }
}
