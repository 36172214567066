import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const MixedBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4204_4252)">
          <rect
            x="1"
            y="2"
            width="20"
            height="20"
            rx="1"
            stroke={blockDefaultColor}
            strokeWidth="2"
          />
          <path
            d="M55.1168 0.2415L57.3027 6.98174L58.254 6.67324L57.3027 6.98175C57.5702 7.80632 58.3383 8.36475 59.2052 8.36475H66.2831L60.5538 12.5351C59.8541 13.0444 59.5613 13.9459 59.8283 14.7691L62.016 21.5145L56.2937 17.3494C55.5922 16.8387 54.6413 16.8387 53.9398 17.3494L48.2175 21.5145L50.4052 14.7691C50.6722 13.9459 50.3794 13.0444 49.6797 12.5351L43.9504 8.36475L51.0283 8.36475C51.8952 8.36475 52.6633 7.80632 52.9308 6.98174L55.1168 0.2415Z"
            stroke={blockDefaultColor}
            strokeWidth="2"
          />
          <rect
            x="89.2334"
            y="1"
            width="21.9556"
            height="22"
            rx="10.9778"
            stroke={blockDefaultColor}
            strokeWidth="2"
          />
          <path
            d="M143.167 2.49076L154.302 22.5716H132.031L143.167 2.49076Z"
            stroke={blockDefaultColor}
            strokeWidth="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_4204_4252">
            <rect width="156" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
