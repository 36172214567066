import { makeStyles } from "@material-ui/core";


export const addCommentStyle = makeStyles(()=>({
  addComment: {
    border: '1px solid transparent',
    borderRadius: '0%',
    transition: 'all .3s linear',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      border: '1px solid var(--border-color)',
      borderRadius: '50%',
      cursor: 'pointer',
    }
  }
}))
