import { IDraftTempCategory, IDraftTempCategoryItem } from '../../api/draftTempCategory';
import { ISiteDraft } from './editors';

export interface IDraftTempReducer {
  draftTempList: IDraftTempCategory[] | null;
  draftTempCatagoryList: IDraftTempCategoryItem[] | null;
  tempItem: ISiteDraft | null
}

export enum IDraftTempCategoryActionEnum {
  setDraftTempList = 'SET_DRAFT_TEMP_LIST',
  setDraftTempCatagoryList = 'SET_DRAFT_TEMP_CATEGORY_LIST',
  clear = 'CLEAR_DRAFT_TEMP',
  setTempItem = 'SET_TEMP_ITEM'
}

const IS: IDraftTempReducer = {
  draftTempList: null,
  draftTempCatagoryList: null,
  tempItem: null
};

interface IDraftTempReducerActionSetTempList {
  type: IDraftTempCategoryActionEnum.setDraftTempList;
  payload: IDraftTempCategory[] | null;
}
interface IDraftTempReducerActionSetDraftTempCategoryList {
  type: IDraftTempCategoryActionEnum.setDraftTempCatagoryList;
  payload: IDraftTempCategoryItem[];
}
interface IDraftTempReducerActionClear {
  type: IDraftTempCategoryActionEnum.clear;
}
interface IDraftTempReducerActionSetTempItem {
  type: IDraftTempCategoryActionEnum.setTempItem,
  payload: ISiteDraft | null
}

export type IDraftTempReducerActionTypes =
  | IDraftTempReducerActionSetTempList
  | IDraftTempReducerActionSetDraftTempCategoryList
| IDraftTempReducerActionClear
| IDraftTempReducerActionSetTempItem

export const draftTempReducer = (
  state: IDraftTempReducer = IS,
  action: IDraftTempReducerActionTypes,
): IDraftTempReducer => {
  switch (action.type) {
    case IDraftTempCategoryActionEnum.setDraftTempList:
      return { ...state, draftTempList: action.payload };
    case IDraftTempCategoryActionEnum.setDraftTempCatagoryList:
      return { ...state, draftTempCatagoryList: action.payload };
    case IDraftTempCategoryActionEnum.clear:
      return { ...state, draftTempCatagoryList: null, draftTempList: null };
    case IDraftTempCategoryActionEnum.setTempItem:
      return { ...state, tempItem: action.payload };
    default:
      return state;
  }
};
