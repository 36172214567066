import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const AuthBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4631_4763)">
          <path
            d="M7.61756 6.7426C4.23789 6.54594 1.44617 9.5302 2.1493 12.9755C2.5243 15.0755 4.1743 16.7255 6.2743 17.1005C9.05128 17.6106 11.5687 15.9341 12.3109 13.4999H15.4999V14.2499C15.4999 15.0749 16.1749 15.7499 16.9999 15.7499C17.8249 15.7499 18.4999 15.0749 18.4999 14.2499V13.4999C19.3249 13.4999 19.9999 12.8249 19.9999 11.9999C19.9999 11.1749 19.3249 10.4999 18.4999 10.4999H12.2889C11.709 8.68586 10.1713 7.24043 8.30018 6.82463C8.07049 6.78244 7.84287 6.75571 7.61756 6.7426ZM7.24988 9.74992C8.52488 9.74992 9.49988 10.7249 9.49988 11.9999C9.49988 13.2749 8.52488 14.2499 7.24988 14.2499C5.97488 14.2499 4.99988 13.2749 4.99988 11.9999C4.99988 10.7249 5.97488 9.74992 7.24988 9.74992Z"
            fill={blockDefaultColor}
          />
        </g>
        <path
          d="M38 5.25C36.352 5.25 35 6.60203 35 8.25C35 9.89797 36.352 11.25 38 11.25C39.648 11.25 41 9.89797 41 8.25C41 6.60203 39.648 5.25 38 5.25ZM38 6.75C38.8373 6.75 39.5 7.41269 39.5 8.25C39.5 9.08731 38.8373 9.75 38 9.75C37.1627 9.75 36.5 9.08731 36.5 8.25C36.5 7.41269 37.1627 6.75 38 6.75ZM38 13.5C36.6913 13.5 35.1404 13.8073 33.8369 14.3218C33.1852 14.579 32.596 14.8845 32.1245 15.2769C31.653 15.6692 31.25 16.1995 31.25 16.875V18.75H44.75V18V16.875C44.75 16.1995 44.347 15.6692 43.8755 15.2769C43.404 14.8845 42.8148 14.579 42.1631 14.3218C40.8596 13.8073 39.3087 13.5 38 13.5ZM38 15C39.0605 15 40.5095 15.2805 41.6138 15.7163C42.1659 15.9342 42.6327 16.194 42.916 16.4297C43.1993 16.6654 43.25 16.826 43.25 16.875V17.25H32.75V16.875C32.75 16.826 32.8007 16.6654 33.084 16.4297C33.3673 16.194 33.8341 15.9342 34.3862 15.7163C35.4905 15.2805 36.9395 15 38 15Z"
          fill={blockDefaultColor}
        />
        <rect
          x="27"
          y="1"
          width="59"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path
          d="M105.463 12.6154L102.332 11.7241C102.014 11.6337 101.835 11.2971 101.937 10.9827L102.255 10.0044C102.359 9.68242 102.714 9.51675 103.027 9.64333L106.125 10.8934L105.924 7.36717C105.904 7.03175 106.171 6.75 106.506 6.75H107.589C107.924 6.75 108.19 7.03175 108.171 7.366L107.969 10.9762L110.969 9.75358C111.283 9.62525 111.64 9.79208 111.745 10.1152L112.064 11.111C112.165 11.426 111.984 11.762 111.666 11.8512L108.466 12.7449L110.572 15.4259C110.777 15.6872 110.723 16.0676 110.452 16.2607L109.575 16.8872C109.301 17.0832 108.918 17.0067 108.741 16.7197L106.916 13.7786L105.112 16.6212C104.935 16.8994 104.563 16.9764 104.29 16.7903L103.425 16.1994C103.145 16.0087 103.086 15.6213 103.296 15.3559L105.463 12.6154Z"
          fill={blockDefaultColor}
        />
        <path
          d="M124.463 12.6154L121.332 11.7241C121.014 11.6337 120.835 11.2971 120.937 10.9827L121.255 10.0044C121.359 9.68242 121.714 9.51675 122.027 9.64333L125.125 10.8934L124.924 7.36717C124.904 7.03175 125.171 6.75 125.506 6.75H126.589C126.924 6.75 127.19 7.03175 127.171 7.366L126.969 10.9762L129.969 9.75358C130.283 9.62525 130.64 9.79208 130.745 10.1152L131.064 11.111C131.165 11.426 130.984 11.762 130.666 11.8512L127.466 12.7449L129.572 15.4259C129.777 15.6872 129.723 16.0676 129.452 16.2607L128.575 16.8872C128.301 17.0832 127.918 17.0067 127.741 16.7197L125.916 13.7786L124.112 16.6212C123.935 16.8994 123.563 16.9764 123.29 16.7903L122.425 16.1994C122.145 16.0087 122.086 15.6213 122.296 15.3559L124.463 12.6154Z"
          fill={blockDefaultColor}
        />
        <path
          d="M142.463 12.6154L139.332 11.7241C139.014 11.6337 138.835 11.2971 138.937 10.9827L139.255 10.0044C139.359 9.68242 139.714 9.51675 140.027 9.64333L143.125 10.8934L142.924 7.36717C142.904 7.03175 143.171 6.75 143.506 6.75H144.589C144.924 6.75 145.19 7.03175 145.171 7.366L144.969 10.9762L147.969 9.75358C148.283 9.62525 148.64 9.79208 148.745 10.1152L149.064 11.111C149.165 11.426 148.984 11.762 148.666 11.8512L145.466 12.7449L147.572 15.4259C147.777 15.6872 147.723 16.0676 147.452 16.2607L146.575 16.8872C146.301 17.0832 145.918 17.0067 145.741 16.7197L143.916 13.7786L142.112 16.6212C141.935 16.8994 141.563 16.9764 141.29 16.7903L140.425 16.1994C140.145 16.0087 140.086 15.6213 140.296 15.3559L142.463 12.6154Z"
          fill={blockDefaultColor}
        />
        <rect
          x="96"
          y="1"
          width="59"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <defs>
          <clipPath id="clip0_4631_4763">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(2 3)"
            />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
