import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  wrapper: {
    background: "no-repeat center url('/background.png')",
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 900,
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  card: {
    maxWidth: 456,
    width: '100%',
    position: 'relative',
  },
  cardContent: {
    padding: '48px 48px 24px',
  },
  button: {
    maxWidth: 240,
    width: '100%',
    marginTop: 38,
  },
  signUpWrapper: {
    display: 'grid',
    alignItems: 'center',
    marginTop: 32,
    justifyContent: 'center',
  },
  signUpButton: {
    position: 'relative',
    padding: '8px 12px',
    zIndex: 21,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 9,
      right: -10,
      height: 24,
      width: 24,
      backgroundColor: 'inherit',
      zIndex: 10,
      transform: 'rotateZ(45deg)',
      borderRadius: 4,
      '-webkit-transform': 'rotate(45deg) skew(16deg, 16deg)',
    },
  },
  signUpButtonLabel: {
    zIndex: 20,
  },
  finishbtn: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
}));
