import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const TextWithVideoBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="74" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          y="7"
          width="71.7576"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect y="14" width="74" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          y="21"
          width="31.3939"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <path
          d="M119 4.5C114.858 4.5 111.5 7.85775 111.5 12C111.5 16.1423 114.858 19.5 119 19.5C123.142 19.5 126.5 16.1423 126.5 12C126.5 7.85775 123.142 4.5 119 4.5ZM117.5 14.625V9.375C117.5 9.066 117.852 8.88975 118.1 9.075L121.6 11.7C121.8 11.85 121.8 12.15 121.6 12.3L118.1 14.925C117.852 15.1102 117.5 14.934 117.5 14.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="83"
          y="1"
          width="72"
          height="22"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
