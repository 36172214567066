import React, { useCallback } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { LoginForm } from './LoginForm';
import useStyles from './styles';
import { Back } from '../../components/Back/Back';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import cx from 'clsx';

export function Login() {
  const classes = useStyles();
  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();
  const onSnackbarClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  return (
    <div className={classes.signUpWrapper}>
      <Back />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {/* <MVC /> */}
          <LoginForm />
        </CardContent>
      </Card>
      {/* <WaterMarkLogo /> */}
      <Snackbar
        open={Boolean(snackbarValue)}
        onClose={onSnackbarClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          className={cx({
            [classes.snackbarError]: snackbarType === SnackbarType.Error,
            [classes.snackbarSuccess]: snackbarType === SnackbarType.Success,
          })}
          message={snackbarValue}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
}
