import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const SocialBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.3584 9.38197C11.3857 9.78653 9.4418 10.6239 6.5266 11.894C6.05321 12.0822 5.80524 12.2664 5.78266 12.4465C5.74451 12.7508 6.12561 12.8706 6.64458 13.0338C6.71517 13.056 6.78832 13.079 6.8633 13.1034C7.37388 13.2693 8.06071 13.4635 8.41776 13.4712C8.74164 13.4782 9.10313 13.3447 9.50222 13.0706C12.226 11.232 13.632 10.3027 13.7203 10.2827C13.7826 10.2685 13.8689 10.2508 13.9273 10.3027C13.9858 10.3547 13.98 10.4531 13.9739 10.4795C13.9361 10.6405 12.4401 12.0313 11.6659 12.751C11.4246 12.9754 11.2534 13.1345 11.2184 13.1709C11.14 13.2523 11.0601 13.3293 10.9833 13.4034C10.509 13.8606 10.1533 14.2036 11.003 14.7635C11.4114 15.0326 11.7382 15.2551 12.0641 15.4772C12.4202 15.7196 12.7753 15.9614 13.2347 16.2626C13.3518 16.3393 13.4635 16.419 13.5724 16.4966C13.9867 16.792 14.359 17.0574 14.8188 17.0151C15.0861 16.9905 15.3621 16.7392 15.5022 15.9898C15.8335 14.2188 16.4847 10.3816 16.6352 8.80034C16.6484 8.66181 16.6318 8.48451 16.6185 8.40668C16.6052 8.32885 16.5773 8.21796 16.4762 8.13587C16.3563 8.03865 16.1714 8.01814 16.0887 8.0196C15.7125 8.02623 15.1355 8.22688 12.3584 9.38197Z"
          fill={blockDefaultColor}
        />
        <path
          d="M51.5556 2C48.4878 2 46 4.48778 46 7.55556V16.4444C46 19.5122 48.4878 22 51.5556 22H60.4444C63.5122 22 66 19.5122 66 16.4444V7.55556C66 4.48778 63.5122 2 60.4444 2H51.5556ZM62.6667 4.22222C63.28 4.22222 63.7778 4.72 63.7778 5.33333C63.7778 5.94667 63.28 6.44444 62.6667 6.44444C62.0533 6.44444 61.5556 5.94667 61.5556 5.33333C61.5556 4.72 62.0533 4.22222 62.6667 4.22222ZM56 6.44444C59.0678 6.44444 61.5556 8.93222 61.5556 12C61.5556 15.0678 59.0678 17.5556 56 17.5556C52.9322 17.5556 50.4444 15.0678 50.4444 12C50.4444 8.93222 52.9322 6.44444 56 6.44444ZM56 8.66667C55.1159 8.66667 54.2681 9.01786 53.643 9.64298C53.0179 10.2681 52.6667 11.1159 52.6667 12C52.6667 12.8841 53.0179 13.7319 53.643 14.357C54.2681 14.9821 55.1159 15.3333 56 15.3333C56.8841 15.3333 57.7319 14.9821 58.357 14.357C58.9821 13.7319 59.3333 12.8841 59.3333 12C59.3333 11.1159 58.9821 10.2681 58.357 9.64298C57.7319 9.01786 56.8841 8.66667 56 8.66667Z"
          fill={blockDefaultColor}
        />
        <path
          d="M107.1 4.9C105.2 3 102.7 2 100 2C94.5 2 90 6.5 90 12C90 13.8 90.5 15.5 91.3 17L90 22L95.2 20.8C96.7 21.6 98.3 22 100 22C105.5 22 110 17.5 110 12C110 9.3 109 6.8 107.1 4.9ZM104.9 15.6C104.7 16.2 103.7 16.7 103.2 16.8C102.7 16.8 102.3 17 100.2 16.2C97.7 15.2 96.1 12.6 95.9 12.4C95.8 12.2 94.9 11 94.9 9.8C94.9 8.6 95.5 8 95.8 7.7C96 7.4 96.3 7.4 96.5 7.4C96.7 7.4 96.8 7.4 97 7.4C97.2 7.4 97.4 7.4 97.6 7.8C97.8 8.3 98.3 9.5 98.4 9.7C98.5 9.9 98.5 10 98.4 10.1C98.3 10.2 98.3 10.4 98.2 10.5C98.1 10.6 97.9 10.8 97.8 10.9C97.7 11 97.5 11.2 97.7 11.4C97.8 11.7 98.3 12.5 99.1 13.1C100.1 14 100.9 14.2 101.1 14.3C101.4 14.4 101.5 14.4 101.6 14.2C101.7 14 102.2 13.5 102.4 13.2C102.6 12.9 102.7 13 103 13.1C103.3 13.2 104.5 13.8 104.7 13.9C105 14 105.1 14.1 105.2 14.2C105.1 14.5 105.1 15 104.9 15.6Z"
          fill={blockDefaultColor}
        />
        <path
          d="M151.778 2H136.222C135 2 134 3 134 4.22222V19.7778C134 21 135 22 136.222 22H151.778C153 22 154 21 154 19.7778V4.22222C154 3 153 2 151.778 2ZM140.667 17.5556H137.889V9.77778H140.667V17.5556ZM139.222 8.33333C138.333 8.33333 137.778 7.77778 137.778 7C137.778 6.22222 138.333 5.66667 139.333 5.66667C140.222 5.66667 140.778 6.22222 140.778 7C140.778 7.77778 140.222 8.33333 139.222 8.33333ZM150.667 17.5556H148V13.3333C148 12.1111 147.222 11.8889 147 11.8889C146.778 11.8889 145.778 12.1111 145.778 13.3333C145.778 13.5556 145.778 17.5556 145.778 17.5556H143V9.77778H145.778V10.8889C146.111 10.2222 146.889 9.77778 148.222 9.77778C149.556 9.77778 150.667 10.8889 150.667 13.3333V17.5556Z"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
