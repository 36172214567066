import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const NewShareBtnStyle = makeStyles((theme: Theme) =>
  createStyles({
    share_wrapper: {
      position: 'relative',
    },
    share_btn: {
      width: 60,
      height: 60,
      background: '#3090E8',
      display: 'flex',
      columnGap: 10,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '& span': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: 21,
        color: '#FFFFFF',
      },
      '&:hover': {
        background: '#0a72d1',
      },
    },
    share_box: {
      position: 'absolute',
      top: 60,
      right: 0,
      width: 480,
      height: 80,
      background: '#FFFFFF',
      boxShadow:
        '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
      borderRadius: 10,
      zIndex: 999999,
      padding: '20px 24px',
      transition: 'all .5s ease-in-out',
      '& .share_copy': {
        transition: 'all .5s ease-in-out',
        opacity: 0,
      },
      '&.open': {
        height: 137,
        '& .share_copy': {
          opacity: 1,
        },
      },
      '& h2': {
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': 'bold',
        'font-size': '24px',
        'line-height': '28px',
        display: 'flex',
        'align-items': 'center',
        color: '#353E47',
        margin: 0,
      },
    },
    share_header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    share_body: {
      padding: '24px 0 0 0',
      '& span': {
        'font-family': 'Roboto',
        'font-style': 'normal',
        'font-weight': '500',
        'font-size': '14px',
        'line-height': '26px',
        display: 'flex',
        'align-items': 'center',
        color: '#6F8194',
      },
      '& select': {
        width: 432,
        height: 40,
        background: '#FCFEFF',
        boxShadow: 'inset 0px -2px 0px #C7D6E5',
        border: 'none',
      },
    },
  }),
);
