import { ISiteDraft } from '../store/reducers/editors';
import { IDraftTempCategoryItem } from './draftTempCategory';
import { request } from './request';

export function getDraftTemp() {
  return request<IDraftTempCategoryItem[]>('get', 'site-draft-templates');
}

export function getDraftTempByTempId(temp_id: string) {
  return request<ISiteDraft>('get', `site-draft-templates/${temp_id}`);
}
