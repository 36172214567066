import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { TariffTypeSelectorStyles } from './TariffTypeSelectorStyles';

export interface ITariffTypeSelectorProps {
  value: boolean;
  onChange: (val: boolean) => void;
  style?: CSSProperties;
  mobile?: boolean;
}

export const TariffTypeSelector = memo(
  ({ value, onChange, style, mobile }: ITariffTypeSelectorProps) => {
    const cc = TariffTypeSelectorStyles();
    const [v, setV] = useState(true);

    const setType = useCallback(() => {
      onChange(!v);
    }, [v, onChange]);

    useEffect(() => {
      setV(value);
    }, [value]);
    return (
      <div
        className={
          mobile
            ? cc.tariff_type_selector_wrapper_mobile
            : cc.tariff_type_selector_wrapper
        }
        style={style && style}
      >
        <h2 className={ cc.tariff_type_selector_wrapper_header}>
          Поддержите проект и откройте все возможности сервиса
        </h2>
        <div
          onClick={setType}
          className={cc.tariff_type_selector_wrapper__checkbox}
        >
          <span className={`left ${value ? 'active' : ''}`}>Месяц</span>
          <span className={`right ${!value ? 'active' : ''}`}>Год</span>
        </div>
      </div>
    );
  },
);
