import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export default makeStyles((theme: Theme) =>
  createStyles({
    PopUpWindow: {
      position: 'fixed',
      bottom: 50,
      right: 50,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    },
    paper: {
      background:
        'linear-gradient(102.81deg, rgba(255, 255, 255, 0.75) -1.97%, rgba(255, 255, 255, 0.55) 101.73%)',
      boxShadow: '0px 15px 50px rgba(88, 128, 171, 0.3)',
      backdropFilter: 'blur(10px)',
      borderRadius: '15px',
      backgroundColor: theme.palette.background.paper,
      maxWidth: 725,
      width: '80vw',
      height: 'auto',
      outline: 'none',
    },
    header: {
      background:
        'radial-gradient(37.91% 100% at 39.58% 0%, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0) 100%), #E2EFF8',
      boxShadow: '0px 4px 12px rgba(112, 161, 201, 0.2)',
      height: '184px',
      borderRadius: '15px 15px 0 0 ',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    body: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      display: 'grid',
      alignItems: 'center',
      textAlign: 'center',
      color: '#353E47',
      padding: '30px',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '28px',
    },
    p: {
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left',
    },
    'btn-group': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '20px',
    },
    button: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      // display: 'flex',
      // alignItems: 'center',
      // textAlign: 'center',
      // color: '#FFFFFF',
      width: '173px',
      height: '43px',
      textTransform: 'none',
      justifySelf: 'center',
    },
    decoration: {
      textDecoration: 'none',
    },
  }),
);
