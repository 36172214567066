import React, { memo, useCallback, useState } from 'react';
import { IconsOutline } from '../IconOutliner';
import useLocation from 'wouter/use-location';
import { Menu, MenuItem } from '@material-ui/core';
import { useEditorList } from '../../hooks/useEditorList';
import { useEditorSitemap } from '../../hooks/useEditorSitemap';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { ProjectCardMoreBtnStyle } from './ProjectCardMoreBtnStyle';
import { IProjectCardProps } from '../Editor/list/ProjectCard';
import { createSiteDraftBySite } from '../../api/siteDraft';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

export const ProjectCardMoreBtn = memo((props: IProjectCardProps) => {
  const cc = ProjectCardMoreBtnStyle();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [, setLocation] = useLocation();
  const { setDraftId, getList } = useEditorList();
  const { removeDraft, copyDraft } = useEditorSitemap();
  const page = useTypeSelector((s) => s.editorList.page);
  const { setSnackbarValue } = useSnackbar();
  const { setInProcess } = useEditorList();
  const [remove, setRemove] = useState(false);

  const handleMenuClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(evt.currentTarget);
    },
    [setAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    setRemove(false);
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onGoTo = useCallback(() => {
    setDraftId(props.id);
    setLocation(`/editor/${props.id}`);
  }, [props.id, setDraftId, setLocation]);

  const onDeleteClick = useCallback(async () => {
    await removeDraft(props.id);
    await getList(page);
    handleMenuClose();
    setRemove(false);
  }, [getList, removeDraft, handleMenuClose, props.id, page]);

  const repeat = useCallback(async () => {
    try {
      onDeleteClick();
      await createSiteDraftBySite(props.name, props.scrape_mode);
    } catch (e: any) {
      setSnackbarValue(e.error.error_description, SnackbarType.Error);
    } finally {
      setInProcess(true);
    }
  }, [props, setInProcess, setSnackbarValue, onDeleteClick]);

  const makeCopy = useCallback(async () => {
    await copyDraft(props.id);
    handleMenuClose();
    await getList(page);
  }, [props.id, page, copyDraft, handleMenuClose, getList]);

  return (
    <div>
      <div className={cc.btn} onClick={(evt: any) => handleMenuClick(evt)}>
        <IconsOutline types="more" noHover invert />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        id="sitemap-menu"
      >
        {!props.scrape_error && props.scrape_status !== 'IN_PROGRESS' && (
          <MenuItem onClick={onGoTo}>Редактировать</MenuItem>
        )}
        {!props.scrape_error && props.scrape_status !== 'IN_PROGRESS' && (
          <MenuItem onClick={makeCopy}>Дублировать</MenuItem>
        )}
        {props.scrape_status === 'FAIL' && (
          <MenuItem onClick={repeat}>Попробовать еще раз</MenuItem>
        )}
        {!remove ? (
          <MenuItem
            onClick={() => setRemove(true)}
            className={cc.deleteMenuItem}
          >
            Удалить
          </MenuItem>
        ) : (
          <MenuItem onClick={onDeleteClick} className={cc.deleteMenuItem}>
            Точно удалить?
          </MenuItem>
        )}
      </Menu>
    </div>
  );
});
