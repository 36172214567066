import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const VideoWithTextBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37 4.5C32.8578 4.5 29.5 7.85775 29.5 12C29.5 16.1423 32.8578 19.5 37 19.5C41.1423 19.5 44.5 16.1423 44.5 12C44.5 7.85775 41.1423 4.5 37 4.5ZM35.5 14.625V9.375C35.5 9.066 35.8525 8.88975 36.1 9.075L39.6003 11.7C39.8005 11.85 39.8005 12.15 39.6003 12.3L36.1 14.925C35.8525 15.1102 35.5 14.934 35.5 14.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="72"
          height="22"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect x="82" width="74" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="82"
          y="7"
          width="71.7576"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="82"
          y="14"
          width="74"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="82"
          y="21"
          width="31.3939"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
