import React, { memo, useCallback, useMemo, useState } from 'react';
import useStyles from '../../../../views/EditorItem/styles';
import { GenerateBlocks } from '../GenerateBlock';
import { useSettingsEditor } from '../../../../hooks/useSettingEditor';
import { useEditorSitemap } from '../../../../hooks/useEditorSitemap';
import { useTypeSelector } from '../../../../hooks/useTypesSelector';
import { v4 as uuid } from 'uuid';
import { SearchInput } from '../../../SearchInput';

interface ISelectBlockProps {
  onCloseEditor: () => void;
}

export const SelectBlock = memo((props: ISelectBlockProps) => {
  const cc = useStyles();
  const [show, setShow] = useState(false);
  const [blockId, setBlockId] = useState('');
  const [search, setSearch] = useState('');
  const { setSelectedPageId, setEditBlock, setBlockIdx, setEditorTitle } =
    useSettingsEditor();
  const { addBlockToPageById, addBlockAfterIdxToPageById, findBlockAndUpdate } =
    useEditorSitemap();
  const EditBlock = useTypeSelector((s) => s.settings.editableBlock.editBlock);
  const selectedId = useTypeSelector(
    (s) => s.settings.editableBlock.selectedPageId,
  );
  const blockIdx = useTypeSelector((s) => s.settings.editableBlock.blockIdx);
  const list = useTypeSelector((s) => s.settings.editableBlock.blocksList);
  const filtredList = useMemo(
    () =>
      list.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase()),
      ),
    [list, search],
  );

  const clickOnBlock = useCallback(
    (id: string) => {
      const block = list.filter((item) => item.id === id)[0];
      block.id = uuid();
      block.comments = [];

      if (EditBlock === null) {
        blockIdx === null &&
          selectedId &&
          addBlockToPageById(block, selectedId);

        blockIdx !== null &&
          selectedId &&
          addBlockAfterIdxToPageById(selectedId, blockIdx, block);
      }

      if (EditBlock !== null) {
        findBlockAndUpdate(block, EditBlock.id);
      }

      setSelectedPageId(null);
      setEditBlock(null);
      setBlockIdx(null);
      setEditorTitle('');
      props.onCloseEditor();
    },
    [
      list,
      EditBlock,
      addBlockAfterIdxToPageById,
      addBlockToPageById,
      setEditorTitle,
      blockIdx,
      findBlockAndUpdate,
      props,
      selectedId,
      setBlockIdx,
      setEditBlock,
      setSelectedPageId,
    ],
  );

  return (
    <>
      <hr style={{ margin: '0 15px 10px 15px' }} />
      <div style={{ padding: '0 15px', marginBottom: 10 }}>
        <h3 className={cc.EditableBlockSelectColorTitle}>Наполнение</h3>
        <SearchInput
          searchText={search}
          onChangeSearchText={setSearch}
          placeholder="Поиск блока"
        />
      </div>
      <hr style={{ margin: '0 15px 10px 15px' }} />
      <div className={cc.SelectBlockBody}>
        {filtredList.length ? (
          <GenerateBlocks
            blocks={filtredList}
            clickOnBlock={clickOnBlock}
            pageId={'newBlock'}
            showAddBtn={false}
            showEditableBlock={show}
            setShowEditableBlock={setShow}
            editBlockId={blockId}
            setShowEditPageId={setBlockId}
            titleEditAllowed={true}
            pub={true}
          />
        ) : (
          <h2
            style={{
              color: '#333',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              margin: 0,
            }}
          >
            Результатов нет
          </h2>
        )}
      </div>
    </>
  );
});
