import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import {
  RegistrationTypeMessageEnum,
  IRegistrationMessage,
} from '../../../store/reducers/registration';
import { useRegistration } from '../../../hooks/useRegistration';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { VisibilityIcon } from '../../../components/VisibilityIcon';
import { VisibilityOffIcon } from '../../../components/VisibilityOffIcon';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 20,
  },
  form: {
    textAlign: 'center',
  },
  button: {
    maxWidth: 240,
    width: '100%',
    marginTop: 38,
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#EE6475',
  },
}));

export function RequestPassword() {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);
  const password = useTypeSelector((s) => s.registration.user.password);
  const reppassword = useTypeSelector(
    (s) => s.registration.user.retype_password,
  );
  const [regPolice, setRegPolice] = useState(new RegExp(''));

  const {
    addStep,
    setDescription,
    setTitle,
    setUserPassword,
    setUserRetypePassword,
    setRegistrationMessage,
    getPasswordRegPolice,
  } = useRegistration();

  const setMessage = useCallback(
    (msg: string | undefined) => {
      const newmessage: IRegistrationMessage = {
        text: msg ? msg : null,
        type: RegistrationTypeMessageEnum.warning,
      };
      setRegistrationMessage(newmessage);
    },
    [setRegistrationMessage],
  );

  const onSubmit = useCallback(() => {
    setTitle('Расскажите о себе');
    setDescription('ВВЕДИТЕ ВАШИ ДАННЫЕ');
    addStep();
  }, [setTitle, setDescription, addStep]);

  const getPolice = useCallback(async () => {
    const data = await getPasswordRegPolice();
    setRegPolice(new RegExp(data.regexp));
  }, [getPasswordRegPolice]);

  useEffect(() => {
    if (!regPolice.test(password)) {
      setMessage('Пароль должен содержать \n не менее 8 символов на латинице');
      setValid(false);
    } else if (password && reppassword && password !== reppassword) {
      setMessage('Пароли не совпадают');
      setValid(false);
    } else {
      setMessage(undefined);
      setValid(true);
    }
  }, [password, reppassword, setMessage, regPolice]);

  useEffect(() => {
    (async () => {
      await getPolice();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <TextField
        fullWidth
        required
        margin="dense"
        onChange={(evt) => {
          setUserPassword(evt.target.value);
        }}
        value={password}
        name="password"
        autoComplete="false"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((s) => !s)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Пароль"
        type={showPassword ? 'text' : 'password'}
      />
      <TextField
        fullWidth
        required
        margin="dense"
        onChange={(evt) => {
          setUserRetypePassword(evt.target.value);
        }}
        value={reppassword}
        name="retype_password"
        autoComplete="false"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((s) => !s)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Повторите пароль"
        type={showPassword ? 'text' : 'password'}
      />
      <div className={classes.grid}>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!valid}
        >
          СОХРАНИТЬ
        </Button>
      </div>
    </div>
  );
}
