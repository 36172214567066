import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const CaruselAndTextBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 1.90039H55.5V12.1004H31C30.1716 12.1004 29.5 11.4288 29.5 10.6004V3.40039C29.5 2.57196 30.1716 1.90039 31 1.90039Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M144 3.2998H126.5V10.6998H144C144.828 10.6998 145.5 10.0282 145.5 9.1998V4.79981C145.5 3.97138 144.828 3.2998 144 3.2998Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M12 3.2998H29.5V10.6998H12C11.1716 10.6998 10.5 10.0282 10.5 9.1998V4.79981C10.5 3.97138 11.1716 3.2998 12 3.2998Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M125 1.90039H100.5V12.1004H125C125.828 12.1004 126.5 11.4288 126.5 10.6004V3.40039C126.5 2.57196 125.828 1.90039 125 1.90039Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M75.7095 3.75628C75.8674 3.59219 76.0815 3.5 76.3048 3.5C76.5281 3.5 76.7422 3.59219 76.9001 3.75628C77.058 3.92038 77.1466 4.14294 77.1466 4.375C77.1466 4.60706 77.058 4.82962 76.9001 4.99372C76.7422 5.15781 76.5281 5.25 76.3048 5.25C76.0815 5.25 75.8674 5.15781 75.7095 4.99372C75.5517 4.82962 75.463 4.60706 75.463 4.375C75.463 4.14294 75.5517 3.92038 75.7095 3.75628Z"
          fill={blockDefaultColor}
        />
        <path
          d="M77.1466 9.625L80.0931 6.125L83.25 10.5H72.75L75.042 7.4375L77.1466 9.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="56"
          y="1"
          width="44"
          height="12"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="72"
          y="16"
          width="12"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="73"
          y="22"
          width="10"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
        <rect
          x="69"
          y="19"
          width="18"
          height="2"
          rx="1"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
