import { IResponse, request } from './request';

export interface IProfile {
  account: {
    company: {
      address: string;
      agreementNumber: string;
      bank: string;
      checking_account: string;
      contact_number: string;
      correspondent_account: string;
      email: string;
      fax_number: string;
      inn: string;
      kpp: string;
      name: string;
      ogrn: string;
      phone_number: string;
    };
    subscription: {
      active_to: null | Date;
      status: 'ACTIVE' | 'DISACTIVE';
      tariff_type: 'TRIAL' | 'PAID';
    };
    description: string;
    type: 'PERSON' | 'COMPANY';
  };
  name: string;
  surname: string;
  email: string;
  account_type: 'PERSON' | 'COMPANY';
  email_preferences?: {
    allow_site_news: boolean;
    allow_system_notifications: boolean;
  };
  language: string;
  onboarding_completed: boolean;
}

export interface IProfileUpdate {
  description?: string;
  email_preferences?: {
    allow_site_news: boolean;
    allow_system_notifications: boolean;
  };
  language?: string;
  name?: string;
}

export function getProfile() {
  return request('get', 'profile');
}

export function changePassword(currentPassword: string, newPassword: string) {
  return request('post', 'profile/change-password', {
    current_password: currentPassword,
    new_password: newPassword,
  });
}

export function onboarding() {
  return request('post', 'profile/onboarding');
}

export function updateProfile(profile: IProfileUpdate) {
  return request('post', 'profile/update', profile);
}

export function requestChangeRequisites() {
  return request('post', 'profile/change-requisites/request');
}

export const getProfileImage = (): Promise<IResponse<Blob>> => {
  return request('get', 'profile/image', undefined, {
    responseType: 'blob',
  });
};

export function setProfileImage(payload: File) {
  const form = new FormData();
  form.append('file', payload);
  return request('post', 'profile/image', form);
}

export function deleteProfileImage() {
  return request('delete', 'profile/image');
}

export function checkRegistartionToken(token: string) {
  return request('post', 'users/registration/check', { token });
}
