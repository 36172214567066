export enum ImageProfileActionTypeEnum {
  setImageToProfile = 'SET_IMAGE_TO_PROFILE',
}

interface ISetImageToProfile {
  type: ImageProfileActionTypeEnum.setImageToProfile;
  payload: string | null;
}

export type IProfileImageAction = ISetImageToProfile;

export interface IProfileImage {
  image: string | null;
}

const IS: IProfileImage = {
  image: null,
};

export const ProfileImageReducer = (
  state: IProfileImage = IS,
  action: IProfileImageAction,
) => {
  switch (action.type) {
    case ImageProfileActionTypeEnum.setImageToProfile:
      return { ...state, image: action.payload };

    default:
      return state;
  }
};
