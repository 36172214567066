import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import useStyles from '../../../../views/EditorItem/styles';
import { Block } from './Block';
import { blockDefaultColor } from './blockColor';

export const Columns2TextBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="74" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          y="6.66699"
          width="71.7576"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="13.333"
          width="74"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="20"
          width="31.3939"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect x="82" width="74" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="82"
          y="6.66699"
          width="71.7576"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="82"
          y="13.333"
          width="74"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="82"
          y="20"
          width="31.3939"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
