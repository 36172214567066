import React from 'react';
import useStyles from './styles/index';
import cx from 'clsx';

interface FeedbackSuccessMessageProps {
  close: () => void;
}

export const SuccessMessage = (props:FeedbackSuccessMessageProps) => {
  const classes = useStyles();
  const { close } = props;
  return (
    <div className={classes.messageBlock}>
      <h2>Успех!</h2>
      <p>
        Сообщение отправлено.
        <br />
        Благодарим, что помогаете
        <br />
        делать продукт лучше!
      </p>
      <div className={classes.btnGroup}>
        <div className={cx(classes.btn, classes.blueBtn)} onClick={close}>Продолжить</div>
      </div>
    </div>
  );
};
