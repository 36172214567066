import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Container,
} from '@material-ui/core';

import { makeFeedback } from '../../api/feedback.api';

import useStyles from './styles/index';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useDispatch } from 'react-redux';
import { FeedbackActionEnum, IFeedback } from '../../store/reducers/feedback';
import MessageWrapper from './FormMessage/messageWrapper';
import { FeedbackMessageType } from './FormMessage/messageWrapper';
import { getSiteDrafts } from '../../api/siteDraft';
import { IconsOutline } from '../../components/IconOutliner';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

export function Feedback() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [messageType, setMessageType] = useState<FeedbackMessageType>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const feedbackState: IFeedback = useTypeSelector((s) => s.feedback);
  const { setSnackbarValue } = useSnackbar();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  useEffect(() => {
    const canSubmit =
      feedbackState.type !== undefined &&
      feedbackState.type !== null &&
      feedbackState.description.trim().length >= 20;
    setCanSubmit(canSubmit);
  }, [feedbackState.type, feedbackState.description]);

  const setLoading = (payload: boolean) => {
    dispatch({ type: FeedbackActionEnum.setLoading, payload });
  };

  const setType = (event: any) => {
    const payload = event.target.value;
    dispatch({ type: FeedbackActionEnum.setType, payload });
  };

  const setDescription = (event: any) => {
    const payload = event.target.value;
    dispatch({ type: FeedbackActionEnum.setDescription, payload });
  };

  const setErrors = useCallback(
    (clear = false) => {
      if (feedbackState.description.length === 0) {
        const payload = clear
          ? { description: null, project: null }
          : {
              description:
                feedbackState.description.length === 0
                  ? 'Обязательное поле'
                  : null,
              project: !feedbackState.project ? 'Обязательное поле' : null,
            };
        dispatch({ type: FeedbackActionEnum.setErrors, payload });
      }
    },
    [dispatch, feedbackState],
  );

  const setFile = (file: any) => {
    dispatch({ type: FeedbackActionEnum.setFile, payload: file });
  };
  const addFile = (file: any) => {
    dispatch({ type: FeedbackActionEnum.addFile, payload: file });
  };
  const deleteFile = (file: File, idx: number) => {
    dispatch({ type: FeedbackActionEnum.deleteFile, payload: idx });
  };

  const setProject = (e: any) => {
    dispatch({ type: FeedbackActionEnum.setProject, payload: e.target.value });
  };

  const closeMessageType = () => {
    setMessageType(null);
  };

  const clearFeedback = () => {
    dispatch({ type: FeedbackActionEnum.clear });
  };

  const init = useCallback(async () => {
    const { data } = await getSiteDrafts(100, 0);
    dispatch({ type: FeedbackActionEnum.draftList, payload: data });
  }, [dispatch]);

  const submit = async () => {
    if (!canSubmit) return;
    setLoading(true);
    if (feedbackState.description.length === 0) {
      setErrors();
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append('type', feedbackState.type);
    formData.append('description', feedbackState.description);
    feedbackState.project &&
      formData.append('project_id', feedbackState.project);

    feedbackState.file &&
      Array.from(feedbackState.file).map((file) => {
        formData.append('files', file);
      });

    try {
      const data = await makeFeedback(formData);

      if (!data?.ok) {
        setMessageType('ERROR');
        setErrorMessage(data?.error?.response?.data?.error_description);
        clearFeedback();
        setLoading(false);
        return;
      }
      setMessageType('SUCCESS');
      clearFeedback();
    } catch (e: any) {
      setSnackbarValue(
        e?.error?.message || 'Что то пошло не так!',
        SnackbarType.Error,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.feedbackWrapper}>
      <Container maxWidth="md">
        <Card className={classes.card}>
          <div className={classes.top}>
            <Typography gutterBottom variant="h2" className={classes.title}>
              Сообщить о проблеме
            </Typography>
            <Typography className={classes.description}>
              Каждое обращение рассматривается оперативно в индивидуальном
              порядке разработчиками сервиса. <br />
              Мы рады вашим отзывам и предложениям.
              <br />
            </Typography>
          </div>

          <CardContent className={classes.writeUsContent}>
            <div className={classes.form}>
              <div className={classes.formHeader}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="type-select">
                    Причина обращения <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="type-select"
                    name="type"
                    value={feedbackState.type}
                    onChange={setType}
                    variant="outlined"
                  >
                    <MenuItem value="BUG">Ошибка</MenuItem>
                    <MenuItem value="QUESTION">Вопрос</MenuItem>
                    <MenuItem value="OTHER">Другое</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControlSelect}>
                  <InputLabel id="project-select">Связанный проект</InputLabel>
                  <Select
                    labelId="project-select"
                    name="project-select"
                    value={feedbackState.project ?? null}
                    onChange={setProject}
                    variant="outlined"
                  >
                    {feedbackState?.draftList?.length ? (
                      feedbackState.draftList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={undefined}>нет проекта</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <TextField
                name="description"
                value={feedbackState.description}
                onChange={setDescription}
                placeholder="Опишите проблему или вопрос (минимум 20 символов)"
                multiline
                error={feedbackState.errors.description ? true : false}
                helperText={feedbackState.errors.description}
                minRows={4}
                variant="outlined"
              />

              <Typography gutterBottom variant="h2" className={classes.title}>
                Прикрепленные файлы
              </Typography>
              {feedbackState.file && Array.from(feedbackState.file).length ? (
                <div className={classes.uploadArea}>
                  <Button component="label">
                    <input
                      hidden
                      name="file"
                      type="file"
                      className={classes.inputFile}
                      onChange={(evt) => {
                        addFile(evt.currentTarget.files);
                      }}
                      multiple
                    />
                    <span>
                      Добавить файл
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9846 3.00012C11.7202 3.00426 11.4681 3.11315 11.2839 3.30289C11.0996 3.49264 10.9982 3.74775 11.0018 4.01222V11.0013H4.01267C3.88036 10.9995 3.749 11.0239 3.62622 11.0732C3.50345 11.1226 3.3917 11.1958 3.29748 11.2887C3.20325 11.3816 3.12843 11.4923 3.07737 11.6144C3.0263 11.7365 3 11.8675 3 11.9998C3 12.1321 3.0263 12.2631 3.07737 12.3852C3.12843 12.5072 3.20325 12.6179 3.29748 12.7108C3.3917 12.8037 3.50345 12.877 3.62622 12.9263C3.749 12.9756 3.88036 13.0001 4.01267 12.9982H11.0018V19.9873C10.9999 20.1196 11.0244 20.251 11.0737 20.3738C11.123 20.4966 11.1963 20.6083 11.2892 20.7025C11.3821 20.7967 11.4928 20.8716 11.6148 20.9226C11.7369 20.9737 11.8679 21 12.0002 21C12.1325 21 12.2635 20.9737 12.3856 20.9226C12.5077 20.8716 12.6184 20.7967 12.7113 20.7025C12.8042 20.6083 12.8774 20.4966 12.9268 20.3738C12.9761 20.251 13.0005 20.1196 12.9987 19.9873V12.9982H19.9878C20.1201 13.0001 20.2514 12.9756 20.3742 12.9263C20.497 12.877 20.6087 12.8037 20.703 12.7108C20.7972 12.6179 20.872 12.5072 20.9231 12.3852C20.9742 12.2631 21.0004 12.1321 21.0004 11.9998C21.0004 11.8675 20.9742 11.7365 20.9231 11.6144C20.872 11.4923 20.7972 11.3816 20.703 11.2887C20.6087 11.1958 20.497 11.1226 20.3742 11.0732C20.2514 11.0239 20.1201 10.9995 19.9878 11.0013H12.9987V4.01222C13.0005 3.87864 12.9755 3.74605 12.9252 3.6223C12.8748 3.49855 12.8002 3.38616 12.7056 3.29179C12.6111 3.19741 12.4985 3.12297 12.3747 3.07287C12.2508 3.02277 12.1182 2.99804 11.9846 3.00012V3.00012Z"
                          fill="#3090E8"
                        />
                      </svg>
                    </span>
                  </Button>
                </div>
              ) : (
                <div className={classes.uploadArea}>
                  <Button component="label">
                    <input
                      hidden
                      name="file"
                      type="file"
                      className={classes.inputFile}
                      onChange={(evt) => {
                        setFile(evt.currentTarget.files);
                      }}
                      multiple
                    />
                    <span>
                      Прикрепить файл
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9846 3.00012C11.7202 3.00426 11.4681 3.11315 11.2839 3.30289C11.0996 3.49264 10.9982 3.74775 11.0018 4.01222V11.0013H4.01267C3.88036 10.9995 3.749 11.0239 3.62622 11.0732C3.50345 11.1226 3.3917 11.1958 3.29748 11.2887C3.20325 11.3816 3.12843 11.4923 3.07737 11.6144C3.0263 11.7365 3 11.8675 3 11.9998C3 12.1321 3.0263 12.2631 3.07737 12.3852C3.12843 12.5072 3.20325 12.6179 3.29748 12.7108C3.3917 12.8037 3.50345 12.877 3.62622 12.9263C3.749 12.9756 3.88036 13.0001 4.01267 12.9982H11.0018V19.9873C10.9999 20.1196 11.0244 20.251 11.0737 20.3738C11.123 20.4966 11.1963 20.6083 11.2892 20.7025C11.3821 20.7967 11.4928 20.8716 11.6148 20.9226C11.7369 20.9737 11.8679 21 12.0002 21C12.1325 21 12.2635 20.9737 12.3856 20.9226C12.5077 20.8716 12.6184 20.7967 12.7113 20.7025C12.8042 20.6083 12.8774 20.4966 12.9268 20.3738C12.9761 20.251 13.0005 20.1196 12.9987 19.9873V12.9982H19.9878C20.1201 13.0001 20.2514 12.9756 20.3742 12.9263C20.497 12.877 20.6087 12.8037 20.703 12.7108C20.7972 12.6179 20.872 12.5072 20.9231 12.3852C20.9742 12.2631 21.0004 12.1321 21.0004 11.9998C21.0004 11.8675 20.9742 11.7365 20.9231 11.6144C20.872 11.4923 20.7972 11.3816 20.703 11.2887C20.6087 11.1958 20.497 11.1226 20.3742 11.0732C20.2514 11.0239 20.1201 10.9995 19.9878 11.0013H12.9987V4.01222C13.0005 3.87864 12.9755 3.74605 12.9252 3.6223C12.8748 3.49855 12.8002 3.38616 12.7056 3.29179C12.6111 3.19741 12.4985 3.12297 12.3747 3.07287C12.2508 3.02277 12.1182 2.99804 11.9846 3.00012V3.00012Z"
                          fill="#3090E8"
                        />
                      </svg>
                    </span>
                  </Button>
                </div>
              )}
              <div className={classes.files}>
                {feedbackState.file &&
                  Array.from(feedbackState.file).map((item, idx) => (
                    <div className={classes.file} key={idx}>
                      {item.name}{' '}
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => deleteFile(item, idx)}
                      >
                        <path
                          d="M10 2L9 3H4V5H5V20C5 20.5222 5.19133 21.0546 5.56836 21.4316C5.94539 21.8087 6.47778 22 7 22H17C17.5222 22 18.0546 21.8087 18.4316 21.4316C18.8087 21.0546 19 20.5222 19 20V5H20V3H15L14 2H10ZM7 5H17V20H7V5ZM9 7V18H11V7H9ZM13 7V18H15V7H13Z"
                          fill="#EB5757"
                        />
                      </svg>
                    </div>
                  ))}
              </div>

              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="secondary"
                disabled={!canSubmit || feedbackState.loading}
                onClick={submit}
              >
                {feedbackState.loading ? (
                  <IconsOutline types="loading" />
                ) : (
                  'Отправить'
                )}
              </Button>
            </div>
            <Typography className={classes.link}>
              <a href="mailto:info@structura.app" className={classes.link}>
                info@structura.app
              </a>
            </Typography>
          </CardContent>
        </Card>
        {messageType !== null && (
          <MessageWrapper
            type={messageType}
            close={closeMessageType}
            repeat={submit}
            errorMessage={errorMessage}
          />
        )}
      </Container>
    </div>
  );
}
