import { makeStyles } from '@material-ui/core';

export const NewButtonStyle = makeStyles((theme) => ({
  btn_wrapper: {
    width: 'auto',
    height: 40,
    backgroundColor: 'white',
    border: '1px solid #B6D6F7',
    borderRadius: 5,
    color: '#3090E8',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    columnGap: 10,
    padding: '0 20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    cursor: 'pointer',
    overflow:'hidden',
    '&:hover':{
      backgroundColor: '#3090E8',
      color: 'white',
    },
  },
  noBorder: {
    border: 'none',
    borderRadius: 0,
  },
  btn_wrapper_primary: {
    backgroundColor: '#3090E8',
    color: 'white',
  },
  btn_wrapper_hover: {
    backgroundColor: '#3090E8',
    color: 'white',
  },
  btn_wrapper_primary_hover:{
    backgroundColor: 'white',
    color: '#3090E8',
  },
  btn_icon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    aligItems: 'center',
    backgroundColor: 'inherit',
    color: 'inherit',
  },
  loading: {
    filter: 'grayscale(1)',
    opacity: .3,
  },
}));
