import React, { FC, useCallback, useEffect, useState } from 'react';
import useLocation from 'wouter/use-location';
import { NewButton } from '../../components/Button/NewButton';
import { ProjectCard } from '../../components/Editor/list/ProjectCard';
import { useEditorList } from '../../hooks/useEditorList';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { NewEditorListStyles } from './styles';
import { useModal } from '../../hooks/useModal';
import { BySite } from './modal/BySite';
import { ByTemplate } from './modal/ByTemplate';
import { useDraftTemp } from '../../hooks/useDraftTemp';
import { createSiteDraft } from '../../api/siteDraft';
import { useRequest } from '../../hooks/useRequest';
import { getDraftTemp } from '../../api/draftTemp';
import { IDraftTempCategoryItem } from '../../api/draftTempCategory';
import { TemplateCard } from '../../components/Editor/temp/TemplateCard';
import { PopupEditorList } from './PopupEditorList';
import { setTitle } from '../../utils/setTitle';

export const EditorList: FC = () => {
  const cc = NewEditorListStyles();
  const { getList } = useEditorList();
  const [showTemp, setShowTemp] = useState(true);
  const [, setLocation] = useLocation();
  const list = useTypeSelector((s) => s.editorList.list);
  const page = useTypeSelector((s) => s.editorList.page);
  const sm = useTypeSelector((s) => s.modal.show);
  const { showModal, setModalBody } = useModal();
  const { clearDraftTemp, TempUse, goToTemp } = useDraftTemp();
  const { request } = useRequest();
  const [tempList, setTempList] = useState<IDraftTempCategoryItem[]>();

  const getTempList = useCallback(async () => {
    try {
      const data = await request(async () => await getDraftTemp());
      setTempList(data?.slice(0, 6));
    } catch (e) {
      console.error('e', e);
    }
  }, [request]);

  const create = useCallback(async () => {
    request(async () => {
      const { data } = await createSiteDraft();
      data && setLocation(`/editor/${data.id}`);
    });
  }, [setLocation, request]);

  const ByURL = () => {
    showModal();
    setModalBody(<BySite />);
  };

  const ByTemp = () => {
    showModal();
    setModalBody(<ByTemplate />);
  };

  const getLists = useCallback(async () => {
    await getList(page);
  }, [page, getList]);

  useEffect(() => {
    if (!sm) {
      clearDraftTemp();
    }
  }, [sm, clearDraftTemp]);

  useEffect(() => {
    setTitle('Конструктор прототипов сайтов');
    (async () => {
      await getTempList();
      await getLists();
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cc.new_editor_wrapper}>
      <h2>Создать проект</h2>
      <div className={cc.new_editor_wrapper_header_btn}>
        <NewButton
          onClick={create}
          caption="С чистого листа"
          afterIcon="plus"
        />
        <NewButton onClick={ByTemp} caption="Из шаблона" afterIcon="plus" />
        <NewButton onClick={ByURL} caption="На основе сайта" afterIcon="plus" />
      </div>
      <hr style={{ width: '100%' }} />
      <div
        className={cc.new_editor_wrapper_header}
        style={{ justifyContent: 'space-between' }}
      >
        <h2>Популярные шаблоны</h2>
        <NewButton
          onClick={() => setShowTemp(!showTemp)}
          caption={showTemp ? 'Скрыть' : 'Показать'}
          afterIcon="minus"
        />
      </div>
      {showTemp ? (
        <div className={cc.new_editor_wrapper_body_template}>
          {tempList && Array.isArray(tempList) ? (
            tempList.map((item, idx) => (
              <TemplateCard
                {...item}
                key={idx}
                useNow={TempUse}
                goToTemp={goToTemp}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <span onClick={ByTemp} className={cc.link}>
          Посмотреть все шаблоны
        </span>
      </div>
      <hr style={{ width: '100%' }} />
      <h2>Мои проекты</h2>
      <div className={cc.new_editor_wrapper_body}>
        {list && list?.length ? (
          list.map((item, index) => <ProjectCard {...item} key={index} />)
        ) : (
          <div className={cc.empty_editor}>
            Тут будет список ваших проектов. Создайте свой первый проект одним
            из способов выше.
          </div>
        )}
      </div>
      <PopupEditorList />
    </div>
  );
};
