import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const FAQBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 18H13V16H11V18ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 12 11 11.75 11 15H13C13 12.75 16 12.5 16 10C16 7.79 14.21 6 12 6Z"
          fill={blockDefaultColor}
        />
        <rect x="32" width="96" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="32"
          y="10.5"
          width="96"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="32"
          y="21"
          width="96"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <path
          d="M144 12.6315L147.821 8.8105C148.235 8.3965 148.907 8.3965 149.321 8.8105C149.735 9.2245 149.735 9.8965 149.321 10.3105L144.707 14.9245C144.316 15.3155 143.683 15.3155 143.293 14.9245L138.679 10.3105C138.265 9.8965 138.265 9.2245 138.679 8.8105C139.093 8.3965 139.765 8.3965 140.179 8.8105L144 12.6315Z"
          fill={blockDefaultColor}
        />
        <path
          d="M144 21.1315L147.821 17.3105C148.235 16.8965 148.907 16.8965 149.321 17.3105C149.735 17.7245 149.735 18.3965 149.321 18.8105L144.707 23.4245C144.316 23.8155 143.683 23.8155 143.293 23.4245L138.679 18.8105C138.265 18.3965 138.265 17.7245 138.679 17.3105C139.093 16.8965 139.765 16.8965 140.179 17.3105L144 21.1315Z"
          fill={blockDefaultColor}
        />
        <path
          d="M144 4.1315L147.821 0.3105C148.235 -0.1035 148.907 -0.1035 149.321 0.3105C149.735 0.7245 149.735 1.3965 149.321 1.8105L144.707 6.4245C144.316 6.8155 143.683 6.8155 143.293 6.4245L138.679 1.8105C138.265 1.3965 138.265 0.7245 138.679 0.3105C139.093 -0.1035 139.765 -0.1035 140.179 0.3105L144 4.1315Z"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
