import React from 'react';

import { SelectTariffs } from './Tarrifs/SelectTariffs';
import { Card, CardContent } from '@material-ui/core';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useProfileStyles } from './styles/profileStyles';
import cx from 'clsx';
import moment from 'moment';

export const Tarrifs = () => {
  const classes = useProfileStyles();
  const subscription = useTypeSelector(
    (s) => s.profile.profile?.account.subscription,
  );
  return (
    <Card className={cx(classes.card, classes.cardTariffs)} style={{ height: '100%'}}>
      <div className={classes.cardHeader}>
        {subscription?.tariff_type === 'TRIAL' ? (
          <>
            Полный доступ с <span className={classes.textActive}>PREMIUM</span>
          </>
        ) : (
          <>
            Платный&nbsp;
            <span className={classes.textActive}>
              тариф активен до{' '}
              {subscription?.active_to
                ? moment(subscription?.active_to).format('DD.MM.YYYY')
                : ''}
            </span>
            , поздравляем!
          </>
        )}
        <hr className={classes.hr} />
      </div>
      <CardContent>
        <SelectTariffs />
      </CardContent>
    </Card>
  );
};
