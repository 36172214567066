import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  btn: {
    backgroundColor: '#3F92D1',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    color: '#FCFEFF',
    border: '1px solid #3F92D1',
    borderRadius: 5,
    minWidth: 165,
    width: 'auto',
    minHeight: 40,
    height: 'auto',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 5,
    '&:hover': {
      backgroundColor: '#FCFEFF',
      color: '#3F92D1',
      '& svg path': {
        fill: '#3F92D1',
      },
    },
  },
  primary: {
    border: '1px solid #FD1F6D',
    backgroundColor: '#FD1F6D',
    '&:hover': {
      backgroundColor: '#FCFEFF',
      color: '#FD1F6D',
      '& svg path': {
        fill: '#FD1F6D !important',
      },
    },
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  primaryTransparent:{
    color: '#FD1F6D',
    '&:hover': {
      backgroundColor: '#FD1F6D',
      color: '#FCFEFF',
      '& svg path': {
        fill: '#FCFEFF !important',
      },
    },
  },
  defaultTransparent: {
    color: '#353E47',
    border: '1px solid #C7D6E6',
    backgroundColor: 'transparent',
    transition: 'All 0.3s ease-in-out',
    '& svg path': {
      fill: '#353E47',
      transition: 'All 0.3s ease-in-out',
    },
    '&:hover': {
      color: '#3090E8',
      border: '1px solid #3090E8',
      '& svg path': {
        fill: '#3090E8',
      },
    },
  },
  loading: {
    filter: 'grayscale(0.5)',
  },
}));
