import React, { useCallback, useEffect } from 'react';
import { RegistrationMessage } from './RegistrationMessage';
import { Step } from './Step';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { PersonForm } from './Person/PersonForm';
import { RequestPassword } from './Person/RequestPassword';
import { useLocation } from 'wouter';
import { RegistrationDescription } from './RegistrationDescription';
import { useRegistration } from '../../hooks/useRegistration';

export const Steper = () => {
  const [location] = useLocation();
  const step = useTypeSelector((s) => s.registration.step);
  const reg = '/registration/';
  const token = location.replace(reg, '');
  const { initThreeStep } = useRegistration();

  const init = useCallback(() => {
    step === 1 && initThreeStep();
  }, [step, initThreeStep]);

  useEffect(() => {
    init();
  }, [step, init]);

  return (
    <div>
      <Step />
      <RegistrationDescription />
      <RegistrationMessage />
      {step === 3 && <RequestPassword />}
      {step === 4 && <PersonForm token={token} />}
    </div>
  );
};
