import { useDispatch } from 'react-redux';
import { DraftReducerActionEnum } from '../store/reducers/draft';
import { IBlocks } from '../store/reducers/editors';
import { EditableBlockSettingsActionEnum } from '../store/reducers/settings/editableBlockReducer';

export const useSettingsEditor = () => {
  const dispatch = useDispatch();

  const setSettingEditorList = (payload: IBlocks[]) => {
    dispatch({
      type: EditableBlockSettingsActionEnum.setBlocksList,
      payload,
    });
  };

  return {
    setSettingEditorList,
    setSelectedPageId: (id: string | null) => {
      // set selected page id
      dispatch({
        type: EditableBlockSettingsActionEnum.setSelectedPageId,
        payload: id,
      });
      // set block idx null
      dispatch({
        type: EditableBlockSettingsActionEnum.setBlockIdx,
        payload: null,
      });
    },
    setCloseEditor: () => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setEditorShow,
        payload: false,
      });
    },
    setShowEditor: () => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setEditorShow,
        payload: true,
      });
    },
    setEditorTitle: (newVal: string) => {
      dispatch({
        type: EditableBlockSettingsActionEnum.changeTitle,
        payload: newVal,
      });
    },
    setBlockIdx: (idx: number | null) => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setBlockIdx,
        payload: idx,
      });
    },
    setEditBlock: (block: IBlocks | null) => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setEditBlock,
        payload: block,
      });
    },
    showRemoveBtn: () => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setRemoveShow,
        payload: true,
      });
    },
    hideRemoveBtn: () => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setRemoveShow,
        payload: false,
      });
    },
    showCreateProjectModal: () => {
      dispatch({
        type: EditableBlockSettingsActionEnum.showCreateProjectModal,
      });
    },
    hideCreateProjectModal: () => {
      dispatch({
        type: EditableBlockSettingsActionEnum.hideCreateProjectModal,
      });
    },
    setSidebarRoll: (payload: boolean) => {
      dispatch({
        type: EditableBlockSettingsActionEnum.setSidebarRollUp,
        payload,
      });
    },
    setDraftLoading: (payload: boolean) => {
      dispatch({
        type: DraftReducerActionEnum.setLoading,
        payload,
      });
    },
    exportReportGeneration: (payload: boolean)=>{
      dispatch({type: EditableBlockSettingsActionEnum.setExportReport, payload})
    }
  };
};
