import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const Columns3TextBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="46.6667" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          y="6.66699"
          width="45.2525"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="13.333"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="20"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="6.66699"
          width="45.2525"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="13.333"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="54.6667"
          y="20"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          y="6.66699"
          width="45.2525"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          y="13.333"
          width="46.6667"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="109.333"
          y="20"
          width="19.798"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
