import { SnackbarType, useSnackbar } from './useSnackbar';
import { revokeToken } from '../api/oauth.api';
import { useLocation } from 'wouter';

export const useRequest = () => {
  const { setSnackbarValue } = useSnackbar();
  const [, setLocation] = useLocation()

  const request = async (needRunCB: Function, ifError?: Function) => {
    try {
      const res = await needRunCB();
      if (res && res.data) {
        return res.data;
      }
      return res;
    } catch (e: any) {
      console.error('e', e);

      if (e.error === 'invalid_token' || e.error === 'Invalid access token') {
        setSnackbarValue(
          'Мы завершили сеанс из соображений безопасности.',
          SnackbarType.Error,
        );
        await revokeToken();
        setLocation('/login')
        return false;
      }

      if (e.error === 'unauthorized') {
        setSnackbarValue('Доступ прекращен или у Вас нет прав на изменение', SnackbarType.Error);
      }
      if (e.error === "not_found") {
        setSnackbarValue('Данная схема не найдена', SnackbarType.Error);
        setLocation('/editor')
      }

      ifError && ifError()

      setSnackbarValue(e.error, SnackbarType.Error);
    }
  }

  return {
    request,
  };
};
