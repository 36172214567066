import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  close: {
    cursor: 'pointer',
    transition: 'All 0.3s ease-in-out',
    '& path': {
      transition: 'All 0.3s ease-in-out',
      fill: '#353E47',
    },
    '&:hover': {
      '& path': {
        fill: '#FD5D95',
      },
    },
  },
}));
