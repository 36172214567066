function removeElementsByClass(node: any, className: string) {
  const elements = node.getElementsByClassName(className);
  while (elements.length > 0) {
    //@ts-ignore
    elements[0].parentNode.removeChild(elements[0]);
  }
}
function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}

export const toHTML = (draftName: string) => {
  const html = document.querySelector('html')?.cloneNode(true);
  const head = document.querySelector('head')?.cloneNode(true);
  const body = document.querySelector('body')?.cloneNode(true);
  //@ts-ignore
  const manifest = head.querySelector('link[rel="manifest"]');
  //@ts-ignore
  const favicons = head.querySelectorAll('link[type="image/svg+xml"]');
  for (const node of Array.from(favicons)) {
    //@ts-ignore
    node.setAttribute('href', 'https://app.structura.app/favicon.svg');
  }

  //@ts-ignore
  const textarea = body?.querySelector('textarea[aria-hidden="true"]');
  textarea && body?.removeChild(textarea);
  //@ts-ignore
  const root = html?.querySelector('#root');
  //@ts-ignore
  const modal = root?.querySelector('#new_modal_wraper');
  const contentWrapper = root?.querySelector('#contentWrapper');
  const addBtnClass = root?.querySelectorAll('div[data-add-class]');
  const arr =
    //@ts-ignore
    addBtnClass && Array.from(addBtnClass).map((item) => item.className);
  const addBtnArr = arr && arr.filter(onlyUnique);
  const content = root?.querySelector('#content');
  const ProjectDescriptionWrapper = content?.querySelector(
    '#ProjectDescriptionWrapper',
  );
  const zoom_wrapper = content?.querySelector('#zoom_wrapper');
  const contentWrapperClass = root
    ?.querySelector('#contentWrapper')
    ?.getAttribute('data-class');
  const toolbar = root?.querySelector('#toolbar');
  const underRootClass = root
    ?.querySelector('#underRoot')
    ?.getAttribute('data-class');

  //start removed heads childs
  manifest && head?.removeChild(manifest);

  //start removed body childs
  underRootClass &&
    root?.querySelector('#underRoot')?.classList.remove(underRootClass);
  toolbar && contentWrapper?.removeChild(toolbar);
  modal && root?.removeChild(modal);
  contentWrapperClass && contentWrapper?.classList.remove(contentWrapperClass);

  ProjectDescriptionWrapper && content?.removeChild(ProjectDescriptionWrapper);
  zoom_wrapper && content?.removeChild(zoom_wrapper);

  if (addBtnArr) {
    for (const item of addBtnArr) {
      removeElementsByClass(root, item);
    }
  }
  removeElementsByClass(root, 'block_comments_btn');
  removeElementsByClass(root, 'block_remove_btn');
  removeElementsByClass(root, 'block_add_btn');
  removeElementsByClass(root, 'page_remove_btn');
  removeElementsByClass(root, 'page_add_btn');
  removeElementsByClass(root, 'page_comments_btn');

  if (html) {
    //@ts-ignore
    const file = new Blob([html.outerHTML], { type: 'text/html' });
    //@ts-ignore
    if (window.navigator.msSaveOrOpenBlob)
      //@ts-ignore
      // IE10+
      window.navigator.msSaveOrOpenBlob(
        file,
        `${draftName.replace(/\./g, '_')}.html`,
      );
    else {
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = `${draftName.replace(/\./g, '_')}.html`;
      document.body.appendChild(a);
      a.click();
    }
  }
};
