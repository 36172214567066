import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  input: {
    background: '#FCFEFF',
    border: '1px solid #353E47',
    boxSizing: 'border-box',
    borderRadius: 4,
    display: 'flex',
    alignSelf: 'stretch',
    margin: 0,
    minHeight: 32,
    height: 'auto',
    maxHeight: 250,
    width: '100%',
    color: '#353E47',
    padding: '0px 5px',
    '-ms-hyphens': 'auto',
    '-webkit-hyphens': 'auto',
    outline: '1px solid #3090E8',
    hyphens: 'auto',
    overflow: 'hidden',
    resize: 'none'
  },
  onOver: {
    cursor: 'text'
  },
  onLeav: {
    cursor: 'default'
  }
}));
