import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { FC } from 'react';
import { addCommentStyle } from './styles/addCommentStyle';

export interface IAddCommentProps {
  width?: number;
  onClick: () => void;
  color?: string;
  style?: CSSProperties
}

export const AddComment: FC<IAddCommentProps> = ({
  width = 30,
  onClick,
  color = '#3090E8',
  style,
}) => {
  const cc = addCommentStyle();
  const styleWrapper = { width, height: width, '--border-color': color} as CSSProperties
  return (
    <div className={cc.addComment} style={{...style ,...styleWrapper}}>
      <svg
        width={width - 6}
        height={width - 6}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path
          d="M11.9846 3.00012C11.7202 3.00426 11.4681 3.11315 11.2839 3.30289C11.0996 3.49264 10.9982 3.74775 11.0018 4.01222V11.0013H4.01267C3.88036 10.9995 3.749 11.0239 3.62622 11.0732C3.50345 11.1226 3.3917 11.1958 3.29748 11.2887C3.20325 11.3816 3.12843 11.4923 3.07737 11.6144C3.0263 11.7365 3 11.8675 3 11.9998C3 12.1321 3.0263 12.2631 3.07737 12.3852C3.12843 12.5072 3.20325 12.6179 3.29748 12.7108C3.3917 12.8037 3.50345 12.877 3.62622 12.9263C3.749 12.9756 3.88036 13.0001 4.01267 12.9982H11.0018V19.9873C10.9999 20.1196 11.0244 20.251 11.0737 20.3738C11.123 20.4966 11.1963 20.6083 11.2892 20.7025C11.3821 20.7967 11.4928 20.8716 11.6148 20.9226C11.7369 20.9737 11.8679 21 12.0002 21C12.1325 21 12.2635 20.9737 12.3856 20.9226C12.5077 20.8716 12.6184 20.7967 12.7113 20.7025C12.8042 20.6083 12.8774 20.4966 12.9268 20.3738C12.9761 20.251 13.0005 20.1196 12.9987 19.9873V12.9982H19.9878C20.1201 13.0001 20.2514 12.9756 20.3742 12.9263C20.497 12.877 20.6087 12.8037 20.703 12.7108C20.7972 12.6179 20.872 12.5072 20.9231 12.3852C20.9742 12.2631 21.0004 12.1321 21.0004 11.9998C21.0004 11.8675 20.9742 11.7365 20.9231 11.6144C20.872 11.4923 20.7972 11.3816 20.703 11.2887C20.6087 11.1958 20.497 11.1226 20.3742 11.0732C20.2514 11.0239 20.1201 10.9995 19.9878 11.0013H12.9987V4.01222C13.0005 3.87864 12.9755 3.74605 12.9252 3.6223C12.8748 3.49855 12.8002 3.38616 12.7056 3.29179C12.6111 3.19741 12.4985 3.12297 12.3747 3.07287C12.2508 3.02277 12.1182 2.99804 11.9846 3.00012Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
