import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const TeamsBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="16"
          height="16"
          rx="8"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="1"
          y="20"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="36"
          y="1"
          width="16"
          height="16"
          rx="8"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="36"
          y="20"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="70"
          y="1"
          width="16"
          height="16"
          rx="8"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="70"
          y="20"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="105"
          y="1"
          width="16"
          height="16"
          rx="8"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="105"
          y="20"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="139"
          y="1"
          width="16"
          height="16"
          rx="8"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="139"
          y="20"
          width="16"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
