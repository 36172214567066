import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const Columns3PictureBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0735 6.43934C19.3441 6.15804 19.7112 6 20.094 6C20.4767 6 20.8438 6.15804 21.1144 6.43934C21.3851 6.72064 21.5371 7.10218 21.5371 7.5C21.5371 7.89782 21.3851 8.27936 21.1144 8.56066C20.8438 8.84196 20.4767 9 20.094 9C19.7112 9 19.3441 8.84196 19.0735 8.56066C18.8028 8.27936 18.6508 7.89782 18.6508 7.5C18.6508 7.10218 18.8028 6.72064 19.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M21.5371 16.5L26.5882 10.5L32 18H14L17.9292 12.75L21.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="44.6667"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path
          d="M74.0735 6.43934C74.3441 6.15804 74.7112 6 75.094 6C75.4767 6 75.8438 6.15804 76.1144 6.43934C76.3851 6.72064 76.5371 7.10218 76.5371 7.5C76.5371 7.89782 76.3851 8.27936 76.1144 8.56066C75.8438 8.84196 75.4767 9 75.094 9C74.7112 9 74.3441 8.84196 74.0735 8.56066C73.8028 8.27936 73.6508 7.89782 73.6508 7.5C73.6508 7.10218 73.8028 6.72064 74.0735 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M76.5371 16.5L81.5882 10.5L87 18H69L72.9292 12.75L76.5371 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="55.6667"
          y="1"
          width="44.6667"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path
          d="M129.073 6.43934C129.344 6.15804 129.711 6 130.094 6C130.477 6 130.844 6.15804 131.114 6.43934C131.385 6.72064 131.537 7.10218 131.537 7.5C131.537 7.89782 131.385 8.27936 131.114 8.56066C130.844 8.84196 130.477 9 130.094 9C129.711 9 129.344 8.84196 129.073 8.56066C128.803 8.27936 128.651 7.89782 128.651 7.5C128.651 7.10218 128.803 6.72064 129.073 6.43934Z"
          fill={blockDefaultColor}
        />
        <path
          d="M131.537 16.5L136.588 10.5L142 18H124L127.929 12.75L131.537 16.5Z"
          fill={blockDefaultColor}
        />
        <rect
          x="110.333"
          y="1"
          width="44.6667"
          height="22"
          rx="3"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
