import { IEditorListItem } from '../store/reducers/editorList';
import {
  InitPage,
  ISiteDraft,
  ISiteDraftPreview,
} from '../store/reducers/editors';
import { request } from './request';
// import { IEditorList } from '../store/reducers/editorList';
import { v4 as uuid } from 'uuid';
import { IDraftAccessState } from '../store/reducers/draft';

export type sourceTypes = 'MANUAL' | 'TEMPLATE' | 'SITE';
export type scrapeStatusTypes = 'SUCCESS' | 'IN_PROGRESS' | 'FAIL';
export type scrapeMode = 'SITEMAP' | 'CRAWL';
export type scrapeErrorTypes =
  | 'SITE_NOT_FOUND'
  | 'SITEMAP_NOT_FOUND'
  | 'SITEMAP_TOO_BIG'
  | 'SITE_NOT_AVAILABLE'
  | 'FORBIDDEN'
  | 'INTERNAL'
  | 'TARIFF_NOT_ACTIVE'
  | 'SITEMAP_NOT_EXIST'
  | 'MALFORMED_SITEMAP'
  | null;

interface ISiteDraftCreate extends ISiteDraft {
  source: sourceTypes;
  source_template_id: string;
}

export interface ICreateNewSiteDraft {
  name: string;
  source: sourceTypes;
  source_url: string;
  scrape_details: {
    url: string;
    mode: string;
  };
}

export function getSiteDrafts(limit: number = 100, offset: number = 0) {
  return request<IEditorListItem[]>(
    'get',
    `site-drafts?limit=${limit}&offset=${offset}`,
  );
}

export function createSiteDraftBySite(url: string, mode: string) {
  const newDraft: ICreateNewSiteDraft = {
    name: url,
    source: 'SITE',
    source_url: url,
    scrape_details: {
      url,
      mode,
    },
  };
  return request<IEditorListItem>('post', 'site-drafts', newDraft);
}
export function countSiteDraftBySite(link: string) {
  const newDraft = {
    link,
  };
  return request<{
    valid: boolean;
    count: number;
  }>('post', 'site-drafts/count', newDraft);
}

export function createSiteDraft() {
  const newDraft: ISiteDraft = {
    id: uuid(),
    name: 'Проект без названия',
    pages: [InitPage],
    preview: '',
    source: 'MANUAL',
    description: '',
  };
  return request<IEditorListItem>('post', 'site-drafts', newDraft);
}

export async function createSiteDraftByTemp(temp: ISiteDraft) {
  const newDraft: ISiteDraftCreate = {
    id: uuid(),
    name: temp.name,
    pages: temp.pages,
    preview: temp.preview ? temp.preview : '',
    source: 'TEMPLATE',
    source_template_id: temp.id,
    description: '',
  };
  const { data } = await request<IEditorListItem>(
    'post',
    'site-drafts',
    newDraft,
  );
  return data;
}

export function getSiteDraft(id: string) {
  return request<ISiteDraft>('get', `site-drafts/${id}`);
}

export function setDraftInfo(id: string, type: 'OPEN' | 'CLOSE') {
  return request<ISiteDraft>('post', `site-drafts/${id}/events`, { type });
}

export function getSiteDraftAccess(id: string) {
  return request<IDraftAccessState>('get', `site-drafts/${id}/link-access`);
}

export function putSiteDraft(id: string, data: ISiteDraft) {
  return request<ISiteDraft>('put', `site-drafts/${id}`, data);
}
export function putSiteDraftPreview(id: string, data: ISiteDraftPreview) {
  return request<ISiteDraft>('put', `site-drafts/${id}/preview`, data);
}

export function putSiteDraftAccess(id: string, data: IDraftAccessState) {
  return request<IDraftAccessState>(
    'put',
    `site-drafts/${id}/link-access`,
    data,
  );
}

export function removeSiteDraft(id: string) {
  return request('delete', `site-drafts/${id}`);
}

export type ExportReportFileTypes = 'PDF' | 'DOC' | 'DOCX';

export interface IGenerateReportData {
  format: ExportReportFileTypes;
  preview: string;
}

export function getSiteDraftPDF(id: string, data: IGenerateReportData) {
  return request('post', `site-drafts/${id}/generate-report`, data, {
    responseType: 'blob',
  });
}
