import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const CaruselBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5 4C29.5 3.17157 30.1716 2.5 31 2.5H55.5V17.5H31C30.1716 17.5 29.5 16.8284 29.5 16V4Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M145.5 6C145.5 5.17157 144.828 4.5 144 4.5H126.5V15.5H144C144.828 15.5 145.5 14.8284 145.5 14V6Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M10.5 6C10.5 5.17157 11.1716 4.5 12 4.5H29.5V15.5H12C11.1716 15.5 10.5 14.8284 10.5 14V6Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M126.5 4C126.5 3.17157 125.828 2.5 125 2.5H100.5V17.5H125C125.828 17.5 126.5 16.8284 126.5 16V4Z"
          stroke={blockDefaultColor}
        />
        <path
          d="M74.7279 5.36612C74.9534 5.1317 75.2593 5 75.5783 5C75.8973 5 76.2031 5.1317 76.4287 5.36612C76.6542 5.60054 76.7809 5.91848 76.7809 6.25C76.7809 6.58152 76.6542 6.89946 76.4287 7.13388C76.2031 7.3683 75.8973 7.5 75.5783 7.5C75.2593 7.5 74.9534 7.3683 74.7279 7.13388C74.5024 6.89946 74.3757 6.58152 74.3757 6.25C74.3757 5.91848 74.5024 5.60054 74.7279 5.36612Z"
          fill={blockDefaultColor}
        />
        <path
          d="M76.7809 13.75L80.9901 8.75L85.5 15H70.5L73.7744 10.625L76.7809 13.75Z"
          fill={blockDefaultColor}
        />
        <rect
          x="56"
          y="1"
          width="44"
          height="18"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <circle opacity="0.7" cx="74" cy="23" r="1" fill={blockDefaultColor} />
        <circle cx="78" cy="23" r="1" fill={blockDefaultColor} />
        <circle opacity="0.7" cx="82" cy="23" r="1" fill={blockDefaultColor} />
      </svg>
    </Block>
  );
});
