import React from 'react';
import { IconsOutline, IIconsOutlineTypes } from '../IconOutliner/index';
import { NewButtonStyles } from './styles/NewButtonStyles';

export interface INewButtonProps {
  title: string;
  onClick: () => void;
  afterIcon?: IIconsOutlineTypes;
  tabIndex?: number;
  disabled?:boolean
  className?: string
}

export const NewButton = (props: INewButtonProps) => {
  const cc = NewButtonStyles();
  return (
    <div
      onClick={props.onClick}
      className={`${cc.new_button} ${props.disabled ? cc.disabled : ''} ${props.className ? props.className : ''}`}
      tabIndex={props.tabIndex}
    >
      {props.title}
      {props.afterIcon && (
        <IconsOutline types={props.afterIcon} noHover invert />
      )}
    </div>
  );
};
