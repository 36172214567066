import { IPayment } from '../../../api/payments.api';

export enum PaymentsReducerActionEnum {
  setPayments = 'SET_PAYMENTS',
}

export interface IPaymentsReducer {
  list: IPayment[] | null;
}

const IS: IPaymentsReducer = {
  list: null,
};

export interface IPaymentsReducerActions {
  type: PaymentsReducerActionEnum.setPayments;
  payload: IPayment[] | null;
}

export const PaymentsReducer = (
  state = IS,
  action: IPaymentsReducerActions,
) => {
  switch (action.type) {
    case PaymentsReducerActionEnum.setPayments:
      return { ...state, list: action.payload };

    default:
      return state;
  }
};
