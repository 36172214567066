import React, { memo } from 'react';
import { PageBlock } from './Blocks/PageBlock';
import { IPages } from '../../../store/reducers/editors';
import useStyles from '../../../views/EditorItem/styles';
import cx from 'clsx';

interface IGenerationPagesProps {
  editor: IPages[];
  top?: boolean;
  pub?: boolean;
  savePageAfterRender: boolean;
  setPageTitle: (page: IPages, newVal: string) => void;
  removePage: (page: IPages) => void;
  addPage: (page: IPages) => void;
  addCommentPage: (page: IPages, comment: string) => void;
  removePageComment: (page: IPages, idx: number) => void;
  updateCommentPage: (page: IPages, val: string, idx: number) => void;
  updateDraft: () => void;
}

export const GeneratePages = memo((props: IGenerationPagesProps) => {
  const cc = useStyles();
  const { editor, top, pub } = props;

  return (
    <>
      {editor?.length &&
        editor.map((page, index) => (
          <PageBlock
            idx={index}
            id={page.id}
            title={page.title}
            comments={page.comments || []}
            savePageAfterRender={props.savePageAfterRender}
            setPageTitle={props.setPageTitle}
            removePage={props.removePage}
            addPage={props.addPage}
            updateDraft={props.updateDraft}
            addCommentPage={props.addCommentPage}
            removePageComment={props.removePageComment}
            updateCommentPage={props.updateCommentPage}
            className={cx(
              editor.length === 1
                ? !top
                  ? cc.onePageChild
                  : ''
                : index === 0
                  ? cc.pageFirstChild
                  : index === editor.length - 1
                    ? cc.pageLastChild
                    : cc.pageChild,
              index === 0 ? 'FirstPage' : '',
            )}
            page={page}
            key={page.id}
            pub={pub}
          />
        ))}
    </>
  );
});
