import { request } from './request';

export interface ITariff {
  id: string;
  name: string;
  description: string;
  duration_months: number;
  cost_rub: number;
}

export function getTarrifsAPI() {
  return request<ITariff[]>('get', 'tariffs');
}

export function getTarrifByIdAPI(id: string) {
  return request<ITariff>('get', `tariffs/${id}`);
}

export function BuyTarrifByIdAPI(id: string) {
  return request('post', `tariffs/${id}/buy`);
}

export function CheckPromoCode(code: string){
  return request('put', `profile/account/promo-code`,{code});
}
