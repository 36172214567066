import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  tw: {
    margin: '30px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
