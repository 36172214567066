import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useRoute } from 'wouter';
import { IconsOutline } from '../../components/IconOutliner';
import useStyles from '../EditorItem/styles/index';
import { GeneratePages } from '../../components/Editor/item/GeneratePage';
import cx from 'clsx';
import { useDraftTemp } from '../../hooks/useDraftTemp';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useDND } from '../../hooks/useDND';
import { Zoom } from '../../components/Editor/item/Zoom/Zoom';

export const TemplateItem: FC = () => {
  const cc = useStyles();
  const [, params] = useRoute('/template/:id');
  const { getTempDraft } = useDraftTemp();
  const editor = useTypeSelector((s) => s.draftTemp.tempItem);
  const ele = useRef(null);
  const cursor = useTypeSelector((s) => s.settings.dnd.cursorDnD);
  const pageBlockDragged = useTypeSelector(
    (s) => s.settings.dnd.pageBlockDragged,
  );
  const posBefore = useTypeSelector((s) => s.settings.dnd.posBefore);
  const pos = useTypeSelector((s) => s.settings.dnd.pos);
  const xy = useTypeSelector((s) => s.settings.dnd.xy);
  const scale = useTypeSelector((s) => s.settings.dnd.scale);
  const { goHome, rollDown, rollUp, setCursor, setPos, setPosBefore, setXY } =
    useDND();

  const mouseDownHandler = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!pageBlockDragged) {
        setCursor('grabbing');
        e instanceof MouseEvent && setXY({ x: e.clientX, y: e.clientY });
        e instanceof TouchEvent &&
          setXY({ x: e.touches[0].clientX, y: e.touches[0].clientY });
      }
    },
    [setXY, pageBlockDragged, setCursor],
  );

  const mouseMoveHandler = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (
        cursor === 'grabbing' &&
        ele !== null &&
        ele.current &&
        !pageBlockDragged
      ) {
        const dx =
          (e instanceof MouseEvent ? e.clientX : e.touches[0].clientX) -
          xy.x +
          posBefore.left;
        const dy =
          (e instanceof MouseEvent ? e.clientY : e.touches[0].clientY) -
          xy.y +
          posBefore.top;
        setPos({ top: dy, left: dx });
      }
    },
    [cursor, ele, xy, posBefore, pageBlockDragged, setPos],
  );

  const mouseUpHandler = useCallback(() => {
    if (!pageBlockDragged) {
      setCursor('grab');
      setPosBefore(pos);
    }
  }, [pos, pageBlockDragged, setCursor, setPosBefore]);

  useEffect(() => {
    if (ele.current !== null) {
      //@ts-ignoreele
      ele.current.addEventListener('mousemove', mouseMoveHandler);
      //@ts-ignore
      ele.current.addEventListener('touchmove', mouseMoveHandler);
      //@ts-ignoreele
      ele.current.addEventListener('mouseup', mouseUpHandler);
      //@ts-ignoreele
      ele.current.addEventListener('touchend', mouseUpHandler);
      //@ts-ignoreele
      ele.current.addEventListener('mousedown', mouseDownHandler);
      //@ts-ignoreele
      ele.current.addEventListener('touchstart', mouseDownHandler);
    }

    return () => {
      if (ele.current !== null) {
        //@ts-ignoreele
        ele.current.removeEventListener('mousemove', mouseMoveHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchmove', mouseMoveHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('mouseup', mouseUpHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchend', mouseUpHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('mousedown', mouseDownHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchstart', mouseDownHandler);
      }
    };
  }, [ele, mouseDownHandler, mouseUpHandler, mouseMoveHandler]);

  const init = useCallback(async () => {
    if (!editor && params && params.id) {
      await getTempDraft(params.id);
      goHome();
      return;
    }

    if (editor && params && editor.id !== params.id) {
      await getTempDraft(params.id);
      goHome();
    }
  }, [editor, params, getTempDraft, goHome]);

  useEffect(() => {
    (async()=>await init())()
  }, [params, init]);

  return (
    <>
      <Zoom in={() => rollUp(0.1)} out={() => rollDown(0.1)} goHome={goHome} />
      <div
        id="drag_wrapper"
        className="drag_wrapper"
        style={{
          cursor,
          // position: 'relative',
          // width: '100%',
          // height: '100%',
          // overflow: 'scroll',
          // whiteSpace: 'nowrap',
        }}
        ref={ele}
      >
        {/* <div
          className="dragContent"
          id="drag_content"
          ref={ele}
          style={{
            position: 'absolute',
            minWidth: 5000,
            minHeight: 5000,
            width: 'fit-content',
            height: 'fit-content',
          }}
        > */}
        <div
          className={cx(cc.pagesWrapper, 'editorContent')}
          style={{
            padding: '90px 0 60px 0',
            top: pos.top,
            left: pos.left,
            transform: `scale(${scale})`,
          }}
        >
          {editor === null && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconsOutline types="loading" />
            </div>
          )}
          {editor && editor.pages && editor.pages.length && (
            <GeneratePages
              //@ts-ignore
              editor={editor.pages}
              top={true}
              pub={true}
              savePageAfterRender={true}
              setPageTitle={() => {}}
              removePage={() => {}}
              addPage={() => {}}
              updateDraft={() => {}}
              addCommentPage={() => {}}
              removePageComment={() => {}}
              updateCommentPage={() => {}}
            />
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
