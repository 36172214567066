export enum SocialGoogleAuthEnum {
  setLink = 'SET_LINK',
}

interface ISocialGoogleAuthActionSetLink {
  type: SocialGoogleAuthEnum.setLink;
  payload: string;
}

interface ISocialGoogleAuthState {
  link: string;
}

const IS: ISocialGoogleAuthState = {
  link: '',
};

type SocialGoogleAuthActionTypes = ISocialGoogleAuthActionSetLink;

export const SocialGoogleAuth = (
  state = IS,
  action: SocialGoogleAuthActionTypes,
): ISocialGoogleAuthState => {
  switch (action.payload) {
    case SocialGoogleAuthEnum.setLink:
      return { ...state, link: action.payload };
    default:
      return state;
  }
};
