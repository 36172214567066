import React, { FC } from 'react';
import ClosePopup from '../ClosePopup';
import useStyles from '../PopUp/styles';

interface IPopUpWindowProps {
  title: string;
  children: any;
  closePopUp: ()=>void
}

export const PopUpWindow: FC<IPopUpWindowProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.PopUpWindow}>
      <div className={classes.paper}>
        <div className={classes.body}>
          <h2 className={classes.h2}>{props.title}</h2>
          <div className={classes.p}>{props.children}</div>
        </div>
      </div>
      <ClosePopup onClick={props.closePopUp} style={{position: 'absolute', top: 10, right: 10}}/>
    </div>
  );
};
