import React, { CSSProperties, memo, ReactNode } from 'react';
import useStyles from './styles';
import cx from 'clsx';
interface IButtonProps {
  children: ReactNode;
  onClick: () => void;
  className?: string;
  primary?: boolean;
  transparent?: boolean;
  defaultTransparent?: boolean;
  redTransparent?: boolean;
  loading?: boolean;
  title?: string;
  style?: CSSProperties;
}

export const Button = memo((props: IButtonProps) => {
  const classes = useStyles();
  const { children, onClick } = props;
  return (
    <button
      className={cx(
        classes.btn,
        props.className,
        props.primary === true && classes.primary,
        props.transparent && classes.transparent,
        props.defaultTransparent && classes.defaultTransparent,
        props.primary && props.transparent && classes.primaryTransparent,
        props.loading ? classes.loading : '' ,
      )}
      onClick={onClick}
      title={props.title}
      style={props.style}
    >
      {children}
    </button>
  );
});

export default Button;
