import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const Columns3CardBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5024_5731)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47 0H0V24H47V0ZM23.3376 4.02637C23.4934 3.80137 23.7495 3.66667 24.0228 3.66667H24.026C24.3002 3.6675 24.5563 3.80296 24.7113 4.02962L28.4499 9.5H32.3317C32.5917 9.5 32.8374 9.62128 32.9958 9.82878C33.1524 10.0354 33.2049 10.3055 33.1357 10.5563L31.002 18.2777C30.8036 18.9968 30.1438 19.5 29.3971 19.5H18.6029C17.8562 19.5 17.1956 18.996 16.9964 18.276L14.8626 10.5547C14.7943 10.3039 14.8476 10.0354 15.0042 9.82878C15.1626 9.62128 15.4075 9.5 15.6683 9.5H19.5566L23.3376 4.02637ZM26.43 9.5L24.0195 5.97135L21.5814 9.5H26.43ZM28.0104 11.1667H16.762L18.6029 17.8333H29.3955L31.238 11.1667H28.0104Z"
            fill={blockDefaultColor}
          />
        </g>
        <g clipPath="url(#clip1_5024_5731)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.667 0H54.6667V24H101.667V0ZM78.0043 4.02637C78.1601 3.80137 78.4162 3.66667 78.6895 3.66667H78.6928C78.967 3.6675 79.223 3.80296 79.378 4.02962L83.1166 9.5H86.9985C87.2585 9.5 87.5042 9.62128 87.6625 9.82878C87.8192 10.0354 87.8717 10.3055 87.8025 10.5563L85.6687 18.2777C85.4704 18.9968 84.8106 19.5 84.0639 19.5H73.2696C72.5229 19.5 71.8623 18.996 71.6632 18.276L69.5294 10.5547C69.461 10.3039 69.5143 10.0354 69.671 9.82878C69.8293 9.62128 70.0742 9.5 70.335 9.5H74.2234L78.0043 4.02637ZM81.0968 9.5L78.6863 5.97135L76.2481 9.5H81.0968ZM82.6772 11.1667H71.4288L73.2696 17.8333H84.0623L85.9047 11.1667H82.6772Z"
            fill={blockDefaultColor}
          />
        </g>
        <g clipPath="url(#clip2_5024_5731)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.333 0H109.333V24H156.333V0ZM132.671 4.02637C132.827 3.80137 133.083 3.66667 133.356 3.66667H133.359C133.633 3.6675 133.89 3.80296 134.045 4.02962L137.783 9.5H141.665C141.925 9.5 142.171 9.62128 142.329 9.82878C142.486 10.0354 142.538 10.3055 142.469 10.5563L140.335 18.2777C140.137 18.9968 139.477 19.5 138.73 19.5H127.936C127.189 19.5 126.529 18.996 126.33 18.276L124.196 10.5547C124.128 10.3039 124.181 10.0354 124.337 9.82878C124.496 9.62128 124.741 9.5 125.002 9.5H128.89L132.671 4.02637ZM135.763 9.5L133.353 5.97135L130.915 9.5H135.763ZM137.344 11.1667H126.095L127.936 17.8333H138.729L140.571 11.1667H137.344Z"
            fill={blockDefaultColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_5024_5731">
            <rect width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
          <clipPath id="clip1_5024_5731">
            <rect x="54.6667" width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
          <clipPath id="clip2_5024_5731">
            <rect x="109.333" width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
