import React, { FC, CSSProperties, useCallback } from 'react';
import useStyles from '../views/EditorItem/styles';

export interface ISearchInputProps {
  searchText: string;
  onChangeSearchText: (searchText: string) => void;
  style?: CSSProperties;
  className?: string;
  placeholder?: string;
}

export const SearchInput: FC<ISearchInputProps> = ({
  style,
  className,
  onChangeSearchText,
  searchText,
  placeholder,
}) => {
  const cc = useStyles();

  const onClick = useCallback(() => {
    onChangeSearchText(searchText);
  }, [searchText, onChangeSearchText]);

  return (
    <div className={cc.searchInput_wrapper}>
      <input
        type="text"
        value={searchText}
        onChange={(e) => onChangeSearchText(e.target.value)}
        placeholder={placeholder ? placeholder : 'Поиск'}
        style={style}
        className={`${cc.searchInput} ${className ? className : ''}`}
        autoFocus
        name="search"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        onClick={onClick}
      >
        <path
          d="M11.0667 12L6.86667 7.8C6.53333 8.06667 6.15 8.27778 5.71667 8.43333C5.28333 8.58889 4.82222 8.66667 4.33333 8.66667C3.12222 8.66667 2.09722 8.24722 1.25833 7.40833C0.419445 6.56944 0 5.54444 0 4.33333C0 3.12222 0.419445 2.09722 1.25833 1.25833C2.09722 0.419445 3.12222 0 4.33333 0C5.54444 0 6.56944 0.419445 7.40833 1.25833C8.24722 2.09722 8.66667 3.12222 8.66667 4.33333C8.66667 4.82222 8.58889 5.28333 8.43333 5.71667C8.27778 6.15 8.06667 6.53333 7.8 6.86667L12 11.0667L11.0667 12ZM4.33333 7.33333C5.16667 7.33333 5.875 7.04167 6.45833 6.45833C7.04167 5.875 7.33333 5.16667 7.33333 4.33333C7.33333 3.5 7.04167 2.79167 6.45833 2.20833C5.875 1.625 5.16667 1.33333 4.33333 1.33333C3.5 1.33333 2.79167 1.625 2.20833 2.20833C1.625 2.79167 1.33333 3.5 1.33333 4.33333C1.33333 5.16667 1.625 5.875 2.20833 6.45833C2.79167 7.04167 3.5 7.33333 4.33333 7.33333Z"
          fill="#939393"
        />
      </svg>
    </div>
  );
};
