import React, { useCallback } from 'react';
import { User } from './Profile/User';
import { Card, CardContent } from '@material-ui/core';
import { useProfileStyles } from './styles/profileStyles';
import Button from '../../components/Button/Button';
import { requestPassportReset } from '../../api/users.api';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

export function Profile() {
  const classes = useProfileStyles();
  const email = useTypeSelector((s) => s.profile.profile?.email);
  const { setSnackbarValue } = useSnackbar();

  const onCreateNewPassword = useCallback(async () => {
    try {
      const data: any = email && (await requestPassportReset(email));
      if (data?.ok) {
        setSnackbarValue(
          'Письмо с инструкциями по изменению пароля отправлено вам на почту',
          SnackbarType.Success,
        );
      }
    } catch (e) {
      console.error('Error', e);
      setSnackbarValue('Что то пошло не так!', SnackbarType.Error);
    }
  }, [email, setSnackbarValue]);

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        Профиль
        <hr className={classes.hr} />
      </div>
      <CardContent>
        <User />
        <Button onClick={onCreateNewPassword} className={classes.blueBtn}>
          Задать новый пароль
        </Button>
      </CardContent>
    </Card>
  );
}
