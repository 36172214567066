import React, { FC, memo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { ExportToPng } from '../ExportFileEditor/ExportToFile/ExportToPng';
import { ExportToReport } from '../ExportFileEditor/ExportToFile/ExportToReport';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useLocation } from 'wouter';
import { useModal } from '../../hooks/useModal';
import { ExportToHTML } from '../ExportFileEditor/ExportToFile/ExportToHTML';
import { IProfile } from '../../api/profile.api';

const useStyle = makeStyles(() => ({
  exportWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: 16,
    rowGap: 16,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  exportItem: {
    width: 80,
    height: 72,
    background: '#F6FBFF',
    borderRadius: 8,
    border: '1px solid transparent',
    transition: 'all 0.3s linear',
    cursor: 'pointer',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    '&:hover': {
      border: '1px solid #3090E8',
    },
    '& svg': {
      justifySelf: 'center',
    },
  },
  disabledItem: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    userSelect: 'none',
    filter: 'grayscale(.4)',
    '&:hover': {
      border: '1px solid transparent',
    },
  },
  message: {
    display: 'grid',
    justifyContent: 'center',
    rowGap: 8,
    padding: 10,
    background: '#FFF4EA',
    color: '#F2994A',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    borderRadius: 5,
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
    userSelect: 'none',
    transition: 'all 0.3s linear',
    overflow: 'hidden',
  },
  showMessage: {
    opacity: 1,
    height: 'auto',
    marginBottom: 26,
    pointerEvents: 'all',
  },
  link: {
    textDecoration: 'underline',
    display: 'inline',
    textAlign: 'center',
    color: '#F2994A',
    cursor: 'pointer',
  },
}));

export interface IExportToFileModalProps {
  close: () => void;
  isAuthorized: IProfile | null;
}

export const ExportToFile: FC<IExportToFileModalProps> = memo(({ close, isAuthorized }) => {
  const cc = useStyle();
  const { hideModal } = useModal();
  const [, setLoc] = useLocation();
  const exportReport = useTypeSelector(
    (s) => s.settings.editableBlock.exportReport,
  );
  const tariff_type = useTypeSelector(
    (s) => s.profile.profile?.account.subscription.tariff_type,
  );

  const onTariff = useCallback(() => {
    hideModal();
    setLoc('/profile');
  }, [hideModal, setLoc]);

  const shouldShowMessage = tariff_type === 'TRIAL' || !isAuthorized;

  return (
    <div>
      <h2>Экспорт</h2>
      <p>Выберите формат файла для скачивания</p>

      <div
        className={`${cc.message} ${shouldShowMessage ? cc.showMessage : ''}`}
      >
        Чтобы скачать проект перейдите на другой тариф
        <div className={cc.link} onClick={onTariff}>
          Выбрать тариф
        </div>
      </div>
      <div
        className={`${cc.exportWrapper} ${shouldShowMessage ? cc.disabled : ''}`}
      >
        <ExportToReport
          className={`${cc.exportItem} ${exportReport ? cc.disabledItem : ''}`}
          format="PDF"
          loading={exportReport}
        />
        <ExportToReport
          className={`${cc.exportItem} ${exportReport ? cc.disabledItem : ''}`}
          format="DOC"
          loading={exportReport}
        />
        <ExportToReport
          className={`${cc.exportItem} ${exportReport ? cc.disabledItem : ''}`}
          format="DOCX"
          loading={exportReport}
        />
        <ExportToPng
          className={`${cc.exportItem} ${exportReport ? cc.disabledItem : ''}`}
          loading={exportReport}
        />
        <ExportToHTML
          className={`${cc.exportItem} ${exportReport ? cc.disabledItem : ''}`}
          loading={exportReport}
        />
      </div>
    </div>
  );
});
