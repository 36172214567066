import { makeStyles } from "@material-ui/core";

export const TogglerStyle = makeStyles(()=>({
  togglerWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    cursor: 'pointer',
    '& span':{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      color: '#353E47',
    }
  },
  toggler: {
    border: '3px solid #3090E8',
    width: 30,
    height: 16,
    borderRadius: 15,
    position: 'relative',
    '& span':{
      position: 'absolute',
      top: 3,
      left: 2,
      display: 'block',
      width: 10,
      height: 10,
      border: '1px solid #3090E8',
      borderRadius: '50%',
      transition: 'all .3s linear',
      boxSizing: 'border-box'
    },
    '& span.active':{
      position: 'absolute',
      left: 19,
      backgroundColor: '#3090E8',
      border: '1px solid #3090E8',
    }
  }
}))
