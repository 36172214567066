import React, { FC, useCallback, useEffect, useState } from 'react';
import { IDraftTempCategoryItem } from '../../../api/draftTempCategory';
import { TemplateCard } from '../../../components/Editor/temp/TemplateCard';
import { useDraftTemp } from '../../../hooks/useDraftTemp';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { ByTemplateStyles } from './styles/ByTemplateStyles';
import { useModal } from '../../../hooks/useModal';
import { useRequest } from '../../../hooks/useRequest';

export const ByTemplate: FC = () => {
  const cc = ByTemplateStyles();
  const tempList = useTypeSelector((s) => s.draftTemp.draftTempList);
  const categoryList = useTypeSelector(
    (s) => s.draftTemp.draftTempCatagoryList,
  );
  const {
    getDraftTempList,
    getDraftTempById,
    clearDraftTemp,
    TempUse,
    goToTemp,
  } = useDraftTemp();
  const [selectedCategory, setSelectedCategory] = useState('');
  const { hideModal } = useModal();
  const { request } = useRequest();

  const checkList = useCallback(async () => {
    !tempList && await request(async () => await getDraftTempList());
  }, [tempList, getDraftTempList, request]); // getDraftTempList залитает в цикл

  const copyTemp = useCallback(
    (item: IDraftTempCategoryItem) => {
      TempUse(item);
      hideModal();
      clearDraftTemp();
    },
    [TempUse, hideModal, clearDraftTemp],
  );

  const toTemp = useCallback(async (item: IDraftTempCategoryItem) => {
    await goToTemp(item);
    hideModal();
    clearDraftTemp();
  }, [goToTemp, hideModal, clearDraftTemp]);


  useEffect(() => {
    selectedCategory && getDraftTempById(selectedCategory);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]); // getDraftTempById залитает в цикл

  useEffect(() => {

    if (tempList && tempList.length) {
      setSelectedCategory(tempList[0].id);
    }
  }, [tempList]);

  useEffect(() => {
    (async () => {
      await checkList();
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cc.wrapper}>
      <div className={cc.sidebar}>
        {tempList &&
          tempList.length &&
          tempList.map((item, idx) => (
            <div
              className={`${cc.sidebar_item} ${
                selectedCategory === item.id ? cc.sidebar_item_active : ''
              }`}
              onClick={() => setSelectedCategory(item.id)}
              key={idx}
            >
              {item.name}
            </div>
          ))}
      </div>
      <div className={cc.template_card_wrapper}>
        {categoryList &&
          categoryList.length &&
          categoryList.map((item, idx) => (
            <TemplateCard
              {...item}
              key={idx}
              useNow={() => copyTemp(item)}
              goToTemp={() => toTemp(item)}
            />
          ))}
      </div>
    </div>
  );
};
