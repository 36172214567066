import { makeStyles } from '@material-ui/core';

export const ByTemplateStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 1220,
    height: 'calc(100vh - 20vh)',
    display: 'grid',
    gridTemplateColumns: '284px 1fr',
    width: '100%',
    '@media (max-width: 1300px)': {
      display: 'flex',
      flexDirection: 'column',
      // '& button': {
      //   width: '100%',
      // },
    },
  },
  sidebar: {
    width: 252,
    background: '#F6FBFF',
    margin: 0,
    height: 'calc(100% - 32px)',
    display: 'flex',
    rowGap: 10,
    padding: 16,
    flexDirection: 'column',
    '@media (max-width: 1300px)': {
      height: 'fit-content',
      width: '97%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  },
  sidebar_item_active: {
    background: '#3090E8',
    borderRadius: '5px 15px 15px 5px',
    color: '#FFFFFF !important',
    '@media (max-width: 1300px)': {
      borderRadius: '15px 15px 5px 5px',
    },
  },
  sidebar_item: {
    width: 220,
    height: 32,
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '22px',
    color: '#353E47',
    padding: 10,
    cursor: 'pointer',
    '@media (max-width: 1300px)': {
      width: 'fit-content',
      display: 'inline-flex',
    },
  },
  template_card_wrapper: {
    display: 'flex',
    columnGap: 24,
    rowGap: 24,
    padding: '0 0 0 24px',
    flexWrap: 'wrap',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 20vh)',
    overflowY: 'scroll',
    '@media (max-width: 1300px)': {
      justifyContent: 'space-evenly',
      marginTop: 24,
    },
    '@media (max-width: 360px)': {
      padding: 0
    },
    '&::-webkit-scrollbar': {
      scrollbarWidth: 2,
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(gray, 0.7)',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#353E47',
      borderRadius: 10,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#3090E8',
      },
    },
  },
}));
