import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const UnknowBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_7222_22199)">
          <path
            d="M0 3.27617L3.27083 0H7.04167L0 7.05054V3.27617Z"
            fill={blockDefaultColor}
          />
          <path
            d="M0 12.7969L12.776 0H16.5469L0 16.5739V12.7969Z"
            fill={blockDefaultColor}
          />
          <path
            d="M0 22.1637L22.125 0H26.0417L19 7.05054L1.9375 24H0V22.1637Z"
            fill={blockDefaultColor}
          />
          <path
            d="M7.59375 24L24 7.567L31.776 0H35.5469L19 16.3521L11.3646 24H7.59375Z"
            fill={blockDefaultColor}
          />
          <path
            d="M17.0234 24L24 17.1549L41.125 0H45.0417L38 6.90767L20.9375 24H17.0234Z"
            fill={blockDefaultColor}
          />
          <path
            d="M26.5938 24L43 7.78872L50.776 0H54.5469L38 16.5739L30.3646 24H26.5938Z"
            fill={blockDefaultColor}
          />
          <path
            d="M36.0234 24L43 17.0095L60.125 0H64.0417L57 6.90767L39.9375 24H36.0234Z"
            fill={blockDefaultColor}
          />
          <path
            d="M62 7.78872L45.5938 24H49.3646L57 16.5739L73.5469 0H69.776L62 7.78872Z"
            fill={blockDefaultColor}
          />
          <path
            d="M62 17.1549L55.0234 24H58.9375L76 7.05054L83.0417 0H79.125L62 17.1549Z"
            fill={blockDefaultColor}
          />
          <path
            d="M64.5938 24L81 7.567L88.776 0H92.5469L76 16.5739L68.3646 24H64.5938Z"
            fill={blockDefaultColor}
          />
          <path
            d="M74.0234 24L81 17.1549L98.125 0H102.042L95 7.05054L77.9375 24H74.0234Z"
            fill={blockDefaultColor}
          />
          <path
            d="M100 7.567L83.5938 24H87.3646L95 16.3521L111.547 0H107.776L100 7.567Z"
            fill={blockDefaultColor}
          />
          <path
            d="M100 17.0095L93.0234 24H96.9375L114 7.05054L121.042 0H117.125L100 17.0095Z"
            fill={blockDefaultColor}
          />
          <path
            d="M102.594 24L119 7.567L126.776 0H130.547L114 16.5739L106.365 24H102.594Z"
            fill={blockDefaultColor}
          />
          <path
            d="M112.023 24L119 17.0095L136.125 0H140.042L133 7.05054L115.938 24H112.023Z"
            fill={blockDefaultColor}
          />
          <path
            d="M138 7.567L121.594 24H125.365L133 16.5739L149.547 0H145.776L138 7.567Z"
            fill={blockDefaultColor}
          />
          <path
            d="M138 17.0095L131.023 24H134.938L157 1.89892V0H155.125L138 17.0095Z"
            fill={blockDefaultColor}
          />
          <path
            d="M140.594 24L157 7.567V11.344L144.365 24H140.594Z"
            fill={blockDefaultColor}
          />
          <path
            d="M150.023 24L157 17.0095V20.7864L153.794 24H150.023Z"
            fill={blockDefaultColor}
          />
        </g>
      </svg>
    </Block>
  );
});
