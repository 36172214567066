import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'wouter';
import { DropDownMenuBtn } from '../DropDownMenuBtn/DropDownMenuBtn';
import { IconsOutline } from '../IconOutliner/index';
import { makeStyles } from '@material-ui/core';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useEditorSitemap } from '../../hooks/useEditorSitemap';
import { useModal } from '../../hooks/useModal';
import { ExportToFile } from './ExportToFile';

const useStyle = makeStyles(() => ({
  links: {
    textDecoration: 'none',
    borderRadius: 10,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    columnGap: 13,
    padding: '0 10px',
    height: 40,
    boxSizing: 'border-box',
    '& .icons': {
      '& svg': {
        fill: '#353E47',
      },
    },
    '&:hover': {
      backgroundColor: '#3090E8',
      '& span': {
        color: 'white',
      },
      '& .icons': {
        '& svg': {
          fill: 'white',
        },
      },
    },
    '& span': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      color: '#353E47',
      whiteSpace: 'nowrap',
    },
  },
  tariff: {
    '& .icons': {
      '& svg': {
        fill: '#FFB340',
      },
    },
  },
  copy: {
    '& .icons': {
      '& svg': {
        fill: '#3090E8',
      },
    },
  },
  export: {
    '& .icons': {
      '& svg': {
        fill: '#17B0A5',
      },
    },
  },
  trash: {
    '& .icons': {
      '& svg': {
        fill: '#EB5757',
      },
    },
  },
  devider: {
    margin: '5px 0',
    width: '100%',
    height: 1,
    backgroundColor: '#C7D6E6',
  },
}));

export const Menu: FC = memo(() => {
  const cc = useStyle();
  const [show, setShow] = useState(false);
  const [location, setLocation] = useLocation();
  const { removeDraft, copyDraft } = useEditorSitemap();
  const profile = useTypeSelector((s) => s.profile.profile);
  const own_draft = useTypeSelector((s) => s.editor.own_draft);
  const editor = useMemo(() => /\/editor\//, []);
  const { hideModal, setModalBody, showModal } = useModal();
  const [remove, setRemove] = useState(false);

  const onMenuClick = useCallback(
    (pathTo: string) => {
      setLocation(pathTo);
      setShow(false);
    },
    [setLocation],
  );

  const deleteDraft = useCallback(async () => {
    await removeDraft(location.replace(editor, ''));
    setShow(false);
    setRemove(false);
    setLocation('/editor');
  }, [location, editor, removeDraft, setShow, setLocation]);

  const makeCopy = useCallback(async () => {
    try {
      await copyDraft(location.replace(editor, ''));
    } finally {
      setShow(false);
    }
  }, [location, copyDraft, editor]);

  const showExport = useCallback(() => {
    showModal();
    setModalBody(<ExportToFile close={hideModal} isAuthorized={profile} />);
  }, [showModal, setModalBody, hideModal, profile]);

  const onClickOutOfMenu = (val: boolean) => {
    setShow(val);
    setRemove(val);
  };

  return (
    <DropDownMenuBtn
      icon="more"
      show={show}
      clickOutside={onClickOutOfMenu}
      setShow={setShow}
    >
      {editor.test(location) && profile && !own_draft ? (
        <div className={`${cc.links} ${cc.copy}`} onClick={makeCopy}>
          <IconsOutline types="download" noHover />
          <span>Дублировать себе</span>
        </div>
      ) : (
        <></>
      )}

      {editor.test(location) && (
        <div className={`${cc.links} ${cc.export}`} onClick={showExport}>
          <IconsOutline types="export" noHover />
          <span>Экспорт</span>
        </div>
      )}

      {/* {profile ? (
        <div
          className={`${cc.links} ${cc.tariff}`}
          onClick={() => onMenuClick('/profile')}
        >
          <IconsOutline types="crown" noHover />
          <span>Тариф</span>
        </div>
      ) : (
        <a
          className={`${cc.links} ${cc.tariff}`}
          href="https://structura.app/#tariffs"
          target="_blank"
          rel="noreferrer"
        >
          <IconsOutline types="crown" noHover />
          <span>Тариф</span>
        </a>
      )} */}

      {profile && (
        <div className={`${cc.links}`} onClick={() => onMenuClick('/profile')}>
          <IconsOutline types="profile" noHover />
          <span>Профиль и Тарифы</span>
        </div>
      )}

      {profile && editor.test(location) && own_draft && (
        <>
          <div className={cc.devider}></div>
          {!remove ? (
            <div
              className={`${cc.links} ${cc.trash}`}
              onClick={() => setRemove(true)}
            >
              <IconsOutline types="trash" noHover />
              <span>Удалить проект</span>
            </div>
          ) : (
            <div className={`${cc.links} ${cc.trash}`} onClick={deleteDraft}>
              <IconsOutline types="trash" noHover />
              <span>Точно удалить?</span>
            </div>
          )}
        </>
      )}
    </DropDownMenuBtn>
  );
});
