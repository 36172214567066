import { getSiteDrafts } from '../api/siteDraft';
import { useDispatch } from 'react-redux';
import { EditorListEnum } from '../store/reducers/editorList';
import { EditorReducerActionEnum } from '../store/reducers/editors';
import { useTypeSelector } from './useTypesSelector';
import { useCallback } from 'react';
import { useRequest } from './useRequest';

interface LimitOffset {
  limit: number;
  offset: number;
}

export const useEditorList = () => {
  const dispatch = useDispatch();
  const editorLimit = useTypeSelector((s) => s.editorList.limit);
  const { request } = useRequest();

  const PageToLimitOffset = useCallback(
    (page: number): LimitOffset => {
      if (page >= 1) {
        const off = (page - 1) * editorLimit;
        return { limit: editorLimit, offset: off };
      }
      return { limit: editorLimit, offset: 0 };
    },
    [editorLimit],
  );

  const getList = useCallback(
    async (page: number) => {
      const { limit, offset } = PageToLimitOffset(page);
      const data = await request(
        async () => await getSiteDrafts(limit, offset),
      );
      dispatch({ type: EditorListEnum.setList, payload: data });
      return data;
    },
    [request, dispatch, PageToLimitOffset],
  );

  const setInProcess = (payload: boolean) => {
    dispatch({ type: EditorListEnum.setInProcess, payload });
    getList(1);
    let int: NodeJS.Timeout;
    if (payload) {
      int = setInterval(async () => {
        let proc = false;
        const { data } = await getSiteDrafts();
        dispatch({ type: EditorListEnum.setList, payload: data });
        if (data) {
          for (const item of data) {
            if (item.scrape_status === 'IN_PROGRESS') {
              proc = true;
              break;
            }
          }
        }
        if (!proc) {
          clearInterval(int);
        }
      }, 2000);
    }
  };

  return {
    setInProcess,
    getList,
    setDraftId: (id: string) => {
      dispatch({
        type: EditorReducerActionEnum.setDraftId,
        payload: id,
      });
    },
    setPage: async (page: number) => {
      const { limit, offset } = PageToLimitOffset(page);
      dispatch({
        type: EditorListEnum.setEditorPage,
        payload: page,
      });
      dispatch({
        type: EditorListEnum.setEditorLimit,
        payload: limit,
      });
      dispatch({
        type: EditorListEnum.setEditorOffset,
        payload: offset,
      });
    },
  };
};
