import React, { useEffect, useRef } from 'react';
import useStyles from '../../../../views/EditorItem/styles';
import { SelectBlock } from './SelectBlock';
import { useTypeSelector } from '../../../../hooks/useTypesSelector';
import ClosePopup from '../../../ClosePopup';

interface IEditableBlockProps {
  setCloseEditor: () => void;
}

export const EditableBlock = (props: IEditableBlockProps) => {
  const cc = useStyles();
  const wrapperRef = useRef(null);
  const title = useTypeSelector((s) => s.settings.editableBlock.title);

  useEffect(() => {
    //@ts-ignore
    const clientRect = wrapperRef?.current?.getBoundingClientRect();
    const xyCorrection = 30;
    const { innerHeight, innerWidth } = window;
    const x = clientRect.x + clientRect.width - innerWidth + xyCorrection;
    const y = clientRect.y + clientRect.height - innerHeight + xyCorrection;
    //@ts-ignore
    wrapperRef?.current.setAttribute(
      'style',
      `transform: translateX(-${x && x > 0 ? x : 0}px) translateY(-${
        y && y > 0 ? y : 0
      }px)`,
    );

    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.setCloseEditor();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props, wrapperRef]);

  return (
    <div className={cc.EditableBlock} ref={wrapperRef}>
      <div className={cc.EditableBlockHeader}>
        <h2 className={cc.EditableBlockHeaderTitle}>{title}</h2>
        <ClosePopup
          className={cc.EditableBlockClose}
          onClick={props.setCloseEditor}
        />
      </div>

      <SelectBlock onCloseEditor={props.setCloseEditor} />
    </div>
  );
};
