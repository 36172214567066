import { makeStyles } from '@material-ui/core';

export const NewModalStyles = makeStyles(() => ({
  new_modal_wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 10000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  new_modal: {
    position: 'relative',
    // minWidth: 968,
    width: 'auto',
    minHeight: 160,
    maxHeight: 'calc(100vh - 20vh)',
    height: 'auto',
    background: '#FFFFFF',
    boxShadow:
      '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 10,
    padding: 24,
    '@media (max-width: 360px)': {
      padding: 12
    },
  },
  close: {
    position: 'absolute',
    top: -20,
    right: -20,
    background: '#FFFFFF',
    boxShadow:
      '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .4s linear',
    '& svg': {
      '& path': {
        transition: 'all .4s linear',
        fill: '#EB5757',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      background: '#EB5757',
      '& svg': {
        '& path': {
          fill: '#FFFFFF',
        },
      },
    },
    '@media (max-width: 1300px)': {
      top: 0,
      right: 0,
      boxShadow: 'none',
    },
  },
}));
