import { IPages } from './editors';

export enum PageReducerActionEnum {
  setPage = 'SET_PAGE',
  setPageEvent = 'SET_PAGE_EVENT',
}

interface PageReducerActionSetPage {
  type: PageReducerActionEnum.setPage;
  payload: IPages;
}
interface PageReducerActionSetPageEvent {
  type: PageReducerActionEnum.setPageEvent;
  payload: MouseEvent;
}

interface IPageReducerState {
  selectedPage: IPages | null;
  events: MouseEvent | null;
}
const IS: IPageReducerState = {
  selectedPage: null,
  events: null,
};

type PagerReducerActionType =
  | PageReducerActionSetPageEvent
  | PageReducerActionSetPage;

export const PageReducer = (
  state = IS,
  action: PagerReducerActionType,
): IPageReducerState => {
  switch (action.type) {
    case PageReducerActionEnum.setPage:
      return { ...state, selectedPage: action.payload };
    case PageReducerActionEnum.setPageEvent:
      return { ...state, events: action.payload };
    default:
      return state;
  }
};
