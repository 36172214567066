import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'clsx';

const DeviderStyle = makeStyles(() => ({
  devider_wrapper: {
    width: 1,
    height: 24,
  },
  white: {
    backgroundColor: '#FFFFFF',
  },
  primary: {
    backgroundColor: '#3090E8',
  },
  red: {
    backgroundColor: '#DE024F',
  },
  gray: {
    backgroundColor: '#C7D6E6',
  },
}));

export type IDeviderColorTypes = 'white' | 'primary' | 'red' | 'gray';

export interface IDeviderProps {
  color: IDeviderColorTypes;
}

export const Devider = memo((props: IDeviderProps) => {
  const cc = DeviderStyle();
  return (
    <div
      className={cx(cc.devider_wrapper, props.color ? cc[props.color] : '')}
    ></div>
  );
});
