import React, { BaseSyntheticEvent, memo, useCallback, useState } from 'react';
import useStyles from '../../../../views/EditorItem/styles';
import { useSettingsEditor } from '../../../../hooks/useSettingEditor';
import { EditableBlock } from '../EditableBlock';
import { IBlock } from '../../../../store/reducers/editors';
import { useTypeSelector } from '../../../../hooks/useTypesSelector';
import { useEditorSitemap } from '../../../../hooks/useEditorSitemap';
import { useDND } from '../../../../hooks/useDND';
import { SnackbarType, useSnackbar } from '../../../../hooks/useSnackbar';

export const AddBlockBtn = memo((props: IBlock) => {
  const cc = useStyles();
  const { setSelectedPageId, setEditorTitle, hideRemoveBtn } =
    useSettingsEditor();
  const { dndBlock } = useEditorSitemap();
  const [show, setShow] = useState(false);
  const dragBlock = useTypeSelector((s) => s.settings.dnd.dragBlock);
  const { setDragOverBlock, setPageBlockDragged, setCursor } = useDND();
  const { setSnackbarValue } = useSnackbar();
  const reg = /\sblock\s/g;

  const addHandler = useCallback(() => {
    if (!props.pub) {
      setSelectedPageId(props.pageId);
      setEditorTitle('Добавить блок');
      hideRemoveBtn();
      setShow(true);
      return;
    }

    setSnackbarValue('Нет прав на изменения страницы', SnackbarType.Error);
  }, [
    props.pageId,
    props.pub,
    setSelectedPageId,
    setEditorTitle,
    hideRemoveBtn,
    setSnackbarValue,
  ]);

  const setCloseEditor = () => {
    setShow(false);
  };
  const DragOver = (e: BaseSyntheticEvent, block: IBlock) => {
    e.preventDefault();
    const find =
      //@ts-ignore
      e.nativeEvent.path &&
      //@ts-ignore
      e.nativeEvent.path.find((items: any) =>
        reg.test(items.className) ? true : false,
      );
    find && find.classList.add(cc.blockDND);

    e.target.style.boxShadow = '0 4px 4px gray';
    // if (reg.test(e.target.className)) {
    //   e.target.classList.add(cc.blockDND)
    // }
    setDragOverBlock(block, block.pageId);
  };

  const dragEnd = (e: any) => {
    setTimeout(() => {
      setPageBlockDragged(false);
      setCursor('grab');
    }, 1000);
  };

  const Drop = (e: BaseSyntheticEvent) => {
    e.target.style.boxShadow = 'none';
    dragBlock && dndBlock(dragBlock, props);
  };

  const DropLeave = (e: BaseSyntheticEvent) => {
    e.target.style.boxShadow = 'none';
  };
  return (
    <div
      onDragOver={(e) => DragOver(e, props)}
      onDrop={Drop}
      onDragEnd={dragEnd}
      onDragLeave={DropLeave}
    >
      <div
        className={cc.addBlockBtn}
        onClick={addHandler}
        data-add-class={cc.addBlockBtn}
      >
        +
      </div>
      {show && <EditableBlock setCloseEditor={setCloseEditor} />}
    </div>
  );
});
