import { Card, CardContent, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { RequestRegistrationForm } from './RequestRegistrationForm';
import useStyles from './styles';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { Back } from '../../components/Back/Back';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import cx from 'clsx';
import { useRegistration } from '../../hooks/useRegistration';

export const Registration = () => {
  const classes = useStyles();
  const title = useTypeSelector((s) => s.registration.title);
  const { setSiteDraftQuery } = useRegistration();
  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();
  const search = window.location.search
    .replace(/\?/g, '')
    .split('&')
    .map((item) => ({ key: item.split('=')[0], value: item.split('=')[1] }));

  useEffect(() => {
    if (search && search.length) {
      for (const item of search) {
        if (item.key === 'site_draft_query') {
          setSiteDraftQuery(item.value);
        }
      }
    }
  }, [setSiteDraftQuery, search]);

  const onSnackbarClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  return (
    <div className={classes.wrapper}>
      <Back />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.subtitle}>
            Введите данные регистрации
          </Typography>
          <RequestRegistrationForm />
        </CardContent>
      </Card>
      {/* <WaterMarkLogo /> */}
      <Snackbar
        open={Boolean(snackbarValue)}
        onClose={onSnackbarClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          className={cx({
            [classes.snackbarError]: snackbarType === SnackbarType.Error,
            [classes.snackbarSuccess]: snackbarType === SnackbarType.Success,
          })}
          message={snackbarValue}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
};
