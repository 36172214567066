import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const FiltersBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="1.5" width="22" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect x="26" width="6" height="6" rx="3" fill={blockDefaultColor} />
        <rect
          x="36"
          y="1.5"
          width="22"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="10.5"
          width="12"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="16"
          y="9"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="26"
          y="10.5"
          width="32"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          y="19.5"
          width="32"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="36"
          y="18"
          width="6"
          height="6"
          rx="3"
          fill={blockDefaultColor}
        />
        <rect
          x="46"
          y="19.5"
          width="12"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="66"
          y="6"
          width="90"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
        <rect
          x="66"
          y="14"
          width="38"
          height="4"
          rx="2"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
