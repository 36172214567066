import { IProfile } from '../../api/profile.api';

export interface ITarrifResponseOption {
  cost: string;
  duration_months: number;
  id: string;
  name: string;
}
export interface ITarrifResponse {
  drafts_limit: number;
  id: string;
  name: string;
  options: ITarrifResponseOption[];
  pages_limit: number;
}

export interface IProfileReducerState {
  profile: IProfile | null;
  tariffs: ITarrifResponse[] | null
  promo: boolean
  recurring: boolean
}

export enum ProfileReducerActionEnum {
  setProfile = 'SET_PROFILE',
  setTarrifs = 'SET_TARIFFS',
  setPromo = 'SET_PROMO',
  setRecurring = 'SET_RECURRING',
}

const IS: IProfileReducerState = {
  profile: null,
  tariffs: null,
  promo: false,
  recurring: false,
};

interface ProfileReducerActionSetProfile {
  type: ProfileReducerActionEnum.setProfile;
  payload: IProfile | null;
}
interface ProfileReducerActionSetTarrifs {
  type: ProfileReducerActionEnum.setTarrifs;
  payload: ITarrifResponse[]
}
interface ProfileReducerActionSetPromo {
  type: ProfileReducerActionEnum.setPromo;
  payload: boolean
}
interface ProfileReducerActionSetRecurring{
  type: ProfileReducerActionEnum.setRecurring;
  payload: boolean
}

export type ProfileReducerActionTypes =
  | ProfileReducerActionSetProfile
  | ProfileReducerActionSetTarrifs
  | ProfileReducerActionSetPromo
  | ProfileReducerActionSetRecurring

export const ProfileReducer = (
  state = IS,
  action: any,
): IProfileReducerState => {
  switch (action.type) {
    case ProfileReducerActionEnum.setProfile:
      return { ...state, profile: action.payload };
    case ProfileReducerActionEnum.setTarrifs:
      return { ...state, tariffs: action.payload };
    case ProfileReducerActionEnum.setPromo:
      return { ...state, promo: action.payload };
    case ProfileReducerActionEnum.setRecurring:
      return { ...state, recurring: action.payload };

    default:
      return state;
  }
};
