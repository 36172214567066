import { makeStyles } from '@material-ui/core';
export default makeStyles(() => ({
  backWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'fixed',
    top: 16,
    left: 40,
    columnGap: 5,
    cursor: 'pointer',
    textDecoration: 'none',
    '& h2':{
      margin: 0,
      padding: 0,
      color: '#264A67',
      fontSize: 20,
    },
  },
}));
