import { request } from './request';

interface ICompleteRegistration {
  token: string;
  password: string;
  account_type: 'COMPANY' | 'PERSON';
  first_name: string;
  last_name: string;
  middle_name?: string;
  company_name?: string;
  company_address?: string;
  company_inn?: number;
  company_kpp?: number;
  company_ogrn?: number;
}

interface ICompleteResetPassword {
  token: string;
  password: string;
}

export interface IRequestRegistration {
  email: string;
  promo_code?: string
  referrer_id?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export function getPasswordPolice() {
  return request('get', 'configuration/password-policy');
}

export function requestRegistration(data: IRequestRegistration) {
  return request('post', 'users/registration/request', data);
}

export function completeRegistration(data: ICompleteRegistration) {
  return request('post', 'users/registration/complete', data);
}

export function requestPassportReset(email: string) {
  return request('post', 'users/forgotten-password/request', { email });
}

export function completePassportReset(data: ICompleteResetPassword) {
  return request('post', 'users/forgotten-password/reset', data);
}
