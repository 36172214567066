import React, { memo, useCallback, useState } from 'react';
import { Comments } from '.';
import { IComment } from '../../store/reducers/editors';
import { SetComment } from './SetComment';

export interface ICommentsWrapperProps {
  id: string;
  pub?: boolean;
  comments: IComment[];
  title?: string;
  className?: string;
  page: any; // IPages | IBlocks
  onAdded: (
    page: any, // IPages | IBlocks
    comment: string,
  ) => void;
  onClose: () => void;
  onRemove: (
    page: any, // IPages | IBlocks
    idx: number,
  ) => void;
  onUpdate: (
    page: any, // IPages | IBlocks
    comment: string,
    idx: number,
  ) => void;
}

export const CommentsWrapper = memo((props: ICommentsWrapperProps) => {
  const [show, setShow] = useState(false);

  const updateDraft = useCallback(() => {
    setShow(false);
    props.onClose();
  }, [props]);

  return (
    <>
      <div
        className={`${props.className ? props.className : ''} ${
          props.comments.length > 0 ? 'comments_wrapper_show' : ''
        }`}
      >
        <Comments
          onClick={() => setShow(!show)}
          setted={props.comments.length > 0 ? true : false}
          style={{
            top: 5,
          }}
        />
      </div>
      {show ? (
        <SetComment
          page={props.page}
          title={props.title}
          value={props.comments}
          onAdded={props.onAdded}
          onClose={updateDraft}
          onRemove={props.onRemove}
          onChange={props.onUpdate}
        />
      ) : (
        <></>
      )}
    </>
  );
});
