import React, { memo, useCallback, useState } from 'react';
import { ZoomStyle } from './ZoomStyle';
import ZoomIn from '../../../../assets/zoom_in.svg';
import ZoomInHover from '../../../../assets/zoom_in_hover.svg';
import ZoomOut from '../../../../assets/zoom_out.svg';
import ZoomOutHover from '../../../../assets/zoom_out_hover.svg';
import ZoomHome from '../../../../assets/zoom_home.svg';
import ZoomHomeHover from '../../../../assets/zoom_home_hover.svg';

export interface IZoomProps {
  in: () => void;
  out: () => void;
  goHome: () => void;
}

export const Zoom = memo((props: IZoomProps) => {
  const [inOver, setInOver] = useState(false);
  const [outOver, setOutOver] = useState(false);
  const [homeOver, setHomeOver] = useState(false);
  const cc = ZoomStyle();

  const plusOver = useCallback(
    (val: boolean) => {
      if (inOver !== val) {
        setInOver(val);
      }
    },
    [inOver],
  );

  const minusOver = useCallback(
    (val: boolean) => {
      if (outOver !== val) {
        setOutOver(val);
      }
    },
    [outOver],
  );

  const homesOver = useCallback(
    (val: boolean) => {
      if (homeOver !== val) {
        setHomeOver(val);
      }
    },
    [homeOver],
  );

  return (
    <div className={cc.zoom_wrapper} id="zoom_wrapper">
      <div
        className={cc.btn}
        onClick={props.in}
        onMouseOver={() => plusOver(true)}
        onMouseLeave={() => plusOver(false)}
      >
        {!inOver ? (
          <img src={ZoomIn} alt="zoom in" />
        ) : (
          <img src={ZoomInHover} alt="zoom in" />
        )}
      </div>
      <div
        className={cc.btn}
        onClick={props.goHome}
        onMouseOver={() => homesOver(true)}
        onMouseLeave={() => homesOver(false)}
      >
        {!homeOver ? (
          <img src={ZoomHome} alt="zoom in" />
        ) : (
          <img src={ZoomHomeHover} alt="zoom in" />
        )}
      </div>
      <div
        className={cc.btn}
        onClick={props.out}
        onMouseOver={() => minusOver(true)}
        onMouseLeave={() => minusOver(false)}
      >
        {!outOver ? (
          <img src={ZoomOut} alt="zoom out" />
        ) : (
          <img src={ZoomOutHover} alt="zoom out" />
        )}
      </div>
    </div>
  );
});
