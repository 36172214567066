import {
  IRegistrationMessage,
  IRegistrationUser,
  RegistrationActionTypesEnum,
} from '../store/reducers/registration';
import { useDispatch } from 'react-redux';
import { IUTMItems } from '../utils/UTMArrayToApiString';
import { getPasswordPolice } from '../api/users.api';
import { checkRegistartionToken } from '../api/profile.api';

export const useRegistration = () => {
  const dispatch = useDispatch();

  return {
    getPasswordRegPolice: async () => {
      const { data } = await getPasswordPolice();
      return data;
    },
    addStep: () => {
      dispatch({ type: RegistrationActionTypesEnum.addStep, payload: '+' });
    },
    setStep: (step: number) => {
      dispatch({ type: RegistrationActionTypesEnum.setStep, payload: step });
    },
    clearStep: () => {
      dispatch({ type: RegistrationActionTypesEnum.clearStep });
    },
    setDescription: (text: string) => {
      dispatch({
        type: RegistrationActionTypesEnum.setDescription,
        payload: text,
      });
    },
    setTitle: (text: string) => {
      dispatch({
        type: RegistrationActionTypesEnum.setTitle,
        payload: text,
      });
    },
    setAllUser: (payload: IRegistrationUser) => {
      dispatch({ type: RegistrationActionTypesEnum.setUser, payload });
    },
    setUserEmail: (payload: string) => {
      dispatch({
        type: RegistrationActionTypesEnum.setEmail,
        payload,
      });
    },
    setUserPassword: (payload: string) => {
      dispatch({
        type: RegistrationActionTypesEnum.setUserPassword,
        payload,
      });
    },
    setUserRetypePassword: (payload: string) => {
      dispatch({
        type: RegistrationActionTypesEnum.setUserRetypePassword,
        payload,
      });
    },
    setRegistrationMessage: (payload: IRegistrationMessage) => {
      dispatch({
        type: RegistrationActionTypesEnum.setMessage,
        payload,
      });
    },
    initThreeStep: () => {
      dispatch({ type: RegistrationActionTypesEnum.initThreeStep });
    },
    startTimer: () => {
      dispatch({ type: RegistrationActionTypesEnum.startTimer });
    },
    setUTM: (payload: IUTMItems) => {
      dispatch({
        type: RegistrationActionTypesEnum.setUTM,
        payload,
      });
    },
    setPromo: async (payload: string) => {
      dispatch({ type: RegistrationActionTypesEnum.setPROMO, payload });
    },
    setAgreement: (payload: boolean) => {
      dispatch({
        type: RegistrationActionTypesEnum.setAgreement,
        payload,
      });
    },
    setSiteDraftQuery: (payload: string) => {
      dispatch({
        type: RegistrationActionTypesEnum.setSiteDraftQuery,
        payload,
      });
    },
    checkTokenExperation: async (payload: string) => {
      return await checkRegistartionToken(payload);
    },
  };
};
