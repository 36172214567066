import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import ClosePopup from '../../ClosePopup';
import { CommentInput } from '../../CommentInput/CommentInput';
import { useEditorSitemap } from '../../../hooks/useEditorSitemap';
import { useTypeSelector } from '../../../hooks/useTypesSelector';

const ProjectDescriptionStyle = makeStyles(() => ({
  ProjectDescriptionWrapper: {
    position: 'fixed',
    top: 80,
    left: 30,
    zIndex: 99999,
  },
  ProjectDescription: {
    position: 'relative',
    width: 40,
    height: 40,
    border: '1px solid #C7D6E6',
    borderRadius: 5,
    '& svg': {
      '& path': {
        fill: '#353E47',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      '& svg': {
        '& path': {
          fill: '#3090E8',
        },
      },
    },
  },
  ProjectDescriptionBody: {
    position: 'absolute',
    width: 288,
    height: 'fit-content',
    left: 72,
    top: 0,
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 17,
  },
  Header: {
    display: 'flex',
    '& h2': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500px',
      fontSize: 18,
      lineHeight: '22px',
      display: 'flex',
      color: '#353E47',
      margin: 0,
      padding: 0,
    },
  },
  BlockClose: {
    right: 5,
    position: 'absolute',
    top: 5,
    cursor: 'pointer',
  },
  textArea: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const ProjectDescription: FC = memo(() => {
  const [show, setShow] = useState(false);
  const description = useTypeSelector((s) => s.editor.description);
  const [comment, setComment] = useState('');
  const wrapperRef = useRef(null);
  const cc = ProjectDescriptionStyle();
  const { putProjectDescription, putDraftServer } = useEditorSitemap();

  const onClose = useCallback(() => {
    setShow(false);
    putDraftServer();
  }, [putDraftServer]);

  useEffect(() => {
    const time = setTimeout(() => putProjectDescription(comment), 500);
    return () => clearTimeout(time);
  }, [comment, putProjectDescription]);

  useEffect(() => {
    setComment(description);
  }, [description]);

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, onClose]);

  return (
    <div
      className={cc.ProjectDescriptionWrapper}
      id="ProjectDescriptionWrapper"
    >
      <div className={cc.ProjectDescription}>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setShow(!show)}
        >
          <path d="M14 10C12.9057 10 12 10.9057 12 12V28C12 29.0943 12.9057 30 14 30H26C27.0943 30 28 29.0943 28 28V16L22 10H14ZM14 12H21V17H26V28H14V12ZM16 19V21H18V19H16ZM20 19V21H24V19H20ZM16 23V25H18V23H16ZM20 23V25H24V23H20Z" />
        </svg>
      </div>
      {show ? (
        <div className={cc.ProjectDescriptionBody} ref={wrapperRef}>
          <div className={cc.Header}>
            <h2>Описание проекта</h2>
            <ClosePopup onClick={onClose} className={cc.BlockClose} />
          </div>
          <CommentInput
            className={cc.textArea}
            value={comment}
            onChange={setComment}
            // onBlur={onClose}
            placeholder="Добавьте комментарий"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});
