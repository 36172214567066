import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const FormWithVideoBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 4.5C18.8578 4.5 15.5 7.85775 15.5 12C15.5 16.1422 18.8578 19.5 23 19.5C27.1422 19.5 30.5 16.1422 30.5 12C30.5 7.85775 27.1422 4.5 23 4.5ZM21.5 14.625V9.375C21.5 9.066 21.8525 8.88975 22.1 9.075L25.6003 11.7C25.8005 11.85 25.8005 12.15 25.6003 12.3L22.1 14.925C21.8525 15.1102 21.5 14.934 21.5 14.625Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="44.6667"
          height="22"
          rx="1"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <rect
          x="55.1667"
          y="0.5"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="9.16699"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <rect
          x="55.1667"
          y="17.833"
          width="45.6667"
          height="5.66667"
          rx="2.83333"
          stroke={blockDefaultColor}
        />
        <g clipPath="url(#clip0_6812_22936)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.333 0H109.333V24H156.333V0ZM125.493 7.20671L133 2.82682L140.506 7.20671C141.018 7.50471 141.333 8.0539 141.333 8.64551V17C141.333 17.9109 140.577 18.6667 139.667 18.6667H126.333C125.422 18.6667 124.667 17.9109 124.667 17V8.64551C124.667 8.0539 124.982 7.50471 125.493 7.20671ZM139.577 8.59342L133 4.75716L126.423 8.59342L133 12.6852L139.577 8.59342ZM130.5 8.66667L133 6.16667L135.5 8.66667H133.833V10.6491L133 11.1667L132.167 10.6491V8.66667H130.5ZM133 14.6481L126.333 10.501V17H139.667V10.501L133 14.6481Z"
            fill={blockDefaultColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_6812_22936">
            <rect x="109.333" width="46.6667" height="24" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Block>
  );
});
