import React, {memo, useState } from 'react';
import TextArea from 'react-textarea-autosize';
import useStyles from './styles';
import cx from 'clsx';

interface ICommentInputProps {
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  onBlur?: () => void;
  onKeyPress?: () => void;
  className?: string;
}

export const CommentInput = memo((props: ICommentInputProps) => {
  const cc = useStyles();
  const [over, setOver] = useState(false);

  return (
    <TextArea
      className={cx(props.className, cc.input, over ? cc.onOver : cc.onLeav)}
      placeholder={props.placeholder}
      onMouseOver={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      onClick={props.onClick}
      value={props.value}
      onChange={(e)=>props.onChange(e.target.value)}
      onBlur={props.onBlur}
      name="comment"
    />
  );
});
