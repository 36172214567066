import React, { FC, useCallback, useEffect } from 'react';
import { GetSocialAuthGoogleToken } from '../../api/socialLogin.api';
import { useProfile } from '../../hooks/useProfile';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

export const GoogleRegister: FC = () => {
  const { setSnackbarValue } = useSnackbar();
  const { boardingCheckProfile } = useProfile();

  const getData = useCallback(
    async (loc: any, controller: AbortController) => {
      try {
        const at = localStorage.getItem('access_token');
        const rt = localStorage.getItem('refresh_token');
        if (!at && !rt) {
          const res = await GetSocialAuthGoogleToken(loc, controller);
          const access_token =
            res && res.data && res.data.access_token
              ? res.data.access_token
              : undefined;
          const refresh_token =
            res && res.data && res.data.refresh_token
              ? res.data.refresh_token
              : undefined;

          access_token && localStorage.setItem('access_token', access_token);
          refresh_token && localStorage.setItem('refresh_token', refresh_token);

          const profile = await boardingCheckProfile();

          if (profile.onboarding_completed) {
            window.location.reload();
            return;
          }

          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');

          const a = document.createElement('a');
          a.href = `//${window.location.host}/registration/${access_token}?step=5`;
          a.click();
        }
      } catch (e) {
        setSnackbarValue('Ошибка входа в Google Аккаунт', SnackbarType.Error);
      }
    },
    [setSnackbarValue, boardingCheckProfile],
  );

  useEffect(() => {
    const controller = new AbortController();
    const obj = {};
    const l: string[] = window.location.search.replace('?', '').split('&');
    for (const item of l) {
      const d: string[] = item.split('=');
      // @ts-ignore
      obj[d[0]] = d[1];
    }
    getData(obj, controller);
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};
