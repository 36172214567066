import { makeStyles } from '@material-ui/core';

export const NewInputStyles = makeStyles(() => ({
  new_input: {
    width: 520,
    height: 40,
    background: '#FFFFFF',
    border: '1px solid #6F8194',
    boxSizing: 'border-box',
    // borderRadius: '5px 0px 0px 5px',
    outline: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    padding: '0 10px',
    "&::placeholder":{
      color: '#A1AEBB',
    },
    '&:focus': {
      // border: '2px solid #3090E8',
    },
  },
  error: {
    border: '1px solid #6F8194',
    borderBottom: '2px solid #EB5757',
    '&:focus': {
      // border: '2px solid #3090E8',
      borderBottom: '2px solid #EB5757',
    },
  },
}));
