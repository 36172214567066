import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ExampleLink } from '../../../components/ExampleLink/ExampleLink';
import { NewButton } from '../../../components/NewButton/NewButton';
import { NewInput } from '../../../components/NewInput/NewInput';
import { useModal } from '../../../hooks/useModal';
import {
  NewSelect,
  INewSelectListItem,
} from '../../../components/NewSelect/NewSelect';
import {
  countSiteDraftBySite,
  createSiteDraftBySite,
} from '../../../api/siteDraft';
import { useEditorList } from '../../../hooks/useEditorList';
import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';
import { BySiteStyles } from './styles/BySiteStyles';

export const BySite: FC = () => {
  const [url, setUrl] = useState('');
  const cc = BySiteStyles();
  const [errUrl, setErrUrl] = useState(false);
  const [type, setType] = useState('SITEMAP');
  const urlReg = useMemo(
    () => /^(http:\/\/|https:\/\/)?(www\.)?(\S+\.\S+)(\?.+|\/\S+\/\S+)?/,
    [],
  );
  const { hideModal } = useModal();
  const { setInProcess } = useEditorList();
  const { setSnackbarValue } = useSnackbar();
  const [valid, setValid] = useState(false);
  const [countPage, setCountPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const selectList: INewSelectListItem[] = [
    { value: 'SITEMAP', caption: 'из сайтмапа' },
    { value: 'CRAWL', caption: 'фактическая' },
  ];

  const Run = useCallback(async () => {
    if (!errUrl) {
      hideModal();
      try {
        await createSiteDraftBySite(url, type);
      } catch (e: any) {
        setSnackbarValue(e.error.error_description, SnackbarType.Error);
      } finally {
        setInProcess(true);
      }
    }
  }, [url, hideModal, setInProcess, errUrl, type, setSnackbarValue]);

  useEffect(() => {
    urlReg.test(url) ? setErrUrl(false) : setErrUrl(true);
  }, [url, setErrUrl, urlReg]);

  useEffect(() => {
    const time = setTimeout(async () => {
      if (urlReg.test(url)) {
        try {
          setLoading(true);
          const { data } = await countSiteDraftBySite(url);
          data && setValid(data.valid);
          data && setCountPage(data.count);
        } finally {
          setLoading(false);
        }
      }
    }, 500);

    return () => {
      clearTimeout(time);
    };
  }, [url, urlReg]);

  useEffect(() => {
    if (url.length > 0) {
      setLoading(true);
    }
  }, [url]);

  return (
    <div className={cc.wrapper}>
      <h3 className={cc.title}>На основе сайта</h3>
      <a
        href="//structura.app/crawler"
        className={cc.link}
        target="_blank"
        rel="noreferrer"
      >
        Как это работает?
      </a>
      <div className={cc.bySiteInputs}>
        <NewSelect
          list={selectList}
          value={type}
          onChange={setType}
          placeholder="Структура"
          style={{ borderRadius: '5px 0px 0px 5px' }}
        />
        {/* <NewSelect
          list={selectList}
          value={type}
          onChange={setType}
          placeholder="Детализация"
        /> */}
        <NewInput
          type="text"
          value={url}
          onChange={setUrl}
          placeholder="www.адрес-сайта.рф"
          onEnter={Run}
          autoFocus
          tabIndex={1}
        />
        <NewButton
          className={cc.btn}
          onClick={Run}
          title="Сформировать"
          afterIcon="enter"
          tabIndex={2}
          disabled={errUrl}
        />
      </div>
      <h3 className={cc.demo}>
        <span>Посмотреть примеры</span>{' '}
        <span>
          Страниц на сайте: &nbsp;
          {!url
            ? 'укажите адрес'
            : !loading
            ? valid
              ? countPage
              : '> 100'
            : 'идет подсчет...'}
        </span>
      </h3>
      <div className={cc.examples}>
        <ExampleLink
          caption="structura.app"
          link="/editor/cf870d1a-8388-89a2-2ffb-5cebf003396a?utm_source=example"
        />
        <ExampleLink
          caption="sitemaps.org"
          link="/editor/9933a5b5-f3d6-3312-a56b-79334ac2364a?utm_source=example"
        />
      </div>
    </div>
  );
};
