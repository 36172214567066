import { ISitemap } from '../../api/sitemap.api';

export enum SitemapsReducerActionsTypeTypes {
  setSitemap = 'SET_SITEMAPS',
  addToSitemap = 'ADD_TO_SITEMAPS',
  setPage = 'SET_PAGE',
  setOffset = 'SET_OFFSET',
  setLimit = 'SET_LIMIT',
}
export interface ISitemapsReducerActionsSetSitemap {
  type: SitemapsReducerActionsTypeTypes.setSitemap;
  payload: ISitemap[];
}

export interface ISitemapsReducerActionsAddToSitemap {
  type: SitemapsReducerActionsTypeTypes.addToSitemap;
  payload: ISitemap[];
}

export interface ISitemapsReducerActionsSetPage {
  type: SitemapsReducerActionsTypeTypes.setPage;
  payload: number;
}
export interface ISitemapsReducerActionsSetLimit {
  type: SitemapsReducerActionsTypeTypes.setLimit;
  payload: number;
}
export interface ISitemapsReducerActionsSetOffset {
  type: SitemapsReducerActionsTypeTypes.setOffset;
  payload: number;
}
export interface ISitemapsState {
  page: number;
  limit: number;
  offset: number;
  sitemaps: ISitemap[];
}

export type ISitemapsReducerActions =
  | ISitemapsReducerActionsSetSitemap
  | ISitemapsReducerActionsAddToSitemap
  | ISitemapsReducerActionsSetPage
  | ISitemapsReducerActionsSetLimit
  | ISitemapsReducerActionsSetOffset;

const InitialSitemapsState: ISitemapsState = {
  page: 1,
  limit: 25,
  offset: 25,
  sitemaps: [],
};

export const SitemapsReducer = (
  state = InitialSitemapsState,
  action: ISitemapsReducerActions,
) => {
  switch (action.type) {
    case SitemapsReducerActionsTypeTypes.setSitemap: {
      const newState = { ...state };
      newState.sitemaps = action.payload;
      return newState;
    }
    case SitemapsReducerActionsTypeTypes.addToSitemap: {
      const newState = { ...state };
      const id = action.payload[0].id;
      const find = state.sitemaps.filter((item) => item.id === id);
      if (find.length) {
        return newState;
      }

      newState.sitemaps = [...state.sitemaps, ...action.payload];
      return newState;
    }
    case SitemapsReducerActionsTypeTypes.setPage: {
      const newState = { ...state };
      newState.page = action.payload;
      return newState;
    }
    case SitemapsReducerActionsTypeTypes.setLimit: {
      const newState = { ...state };
      newState.limit = action.payload;
      return newState;
    }
    case SitemapsReducerActionsTypeTypes.setOffset: {
      const newState = { ...state };
      newState.offset = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
