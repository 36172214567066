import { combineReducers } from 'redux';
import { DNDReducer } from './DNDReducer';
import { EditableBlockReducer } from './editableBlockReducer';
import { PaymentsReducer } from './payments';
import { ProfileImageReducer } from './profile';
import { SettingTarrifsReducer } from './tarrifs';

export const SettingsReducer = combineReducers({
  tarrifs: SettingTarrifsReducer,
  profile: ProfileImageReducer,
  payments: PaymentsReducer,
  editableBlock: EditableBlockReducer,
  dnd: DNDReducer,
});

export type SettingsState = ReturnType<typeof SettingsReducer>;
