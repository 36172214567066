import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  feedbackWrapper: {
    paddingTop: 90,
    height: 'calc(100vh - 155px)',
    position: 'relative',
    backgroundImage: "url('/feedback.webp')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  card: {
    marginBottom: theme.spacing(0.5),
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px rgba(167, 167, 167, 0.35)',
    backdropFilter: 'blur(7px)',
    borderRadius: 15,
  },
  title: {
    color: '#333',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
  },
  top: {
    padding: 32,
  },

  description: {
    fontFamily: 'Roboto',
    fontSize: 16,
    color: '#333',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'normal',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    '@media (max-width: 700px)': {
      '& img': {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: 20,
        opacity: 0.4,
      },
    },
  },
  writeUsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formControl: {
    width: '49%',
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-formControl': {
      height: '100%',
    },
    '& div.MuiOutlinedInput-input': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '@media (max-width: 612px)': {
      width: '100%',
    },
    '& #type-select': {
      position: 'absolute',
      top: -18,
      left: 0,
    },
  },
  formControlSelect: {
    width: '49%',
    '@media (max-width: 612px)': {
      width: '100%',
    },
    '& label': {
      position: 'absolute',
      top: -12,
      left: 16,
      '&.MuiFormLabel-filled': {
        top: -15,
      },
    },
    '& div.MuiOutlinedInput-input': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  },
  form: {
    display: 'grid',
    flexWrap: 'wrap',
    width: '100%',
    rowGap: 16,
  },
  formHeader: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    columnGap: 10,
  },
  button: {
    width: '100%',
    height: 40,
    marginTop: theme.spacing(2),
  },
  uploadTitle: {
    color: '#6F8194',
    fontWeight: 500,
  },
  uploadArea: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid  #3090E8',
    background: '#F1F8FE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    '& label': {
      background: '#F1F8FE',
    },
    '& span': {
      display: 'flex',
      columnGap: 8,
      alignItems: 'center',
      position: 'relative',
      '& svg': {
        top: -2,
        position: 'relative',
      },
    },
  },
  inputFile: {
    position: 'absolute',
    width: '100%',
    height: 40,
    top: 0,
    left: 0,
  },
  fileName: {
    wordBreak: 'break-all',
    marginRight: theme.spacing(1),
  },
  link: {
    marginTop: 24,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '22px',
    letterSpacing: '0.03em',
    color: '#3090E8',
  },
  files: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    rowGap: 16,
    columnGap: 16,
  },
  file: {
    borderRadius: 5,
    border: '1px solid  #3090E8',
    background: '#F1F8FE',
    display: 'flex',
    height: 40,
    padding: '0 8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#3090E8',
    '& svg': {
      transition: 'All .3s linear',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
  },
}));
