import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useLocation } from 'wouter';
import { RegistrationDescription } from '../RegistrationDescription';
import { Step } from '../Step';
import useStyles from '../styles/index';
import { useRegistration } from '../../../hooks/useRegistration';
import { useProfile } from '../../../hooks/useProfile';

export const Finish = () => {
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const { setTitle, setDescription, clearStep } = useRegistration();
  const { boardingComplite } = useProfile();
  const token = window.location.pathname.replace(/\/registration\//g, '');

  const goto = useCallback(async () => {
    if (localStorage.getItem('reg_type') !== 'normal') {
      localStorage.setItem('access_token', token);
    }

    setTitle('Регистрация');
    setDescription('ВВЕДИТЕ ДАННЫЕ РЕГИСТРАЦИИ');
    clearStep();
    await boardingComplite();
    setLocation('/editor');
  }, [
    token,
    clearStep,
    boardingComplite,
    setDescription,
    setTitle,
    setLocation,
  ]);

  return (
    <div>
      <Step />
      <RegistrationDescription />
      <div className={classes.finishbtn}>
        <Button size="large" variant="contained" color="primary" onClick={goto}>
          НАЧАТЬ
        </Button>
      </div>
    </div>
  );
};
