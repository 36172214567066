import { IBlock } from '../editors';

export type CursorTypes = 'grabbing' | 'grab' | 'default' | 'edit';

export interface IPosition {
  top: number;
  left: number;
}
export interface IXY {
  x: number;
  y: number;
}

export interface IDNDReducerState {
  dragBlock: IBlock | null;
  dragBlockPage: string;
  dragOverBlock: IBlock | null;
  dragOverBlockPage: string;
  pageBlockDragged: boolean
  cursorDnD: CursorTypes
  pos: IPosition
  posBefore: IPosition
  xy: IXY
  dragWrapperScrollLeft: number
  scale: number
  scaleOrigin: string
  noScroll: boolean
  pageScroll: boolean
}

const IS: IDNDReducerState = {
  dragBlock: null,
  dragBlockPage: '',
  dragOverBlock: null,
  dragOverBlockPage: '',
  pageBlockDragged: false,
  cursorDnD: 'grab',
  pos: {top: 20, left: 0},
  posBefore: {top: 20, left: 0},
  xy: {x: 0, y: 0},
  dragWrapperScrollLeft: 0, // прокуртка в px
  scale: 1, // размер скела
  scaleOrigin: '',
  noScroll: false, //отвечает за не прокрутку в момент создания страницы
  pageScroll: false,
};

export enum DNDReducerActionEnum {
  setDragBlock = 'SET_DND_BLOCK',
  setDragBlockPage = 'SET_DND_BLOCK_PAGE',
  setDragOverBlock = 'SET_DND_OVER_BLOCK',
  setDragOverBlockPage = 'SET_DND_OVER_BLOCK_PAGE',
  setPageBlockDragged = 'SET_PAGE_BLOCK_DRAGGED',
  setCursorDnD = 'SET_CURSOR_DND',
  setPos = 'SET_POSITION',
  setPosBefore = 'SET_POSITION_BEFORE',
  setXY = 'SET_XY',
  setDrageWrapperScrollLeft = 'SET_DRAGE_WRAPPER_SCROLL_LEFT',
  setScale = 'SET_EDITOR_SCALE',
  setNoScroll = 'SET_NO_SCROLL',
  setPageScroll = 'SET_PAGE_SCROLL',
  setScalsOrigin = "SET_SCALE_ORIGIN"
}

interface DNDReducerActionSetScalsOrigin {
  type: DNDReducerActionEnum.setScalsOrigin;
  payload: string;
}
interface DNDReducerActionSetDragBlock {
  type: DNDReducerActionEnum.setDragBlock;
  payload: IBlock | null;
}
interface DNDReducerActionSetDragBlockPage {
  type: DNDReducerActionEnum.setDragBlockPage;
  payload: string;
}
interface DNDReducerActionSetDragOverBlock {
  type: DNDReducerActionEnum.setDragOverBlock;
  payload: IBlock | null;
}
interface DNDReducerActionSetDragOverBlockPage {
  type: DNDReducerActionEnum.setDragOverBlockPage;
  payload: string;
}
interface DNDReducerActionSetPageBlockDragged {
  type: DNDReducerActionEnum.setPageBlockDragged;
  payload: boolean;
}
interface DNDReducerActionSetCursorDND {
  type: DNDReducerActionEnum.setCursorDnD;
  payload: CursorTypes
}
interface DNDReducerActionSetPos {
  type: DNDReducerActionEnum.setPos;
  payload: IPosition
}
interface DNDReducerActionSetPosBefore {
  type: DNDReducerActionEnum.setPosBefore;
  payload: IPosition
}
interface DNDReducerActionSetXY {
  type: DNDReducerActionEnum.setXY;
  payload: IXY
}
interface DNDReducerActionSetDrageWrapperScrollLeft {
  type: DNDReducerActionEnum.setDrageWrapperScrollLeft;
  payload: number
}
interface DNDReducerActionSetScale {
  type: DNDReducerActionEnum.setScale;
  payload: number
}
interface DNDReducerActionSetNoScroll {
  type: DNDReducerActionEnum.setNoScroll;
  payload: boolean
}
interface DNDReducerActionSetPageScroll {
  type: DNDReducerActionEnum.setPageScroll;
  payload: boolean
}

export type DNDReducerTypes =
  | DNDReducerActionSetDragBlock
  | DNDReducerActionSetDragOverBlock
  | DNDReducerActionSetDragBlockPage
  | DNDReducerActionSetDragOverBlockPage
  | DNDReducerActionSetPageBlockDragged
  | DNDReducerActionSetCursorDND
  | DNDReducerActionSetPos
  | DNDReducerActionSetPosBefore
  | DNDReducerActionSetXY
  | DNDReducerActionSetDrageWrapperScrollLeft
  | DNDReducerActionSetScale
  | DNDReducerActionSetNoScroll
  | DNDReducerActionSetPageScroll
  | DNDReducerActionSetScalsOrigin

export const DNDReducer = (
  state = IS,
  action: DNDReducerTypes,
): IDNDReducerState => {
  switch (action.type) {
    case DNDReducerActionEnum.setDragBlock:
      return { ...state, dragBlock: action.payload };

    case DNDReducerActionEnum.setDragBlockPage:
      return { ...state, dragBlockPage: action.payload };

    case DNDReducerActionEnum.setDragOverBlock:
      return { ...state, dragOverBlock: action.payload };

    case DNDReducerActionEnum.setDragOverBlockPage:
      return { ...state, dragOverBlockPage: action.payload };

    case DNDReducerActionEnum.setPageBlockDragged:
      return { ...state, pageBlockDragged: action.payload };

    case DNDReducerActionEnum.setCursorDnD:
      return { ...state, cursorDnD: action.payload };

    case DNDReducerActionEnum.setPos:
      return { ...state, pos: action.payload };

    case DNDReducerActionEnum.setPosBefore:
      return { ...state, posBefore: action.payload };

    case DNDReducerActionEnum.setXY:
      return { ...state, xy: action.payload };

    case DNDReducerActionEnum.setDrageWrapperScrollLeft:
      return { ...state, dragWrapperScrollLeft: action.payload };

    case DNDReducerActionEnum.setScale:
      return { ...state, scale: action.payload };

    case DNDReducerActionEnum.setNoScroll:
      return { ...state, noScroll: action.payload };

    case DNDReducerActionEnum.setPageScroll:
      return { ...state, pageScroll: action.payload };

    case DNDReducerActionEnum.setScalsOrigin:
      return { ...state, scaleOrigin: action.payload };

    default:
      return state;
  }
};
