import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  textarea: {
    display: 'block',
    width: '200px',
    overflow: 'hidden',
    resize: 'both',
    minHeight: 20,
    lineHeight: '20px',
    height: 'auto',
    '&[contenteditable]:empty::before': {
      content: 'Placeholder still possible',
      color: 'gray',
    },
  },
  editorContent: {
    minWidth: '100vw',
  },

  pagesWrapper: {
    height: 'calc(100vh - 98px)',
    // height: '100vh',
    // width: '100vw',
    display: 'grid',
    // justifyContent: 'center',
    // alignItems: 'center',
    position: 'relative',
    padding: 20,
    overflow: 'visible',
  },
  pagesWrapperUp: {
    // width: 'calc(100vw - 40px)',
    width: 'fit-content',
    // width: '100%',
    height: '100%',
  },
  pagesWrapperDown: {
    // width: 'calc(100vw - 60px)',
  },
  childWrapper: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 60,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -45,
      right: '50%',
      width: 2,
      height: 30,
      backgroundColor: '#264A67',
    },
  },
  pageWrapper: {
    display: 'block',
    userSelect: 'none',
  },
  page: {
    position: 'relative',
    width: 182,
    height: 'auto',
    minHeight: 82,
    background: '#FCFEFF',
    border: '1px solid #C7D6E6',
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '6px 0px 25px 0px',
    justifySelf: 'center',
    alignSelf: 'start',
    margin: '0 auto',
    '&:hover': {
      '& .page_comments_btn': {
        opacity: 1,
        pointerEvents: 'all',
        zIndex: 1,
      },
      '& .page_remove_btn': {
        opacity: 1,
        pointerEvents: 'all',
        zIndex: 1,
      },
      '& .page_add_btn': {
        opacity: 1,
        pointerEvents: 'all',
        zIndex: 1,
      },
    },
    // zIndex: 1,
  },
  pageHoverBtn: {
    position: 'fixed',
    width: 182,
    minHeight: 30,
    // background: '#FCFEFF',
    // border: '1px solid #C7D6E6',
    boxSizing: 'border-box',
    // borderRadius: 8,
    padding: '6px 0px 25px 0px',
    justifySelf: 'center',
    alignSelf: 'start',
    margin: '0 auto',
  },
  pageFirstChild: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 'calc(50% + 35px)',
      height: 2,
      position: 'absolute',
      top: -16,
      left: '50%',
      backgroundColor: '#264A67',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -16,
      right: '50%',
      width: 2,
      height: 16,
      backgroundColor: '#264A67',
    },
  },
  pageChild: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 'calc(100% + 60px)',
      height: 2,
      position: 'absolute',
      top: -16,
      left: -35,
      backgroundColor: '#264A67',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -16,
      right: '50%',
      width: 2,
      height: 16,
      backgroundColor: '#264A67',
    },
  },
  onePageChild: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -16,
      right: '50%',
      width: 2,
      height: 16,
      backgroundColor: '#264A67',
    },
  },
  pageLastChild: {
    position: 'relative',
    '&:before': {
      content: '""',
      width: 'calc(50% + 35px)',
      height: 2,
      position: 'absolute',
      top: -16,
      left: -35,
      backgroundColor: '#264A67',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -16,
      right: '50%',
      width: 2,
      height: 16,
      backgroundColor: '#264A67',
    },
  },
  pageBtn: {
    position: 'absolute',
    top: -7,
    left: 5,
  },
  pageHeader: {
    position: 'relative',
    padding: '0 5px 0 5px',
    display: 'block',
    '& input': {
      width: '100%',
    },
  },
  blockHeader: {
    position: 'relative',
    overflow: 'hidden',
    padding: '0 5px 0 5px',
  },
  pageHeaderBtn: {
    position: 'absolute',
    right: 3,
    bottom: -10,
    cursor: 'pointer',
  },
  blockHeaderBtn: {
    position: 'absolute',
    left: 'calc(50% - 14.5px)',
    bottom: -29,
    cursor: 'pointer',
    // opacity: 0,
    transition: 'all 0.3s ease-in-out',
    // zIndex: 9999,
  },
  pageTitle: {
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    // display: 'flex',
    // alignItems: 'center',
    color: '#353E47',
    // margin: 0,
    width: 163,
    // overflow: 'scroll',
    padding: '0 7px 0 7px',
  },
  blockTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    // fontWeight: 500,
    // fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    color: '#353E47 !important',
    margin: 0,
    maxWidth: 154,
    border: 'none !important',
    fontSize: '0.875rem',
    fontWeight: 400,
    // display: 'grid !important',
  },
  editableInput: {
    fontWeight: 400,
    lineHeight: '18px',
    fontFamily: 'Roboto',
    // fontStyle: 'normal',
    // fontWeight: 'normal',
    // fontSize: 14,
    outlineColor: '#C7D6E6',
    outline: 'none',
    width: 156,
    height: 'auto',
    resize: 'none',
    overflow: 'hidden', // used to hide scrollbars
    border: 'none',
    backgroundColor: 'transparent',
  },
  addBtn: {
    position: 'absolute',
    bottom: -29,
    left: 'calc(50% - 14.5px)',
    cursor: 'pointer',
    zIndex: 99999,
    transition: 'All 0.3s ease-in-out',
  },
  block: {
    maxWidth: '180px',
    width: 'auto',
    position: 'relative',
    // borderRadius: 4,
    padding: 4,
    border: '1px solid transparent',
    borderTop: '1px solid #C7D6E6',
    '&:hover': {
      zIndex: 2,
      backgroundColor: '#F6FBFF',
      '& .block_comments_btn': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& .block_remove_btn': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& .block_add_btn': {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
  blockDND: {
    position: 'relative',
    borderBottom: '2px solid #3090E8 !important',
    '&:before': {
      content: '""',
      borderRadius: '40px 0px 0px 40px',
      border: '15px solid transparent',
      borderLeft: '15px solid #3090E8',
      top: 'calc(100% - 13.7px)',
      left: -12,
      display: 'block',
      position: 'absolute',
    },
    '&:after': {
      content: '""',
      borderRadius: '0px 40px 40px 0px',
      border: '15px solid transparent',
      borderRight: '15px solid #3090E8',
      top: 'calc(100% - 13.7px)',
      right: -12,
      display: 'block',
      position: 'absolute',
    },
  },
  pageBody: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 0,
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'grid',
      gridGap: 15,
      gridTemplateColumns: '200px',
    },
  },
  blockBody: {
    marginTop: '3px',
    minHeight: 20,
  },
  columns1_3: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: '1fr 2fr',
  },
  columns3_1: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: '2fr 1fr',
  },
  columns2: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  columns3: {
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: 'repeat(3,1fr)',
  },
  picture: {
    border: '2px solid #FFFFFF',
    width: '100%',
    height: 28,
    opacity: 0.7,
    display: 'flex',
    justifyContent: 'center',
  },
  textImageWrapper: {
    display: 'grid',
    gridGap: 3,
  },
  textImage: {
    height: 2,
    display: 'block',
    borderRadius: 5,
    opacity: 0.7,
  },
  childs: {
    position: 'relative',
    top: 45,
  },
  ti80: {
    width: '80%',
  },
  ti90: {
    width: '90%',
  },
  ti100: {
    width: '100%',
  },
  ti40: {
    width: '40%',
  },
  EditableBlock: {
    paddingBottom: 15,
    position: 'absolute',
    top: '0',
    left: 'calc(190px + 10px)',
    width: 204,
    display: 'inline-block',
    height: 'auto',
    maxHeight: '450px',
    background: '#FCFEFF',
    // border: '2px solid #FD1F6D',
    boxSizing: 'border-box',
    boxShadow:
      '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 8,
    zIndex: 100000,
  },
  EditableBlockHeader: {
    position: 'relative',
    display: 'block',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  EditableBlockHeaderTitle: {
    display: 'inline-block',
    fontWeight: 500,
    fontSize: 14,
    margin: 10,
    padding: '0 0 0 5px',
  },
  EditableBlockRemove: {
    color: '#FD1F6D',
    textDecoration: 'underline',
    display: 'inline-block',
    cursor: 'pointer',
  },
  EditableBlockClose: {
    right: 4,
    position: 'absolute',
    top: 4,
    cursor: 'pointer',
    width: 16,
    height: 16,
  },
  EditableBlockSelectColorWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 3,
    padding: '10px 10px',
  },
  EditableBlockSelectColorTitle: {
    fontSize: 13,
    padding: '0 0 8px 0px',
    margin: 0,
  },
  EditableBlockSelectColorColor: {
    width: 24,
    height: 24,
    borderRadius: 100,
    cursor: 'pointer',
  },
  EditableBlockSelectColorColorActive: {
    position: 'relative',
    top: 3,
    left: 4,
  },
  blockHeader_title: {
    padding: '0 0 0 5px',
    fontSize: 14,
  },
  SelectBlockBody: {
    // display: 'grid',
    // gridTemplateColumns: '1fr',
    gridGap: 0,
    height: 300,
    padding: '0px 5px 10px 0',
    overflowX: 'auto',
    overflowY: 'auto',
    'scrollbar-width': '7px',
    'scrollbar-color': '#353E47 transparent',
    '&::-webkit-scrollbar': {
      width: 9,
      height: 9,
    },
    '&::-webkit-scrollbar-track': {
      width: 4,
      background: 'transparent',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#353E47',
      borderRadius: 0,
      cursor: 'pointer',
      borderRight: '4px solid white',
      '&:hover': {
        backgroundColor: '#3090E8',
        cursor: 'pointer !important',
      },
    },
    '& ul': {
      listStyle: 'none',
      display: 'grid',
      // gridTemplateColumns: 'repeat(2,1fr)',
      gridGap: 10,
      padding: 10,
      '& li': {
        cursor: 'pointer',
        '& input': {
          cursor: 'pointer',
        },
      },
    },
  },
  addBlockBtn: {
    width: '100%',
    height: 28,
    boxSizing: 'border-box',
    borderTop: '1px solid #C7D6E6',
    borderBottom: '1px solid #C7D6E6',
    // borderRadius: 4,
    fontSize: 30,
    color: '#C7D6E6',
    textAlign: 'center',
    lineHeight: '28px',
    cursor: 'pointer',
    transition: 'All 0.3s ease-in-out',
    userSelect: 'none',
    '&:hover': {
      background: '#3090E8',
      color: '#FFFFFF',
    },
  },
  active: {
    color: 'white',
  },
  active_default: {
    color: 'white !important',
    backgroundColor: '#264467 !important',
  },
  green: {
    border: '1px solid #0BA19F',
    color: '#0BA19F',
    '& svg.block': {
      fill: '#0BA19F',
      stroke: '#0BA19F',
    },
  },
  active_green: {
    color: 'white !important',
    backgroundColor: '#0BA19F !important',
  },
  blue: {
    border: '1px solid #3F92D1',
    color: '#3F92D1',
    '& svg.block': {
      fill: '#3F92D1',
      stroke: '#3F92D1',
    },
  },
  active_blue: {
    color: 'white !important',
    backgroundColor: '#3F92D1 !important',
  },
  searchInput_wrapper: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      top: 8,
      right: 6,
    },
  },
  searchInput: {
    border: '1px solid #3090E8',
    borderRadius: 5,
    width: 'calc(100% - 20px)',
    height: 25,
    outline: '1px solid #3090E8',
    padding: '0 10px',
  },
  purple: {
    border: '1px solid #703772',
    color: '#703772',
    '& svg.block': {
      fill: '#703772',
      stroke: '#703772',
    },
  },
  active_purple: {
    color: 'white !important',
    backgroundColor: '#703772 !important',
  },
  gray: {
    border: '1px solid #264A67',
    color: '#264A67',
    '& svg.block': {
      fill: '#264A67',
      stroke: '#264A67',
    },
  },
  active_gray: {
    color: 'white !important',
    backgroundColor: '#264A67 !important',
  },
  deepblue: {
    border: '1px solid #03336E',
    color: '#03336E',
    '& svg.block': {
      fill: '#03336E',
      stroke: '#03336E',
    },
  },
  active_deepblue: {
    color: 'white !important',
    backgroundColor: '#03336E !important',
  },
  EditableBlockComments: {
    padding: '5px 10px',
  },
  onHover: {
    cursor: 'pointer',
  },
  fillTransparent: {
    fill: 'transparent',
  },
  headerBlock: {
    zIndex: 30,
    '& svg': {
      width: '100%',
    },
  },
  opacity1: {
    opacity: '1 !important',
  },
  onOver: {
    cursor: 'text',
  },
  onLeav: {
    cursor: 'default',
  },
}));
