import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  companyWrapper: {
    marginBottom: theme.spacing(3),
  },
  companyTitle: {
    marginBottom: theme.spacing(2),
  },
  companyFieldKey: {
    color: '#828282',
  },
  companyFields: {
    marginBottom: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 10,
  },
  grid: {
    display: 'grid',
  },
  align: {
    alignItems: 'center',
    display: 'flex',
    gap: 8,
  },
  wrapper: {
    margin: '0px 0px 20px',
  },
  avaInput: {
    display: 'none',
  },
  avaWrapper: {
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '50%',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        width: 64,
        height: 23,
        background: '#A1AEBB',
        zIndex: 8888,
      },
      '&::after': {
        content:
          'url("data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMTI1MDEgMi4zNzVDNi4yNTc3OCAyLjM3NSA1LjU0MTY4IDMuMDkxMSA1LjU0MTY4IDMuOTU4MzNWNC43NUgzLjE2NjY4QzIuMjk5NDQgNC43NSAxLjU4MzM0IDUuNDY2MSAxLjU4MzM0IDYuMzMzMzNWMTUuMDQxN0MxLjU4MzM0IDE1LjkwODkgMi4yOTk0NCAxNi42MjUgMy4xNjY2OCAxNi42MjVIMTUuODMzM0MxNi43MDA2IDE2LjYyNSAxNy40MTY3IDE1LjkwODkgMTcuNDE2NyAxNS4wNDE3VjYuMzMzMzNDMTcuNDE2NyA1LjQ2NjEgMTYuNzAwNiA0Ljc1IDE1LjgzMzMgNC43NUgxMy40NTgzVjMuOTU4MzNDMTMuNDU4MyAzLjA5MTEgMTIuNzQyMiAyLjM3NSAxMS44NzUgMi4zNzVINy4xMjUwMVpNNy4xMjUwMSAzLjk1ODMzSDExLjg3NVY0Ljc1QzExLjg3NSA1LjYxNzIzIDEyLjU5MTEgNi4zMzMzMyAxMy40NTgzIDYuMzMzMzNIMTUuODMzM1YxNS4wNDE3SDMuMTY2NjhWNi4zMzMzM0g1LjU0MTY4QzYuNDA4OTEgNi4zMzMzMyA3LjEyNTAxIDUuNjE3MjMgNy4xMjUwMSA0Ljc1VjMuOTU4MzNaTTkuNTAwMDEgNy4xMjVDNy43NjA0OSA3LjEyNSA2LjMzMzM0IDguNTUyMTQgNi4zMzMzNCAxMC4yOTE3QzYuMzMzMzQgMTIuMDMxMiA3Ljc2MDQ5IDEzLjQ1ODMgOS41MDAwMSAxMy40NTgzQzExLjIzOTUgMTMuNDU4MyAxMi42NjY3IDEyLjAzMTIgMTIuNjY2NyAxMC4yOTE3QzEyLjY2NjcgOC41NTIxNCAxMS4yMzk1IDcuMTI1IDkuNTAwMDEgNy4xMjVaTTE0LjI1IDcuMTI1QzE0LjA0IDcuMTI1IDEzLjgzODcgNy4yMDg0MSAxMy42OTAyIDcuMzU2ODdDMTMuNTQxOCA3LjUwNTM0IDEzLjQ1ODMgNy43MDY3IDEzLjQ1ODMgNy45MTY2N0MxMy40NTgzIDguMTI2NjMgMTMuNTQxOCA4LjMyNzk5IDEzLjY5MDIgOC40NzY0NkMxMy44Mzg3IDguNjI0OTMgMTQuMDQgOC43MDgzMyAxNC4yNSA4LjcwODMzQzE0LjQ2IDguNzA4MzMgMTQuNjYxMyA4LjYyNDkzIDE0LjgwOTggOC40NzY0NkMxNC45NTgzIDguMzI3OTkgMTUuMDQxNyA4LjEyNjYzIDE1LjA0MTcgNy45MTY2N0MxNS4wNDE3IDcuNzA2NyAxNC45NTgzIDcuNTA1MzQgMTQuODA5OCA3LjM1Njg3QzE0LjY2MTMgNy4yMDg0MSAxNC40NiA3LjEyNSAxNC4yNSA3LjEyNVpNOS41MDAwMSA4LjcwODMzQzEwLjM4MzggOC43MDgzMyAxMS4wODMzIDkuNDA3ODQgMTEuMDgzMyAxMC4yOTE3QzExLjA4MzMgMTEuMTc1NSAxMC4zODM4IDExLjg3NSA5LjUwMDAxIDExLjg3NUM4LjYxNjE4IDExLjg3NSA3LjkxNjY4IDExLjE3NTUgNy45MTY2OCAxMC4yOTE3QzcuOTE2NjggOS40MDc4NCA4LjYxNjE4IDguNzA4MzMgOS41MDAwMSA4LjcwODMzWiIgZmlsbD0iIzZGODE5NCIvPgo8L3N2Zz4K")',
        position: 'absolute',
        bottom: 0,
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
  },
  avaBlock: {
    position: 'relative',
  },
  avaBlockWraper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 16,
  },
  removeAva: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
  avaSize: {
    width: '48px !important',
    height: '48px !important',
  },
  avaName: {
    fontWeight: 500,
    fontSize: 18,
  },
  description: {
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media (max-width: 590px)': {
        maxWidth: '350px',
        width: '100%',
      },
    '@media (max-width: 454px)': {
        maxWidth: '300px',
        width: '100%',
      },
    '@media (max-width: 390px)': {
        maxWidth: '240px',
        width: '100%',
      },
  },
  out: {
    width: '100%',
    marginTop: 16,
    display: 'flex',
    rowGap: 16,
    columnGap: 16,
    flexWrap: 'wrap',
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: 12,
    border: '1px solid var(--black-stroke-eb, #E4E4E4)',
  },
}));
