import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const CallActionBlock = memo((props: IBlock) => {
  const cc = useStyles();

  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H152C154.209 0 156 1.79086 156 4V20C156 22.2091 154.209 24 152 24H4C1.79086 24 0 22.2091 0 20V4Z"
          fill={blockDefaultColor}
        />
        <path
          d="M78.3691 4C76.0944 4 74.2441 5.85025 74.2441 8.125C74.2441 9.67506 75.1315 11.0793 76.4941 11.7783V14.2202L75.2534 13.6416C74.5432 13.3101 73.706 13.4687 73.166 14.0342L72 15.2559L76.585 19.8408C77.0102 20.2661 77.575 20.5 78.1758 20.5H82.4941C83.7346 20.5 84.7441 19.4905 84.7441 18.25V14.5015C84.7441 13.4222 83.9751 12.4928 82.9146 12.291L80.2441 11.7842V11.7783C81.6068 11.0793 82.4941 9.67506 82.4941 8.125C82.4941 5.85025 80.6439 4 78.3691 4ZM78.3691 5.5C79.8166 5.5 80.9941 6.6775 80.9941 8.125C80.9941 8.81873 80.7134 9.46723 80.2441 9.9458V8.875C80.2441 7.8415 79.4026 7 78.3691 7C77.3356 7 76.4941 7.8415 76.4941 8.875V9.9458C76.0248 9.46723 75.7441 8.81873 75.7441 8.125C75.7441 6.6775 76.9216 5.5 78.3691 5.5ZM78.3691 8.5C78.5761 8.5 78.7441 8.668 78.7441 8.875V13.0249L82.6348 13.7646C82.988 13.8314 83.2441 14.1422 83.2441 14.5015V18.25C83.2441 18.6632 82.9074 19 82.4941 19H78.1758C77.9755 19 77.7873 18.922 77.6455 18.7803L74.0962 15.231L74.2515 15.0698C74.3475 14.9701 74.4946 14.9432 74.6191 15.001L77.9941 16.5757V8.875C77.9941 8.668 78.1621 8.5 78.3691 8.5Z"
          fill="white"
        />
      </svg>
    </Block>
  );
});
