import { ISiteDraft } from '../store/reducers/editors';
import { request } from './request';

export interface IDraftTempCategory {
  id: string;
  name: string;
  templates_count: number;
}

export interface IDraftTempCategoryItem {
  id: string;
  name: string;
  pages_count: number;
  preview: string;
  category_name: string;
}

export function getDraftTempCategorys() {
  return request<IDraftTempCategory[]>('get', 'site-draft-template-categories');
}

export function getDraftTempCategorysById(id: string) {
  return request<IDraftTempCategoryItem[]>('get', `site-draft-template-categories/${id}/templates`);
}

export function getDraftByTempId(catagory_id: string,temp_id: string) {
  return request<ISiteDraft>('get', `site-draft-template-categories/${catagory_id}/templates/${temp_id}`);
}
