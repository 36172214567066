const rs = '__rs';

export enum RegistrationTypeMessageEnum {
  success = 'success',
  warning = 'warning',
}
export interface IRegistrationMessage {
  text: string | null;
  type: RegistrationTypeMessageEnum;
}
export interface IRegistrationUser {
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  password: string;
  retype_password: string;
  agreement: boolean;
}

export interface IUTM {
  key: string;
  value: string;
}
export interface IRegistrationState {
  step: number;
  title: string;
  description: string;
  message: IRegistrationMessage;
  user: IRegistrationUser;
  timer: number;
  utm: IUTM[];
  promo: string
  siteDraftQuery: string | null
}
const message: IRegistrationMessage = {
  text: null,
  type: RegistrationTypeMessageEnum.success,
};

const InitioalState: IRegistrationState = {
  step: 1,
  title: 'Регистрация',
  description: '',
  message,
  user: {
    email: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    password: '',
    retype_password: '',
    agreement: true,
  },
  timer: 30,
  utm: [],
  promo: '',
  siteDraftQuery: null
};

export enum RegistrationActionTypesEnum {
  setTitle = 'SET_REGISTRATION_TITLE',
  setDescription = 'SET_REGISTRATION_DESCRIPTION',
  clearStep = 'CLEAR_STEP',
  setStep = 'SET_REGISTRATION_STEP',
  addStep = 'ADD_REGISTRATION_STEP',
  setMessage = 'SET_REGISTRATION_MESSAGE',
  startTimer = 'START_REGISTRATION_TIMER',
  setEmail = 'START_REGISTRATION_EMAIL',
  initThreeStep = 'INIT_REGISTRATION_THREE_STEP',
  setUser = 'SET_REGISTRATION_USER',
  setUserPassword = 'SET_REGISTRATION_USER_PASSWORD',
  setUserRetypePassword = 'SET_REGISTRATION_USER_RETYPE_PASSWORD',
  setAgreement = 'SET_REGISTRATION_USER_AGREEMENT',
  setUTM = 'SET_UTM',
  setPROMO = 'SET_PROMO',
  setSiteDraftQuery = 'SET_SITE_DRAFT_QUERY',
}

export interface IRegistrationActions {
  type: RegistrationActionTypesEnum;
  payload: any;
}

const SaveStateToLocalStorage = (state: IRegistrationState) => {
  localStorage.setItem(rs, JSON.stringify(state));
  return state;
};

const LoadStateFtomLocalStorege = (): IRegistrationState => {
  const store = localStorage.getItem(rs);
  const newstore: IRegistrationState = store && JSON.parse(store);
  const newmessage: IRegistrationMessage = {
    text: 'Пароль должен содержать \n не менее 8 символов на латинице',
    type: RegistrationTypeMessageEnum.warning,
  };
  newstore.step = 3;
  newstore.title = 'Задайте пароль';
  newstore.description = 'введите пароль дважды';
  newstore.message = newmessage;
  return newstore;
};

export const RegisrationReducer = (
  state = InitioalState,
  action: IRegistrationActions,
):IRegistrationState => {
  switch (action.type) {
    case RegistrationActionTypesEnum.setTitle: {
      return { ...state, title: action.payload };
    }
    case RegistrationActionTypesEnum.addStep: {
      let step = state.step;
      if (action.payload === '+') {
        step++;
      }
      if (action.payload === '-') {
        step--;
      }
      return SaveStateToLocalStorage({ ...state, step });
    }
    case RegistrationActionTypesEnum.setStep: {
      return SaveStateToLocalStorage({ ...state, step: action.payload });
    }
    case RegistrationActionTypesEnum.setMessage: {
      return SaveStateToLocalStorage({ ...state, message: action.payload });
    }
    case RegistrationActionTypesEnum.startTimer: {
      let timer = state.timer;
      timer--;
      return SaveStateToLocalStorage({ ...state, timer });
    }
    case RegistrationActionTypesEnum.setEmail: {
      const user = { ...state.user };
      user.email = action.payload;
      return SaveStateToLocalStorage({ ...state, user });
    }
    case RegistrationActionTypesEnum.initThreeStep:
      return SaveStateToLocalStorage(LoadStateFtomLocalStorege());
    case RegistrationActionTypesEnum.setUser: {
      const user = { ...state.user, ...action.payload };
      return SaveStateToLocalStorage({ ...state, user });
    }
    case RegistrationActionTypesEnum.setUserPassword: {
      const user = { ...state.user };
      user.password = action.payload;
      return SaveStateToLocalStorage({ ...state, user });
    }
    case RegistrationActionTypesEnum.setUserRetypePassword: {
      const user = { ...state.user };
      user.retype_password = action.payload;
      return SaveStateToLocalStorage({ ...state, user });
    }
    case RegistrationActionTypesEnum.setAgreement: {
      const user = { ...state.user };
      user.agreement = action.payload;
      return SaveStateToLocalStorage({ ...state, user });
    }
    case RegistrationActionTypesEnum.setDescription: {
      return SaveStateToLocalStorage({ ...state, description: action.payload });
    }

    case RegistrationActionTypesEnum.setUTM: {
      return SaveStateToLocalStorage({ ...state, utm: action.payload });
    }

    case RegistrationActionTypesEnum.clearStep:
      return SaveStateToLocalStorage({ ...state, step: 1 });

    case RegistrationActionTypesEnum.setPROMO:
      return SaveStateToLocalStorage({ ...state, promo: action.payload });

    case RegistrationActionTypesEnum.setSiteDraftQuery:
      return SaveStateToLocalStorage({ ...state, siteDraftQuery: action.payload });

    default:
      return SaveStateToLocalStorage(state);
  }
};
