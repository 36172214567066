import React, { FC, useCallback, useEffect, useState } from 'react';
import { getDraftTemp } from '../../api/draftTemp';
import { IDraftTempCategoryItem } from '../../api/draftTempCategory';
import { TemplateCard } from '../../components/Editor/temp/TemplateCard';
import { useDraftTemp } from '../../hooks/useDraftTemp';

export const Template: FC = () => {
  const [tempList, setTempList] = useState<IDraftTempCategoryItem[]>();
  const { TempUse, goToTemp } = useDraftTemp();

  const getTempList = useCallback(async () => {
    const { data } = await getDraftTemp();
    setTempList(data);
  }, []);

  useEffect(() => {
    getTempList();
  }, [getTempList]);

  return (
    <div
      style={{
        padding: 20,
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 20,
        rowGap: 20,
      }}
    >
      {tempList &&
        tempList.length &&
        tempList.map((item, idx) => (
          <TemplateCard
            {...item}
            key={idx}
            useNow={TempUse}
            goToTemp={goToTemp}
          />
        ))}
    </div>
  );
};
