import React, { memo, useCallback, useEffect, useState } from 'react';
import TextArea from 'react-textarea-autosize';
import useStyles from '../../../../views/EditorItem/styles';
import '../../../../assets/main.sass';
import { useDND } from '../../../../hooks/useDND';

interface IBlockTitleInputProps {
  value: string;
  onChange: (page: any, newVal: string) => void;
  className?: string;
  onClick?: () => void;
  textColor?: string;
  page: any;
}

export const BlockTitleInput = memo((props: IBlockTitleInputProps) => {
  const cc = useStyles();
  const [value, setValue] = useState('');
  const { setCursor, setPageBlockDragged } = useDND();

  const onBlur = useCallback(() => {
    props.onChange(props.page, value);
  }, [value, props]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const onOver = useCallback(() => {
    setPageBlockDragged(true);
    setCursor('edit');
  }, [setCursor, setPageBlockDragged]);

  const onLeave = useCallback(() => {
    setCursor('grab');
    setPageBlockDragged(false);
  }, [setPageBlockDragged, setCursor]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <TextArea
      className={`editInput ${cc.editableInput} ${
        props.className ? props.className : ''
      }`}
      onChange={handleChange}
      value={value}
      onBlur={onBlur}
      onMouseOver={onOver}
      onMouseLeave={onLeave}
      name={`title_block_${props.page.id}`}
    />
  );
});
