import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const QuizOrSurveyBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12.5 19H11.5C11.224 19 11 18.776 11 18.5V17.5C11 17.224 11.224 17 11.5 17H12.5C12.776 17 13 17.224 13 17.5V18.5C13 18.776 12.776 19 12.5 19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.867 9.966 14.095 9.308 13.963 8.607C13.828 7.89 13.275 7.28 12.574 7.081C11.468 6.767 10.437 7.4 10.108 8.353C9.98 8.724 9.668 9 9.275 9H8.984C8.403 9 7.997 8.436 8.159 7.878C8.588 6.406 9.84 5.285 11.384 5.047C12.908 4.812 14.353 5.596 15.257 6.846C16.437 8.478 16.089 10.231 15.07 11.25Z"
          fill={blockDefaultColor}
        />
        <path
          d="M40 3C38.9069 3 38 3.90694 38 5V19C38 20.0931 38.9069 21 40 21H54C55.0931 21 56 20.0931 56 19V5C56 3.90694 55.0931 3 54 3H40ZM40 5H54V19H40V5Z"
          fill={blockDefaultColor}
        />
        <path
          d="M75 3C73.9069 3 73 3.90694 73 5V19C73 20.0931 73.9069 21 75 21H89C90.0931 21 91 20.0931 91 19V5C91 3.90694 90.0931 3 89 3H75ZM75 5H89V19H75V5ZM86.293 8.29297L80 14.5859L77.707 12.293L76.293 13.707L80 17.4141L87.707 9.70703L86.293 8.29297Z"
          fill={blockDefaultColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115 2C109.477 2 105 6.47715 105 12C105 17.5228 109.477 22 115 22C120.523 22 125 17.5228 125 12C125 6.47715 120.523 2 115 2ZM115 19.5C119.142 19.5 122.5 16.1421 122.5 12C122.5 7.85786 119.142 4.5 115 4.5C110.858 4.5 107.5 7.85786 107.5 12C107.5 16.1421 110.858 19.5 115 19.5Z"
          fill={blockDefaultColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146 2C140.477 2 136 6.47715 136 12C136 17.5228 140.477 22 146 22C151.523 22 156 17.5228 156 12C156 6.47715 151.523 2 146 2ZM146 19.5C150.142 19.5 153.5 16.1421 153.5 12C153.5 7.85786 150.142 4.5 146 4.5C141.858 4.5 138.5 7.85786 138.5 12C138.5 16.1421 141.858 19.5 146 19.5ZM146 15.9837C143.8 15.9837 142.016 14.2002 142.016 12C142.016 9.79985 143.8 8.01627 146 8.01627C148.2 8.01627 149.984 9.79985 149.984 12C149.984 14.2002 148.2 15.9837 146 15.9837Z"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
