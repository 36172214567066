import React from 'react';
import { Tarrifs } from './Tarrifs';
import { PaymentsList } from './Payments/PaymentsList';
import { useProfileStyles } from './styles/profileStyles';
import { Profile } from './Profile';

export function Settings() {
  const classes = useProfileStyles();
  return (
    <div className={classes.settingsWrapper}>
      <Tarrifs />
      <div className={classes.rightSide}>
        <Profile />
        <PaymentsList />
      </div>
    </div>
  );
}
