import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { NewButton } from '../../components/Button/NewButton';
import { PopUpWindow } from '../../components/PopUpWindow';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import moment from 'moment';
const LS = '__pel_';

export const PopupEditorList: FC = () => {
  const list = useTypeSelector((s) => s.editorList.list);
  const profile = useTypeSelector((s) => s.profile.profile);
  const [tariffPopup, setTarrifPopup] = useState(false);
  const [, setLocation] = useLocation();

  const setPopup = useCallback(
    (val: boolean) => {
      localStorage.setItem(
        LS,
        JSON.stringify({
          email: profile?.email,
          date: moment().add(7, 'days'),
        }),
      );
      setTarrifPopup(val);
    },
    [profile],
  );

  useEffect(() => {
    if (list.length > 0) {
      const data = localStorage.getItem(LS);
      if (data) {
        const info = JSON.parse(data);
        if (moment().isAfter(info.date)) {
          setPopup(true);
          return;
        }
      }
    }
  }, [list, setPopup]);

  return (
    <>
      {tariffPopup && profile?.account.subscription.tariff_type === 'TRIAL' ? (
        <PopUpWindow
          title="Понравилось? Ознакомьтесь с нашими тарифами!"
          closePopUp={() => setPopup(false)}
        >
          <p>
            Вы находитесь на пробном бесплатном тарифе, и вам не доступен полный
            функционал.
            <br />
            Чтобы получить больше возможностей — оплатите подписку. С тарифами
            можно
            <br />
            ознакомиться по кнопке ниже.
            <br />
            Если есть пожелания по работе конструктора — оставьте их в форме
            обратной связи.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              columnGap: 25,
              flexWrap: 'wrap',
              rowGap: 15,
            }}
          >
            <a
              href="//structura.app/crawler"
              target="_blank"
              style={{ fontSize: 16, color: '#3090E8' }}
              rel="noreferrer"
            >
              Как работает краулер
            </a>
            <NewButton
              caption="Обратная связь"
              onClick={() => setLocation('/feedback')}
            />
            <NewButton
              caption="Тарифы"
              primary
              onClick={() => setLocation('/profile/tariffs')}
            />
          </div>
        </PopUpWindow>
      ) : (
        <></>
      )}
    </>
  );
};
