import { combineReducers } from 'redux';
import { RegisrationReducer } from './registration';
import { SitemapsReducer } from './sitemaps';
import { FeedbackReducer } from './feedback';
import { SettingsReducer } from './settings/';
import { EditorReducer } from './editors';
import { SocialAuthReducer } from './socialAuth';
import { EditorListReducer } from './editorList';
import { DraftReducer } from './draft';
import { NewModelReducer } from './newmodel';
import { draftTempReducer } from './draftTemp';
import { ProfileReducer } from './profile';
import { PageReducer } from './page';

export const RootReducer = combineReducers({
  sitemaps: SitemapsReducer,
  registration: RegisrationReducer,
  feedback: FeedbackReducer,
  settings: SettingsReducer,
  editor: EditorReducer,
  editorList: EditorListReducer,
  socialLogin: SocialAuthReducer,
  draft: DraftReducer,
  modal: NewModelReducer,
  draftTemp: draftTempReducer,
  profile: ProfileReducer,
  page: PageReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
