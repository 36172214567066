import React, { useCallback, useEffect } from 'react';
import { getPaymentsAPI } from '../../../api/payments.api';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { useDispatch } from 'react-redux';
import { PaymentsReducerActionEnum } from '../../../store/reducers/settings/payments';
import moment from 'moment';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useProfileStyles } from '../styles/profileStyles';
import cx from 'clsx';
import * as XLSX from 'xlsx';

export const PaymentsList = () => {
  const classes = useProfileStyles();
  const Label = ['Дата и время', 'Сумма ₽'];
  const List = useTypeSelector((s) => s.settings.payments.list);
  // const List = [{ id: 1, confirm_ts: 123, total: 123 }, { id: 2, confirm_ts: 123, total: 123 }, { id: 3, confirm_ts: 123, total: 123 },
  //   { id: 4, confirm_ts: 123, total: 123 }, { id: 5, confirm_ts: 123, total: 123 }, { id: 6, confirm_ts: 123, total: 123 },
  //   { id: 7, confirm_ts: 123, total: 123 }, { id: 8, confirm_ts: 123, total: 123 }, { id: 9, confirm_ts: 123, total: 123 },
  // ];
  const dispatch = useDispatch();

  const getPayment = useCallback(async () => {
    const res = await getPaymentsAPI();
    dispatch({
      type: PaymentsReducerActionEnum.setPayments,
      payload: res.data,
    });
  }, [dispatch]);

  useEffect(() => {
    !List && getPayment();
  }, [List, getPayment]);

  const onDownloadFile = () => {
    const wsData = [
      Label,
      ...(List || []).map((row) => [
        moment(row.confirm_ts).format('DD.MM.YYYY'),
        row.total,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Транзакции');

    XLSX.writeFile(wb, 'История_транзакций.xlsx');
  };

  return (
    <Card
      className={cx(classes.card, 'card')}
      style={{ height: 'calc(100% - 330px)' }}
    >
      <div className={classes.cardHeader}>
        История транзакций
        <hr className={classes.hr} />
      </div>
      {List && List.length > 0 ? (
        <CardContent>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <div
                style={{
                  borderRadius: '8px',
                  overflowY: 'auto',
                  border: '1px solid #b8b8b8',
                  maxHeight: '368px',
                }}
              >
                <Table stickyHeader>
                  <TableHead style={{ backgroundColor: '#f2f2f2' }}>
                    <TableRow>
                      {Label.map((item, idx) => (
                        <TableCell
                          key={idx}
                          style={{
                            borderRight:
                              idx !== Label.length - 1
                                ? '1px solid #b8b8b8'
                                : 'none',
                            borderBottom: '1px solid #b8b8b8',
                            textAlign:
                              idx === Label.length - 1 ? 'right' : 'left',
                            height: '40px',
                            padding: '0 16px',
                            fontWeight: 'bold',
                          }}
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {List?.map((row, idx) => (
                      <TableRow key={row.id}>
                        <TableCell
                          key={idx}
                          style={{
                            borderRight: '1px solid #b8b8b8',
                            borderBottom:
                              idx !== List.length - 1
                                ? '1px solid #b8b8b8'
                                : 'none',
                            height: '40px',
                            padding: '0 16px',
                          }}
                        >
                          {moment(row.confirm_ts).format('DD.MM.YYYY')}
                        </TableCell>
                        <TableCell
                          key={idx}
                          style={{
                            borderRight: 'none',
                            borderBottom:
                              idx !== List.length - 1
                                ? '1px solid #b8b8b8'
                                : 'none',
                            textAlign: 'right',
                            height: '40px',
                            padding: '0 16px',
                          }}
                        >
                          {row.total}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Grid>
            <Grid item>
              <Button onClick={onDownloadFile} className={classes.blueBtn}>
                Скачать выписку
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <p
          style={{
            textAlign: 'center',
            paddingInline: '10px',
            whiteSpace: 'pre-wrap',
            wordBreak: 'normal',
          }}
        >
          Здесь будет история ваших финансовых операций
        </p>
      )}
    </Card>
  );
};
