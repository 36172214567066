import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const QuoteAndReviewBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.48633 5C8.17033 5 7.87455 5.14834 7.68555 5.40234L3.39453 11.166C3.13853 11.511 3 11.9294 3 12.3594V17.0273C3 18.1323 3.895 19.0273 5 19.0273H9C10.105 19.0273 11 18.1323 11 17.0273V13.0273C11 11.9233 10.105 11.0273 9 11.0273H5.99023L9.28906 6.5957C9.77906 5.9367 9.30933 5 8.48633 5ZM18.4668 5C18.1508 5 17.8531 5.14834 17.6641 5.40234L13.3945 11.1387C13.1385 11.4837 13 11.901 13 12.332V17C13 18.105 13.895 19 15 19H19C20.105 19 21 18.105 21 17V13C21 11.895 20.105 11 19 11H15.9902L19.2695 6.59766C19.7595 5.93766 19.2898 5 18.4668 5Z"
          fill={blockDefaultColor}
        />
        <rect x="32" width="124" height="3" rx="1.5" fill={blockDefaultColor} />
        <rect
          x="32"
          y="7"
          width="120.242"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="32"
          y="14"
          width="124"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
        <rect
          x="32"
          y="21"
          width="52.6061"
          height="3"
          rx="1.5"
          fill={blockDefaultColor}
        />
      </svg>
    </Block>
  );
});
