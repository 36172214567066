import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  commentsWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: -28,
    width: 25,
    height: 25,
    transition: 'All 0.3s ease-in-out',
    backgroundColor: '#3090E8',
    border: '1px solid #3090E8',
    borderRadius: '0px 15px 15px 0',
    cursor: 'pointer',
    '& svg': {
      transform: 'translateX(1px)',
      '& path': {
        fill: 'white',
      },
    },
    '&:hover': {
      background: 'white',
      '& svg > path': {
        fill: '#3090E8',
      },
    },
  },
  textArea: {
    fontSize: 16,
    fontFamily: 'Roboto',
    lineHeight: '20px',
    '&:placeholder': {
      fontSize: 16,
      fontFamily: 'Roboto',
      lineHeight: '20px',
    },
  },
  noComment: {
    border: '1px solid #353E47 !important',
    outline: 'none !important',
    fontSize: 16,
    fontFamily: 'Roboto',
    lineHeight: '20px',
  },
  commentsList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    marginBottom: 20,
    maxHeight: 'calc(100vh - 200px)',
    overflowX: 'auto',
    overflowY: 'auto',
    'scrollbar-width': '7px',
    'scrollbar-color': '#353E47 transparent',
    '&::-webkit-scrollbar': {
      width: 9,
      height: 9,
    },
    '&::-webkit-scrollbar-track': {
      width: 4,
      background: 'transparent',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#353E47',
      borderRadius: 0,
      cursor: 'pointer',
      borderRight: '4px solid white',
      '&:hover': {
        backgroundColor: '#3090E8',
        cursor: 'pointer !important',
      },
    },
  },
  commentsListItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    '& svg': {
      cursor: 'pointer',
      border: '1px solid transparent',
      borderRadius: '0%',
      transition: 'all .3s linear',
      '&:hover': {
        border: '1px solid red',
        borderRadius: '50%',
      },
    },
  },
  commentsBlock: {
    padding: '8px 12px 12px',
    position: 'absolute',
    top: 20,
    left: 208,
    width: 320,
    minHeight: 84,
    height: 'auto',
    background: '#FCFEFF',
    boxShadow:
      '0px 8px 16px rgba(51, 51, 51, 0.2), 0px 2px 6px rgba(51, 51, 51, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: 8,
    zIndex: 100000,
    cursor: 'default',
    '& h2': {
      fontWeight: 500,
      fontSize: 18,
      color: '#353E47',
      margin: 0,
      padding: 0,
    },
  },
  commentHeader: {
    height: 32,
    '& h2': {
      fontSize: 18,
      fontFamily: 'Roboto',
      fontWeight: 500,
      lineHeight: '22px',
    },
  },
  commentBlockClose: {
    right: 6,
    position: 'absolute',
    top: 3,
    cursor: 'pointer',
  },
  svg: {
    cursor: 'pointer',
    transition: 'All 0.3s ease-in-out',
    position: 'relative',
    top: 1,
    right: 0,
    '& path': {
      transition: 'All 0.3s ease-in-out',
      fill: 'white',
    },
  },
  setCommentsWrapper: {
    backgroundColor: '#03336E !important',
    border: '1px solid #03336E !important',
    borderRadius: '0px 15px 15px 0',
    cursor: 'pointer',
    '& svg': {
      transform: 'translateX(1px)',
      '& path': {
        fill: 'white',
      },
    },
    '&:hover': {
      background: 'white !important',
      '& svg > path': {
        fill: '#03336E !important',
      },
    },
  },
}));
