import React, { memo } from 'react';
import { IBlock } from '../../../../store/reducers/editors';
import { Block } from './Block';
import useStyles from '../../../../views/EditorItem/styles';
import { blockDefaultColor } from './blockColor';

export const PartnersBlock = memo((props: IBlock) => {
  const cc = useStyles();
  return (
    <Block {...props} className={cc.headerBlock}>
      <svg
        width="156"
        height="24"
        viewBox="0 0 156 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.6602 5.34191C24.2905 5.34526 23.9224 5.42609 23.5795 5.58442L22.3002 6.17524C22.256 6.16896 22.2114 6.16623 22.1667 6.1671H14.6667V14.5004H15.5001L20.5636 18.9308C20.9787 19.2946 21.5132 19.4939 22.0642 19.4939C22.6672 19.4939 23.2482 19.2523 23.6739 18.8266H23.6755L26.3253 16.1671C26.328 16.1671 26.3307 16.1671 26.3334 16.1671H32.1667V5.84484L28.8741 6.94184L25.7458 5.56489C25.4 5.41266 25.03 5.33856 24.6602 5.34191ZM24.6749 7.00857C24.8108 7.00709 24.9478 7.03385 25.0753 7.08995L28.7927 8.72569L30.5001 8.15766V14.5004H27.9854L28.1433 14.3426C29.0862 13.3971 29.0299 11.8206 28.0245 10.9425L24.8393 8.16092L22.1488 9.14236H22.1472C21.7688 9.28033 21.363 9.12078 21.1772 8.76313L21.0795 8.57596L24.2778 7.09809C24.4041 7.03976 24.539 7.01005 24.6749 7.00857ZM16.3334 7.83377H18.8155L19.6977 9.53136C20.2635 10.6204 21.5653 11.1285 22.7185 10.7081L24.4845 10.0636L26.9291 12.1974C27.2271 12.4576 27.2421 12.8862 26.9633 13.1658H26.9617L22.4955 17.6482C22.3762 17.7676 22.2329 17.8273 22.0642 17.8273C21.9153 17.8273 21.7737 17.7753 21.6622 17.6775L16.3334 13.0144V7.83377Z"
          fill={blockDefaultColor}
        />
        <rect
          x="1"
          y="1"
          width="44.6667"
          height="22"
          rx="5"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path
          d="M79.6602 5.34191C79.2905 5.34526 78.9224 5.42609 78.5795 5.58442L77.3002 6.17524C77.256 6.16896 77.2114 6.16623 77.1667 6.1671H69.6667V14.5004H70.5001L75.5636 18.9308C75.9787 19.2946 76.5132 19.4939 77.0642 19.4939C77.6672 19.4939 78.2482 19.2523 78.6739 18.8266H78.6755L81.3253 16.1671C81.328 16.1671 81.3307 16.1671 81.3334 16.1671H87.1667V5.84484L83.8741 6.94184L80.7458 5.56489C80.4 5.41266 80.03 5.33856 79.6602 5.34191ZM79.6749 7.00857C79.8108 7.00709 79.9478 7.03385 80.0753 7.08995L83.7927 8.72569L85.5001 8.15766V14.5004H82.9854L83.1433 14.3426C84.0862 13.3971 84.0299 11.8206 83.0245 10.9425L79.8393 8.16092L77.1488 9.14236H77.1472C76.7688 9.28033 76.363 9.12078 76.1772 8.76313L76.0795 8.57596L79.2778 7.09809C79.4041 7.03976 79.539 7.01005 79.6749 7.00857ZM71.3334 7.83377H73.8155L74.6977 9.53136C75.2635 10.6204 76.5653 11.1285 77.7185 10.7081L79.4845 10.0636L81.9291 12.1974C82.2271 12.4576 82.2421 12.8862 81.9633 13.1658H81.9617L77.4955 17.6482C77.3762 17.7676 77.2329 17.8273 77.0642 17.8273C76.9153 17.8273 76.7737 17.7753 76.6622 17.6775L71.3334 13.0144V7.83377Z"
          fill={blockDefaultColor}
        />
        <rect
          x="55.6667"
          y="1"
          width="44.6667"
          height="22"
          rx="5"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
        <path
          d="M134.66 5.34191C134.291 5.34526 133.922 5.42609 133.58 5.58442L132.3 6.17524C132.256 6.16896 132.211 6.16623 132.167 6.1671H124.667V14.5004H125.5L130.564 18.9308C130.979 19.2946 131.513 19.4939 132.064 19.4939C132.667 19.4939 133.248 19.2523 133.674 18.8266H133.676L136.325 16.1671C136.328 16.1671 136.331 16.1671 136.333 16.1671H142.167V5.84484L138.874 6.94184L135.746 5.56489C135.4 5.41266 135.03 5.33856 134.66 5.34191ZM134.675 7.00857C134.811 7.00709 134.948 7.03385 135.075 7.08995L138.793 8.72569L140.5 8.15766V14.5004H137.985L138.143 14.3426C139.086 13.3971 139.03 11.8206 138.024 10.9425L134.839 8.16092L132.149 9.14236H132.147C131.769 9.28033 131.363 9.12078 131.177 8.76313L131.08 8.57596L134.278 7.09809C134.404 7.03976 134.539 7.01005 134.675 7.00857ZM126.333 7.83377H128.816L129.698 9.53136C130.264 10.6204 131.565 11.1285 132.719 10.7081L134.484 10.0636L136.929 12.1974C137.227 12.4576 137.242 12.8862 136.963 13.1658H136.962L132.496 17.6482C132.376 17.7676 132.233 17.8273 132.064 17.8273C131.915 17.8273 131.774 17.7753 131.662 17.6775L126.333 13.0144V7.83377Z"
          fill={blockDefaultColor}
        />
        <rect
          x="110.333"
          y="1"
          width="44.6667"
          height="22"
          rx="5"
          stroke={blockDefaultColor}
          strokeWidth="2"
        />
      </svg>
    </Block>
  );
});
