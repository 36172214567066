import React, { FC, useCallback, useEffect, useState } from 'react';

import { IEditorListItem } from '../../../store/reducers/editorList';
import { ProjectCardMoreBtn } from '../../ProjectCardMoreBtn';
import { ProjectCardStyle } from './ProjectCardStyle';
import moment from 'moment';
import { Link } from 'wouter';
import { IconsOutline } from '../../IconOutliner';
import { scrapeMode, sourceTypes } from '../../../api/siteDraft';

export interface IProjectCardProps extends IEditorListItem {
  className?: string;
}

const SiteType: FC<{ source: sourceTypes | undefined }> = ({ source }) => {
  switch (source) {
    case 'MANUAL':
      return <span>С чистого листа: </span>;
    case 'SITE':
      return <span>На основе сайта: </span>;
    case 'TEMPLATE':
      return <span>Из шаблона: </span>;
    default:
      return <></>;
  }
};

const ScrapeMode: FC<{ scrape_mode: scrapeMode | undefined }> = ({
  scrape_mode,
}) => {
  switch (scrape_mode) {
    case 'CRAWL':
      return <span>фактическая</span>;
    case 'SITEMAP':
      return <span>из сайтмапа</span>;
    default:
      <></>;
  }
  return <></>;
};

export const ProjectCard = (props: IProjectCardProps) => {
  const cc = ProjectCardStyle();
  const [err, setErr] = useState(false);
  const getError = useCallback(() => {
    if (props.scrape_error === 'SITEMAP_NOT_FOUND') {
      return 'Карты сайта не существует. Создайте ее и попробуйте снова.';
    }
    if (props.scrape_error === 'SITE_NOT_FOUND') {
      return 'Такого сайта не существует. Введите другой URL.';
    }
    if (props.scrape_error === 'SITEMAP_TOO_BIG') {
      return 'Для вашего тарифа этот сайт слишком большой. Попробуйте визуализировать сайт поменьше, либо смените тариф.';
    }
    if (props.scrape_error === 'SITE_NOT_AVAILABLE') {
      return 'Мы не можем попасть на этот сайт. Попробуйте через некоторое время.';
    }
    if (props.scrape_error === 'FORBIDDEN') {
      return 'Этот сайт запрещен к обходу.';
    }
    if (props.scrape_error === 'INTERNAL') {
      return 'Что-то пошло не так. Попробуйте через некоторое время, или обратитесь в поддержку.';
    }
    if (props.scrape_error === 'TARIFF_NOT_ACTIVE') {
      return 'Недоступно. Необходимо обновить тариф.';
    }
    if (props.scrape_error === 'SITEMAP_NOT_EXIST') {
      return 'Карта сайта недоступна, Попробуйте позже.';
    }
    if (props.scrape_error === 'MALFORMED_SITEMAP') {
      return 'Неверное форматирование карты сайта. Проверьте файл sitemap.xml';
    }
  }, [props.scrape_error]);

  useEffect(() => {
    props.scrape_error !== null && setErr(true);
    const time = setTimeout(() => {
      setErr(false);
    }, 8000);

    return () => {
      clearTimeout(time);
    };
  }, [props.scrape_error]);

  return (
    <div
      className={`${cc.project_card_wrapper} ${
        props.scrape_status === 'FAIL' ? cc.error : ''
      }
      ${props.scrape_status === 'IN_PROGRESS' ? cc.in_process : ''}
      `}
    >
      <div
        className={cc.project_card_image}
        style={{
          backgroundImage: `url(data:image/png;base64,${props.preview})`,
        }}
      >
        {props.scrape_status === 'FAIL' && (
          <div
            className={cc.title_bottom}
            style={{ position: 'absolute', top: 5, left: 5 }}
            data-title={getError()}
          >
            <IconsOutline
              types="warning"
              defaultColor="#EA4848"
              noHover
              width={32}
              height={32}
            />
          </div>
        )}
        {props.scrape_status === 'IN_PROGRESS' && (
          <div style={{ position: 'absolute', top: 3, left: 3 }}>
            <IconsOutline
              types="loading"
              defaultColor="#EA4848"
              noHover
              width={32}
              height={32}
            />
          </div>
        )}
        {(props.scrape_status === 'FAIL' ||
          props.scrape_status === 'IN_PROGRESS') && (
          <div
            className={`${cc.link}`}
            style={{
              backgroundColor: `${
                props.scrape_status === 'IN_PROGRESS'
                  ? 'rgba(242,153,74, 0.2)'
                  : 'rgba(234, 72, 72, 0.2)'
              }`,
            }}
          ></div>
        )}
        {props.scrape_status !== 'FAIL' &&
          props.scrape_status !== 'IN_PROGRESS' && (
            <Link to={`/editor/${props.id}`}>
              <div className={cc.link}></div>
            </Link>
          )}

        <ProjectCardMoreBtn {...props} />
      </div>
      {(props.scrape_status === 'FAIL' ||
        props.scrape_status === 'IN_PROGRESS') && (
        <div className={cc.project_card_captions} style={{ cursor: 'pointer' }}>
          <h4 className={cc.name}>{props.name}</h4>
          <h4 className={cc.name}>
            <SiteType source={props.source} />
            <ScrapeMode scrape_mode={props.scrape_mode} />
          </h4>
          <hr
            color={
              props.scrape_status === 'FAIL'
                ? '#EA4848'
                : props.scrape_status === 'IN_PROGRESS'
                ? '#F2994A'
                : ''
            }
          />
          <div className={cc.project_card__desc}>
            {props.scrape_status === 'FAIL' && (
              <span className={cc.title} data-title={getError()}>
                Ошибка
              </span>
            )}
            {props.scrape_status === 'IN_PROGRESS' && (
              <span style={{ color: '#F2994A' }}>В процессе создания</span>
            )}
            {err ? <span className={cc.titleError}>{getError()}</span> : <></>}
          </div>
        </div>
      )}
      {props.scrape_status !== 'FAIL' &&
        props.scrape_status !== 'IN_PROGRESS' && (
          <Link to={`/editor/${props.id}`}>
            <div
              className={cc.project_card_captions}
              style={{ cursor: 'pointer' }}
            >
              <h4 className={cc.name}>{props.name}</h4>
              <h4>
                <SiteType source={props.source} />
                <ScrapeMode scrape_mode={props.scrape_mode} />
              </h4>
              <hr />
              <div className={cc.project_card__desc}>
                <span>Страниц: {props.pages_count}</span>
                {(props.scrape_status === 'SUCCESS' ||
                  props.scrape_status === null) && (
                  <span>
                    {moment(props.update_ts).format('HH:mm - DD.MM.YY')}
                  </span>
                )}
                {err && props.scrape_error ? (
                  <span className={cc.titleError}>{getError()}</span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Link>
        )}
    </div>
  );
};
