import { ITariff } from '../../../api/tarrifs.api';

interface SettingTarrifsInitioalState {
  tarrifs: ITariff[];
  tarrif: ITariff;
  buyTarrif: any;
}
const tarrif: ITariff = {
  id: '',
  name: '',
  description: '',
  duration_months: 0,
  cost_rub: 0,
};
const tarrifs: ITariff[] = [];

const IS: SettingTarrifsInitioalState = {
  tarrifs,
  tarrif,
  buyTarrif: null,
};

export enum SettingTarrifsEnum {
  getTarrifs = 'GET_TARRIFS',
  getTarrifById = 'GET_TARRIF_BY_ID',
  buyTarrifByID = 'BUY_TARRIF_BY_ID',
}

interface ISettingTarrifsGetTarrifsAction {
  type: SettingTarrifsEnum.getTarrifs;
  payload: ITariff[];
}
interface ISettingTarrifsGetTarrifByIdAction {
  type: SettingTarrifsEnum.getTarrifById;
  payload: ITariff;
}
interface ISettingTarrifsBuyTarrifByIDAction {
  type: SettingTarrifsEnum.buyTarrifByID;
  payload: any;
}

export type SettingTarrifsAction =
  | ISettingTarrifsBuyTarrifByIDAction
  | ISettingTarrifsGetTarrifByIdAction
  | ISettingTarrifsGetTarrifsAction;

export const SettingTarrifsReducer = (
  state: SettingTarrifsInitioalState = IS,
  action: SettingTarrifsAction,
) => {
  switch (action.type) {
    case SettingTarrifsEnum.getTarrifs: {
      return { ...state, tarrifs: action.payload };
    }
    case SettingTarrifsEnum.getTarrifById: {
      return { ...state, tarrif: action.payload };
    }
    case SettingTarrifsEnum.buyTarrifByID: {
      return { ...state, buyTarrif: action.payload };
    }

    default:
      return state;
  }
};
