import React, { useCallback } from 'react';
import { Button } from '../../../components/Button/Button';
import { useProfile } from '../../../hooks/useProfile';
import { useLocation } from 'wouter';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logoutWrapper: {
    borderRadius: 10,
    border: '1px solid var(--red-stroke, rgba(255, 80, 80, 0.15))',
    background: 'var(--red-fill, rgba(255, 80, 80, 0.05))',
    '@media (max-width: 498px)': {
        width: '100%',
      },
  },
  logoutWrapperBtn: {
    fontWeight: 400,
    '@media (max-width: 498px)': {
        width: '100%',
      },
  },
}));

export const Logout = () => {
  const cc = useStyles();
  const [, setLocation] = useLocation();
  const { removeProfile } = useProfile();

  const signOut = useCallback(async () => {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    removeProfile();
    setLocation('/login');
  }, [setLocation, removeProfile]);

  return (
    <div className={cc.logoutWrapper}>
      <Button className={cc.logoutWrapperBtn} onClick={signOut} primary transparent>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M4.50347 17.5001C4.08254 17.5001 3.72624 17.3542 3.43457 17.0626C3.1429 16.7709 2.99707 16.4146 2.99707 15.9937V4.42316C2.99707 4.00222 3.1429 3.64591 3.43457 3.35425C3.72624 3.06258 4.08254 2.91675 4.50347 2.91675H10.0964V4.16673H4.50347C4.43936 4.16673 4.38059 4.19344 4.32717 4.24685C4.27374 4.30028 4.24703 4.35905 4.24703 4.42316V15.9937C4.24703 16.0578 4.27374 16.1165 4.32717 16.17C4.38059 16.2234 4.43936 16.2501 4.50347 16.2501H10.0964V17.5001H4.50347ZM13.4458 13.7661L12.5804 12.8622L14.6092 10.8334H7.5964V9.58341H14.6092L12.5804 7.55458L13.4458 6.65077L17.0034 10.2084L13.4458 13.7661Z"
            fill="#FF5050"
          />
        </svg>
        Выйти
      </Button>
    </div>
  );
};
