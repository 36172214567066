import React, { useState } from 'react';
import { IconsOutline } from '../IconOutliner';
import { ExampleLinkStyles } from './ExampleLinkStyles';

export interface IExampleLinkProps {
  caption: string;
  link: string;
}

const iconColor = '#6F8194';
const hoverColor = '#3090E8';

export const ExampleLink = (props: IExampleLinkProps) => {
  const cc = ExampleLinkStyles();
  const [color, setColor] = useState(iconColor);

  const onOver = () => {
    setColor(hoverColor);
  };
  const onLeave = () => {
    setColor(iconColor);
  };

  return (
    <a
      href={props.link}
      target="_blank"
      rel="noreferrer"
      className={cc.example_link}
      onMouseOver={onOver}
      onMouseLeave={onLeave}
    >
      <span style={{ color }}>{props.caption}</span>
      <IconsOutline types="new_window" noHover defaultColor={color} />
    </a>
  );
};
