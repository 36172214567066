import React, { useCallback } from 'react';
import { useLocation, useRoute } from 'wouter';
import useStyles from '../../styles/index';
import { NewFeedback } from '../../NewFeedback';
import { Devider } from './devider';
import { NewButton } from '../../../components/Button/NewButton';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { TemplateButton } from '../../../components/TemplateButton/TemplateButton';
import { useDraftTemp } from '../../../hooks/useDraftTemp';
import cx from 'clsx';
import { Menu } from '../../../components/Menu/Menu';

export const NewProfileBar = () => {
  const [location] = useLocation();
  const classes = useStyles();
  const regProfile =
    /\/profile|\/feedback|\/feedback\/|\/template|\/template\//g;
  const regFeedback = /\/feedback|\/feedback\//g;
  const [, setLocation] = useLocation();
  const tempItem = useTypeSelector((s) => s.draftTemp.tempItem);
  const [, params] = useRoute('/template/:id');
  const { TempUseById } = useDraftTemp();

  const goToProjects = useCallback(async () => {
    setLocation('/editor');
  }, [setLocation]);

  const selectTemplate = useCallback(() => {
    params && params.id && TempUseById(params.id);
  }, [params, TempUseById]);

  return (
    <>
      {regProfile.test(location) && (
        <div
          className={cx(
            classes.btnGroup,
            `${
              /\/profile|\/feedback|\/feedback\//.test(location)
                ? classes.btnGroupProfile
                : ''
            } ${
              /\/template|\/template\//.test(location)
                ? classes.btnGroupTemplate
                : ''
            }`,
          )}
        >
          <div className="item">
            <NewButton
              onClick={goToProjects}
              caption="К проектам"
              beforeIcon="arrow_left"
              height={60}
              noBorder
              className={classes.goToAll}
              style={{ width: 166 }}
            />
            <Devider color="gray" />
            {!regFeedback.test(location) && <NewFeedback />}
          </div>

          <div className="item fullWidth">
            {/feedback/g.test(location) && (
              <h2 className={classes.pageCaption}>Форма обратной связи</h2>
            )}
            {/profile/g.test(location) && (
              <h2 className={classes.pageCaption}>Профиль и Подписка</h2>
            )}
            {/\/template$/g.test(location) && (
              <h2 className={classes.pageCaption}>Все шаблоны</h2>
            )}
            {/\/template\//g.test(location) && tempItem && (
              <>
                <div
                  className={classes.pageCaption}
                  style={{
                    height: 55,
                    display: 'flex',
                    alignContent: 'center',
                    position: 'relative',
                  }}
                >
                  <span>{tempItem.name}</span>
                  <TemplateButton />
                </div>
              </>
            )}
          </div>
          <div className="item">
            {/\/template\//g.test(location) && (
              <div className={classes.redBtn} onClick={selectTemplate}>
                <span>Выбрать шаблон</span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.586 0.586C14.211 0.211 13.702 0 13.172 0H2C0.89 0 0 0.9 0 2V16C0 17.105 0.895 18 2 18H16C17.1 18 18 17.1 18 16V4.828C18 4.298 17.789 3.789 17.414 3.414L14.586 0.586ZM9 16C7.34 16 6 14.66 6 13C6 11.34 7.34 10 9 10C10.66 10 12 11.34 12 13C12 14.66 10.66 16 9 16ZM11 6H3C2.448 6 2 5.552 2 5V3C2 2.448 2.448 2 3 2H11C11.552 2 12 2.448 12 3V5C12 5.552 11.552 6 11 6Z"
                    fill="#FFFFFF"
                  ></path>
                </svg>
              </div>
            )}
            <Devider color="gray" />
            <Menu />
          </div>
        </div>
      )}
    </>
  );
};
